<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">{{ $t('ui.impuls') }}</span>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-container grid>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          lg="2"
          v-for="detektor in detektori" :key="detektor.id"
          style="text-align:center"
        >
          <v-sheet
            rounded="sm"
            min-height="25"
            color="grey"
          >
            <strong style="color:#00000099">{{detektor.id}}</strong>

              <v-progress-linear
                v-model="detektor.percentValue"
                :color="detektor.color"
                height="25"
              >
              </v-progress-linear>
            </v-sheet>

        </v-col>
      </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'

export default {
  name: 't1DetektoriJediniceCard',
  mixins: [T1Mixin],
  data () {
    return {
      loading: false,
      DETEKTORI_IMPULS: null,
      getT1DataId: null
    }
  },
  computed: {
    detektori () {
      if (!this.DETEKTORI_IMPULS) return []

      let id = 0
      let impuls = 0
      let percentValue = 0
      let _detektori = this.DETEKTORI_IMPULS
      let detObjs = []
      let color = 'light-blue darken-2'

      for (let i = 0; i < _detektori.length; i++) {
        id = id + 1
        impuls = _detektori[i]
        percentValue = 0
        if (impuls > 0 ) {
          percentValue = 100
        }

        if (impuls > 0 && impuls < 2) {
          color = '#8FC33A'
        } else if (impuls > 0 && impuls < 4) {
          color = '#FDD017'
        } else if (impuls > 0 && impuls < 5) {
          color = '#3399FF'
        } else {
          color = '#E0E0E0'
        }

        detObjs.push({
          'id': id,
          'impuls': _detektori[i],
          'percentValue': percentValue,
          'color': color
        })
      }

      return detObjs
    }
  },
  methods: {
    init () {
      // this.initT1Data()
    },
    getT1Data () {
      let promises = []

      // promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x41]))
      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, this.t1.DETEKTORSKI_IMPULS]))

      Promise.all(promises)
        .then(([DETEKTORI_IMPULS]) => {
          console.log(DETEKTORI_IMPULS)
          this.DETEKTORI_IMPULS = DETEKTORI_IMPULS
        })
    }
  },
  created () {
    this.init()
    this.getT1DataId = setInterval(this.getT1Data, 900)
  },
  destroyed () {
    if (this.getT1DataId) {
      clearInterval(this.getT1DataId)
    }
  }
}
</script>
