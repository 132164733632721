<template>

  <v-container fluid grid-list-md>

    <v-row>
      <v-col>
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">PTP {{ this.PTP }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="isAuthenticated" @click="submit" color="primary">{{$t('ui.save')}}</v-btn>
          </v-toolbar>
          <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>
      </v-card>
      </v-col>
    </v-row>

    <v-row v-if="PROGRAM_DATA">
      <v-col xs="12" lg="4">
        <v-card>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td><strong>PTP</strong></td>
                    <td>{{PROGRAM_DATA.ptp}}</td>
                  </tr>

                  <!--<tr>
                    <td><strong>NAME</strong></td>
                    <td>{{PROGRAM_DATA.name}}</td>
                  </tr>-->

                  <tr>
                    <td><strong>{{ $t('ui.cycle_length') }} [s]</strong></td>
                    <td>
                      <v-text-field
                        v-model="PROGRAM_DATA.cycle"
                      ></v-text-field>
                    </td>
                  </tr>

                  <tr>
                    <td><strong>{{ $t('ui.offset') }} [s]</strong></td>
                    <td>
                      <v-text-field
                        v-model="PROGRAM_DATA.offset"
                      ></v-text-field>
                    </td>
                  </tr>

                  <tr>
                    <td><strong>{{ $t('ui.fixed_time_control') }}</strong></td>
                    <td>
                      <v-select
                        v-model="PROGRAM_DATA.ftc"
                        :items="yesnoSelect"
                      >
                      </v-select>
                    </td>
                  </tr>

                  <tr>
                    <td><strong>{{ $t('ui.amber_flash') }}</strong></td>
                    <td>
                      <v-select
                        v-model="PROGRAM_DATA.trz"
                        :items="yesnoSelect"
                      >
                      </v-select>
                    </td>
                  </tr>

                  <tr>
                    <td><strong>{{ $t('ui.signals_off') }}</strong></td>
                    <td>
                      <v-select
                        v-model="PROGRAM_DATA.signals_off"
                        :items="yesnoSelect"
                      >
                      </v-select>
                    </td>
                  </tr>

                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xs="12" lg="8">
        <v-card>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <th class="text-left" width="10%">{{ $t('ui.group') }}</th>
                  <th class="text-left">{{ $t('ui.start') }} [s]</th>
                  <th class="text-left">{{ $t('ui.stop') }} [s]</th>
                  <th class="text-left">{{ $t('ui.demand') }}</th>
                  <th class="text-left">{{ $t('ui.privilege') }} [s]</th>
                </tbody>
                <tbody>
                  <tr v-for="grupa in grupe" :key="grupa.id">
                    <td>{{ grupa.id }}</td>
                    <td>
                      <v-text-field
                        v-model="grupa.start"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-model="grupa.stop"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-select
                        v-model="grupa.zahtjev"
                        :items="yesnoSelect"
                      >
                      </v-select>
                    </td>
                    <td>
                      <v-text-field
                        v-model="grupa.privilegija"
                      ></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>

</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'

export default {
  name: 'McoProgramatorEditPage',
  mixins: [AuthMixin, T1Mixin],
  props: ['PTP'],
  data () {
    return {
      loading: true,
      PROGRAM_DATA: null,
      BROJ_GRUPA: 0,
      PROGRAMS: [],
      yesnoSelect: [
        {
          'text': this.$t('ui.yes'),
          'value': 1
        },
        {
          'text': this.$t('ui.no'),
          'value': 0
        }
      ],
      grupe: []
    }
  },
  methods: {
    init () {

      return new Promise((resolve, reject) => {

        this.getBrojGrupa()
          .then((BROJ_GRUPA) => {
            this.BROJ_GRUPA = BROJ_GRUPA
          })
          .then(this.initT1Data)
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    maybeNull(data, def) {
      if (data === 255) {
        if (def === 0 || def) {
          return def
        }
        return null
      }
      return parseInt(data)
    },
    initT1Data () {
      return new Promise((resolve, reject) => {
        this.grupe = []
        let ptp = parseInt(this.PTP)
        let promises = []

        promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x54, ptp]))

        for (let i = 1; i <= this.BROJ_GRUPA; i++) {
          let cmd = [this.t1.CPS, this.t1.READ, 0x53, ptp, i]
          promises.push(this.t1Write(cmd))
        }

        Promise.all(promises)
          .then((responses) => {
            let NMCO_DATA = responses[0]

            this.PROGRAM_DATA = {
              'ptp':          this.maybeNull(NMCO_DATA[0]),
              'name':         null,
              'cycle':        this.maybeNull(NMCO_DATA[1]),
              'offset':       this.maybeNull(NMCO_DATA[2]),
              'ftc':          this.maybeNull(NMCO_DATA[3]),
              'trz':          this.maybeNull(NMCO_DATA[4]),
              'signals_off':  this.maybeNull(NMCO_DATA[5])
            }

            if (this.PROGRAM_DATA.ftc > 1) {
              this.PROGRAM_DATA.ftc = 0
            }

            if (this.PROGRAM_DATA.trz > 1) {
              this.PROGRAM_DATA.trz = 0
            }

            if (this.PROGRAM_DATA.signals_off > 1) {
              this.PROGRAM_DATA.signals_off = 0
            }

            for (let i = 1; i <= this.BROJ_GRUPA; i++) {
              let grupaData = {
                'ptp':          this.maybeNull(responses[i][0]),
                'id':           this.maybeNull(responses[i][1]),
                'start':        this.maybeNull(responses[i][2]),
                'stop':         this.maybeNull(responses[i][3]),
                'zahtjev':      this.maybeNull(responses[i][4]),
                'privilegija':  this.maybeNull(responses[i][5])
              }

              if (grupaData.zahtjev > 1) {
                grupaData.zahtjev = 1
              }

              this.grupe.push(grupaData)
            }

            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    submit () {
      this.loading = true
      let promises = []

      let ptpAdresa = this.PROGRAM_DATA.ptp

      let nmcoOrderData = [
        this.t1.CPS,
        this.t1.WRITE,
        0x54,
        ptpAdresa,
        parseInt(this.PROGRAM_DATA.cycle),
        parseInt(this.PROGRAM_DATA.offset),
        parseInt(this.PROGRAM_DATA.ftc),
        parseInt(this.PROGRAM_DATA.trz),
        parseInt(this.PROGRAM_DATA.signals_off)
      ]

      promises.push(this.t1Write(nmcoOrderData))

      for (let i = 0; i < this.grupe.length; i++) {
        let grupa = this.grupe[i]
        let cmd = [
          this.t1.CPS,
          this.t1.WRITE,
          0x53,
          parseInt(grupa.ptp),
          parseInt(grupa.id),
          parseInt(grupa.start),
          parseInt(grupa.stop),
          parseInt(grupa.zahtjev),
          parseInt(grupa.privilegija)
        ]
        promises.push(this.t1Write(cmd))
      }

      Promise.all(promises)
        .then(this.initT1Data)
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    this.loading = true
    this.init()
      .catch(() => {
        this.$EventBus.$emit('AppSnackbar/show', {
          color: 'error',
          text: this.$t('ui.error')
        })
      })
      .finally(() => {
        this.loading = false
      })
  }
}
</script>
