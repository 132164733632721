<template>
  <v-container fluid grid-list-md>

    <v-row>
      <v-col xs="12" md="2">
        <v-card>
            <v-card-text>
                <v-simple-table>
                  <template v-slot:default>                                        
                    <tbody>
                        <tr>
                            <td><strong>ID</strong></td> <td>{{ config.DEVICE_CODE }}</td>                      
                        </tr>                   
                        <tr>
                            <td><strong>NAME</strong></td> <td>{{ config.DEVICE_NAME }}</td>                                                  
                        </tr>
                        <tr>
                            <td><strong>TYPE</strong></td> <td>{{ config.DEVICE_TYPE }}</td>                                                  
                        </tr>
                        <tr>
                            <td><strong>SIGNAL GROUPS</strong></td> <td>{{ brojGrupa }}</td>                                                  
                        </tr>
                    </tbody>
                  </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import ConfigMixin from '@/mixins/ConfigMixin'

export default {
  name: 't1GainPage',
  mixins: [ConfigMixin, T1Mixin],
  data () {
    return {
        brojGrupa: 0,
        greske: null,
        config: {
            DEVICE_CODE: null,
            DEVICE_NAME: null,
            DEVICE_TYPE: 'T-1'        
        },
    }
  },
  computed: {    
    stanjeSignala () {
      return this.$store.getters['t1/stanjeSignala']
    }    
  },
  methods: {
    init () {
      this.initT1Data()
      this.initConfigData()
    },
    initT1Data () {
      this.loading = true
      var promises = []

      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, this.t1.BROJ_GRUPA]))
      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, this.t1.GRESKE]))      

      Promise.all(promises)
        .then(([BROJ_GRUPA, GRESKE]) => {
          this.brojGrupa = BROJ_GRUPA[0]
          this.greske = GRESKE                    
        })
        .finally(() => {
          this.loading = false
        })
    }    
  },
  created () {
    this.init()    
  },
  destroyed () {
    
  }
}
</script>
