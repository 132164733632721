<template>
  <v-container fluid grid-list-md>

    <v-row>
      <v-col xs="12" md="6">
        <app-t1-postavke-card :postavke="postavke"></app-t1-postavke-card>
      </v-col>
      <v-col xs="12" md="6">
        <app-t1-dimmer-card :postavke="postavke"></app-t1-dimmer-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col xs="12" md="6">
        <app-t1-pin-card v-if="isAuthenticated"></app-t1-pin-card>
      </v-col>
      <v-col xs="12" md="6">
        <app-gpio-switch-card v-if="isAuthenticated"></app-gpio-switch-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col xs="12" md="6">
        <app-t1-akusticki-signali-card></app-t1-akusticki-signali-card>
      </v-col>
      <v-col xs="12" md="6">
        <app-t1-sync-time-card v-if="isAuthenticated"></app-t1-sync-time-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import Vue from 'vue'
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'

export default {
  name: 't1PostavkePage',
  mixins: [T1Mixin, AuthMixin],
  data () {
    return {
      loading: false,
      postavke: {
        TEMP: null,
        NAPON: null,
        UPTIME: null,
        VRIJEME: null,
        LDR: null
      },
      getT1DataId: null
    }
  },
  methods: {
    init () {
      this.getPostavke()
    },
    getPostavke () {
      let promises = []
      // Nemoj ponovo slati upise, ako se vec šalju
      if (this.loading) return

      this.loading = true

      // Temperatura
      promises.push(this.getTemperaturaCPS())
      // Napon
      promises.push(this.getNapon())
      // Uptime
      promises.push(this.getUptime())
      // Vrijeme
      promises.push(this.getCpsTime())
      // Dimmer (LDR)
      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x38]))

      Promise.all(promises)
        .then(([TEMP, NAPON, UPTIME, VRIJEME, LDR]) => {
          Vue.set(this.postavke, 'TEMP', TEMP)
          Vue.set(this.postavke, 'NAPON', NAPON)
          Vue.set(this.postavke, 'UPTIME', UPTIME)
          Vue.set(this.postavke, 'VRIJEME', VRIJEME)
          Vue.set(this.postavke, 'LDR', LDR)
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'red',
            text: this.$t('ui.error')
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    this.init()
    this.getT1DataId = setInterval(this.getPostavke, 900)
  },
  destroyed () {
    if (this.getT1DataId) {
      clearInterval(this.getT1DataId)
    }
  }
}
</script>
