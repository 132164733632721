<template>
  <v-form
    ref="mcoProgramForm"
    v-model="mcoProgramFormValid"
  >
    <v-col>

    </v-col>

  </v-form>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'

export default {
  name: 'McoProgramForm',
  mixins: [T1Mixin, AuthMixin],
  props: ['postavke'],
  data () {
    return {
      mcoProgramFormValid: false,
      loading: false
    }
  },
  computed: {
    submitFormDisabled () {
      if (this.loading || this.mcoProgramFormValid !== true) return true
      return false
    }
  },
  methods: {
  }
}
</script>
