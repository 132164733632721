import Vue from 'vue'
import Vuex from 'vuex'
import { T1Store } from './T1Store'
import { T2000Store } from './T2000Store'
import { TelventStore } from './TelventStore'
import { EtxStore } from './EtxStore'
import { AppStore } from './AppStore'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showLoadingScreen: false
  },
  mutations: {
    showLoadingScreen (state, payload) {
      state.showLoadingScreen = payload
    }
  },
  getters: {
    showLoadingScreen: function (state) {
      return state.showLoadingScreen
    }
  },
  modules: {
    t1: T1Store,
    t2000: T2000Store,
    telvent: TelventStore,
    etx: EtxStore,
    app: AppStore
  }
})
