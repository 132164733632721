<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">{{ this.$t('ui.traffic_controller') }}</span>
      </v-toolbar-title>
    </v-toolbar>

    <v-progress-linear
      v-show="loading"
      color="primary"
      indeterminate
    ></v-progress-linear>

    <v-card-text>
      <v-col xs="12">
        <v-text-field
          v-model="config.DEVICE_CODE"
          :label="this.$t('ui.code')"
          :error-messages="codeErrors"
          @input="$v.config.DEVICE_CODE.$touch()"
          @blur="$v.config.DEVICE_CODE.$touch()"
          required
        ></v-text-field>
      </v-col>

      <v-col xs="12">
        <v-text-field
          v-model="config.DEVICE_NAME"
          :label="this.$t('ui.name')"
          :error-messages="nameErrors"
          @input="$v.config.DEVICE_NAME.$touch()"
          @blur="$v.config.DEVICE_NAME.$touch()"
          required
        ></v-text-field>
      </v-col>

      <v-col xs="12">
        <v-select
          :label="this.$t('ui.system_hardware')"
          :items="systemHardwareTypes"
          v-model="config.SYSTEM_HW"
          item-text="name"
          item-value="id"
          :menu-props="{maxHeight:'400'}"
          persistent-hint
        ></v-select>
      </v-col>

      <v-col xs="12">
        <v-select
          :label="this.$t('ui.traffic_controller_type')"
          :items="trafficControllerTypes"
          v-model="config.DEVICE_TYPE"
          item-text="name"
          item-value="id"
          :menu-props="{maxHeight:'400'}"
          persistent-hint
          :error-messages="trafficControllerTypesErrors"
          @input="$v.config.DEVICE_TYPE.$touch()"
          @blur="$v.config.DEVICE_TYPE.$touch()"
          required
        ></v-select>
      </v-col>

      <v-col xs="12" v-if="config.DEVICE_TYPE === 'Telvent' || config.DEVICE_TYPE === 'ETX'">
        <v-text-field
          v-model="config.TELVENT_NR_SIGNAL_GROUPS"
          type="number"
          label="NUMBER OF SIGNAL GROUPS"
        ></v-text-field>
      </v-col>

      <v-col xs="12" v-if="pokreniBackend && config.DEVICE_TYPE === 'T-2000' && !config.REVERSE_PROXY_REQUESTS">
        <v-combobox
          v-model="config.DEVICE_PORT"
          :items="comPorts"
          label="COM PORT"
        ></v-combobox>
      </v-col>

      <v-col xs="12" v-if="config.DEVICE_TYPE === 'ETX'">
        <v-combobox
          v-model="config.ETX_IP"
          :items="['10.0.0.1']"
          label="ETX IP"
        ></v-combobox>
      </v-col>

      <v-col xs="12" v-if="config.DEVICE_TYPE === 'ETX'">
        <v-text-field
          v-model="config.ETX_WEB_USERNAME"
          :label="this.$t('ui.username')"
          :error-messages="ETX_WEB_USERNAME_Errors"
          @input="$v.config.ETX_WEB_USERNAME.$touch()"
          @blur="$v.config.ETX_WEB_USERNAME.$touch()"
          required
        ></v-text-field>
      </v-col>

      <v-col xs="12" v-if="config.DEVICE_TYPE === 'ETX'">
        <v-text-field
          v-model="config.ETX_WEB_PASSWORD"
          :label="this.$t('ui.password')"
          :error-messages="ETX_WEB_PASSWORD_Errors"
          @input="$v.config.ETX_WEB_PASSWORD.$touch()"
          @blur="$v.config.ETX_WEB_PASSWORD.$touch()"
          required
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'password' : 'text'"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
      </v-col>

      <!--<v-col xs="12" v-if="config.DEVICE_TYPE === 'ETX'">
        <v-switch
          v-model="enableAtmsControl"
          :label="this.$t('ui.enable_atms_control')"
        ></v-switch>
      </v-col>-->

      <v-col xs="12">
        <v-switch
          v-model="proxyRequests"
          :label="this.$t('ui.proxy_requests')"
        ></v-switch>
      </v-col>

      <v-col xs="12" v-if="pokreniBackend && proxyRequests">
        <v-combobox
          v-model="config.REVERSE_PROXY_REQUESTS_URL"
          :items="['http://192.168.0.21:8888/t-2000/za-centar/', 'http://192.168.0.21:8888/t-1/za-centar/', 'http://192.168.0.21:8888/telvent/za-centar/', 'http://192.168.0.21:8888/etx/za-centar/']"
          label="PROXY URL"
        ></v-combobox>
      </v-col>

      <v-col xs="12" v-if="pokreniBackend">
        <v-text-field
          v-model="config.DEVICE_BACKEND_PORT"
          label="HTTP Port"
          :error-messages="portErrors"
          @input="$v.config.DEVICE_BACKEND_PORT.$touch()"
          @blur="$v.config.DEVICE_BACKEND_PORT.$touch()"
          required
        ></v-text-field>
      </v-col>

      <v-col xs="12">
        <v-switch
          v-model="pokreniBackend"
          :label="this.$t('ui.start_backend')"
        ></v-switch>
      </v-col>

    </v-card-text>

    <v-card-actions>
      <v-btn
        :disabled="submitAvailable ? false : true"
        @click="submit"
        color="primary"
      >{{ this.$t('ui.submit') }}</v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>
import ConfigMixin from '@/mixins/ConfigMixin'
import SupervisorMixin from '@/mixins/SupervisorMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'TrafficControllerPage',
  mixins: [ConfigMixin, validationMixin, SupervisorMixin],
  data () {
    return {
      loading: false,
      showPassword: 'password',
      config: {
        DEVICE_CODE: null,
        DEVICE_NAME: null,
        DEVICE_TYPE: 'T-1',
        DEVICE_PORT: '/dev/ttyAMA0',
        DEVICE_BACKEND_START: '1',
        DEVICE_BACKEND_PORT: 8888,
        REVERSE_PROXY_REQUESTS: false,
        REVERSE_PROXY_REQUESTS_URL: null,
        SYSTEM_HW: null,
        TELVENT_NR_SIGNAL_GROUPS: null,
        ETX_IP: '10.0.0.1',
        ETX_WEB_USERNAME: 'admin',
        ETX_WEB_PASSWORD: 'password',
        ENABLE_ATMS_CONTROL: null
      },
      trafficControllerTypes: [],
      systemHardwareTypes: [
        {
          id: 'npe_9500',
          name: 'NPE 9500'
        },
        {
          id: 'kontron_alpitron_cm3+',
          name: 'KONTRON AL PiTron CM3+'
        }
      ],
      comPorts: [
      '/dev/ttyS0',
      '/dev/ttyAMA0',
      ]
    }
  },
  validations: {
    config: {
      DEVICE_CODE: {
        required
      },
      DEVICE_NAME: {
        required
      },
      DEVICE_TYPE: {
        required
      },
      DEVICE_BACKEND_PORT: {
        required
      },
      ETX_WEB_USERNAME: {
        required
      },
      ETX_WEB_PASSWORD: {
        required
      }
    }
  },
  computed: {
    isFormValid () {
      return !this.$v.$invalid
    },
    submitAvailable () {
      if (!this.loading && this.isFormValid) {
        return true
      }
      return false
    },
    codeErrors () {
      const errors = []
      if (!this.$v.config.DEVICE_CODE.$dirty) return errors
      !this.$v.config.DEVICE_CODE.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    nameErrors () {
      const errors = []
      if (!this.$v.config.DEVICE_NAME.$dirty) return errors
      !this.$v.config.DEVICE_NAME.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    trafficControllerTypesErrors () {
      const errors = []
      if (!this.$v.config.DEVICE_TYPE.$dirty) return errors
      !this.$v.config.DEVICE_TYPE.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    portErrors () {
      const errors = []
      if (!this.$v.config.DEVICE_BACKEND_PORT.$dirty) return errors
      !this.$v.config.DEVICE_BACKEND_PORT.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    ETX_WEB_USERNAME_Errors () {
      const errors = []
      if (!this.$v.config.ETX_WEB_USERNAME.$dirty) return errors
      !this.$v.config.ETX_WEB_USERNAME.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    ETX_WEB_PASSWORD_Errors () {
      const errors = []
      if (!this.$v.config.ETX_WEB_PASSWORD.$dirty) return errors
      !this.$v.config.ETX_WEB_PASSWORD.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    proxyRequests: {
      get () {
        if (this.config.REVERSE_PROXY_REQUESTS === '1') return true
        return false
      },
      set (newValue) {
        if (newValue) {
          this.config.REVERSE_PROXY_REQUESTS = '1'
        } else {
          this.config.REVERSE_PROXY_REQUESTS = '0'
        }
      }
    },
    pokreniBackend: {
      get () {
        if (this.config.DEVICE_BACKEND_START === '1') return true
        return false
      },
      set (newValue) {
        if (newValue) {
          this.config.DEVICE_BACKEND_START = '1'
        } else {
          this.config.DEVICE_BACKEND_START = '0'
        }
      }
    },
    enableAtmsControl: {
      get () {
        if (this.config.ENABLE_ATMS_CONTROL === '1') return true
        return false
      },
      set (newValue) {
        if (newValue) {
          this.config.ENABLE_ATMS_CONTROL = '1'
        } else {
          this.config.ENABLE_ATMS_CONTROL = '0'
        }
      }
    },
    jobAutoStart () {
      if (this.config.DEVICE_BACKEND_START === '1') {
        return true
      }

      return false
    },
    supervisorConfigKey () {
      return 'traffic_controller_backend'
    },
    supervisorAppDirectory () {
      if (this.config.DEVICE_TYPE === 'T-1') {
        return '/opt/trafsignal/traffic_controller/t1'
      } else if (this.config.DEVICE_TYPE === 'T-2000') {
        return '/opt/trafsignal/traffic_controller/t2000'
      } else if (this.config.DEVICE_TYPE === 'S-1') {
        return '/opt/trafsignal/traffic_controller/s1'
      } else if (this.config.DEVICE_TYPE === 'Telvent') {
        return '/opt/trafsignal/traffic_controller/telvent'
      } else if (this.config.DEVICE_TYPE === 'ETX') {
        return '/opt/trafsignal/traffic_controller/etx'
      }

      return '/opt/trafsignal/traffic_controller/t1'
    },
    supervisorTemplate () {
      if (this.proxyRequests) {
        return `[program:${this.supervisorConfigKey}]
command=/opt/.virtualenvs/trafconnect_v2/bin/python ${this.supervisorAppDirectory}/app.py --port=${this.config.DEVICE_BACKEND_PORT} --reverse_proxy --reverse_proxy_url=${this.config.REVERSE_PROXY_REQUESTS_URL}
directory=${this.supervisorAppDirectory}
environment=PYTHONPATH="/opt"
priority=1
autostart=${this.jobAutoStart}
autorestart=${this.jobAutoStart}
startretries=999
user=root
stdout_logfile_maxbytes=10KB
stdout_logfile_backups=0
stderr_logfile_maxbytes=10KB
stderr_logfile_backups=0
stdout_capture_maxbytes=10KB
`
      }

      return `[program:${this.supervisorConfigKey}]
command=/opt/.virtualenvs/trafconnect_v2/bin/python ${this.supervisorAppDirectory}/app.py --port=${this.config.DEVICE_BACKEND_PORT}
directory=${this.supervisorAppDirectory}
environment=PYTHONPATH="/opt"
priority=1
autostart=${this.jobAutoStart}
autorestart=${this.jobAutoStart}
startretries=999
user=root
stdout_logfile_maxbytes=10KB
stdout_logfile_backups=0
stderr_logfile_maxbytes=10KB
stderr_logfile_backups=0
stdout_capture_maxbytes=10KB
`
    },
    aciaSupervisorTemplate () {
      return `[program:acia]
command=/opt/.virtualenvs/trafconnect_v2/bin/python ${this.supervisorAppDirectory}/acia.py
directory=${this.supervisorAppDirectory}
environment=PYTHONPATH="/opt"
priority=1
autostart=${this.jobAutoStart}
autorestart=${this.jobAutoStart}
startretries=999
user=root
stdout_logfile_maxbytes=10KB
stdout_logfile_backups=0
stderr_logfile_maxbytes=10KB
stderr_logfile_backups=0
stdout_capture_maxbytes=10KB
`
    },
    telventManualControlTemnplate () {
      return `[program:telvent_manual_control]
command=/opt/.virtualenvs/trafconnect_v2/bin/python ${this.supervisorAppDirectory}/manual_control.py
directory=${this.supervisorAppDirectory}
environment=PYTHONPATH="/opt"
priority=1
autostart=${this.jobAutoStart}
autorestart=${this.jobAutoStart}
startretries=999
user=root
stdout_logfile_maxbytes=10KB
stdout_logfile_backups=0
stderr_logfile_maxbytes=10KB
stderr_logfile_backups=0
stdout_capture_maxbytes=10KB
`
    },
    etxSignaliSupervisorTemplate () {
      return `[program:etx_signali]
command=/opt/.virtualenvs/trafconnect_v2/bin/python ${this.supervisorAppDirectory}/signali.py
directory=${this.supervisorAppDirectory}
environment=PYTHONPATH="/opt"
priority=1
autostart=${this.jobAutoStart}
autorestart=${this.jobAutoStart}
startretries=999
user=root
stdout_logfile_maxbytes=10KB
stdout_logfile_backups=0
stderr_logfile_maxbytes=10KB
stderr_logfile_backups=0
stdout_capture_maxbytes=10KB
`
    },
    etxManualControlTemnplate () {
      return `[program:etx_manual_control]
command=/opt/.virtualenvs/trafconnect_v2/bin/python ${this.supervisorAppDirectory}/manual_control.py
directory=${this.supervisorAppDirectory}
environment=PYTHONPATH="/opt"
priority=1
autostart=${this.jobAutoStart}
autorestart=${this.jobAutoStart}
startretries=999
user=root
stdout_logfile_maxbytes=10KB
stdout_logfile_backups=0
stderr_logfile_maxbytes=10KB
stderr_logfile_backups=0
stdout_capture_maxbytes=10KB
`
    }
  },
  methods: {
    submit () {
      this.loading = true
      var promises = []

      promises.push(this.createConfig({
        'key': 'DEVICE_CODE',
        'value': this.config.DEVICE_CODE
      }))

      promises.push(this.createConfig({
        'key': 'DEVICE_NAME',
        'value': this.config.DEVICE_NAME
      }))

      promises.push(this.createConfig({
        'key': 'DEVICE_TYPE',
        'value': this.config.DEVICE_TYPE
      }))

      promises.push(this.createConfig({
        'key': 'DEVICE_BACKEND_PORT',
        'value': this.config.DEVICE_BACKEND_PORT
      }))

      promises.push(this.createConfig({
        'key': 'DEVICE_BACKEND_START',
        'value': this.config.DEVICE_BACKEND_START
      }))

      promises.push(this.createConfig({
        'key': 'REVERSE_PROXY_REQUESTS',
        'value': this.config.REVERSE_PROXY_REQUESTS
      }))

      promises.push(this.createConfig({
        'key': 'REVERSE_PROXY_REQUESTS_URL',
        'value': this.config.REVERSE_PROXY_REQUESTS_URL
      }))

      promises.push(this.createConfig({
        'key': 'SYSTEM_HW',
        'value': this.config.SYSTEM_HW
      }))

      promises.push(this.createConfig({
        'key': 'TELVENT_NR_SIGNAL_GROUPS',
        'value': parseInt(this.config.TELVENT_NR_SIGNAL_GROUPS)
      }))

      promises.push(this.createConfig({
        'key': 'ETX_IP',
        'value': this.config.ETX_IP
      }))

      promises.push(this.createConfig({
        'key': 'ETX_WEB_USERNAME',
        'value': this.config.ETX_WEB_USERNAME
      }))

      //promises.push(this.createConfig({
      //  'key': 'ENABLE_ATMS_CONTROL',
      //  'value': this.config.ENABLE_ATMS_CONTROL
      //}))

      promises.push(this.createConfig({
        'key': 'ETX_WEB_PASSWORD',
        'value': this.config.ETX_WEB_PASSWORD
      }))

      promises.push(this.createSupervisorConfig({
        'key': this.supervisorConfigKey,
        'config': this.supervisorTemplate
      }))

      if (this.config.DEVICE_TYPE === 'Telvent') {

        promises.push(this.createSupervisorConfig({
          'key': 'telvent_acia',
          'config': this.aciaSupervisorTemplate
        }))

        promises.push(this.createSupervisorConfig({
          'key': 'telvent_manual_control',
          'config': this.telventManualControlTemnplate
        }))

      } else {
        promises.push(this.deleteSupervisorConfig('telvent_acia'))
        promises.push(this.deleteSupervisorConfig('telvent_manual_control'))
      }

      if (this.config.DEVICE_TYPE === 'ETX') {

        promises.push(this.createSupervisorConfig({
          'key': 'etx_signali',
          'config': this.etxSignaliSupervisorTemplate
        }))

        promises.push(this.createSupervisorConfig({
          'key': 'etx_manual_control',
          'config': this.etxManualControlTemnplate
        }))

      } else {
        promises.push(this.deleteSupervisorConfig('etx_signali'))
        promises.push(this.deleteSupervisorConfig('etx_manual_control'))
      }

      Promise.all(promises)
        .then(() => {
          // svi upiti su gotovi
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .finally(() => {
          this.loading = false
          this.initConfigData()
        })
    }
  },
  beforeMount () {
    this.trafficControllerTypes = [
      {
        id: 'T-1',
        name: 'T-1'
      },
      {
        id: 'T-2000',
        name: 'T-2000'
      },
      {
        id: 'S-1',
        name: 'SIG-1'
      },
      {
        id: 'Telvent',
        name: 'Telvent'
      },
      {
        id: 'ETX',
        name: 'ETX'
      },
      {
        id: 'n/a',
        name: this.$t('ui.not_connected_to_traffic_controller')
      }
    ]
  },
  created () {
    this.initConfigData()
  }
}
</script>
