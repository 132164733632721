<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">{{ $t('ui.gpio') }}</span>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr v-for="gpio in gpioObjects" :key="gpio.id">
              <td>{{gpio.label}}</td>
              <td>{{gpio.status | gpioStatusText}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'

export default {
  name: 't1GpioCard',
  mixins: [T1Mixin],
  data () {
    return {
      loading: false,
      GPIOS: null,
      GPIO_STATE: null,
      getT1DataId: null
    }
  },
  filters: {
    gpioStatusText: function (value) {
      if (!value) return 'OFF'
      if (value > 0) return 'ON'
    }
  },
  computed: {
    gpioObjects () {
      if (!this.GPIOS || !this.GPIO_STATE) return []

      let labels = []
      let gpios = []
      let b = [1, 2, 4, 8, 16, 32, 64, 128]

      for (let i = 0; i < this.GPIOS.length; i++) {
        labels.push(String.fromCharCode(this.GPIOS[i]))
      }

      labels = labels.join('')
      labels = labels.replaceAll('><', ';')
      labels = labels.replaceAll('<', '')
      labels = labels.split(';')

      for (let i = 0; i < labels.length; i++) {
        if (labels[i]) {
          gpios.push({'id': i, 'label': labels[i], 'status': this.GPIO_STATE & b[i]})
        }
      }

      return gpios
    }
  },
  methods: {
    init () {
      this.initT1Data()
    },
    initT1Data () {
      let promises = []

      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x39]))
      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x40]))

      Promise.all(promises)
        .then(([GPIOS, GPIO_STATE]) => {
          this.GPIOS = GPIOS
          this.GPIO_STATE = GPIO_STATE
        })
    },
    getGpioState () {
      this.t1Write([this.t1.CPS, this.t1.READ, 0x40])
        .then((GPIO_STATE) => {
          this.GPIO_STATE = GPIO_STATE
        })
    }
  },
  created () {
    this.init()
    this.getT1DataId = setInterval(this.getGpioState, 900)
  },
  destroyed () {
    if (this.getT1DataId) {
      clearInterval(this.getT1DataId)
    }
  }
}
</script>
