<template>

  <v-container fluid grid-list-md>
    <v-row>
      <v-col xs="12">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ this.$t('ui.postavke_snage_signala') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="isAuthenticated" @click="submit" color="primary" :disabled="saveDisabled">{{ this.$t('ui.save') }}</v-btn>
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, i) in availableActions"
                  :key="i"
                  link
                  @click="item.action(...item.actionArgs)"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
          <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>
          <v-card-text>
            <v-row>
              <v-col xs="12" md="2">
                <v-combobox
                  v-model="selectedSigs"
                  :items="sigoviZaOdabrati"
                  label="SIG"
                  item-text="name"
                  item-value="id"
                  multiple
                ></v-combobox>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div v-if="initDone">
      <v-row v-for="sig in sigovi" :key="sig.id">
        <v-col v-for="grupa in sig.grupe" :key="grupa.grupaId" xs="12" lg="6">
          <app-t1-postavke-snaga-signala-grupe
            :grupa-id="grupa.grupaId"
            :signali="grupa.signali"
            :snage="grupa.snage"
            :saved-snage-full="grupa.savedSnageFull"
            :saved-snage-dimm="grupa.savedSnageDimming"
            :prag1="grupa.prag1"
            :prag2="grupa.prag2"
          ></app-t1-postavke-snaga-signala-grupe>
        </v-col>
      </v-row>
    </div>

    <app-t1-custom-prag-dialog
      :open-dialog="openCustomPragDialog"
      :headline="$t('ui.postavi_tolerancije')"
      @userAction="customPragUserActionHandler"
    ></app-t1-custom-prag-dialog>

    <form id="upload_backup_form">
      <input
        type="file"
        id="upload_backup_input"
        ref="uploadBackup"
        accept=".csv"
        hidden
        v-on:change="handleCsvBackupUpload()"
      />
    </form>

  </v-container>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'
import ConfigMixin from '@/mixins/ConfigMixin'
import SupervisorMixin from '@/mixins/SupervisorMixin'

export default {
  name: 't1SnageSignalaPostavkePage',
  mixins: [ConfigMixin, SupervisorMixin, T1Mixin, AuthMixin],
  data () {
    return {
      initDone: false,
      loading: false,
      gettingSignaliSnage: false,
      brojGrupa: 0,
      selectedSigs: [],
      snageSignala: [],
      nominalneSnageSignalaFull: [],
      nominalneSnageSignalaFullCopy: [],
      nominalneSnageSignalaDimming: [],
      nominalneSnageSignalaDimmingCopy: [],
      prag1: [],
      // gainData: null,
      config: {
        'T1_DOZVOLJENO_ODSTUPANJE_PRAG_2': null
      },
      configMeta: {
        T1_DOZVOLJENO_ODSTUPANJE_PRAG_2: {
          type: Array
        }
      },
      actions: [],
      defaultSettings: [],
      openCustomPragDialog: false
    }
  },
  computed: {
    availableActions () {
      let actions = []
      let action = null
      for (let i = 0; i < this.actions.length; i++) {
        action = this.actions[i]
        if (action.needsAuth === true) {
          if (this.isAuthenticated) {
            actions.push(action)
          }
        } else {
          actions.push(action)
        }
      }

      return actions
    },
    saveDisabled () {
      if (this.loading) return true
      return false
    },
    brojSigova () {
      return Math.ceil(this.brojGrupa/4)
    },
    sigoviZaOdabrati () {
      let s = []
      for (let i = 0; i < this.brojSigova; i++) {
        s.push({id: i, name: `SIG ${i+1}`})
      }

      return s
    },
    postavke () {
      return {
        'nominalneSnageSignalaFull': JSON.parse(JSON.stringify(this.nominalneSnageSignalaFull)),
        'nominalneSnageSignalaDimming': JSON.parse(JSON.stringify(this.nominalneSnageSignalaDimming)),
        'prag1': JSON.parse(JSON.stringify(this.prag1)),
        'prag2': JSON.parse(JSON.stringify(this.config.T1_DOZVOLJENO_ODSTUPANJE_PRAG_2))
      }
    },
    sigovi () {
      let s = []
      let odabraniSigovi = []
      let sig = {}
      let grupe = []
      let g = null
      let nominalneSnageSignalaFull = []
      let nominalneSnageSignalaFullCopy = []
      let nominalneSnageSignalaDimming = []
      let nominalneSnageSignalaDimmingCopy = []
      let prag1 = []
      let prag1Copy = []
      let prag2 = []
      let prag2Copy = []
      let signali = []

      // Napravi objekte za grupe, koliko ih ima
      for (let g = 1; g <= this.brojGrupa; g++) {

        if (this.nominalneSnageSignalaFull.length > 0 && typeof this.nominalneSnageSignalaFull[g-1] === 'undefined') {
          nominalneSnageSignalaFull = []
        } else {
          nominalneSnageSignalaFull = this.nominalneSnageSignalaFull[g-1]
        }

        if (this.nominalneSnageSignalaFullCopy.length > 0 && typeof this.nominalneSnageSignalaFullCopy[g-1] === 'undefined') {
          nominalneSnageSignalaFullCopy = []
        } else {
          nominalneSnageSignalaFullCopy = this.nominalneSnageSignalaFullCopy[g-1]
        }

        if (this.nominalneSnageSignalaDimming.length > 0 && typeof this.nominalneSnageSignalaDimming[g-1] === 'undefined') {
          nominalneSnageSignalaDimming = []
        } else {
          nominalneSnageSignalaDimming = this.nominalneSnageSignalaDimming[g-1]
        }

        if (this.nominalneSnageSignalaDimmingCopy.length > 0 && typeof this.nominalneSnageSignalaDimmingCopy[g-1] === 'undefined') {
          nominalneSnageSignalaDimmingCopy = []
        } else {
          nominalneSnageSignalaDimmingCopy = this.nominalneSnageSignalaDimmingCopy[g-1]
        }

        if (typeof this.prag1[g-1] === 'undefined') {
          prag1 = []
          prag1Copy = []
        } else {
          prag1 = this.prag1[g-1]
          prag1Copy = this.prag1Copy[g-1]
        }

        if (this.config.T1_DOZVOLJENO_ODSTUPANJE_PRAG_2) {
          if (typeof this.config.T1_DOZVOLJENO_ODSTUPANJE_PRAG_2[g-1] === 'undefined') {
            prag2 = []
            prag2Copy = []
          } else {
            prag2 = this.config.T1_DOZVOLJENO_ODSTUPANJE_PRAG_2[g-1]
            prag2Copy = this.prag2Copy[g-1]
          }
        }

        if (this.stanjeSignala && this.stanjeSignala.length > 0) {
          signali = this.stanjeSignala[g-1]
        } else {
          signali = [0,0,0,0]
        }

        grupe.push({
          'grupaId': g,
          snage: this.snageSignala[g-1],
          signali: signali,
          savedSnageFull: nominalneSnageSignalaFull,
          savedSnageFullCopy: nominalneSnageSignalaFullCopy,
          savedSnageDimming: nominalneSnageSignalaDimming,
          savedSnageDimmingCopy: nominalneSnageSignalaDimmingCopy,
          prag1: prag1,
          prag1Copy: prag1Copy,
          prag2: prag2,
          prag2Copy: prag2Copy
        })
      }

      // Napravi objekte za sigove i dodaj im pripadajuce grupe
      for (let i = 0; i < this.brojSigova; i++) {
        sig = {id: i, name: `SIG ${i+1}`, grupe: []}
        for (let n = 1; n <=4; n++ ) {
          g = grupe.shift()
          if (g) {
            sig.grupe.push(g)
          }

        }
        s.push(sig)
      }

      // Prođi sve sigove i ako su u odabranim sigovima dodaj ih u listu koju vracamo dalje
      for (let i = 0; i < s.length; i++) {
        for (let n = 0; n < this.selectedSigs.length; n++) {
          if (this.selectedSigs[n].id === s[i].id) {
            odabraniSigovi.push(s[i])
          }
        }
      }

      return odabraniSigovi
    },
    stanjeSignala () {
      return this.$store.getters['t1/stanjeSignala']
    }
  },
  methods: {
    init () {
      return new Promise((resolve, reject) => {
        this.initT1Data()
          .then(this.getNominalneSnageSignala)
          .then(this.getPrag1)
          .then(this.initConfigData)
          .then(() => {
            if (!this.config.T1_DOZVOLJENO_ODSTUPANJE_PRAG_2) {
              // Ako nema definiranog praga2, neka je 100%
              this.config.T1_DOZVOLJENO_ODSTUPANJE_PRAG_2 = []
              for (let g = 0; g < this.brojGrupa; g++) {
                this.config.T1_DOZVOLJENO_ODSTUPANJE_PRAG_2.push([100,100,100,100])
              }
            } else if (this.config.T1_DOZVOLJENO_ODSTUPANJE_PRAG_2.length < this.brojGrupa) {
              // Ako se doda novi SIG koji nema definirani prag2, dodaj da je 100%
              for (let g = 0; g < this.brojGrupa; g++) {
                if (typeof this.config.T1_DOZVOLJENO_ODSTUPANJE_PRAG_2[g] === 'undefined') {
                  this.config.T1_DOZVOLJENO_ODSTUPANJE_PRAG_2[g] = [100, 100, 100, 100]
                }
              }
            }

            this.nominalneSnageSignalaDimmingCopy = JSON.parse(JSON.stringify(this.nominalneSnageSignalaDimming))
            this.nominalneSnageSignalaFullCopy = JSON.parse(JSON.stringify(this.nominalneSnageSignalaFull))
            this.prag1Copy = JSON.parse(JSON.stringify(this.prag1))
            this.prag2Copy = JSON.parse(JSON.stringify(this.config.T1_DOZVOLJENO_ODSTUPANJE_PRAG_2))
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    initT1Data () {
      return new Promise((resolve, reject) => {
        this.getBrojGrupa()
          .then((BROJ_GRUPA) => {
            this.brojGrupa = BROJ_GRUPA
            if (this.brojGrupa) {
              if (this.selectedSigs.length === 0) {
                this.selectedSigs = this.sigoviZaOdabrati
              }
            }
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getSnageSignala () {
      if (this.gettingSignaliSnage) {
        console.warn('DOHVAT SNAGA SIGNALA TRAJE DULJE OD 900ms!')
        return false
      } else {

        this.gettingSignaliSnage = true

        this.getSignaliSnage(this.brojGrupa)
          .then((SIGNALI_SNAGE) => {
            this.snageSignala = SIGNALI_SNAGE
          })
          .finally(() => {
            this.gettingSignaliSnage = false
          })

      }
    },
    getNominalneSnageSignala () {

      return new Promise((resolve, reject) => {
        let promises = []
        promises.push(this.getNominalneSnageSignalaFull(this.brojGrupa))
        promises.push(this.getNominalneSnageSignalaDimming(this.brojGrupa))

        Promise.all(promises)
          .then(([FULL, DIMMING]) => {
            this.nominalneSnageSignalaFull = FULL
            this.nominalneSnageSignalaDimming = DIMMING
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getPrag1 () {
      return new Promise((resolve, reject) => {
        this.getSignaliSnageDozvoljenoOdstupanjePrag1(this.brojGrupa)
          .then((answer) => {
            this.prag1 = answer
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    postaviPragNa (postotak, pragFn) {
      let prag = pragFn()

      for (let i = 0; i < prag.length; i++) {
        prag[i] = [postotak, postotak, postotak, postotak]
      }
    },
    getExistingPrag1 () {
      return this.prag1
    },
    getExistingPrag2 () {
      return this.config.T1_DOZVOLJENO_ODSTUPANJE_PRAG_2
    },
    spremiStanjeKaoDefault () {
      let serBr = this.$store.getters['t1/serBr']
      let postavkeSnagaSignalaLocal = localStorage.getItem('postavkeSnagaSignala')

      if (!postavkeSnagaSignalaLocal) {
        postavkeSnagaSignalaLocal = {}
        postavkeSnagaSignalaLocal[serBr] = {}
      } else {
        postavkeSnagaSignalaLocal = JSON.parse(postavkeSnagaSignalaLocal)
      }

      this.defaultSettings = {
        'nominalneSnageSignalaFull': JSON.parse(JSON.stringify(this.nominalneSnageSignalaFull)),
        'nominalneSnageSignalaDimming': JSON.parse(JSON.stringify(this.nominalneSnageSignalaDimming)),
        'prag1': JSON.parse(JSON.stringify(this.prag1)),
        'prag2': JSON.parse(JSON.stringify(this.config.T1_DOZVOLJENO_ODSTUPANJE_PRAG_2))
      }

      postavkeSnagaSignalaLocal[serBr] = JSON.parse(JSON.stringify(this.defaultSettings))
      localStorage.setItem('postavkeSnagaSignala', JSON.stringify(postavkeSnagaSignalaLocal))
    },
    restoreDefaultVrijednosti () {
      let serBr = this.$store.getters['t1/serBr']
      let postavkeSnagaSignalaLocal = localStorage.getItem('postavkeSnagaSignala')

      if (!postavkeSnagaSignalaLocal) {
        return false
      } else {
        postavkeSnagaSignalaLocal = JSON.parse(postavkeSnagaSignalaLocal)
        if (!postavkeSnagaSignalaLocal[serBr]) return false
      }

      this.nominalneSnageSignalaFull = postavkeSnagaSignalaLocal[serBr]['nominalneSnageSignalaFull']
      this.nominalneSnageSignalaDimming = postavkeSnagaSignalaLocal[serBr]['nominalneSnageSignalaDimming']
      this.prag1 = postavkeSnagaSignalaLocal[serBr]['prag1']
      this.config.T1_DOZVOLJENO_ODSTUPANJE_PRAG_2 = postavkeSnagaSignalaLocal[serBr]['prag2']
    },
    submitIfPossible () {
      if (this.loading) return false
      if (!this.isAuthenticated) return false
      this.submit()
    },
    doOpenCustomPragDialog () {
      this.openCustomPragDialog = true
    },
    doCloseCustomPragDialog () {
      this.openCustomPragDialog = false
    },
    customPragUserActionHandler (action) {
      if (action.method == 'cancel') {
        this.doCloseCustomPragDialog()
      } else if (action.method == 'save') {
        let prag = action.methodArgs[0]
        let vrijednost = parseInt(action.methodArgs[1])

        if (prag == 1) {
          this.postaviPragNa(vrijednost, this.getExistingPrag1)
        } else if (prag == 2) {
          this.postaviPragNa(vrijednost, this.getExistingPrag2)
        }
      }
    },
    restoreFromBackup () {
      var uploadInput = this.jQuery('#upload_backup_input')
      uploadInput.trigger('click')
    },
    handleCsvBackupUpload () {
      this.loading = true
      this.t1HandleCVSBackupUpload(this.$refs.uploadBackup.files[0])
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .then(this.init)
        .catch((error) => {
          console.error('handleCsvBackupUpload')
          console.error(error)
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'error',
            text: this.$t('ui.error')
          })
        })
        .finally(() => {
          this.jQuery('#upload_backup_input').val(null)
          this.loading = false
        })
    },
    downloadCSVNew () {
      this.loading = true
      this.t1BackupSettingsDownloadCSV(this.brojGrupa)
        .catch((error) => {
          // TODO: SHOW ERROR UX
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    restartPrag2Backend () {
      return new Promise((resolve, reject) => {

        if (this.restartBackend === true) {
          this.startSupervisorJob('t1_tolerancija_2_handler')
            .then(() => {
              this.restartBackend = false
              resolve()
            })
            .catch(() => {
              reject()
            })
        } else {
          resolve()
        }
      })

    },
    submit () {
      this.loading = true
      let promises = []
      let grupa = null
      let signalId = null
      let signalValue = null
      let signalValueCopy = null
      //let prag = null

      // SPREMI PRAG1
      for (let i = 0; i < this.prag1.length; i++) {
        let grupaId = i + 1
        let pragC = parseInt(this.prag1[i][0])
        let pragCK = parseInt(this.prag1[i][1])
        let pragŽ = parseInt(this.prag1[i][2])
        let pragZ = parseInt(this.prag1[i][3])

        let pragCCopy = parseInt(this.prag1Copy[i][0])
        let pragCKCopy = parseInt(this.prag1Copy[i][1])
        let pragŽCopy = parseInt(this.prag1Copy[i][2])
        let pragZCopy = parseInt(this.prag1Copy[i][3])

        if (pragC != pragCCopy && pragC >= 0 && pragC <= 100 ) {
          promises.push(this.saveSignaliSnageDozvoljenoOdstupanjePrag1(grupaId, 1, pragC))
        }

        if (pragCK != pragCKCopy && pragCK >= 0 && pragCK <= 100) {
          promises.push(this.saveSignaliSnageDozvoljenoOdstupanjePrag1(grupaId, 2, pragCK))
        }

        if (pragŽ != pragŽCopy && pragŽ >= 0 && pragŽ <= 100) {
          promises.push(this.saveSignaliSnageDozvoljenoOdstupanjePrag1(grupaId, 3, pragŽ))
        }

        if (pragZ != pragZCopy && pragZ >= 0 && pragZ <= 100) {
          promises.push(this.saveSignaliSnageDozvoljenoOdstupanjePrag1(grupaId, 4, pragZ))
        }
      }

      // SPREMI PRAG2
      let promisecc = this.createConfig({
        'key': 'T1_DOZVOLJENO_ODSTUPANJE_PRAG_2',
        'value': this.config.T1_DOZVOLJENO_ODSTUPANJE_PRAG_2
      })
      promises.push(promisecc)

      // uvijek napravi restart tolerancije 2
      this.restartBackend = true

      // SPREMI NOMINALNE SNAGE FULL
      for (let i = 0; i < this.sigovi.length; i++) {
        for (let g = 0; g < this.sigovi[i].grupe.length; g++) {
          grupa = this.sigovi[i].grupe[g]
          for (let gi = 0; gi < grupa.savedSnageFull.length; gi++) {
            signalId = gi+1
            signalValue = parseInt(grupa.savedSnageFull[gi])
            signalValueCopy = parseInt(grupa.savedSnageFullCopy[gi])
            if (signalValue != signalValueCopy && signalValue >= 0 && signalValue <= 255) {
              promises.push(this.saveNominalneSnageSignalaFull(grupa.grupaId, signalId, signalValue))
            }
          }
        }
      }

      // SPREMI NOMINALNE SNAGE DIMMING
      for (let i = 0; i < this.sigovi.length; i++) {
        for (let g = 0; g < this.sigovi[i].grupe.length; g++) {
          grupa = this.sigovi[i].grupe[g]
          for (let gi = 0; gi < grupa.savedSnageDimming.length; gi++) {
            signalId = gi+1
            signalValue = parseInt(grupa.savedSnageDimming[gi])
            signalValueCopy = parseInt(grupa.savedSnageDimmingCopy[gi])
            if (signalValue != signalValueCopy && signalValue >= 0 && signalValue <= 255) {
              promises.push(this.saveNominalneSnageSignalaDimming(grupa.grupaId, signalId, signalValue))
            }
          }
        }
      }

      Promise.all(promises)
        .then(this.restartPrag2Backend)
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'error',
            text: this.$t('ui.error')
          })
        })
        .then(this.init)
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
    }
  },
  created () {
    this.loading = true

    this.init()
    .then(() => {
      this.getSnageSignalaId = setInterval(this.getSnageSignala, 900)
      this.actions = [
        {'title': this.$t('ui.postavi_tolerancije'), 'action': this.doOpenCustomPragDialog, 'actionArgs': [], 'needsAuth': true },
        {'title': this.$t('ui.spremi_kao_default'), 'action': this.spremiStanjeKaoDefault, 'actionArgs': [], 'needsAuth': true },
        {'title': this.$t('ui.restore_default_values'), 'action': this.restoreDefaultVrijednosti, 'actionArgs': [], 'needsAuth': true },
        {'title': this.$t('ui.restore_backup'), 'action': this.restoreFromBackup, 'actionArgs': [], 'needsAuth': true },
        {'title': this.$t('ui.download_backup'), 'action': this.downloadCSVNew, 'actionArgs': [], 'needsAuth': true }
      ]
      this.initDone = true
    })
    .catch(() => {
      this.initDone = false
    })
    .finally(() => {
      this.loading = false
    })
  },
  destroyed () {
    if (this.getSnageSignalaId) {
      clearInterval(this.getSnageSignalaId)
    }
  }
}
</script>
