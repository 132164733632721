<template>
  <v-container fluid grid-list-md>

    <v-row>
      <v-col xs="12">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ this.$t('ui.raspored') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon
              :loading="loading"
              :disabled="loading"
              @click="downloadBackup">
              <v-icon>get_app</v-icon>
            </v-btn>
            <v-btn
              v-if="isAuthenticated"
              icon
              :disabled="loading"
              @click="selectFilesToUpload()">
              <v-icon>publish</v-icon>
            </v-btn>
            <v-btn
              @click="submit"
              color="primary"
              v-if="isAuthenticated">{{ this.$t('ui.save') }}</v-btn>
          </v-toolbar>
          <v-progress-linear
            v-show="loading"
            color="primary"
            indeterminate
          ></v-progress-linear>

          <v-card-text>
            <v-alert type="error" v-if="!rasporedSupported">
              {{this.$t('ui.not_supported')}}
            </v-alert>
          </v-card-text>

        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="rasporedSupported">
      <!-- PRIKAZ RASPOREDA -->
      <v-col xs="12" md="4">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">PTP</th>
                <th class="text-left">{{ $t('ui.day') }}</th>
                <th class="text-left">{{ $t('ui.vrijeme') }}</th>
                <th class="text-left">{{ $t('ui.actions') }}</th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="(stavka, index) in raspored" :key="index">
              <td>{{stavka.program}}</td>
              <td>{{stavka.dan | danText(dani)}}</td>
              <td>{{stavka.vrijeme}}</td>
              <td>
                <v-btn
                  v-if="isAuthenticated"
                  class="mr-15"
                  icon
                  @click="openConfirmDeleteDialog(stavka)">
                  <v-icon color="red">delete</v-icon>
                </v-btn>
                <v-btn
                  icon
                  v-if="stavka.pos>0 && isAuthenticated"
                  @click="itemChangePos(stavka, -1)">
                  <v-icon>keyboard_arrow_up</v-icon>
                </v-btn>
                <v-btn
                  icon
                  v-if="stavka.pos<raspored.length-1 && isAuthenticated"
                  @click="itemChangePos(stavka, 1)">
                  <v-icon>keyboard_arrow_down</v-icon>
                </v-btn>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <!-- /PRIKAZ RASPOREDA -->

      <!-- UREĐIVANJE RASPOREDA -->
      <v-col xs="12" md="4">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ this.$t('ui.uredi_raspored') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-col xs="12">
              <v-select
                v-model="stavka.program"
                :items="programi"
                label="PTP"
              ></v-select>
            </v-col>

            <v-col xs="12">
              <v-select
                v-model="stavka.dan"
                :items="dani"
                item-text="name"
                item-value="id"
                :label="$t('ui.day')"
              ></v-select>
            </v-col>

            <v-col xs="12">
              <v-time-picker
                v-model="stavka.vrijeme"
                format="24hr"
                color="primary"
                header-color="black"
                dark
                landscape
                scrollable
              ></v-time-picker>
            </v-col>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="isAuthenticated" @click="add" color="primary">{{ this.$t('ui.add') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <!-- /UREĐIVANJE RASPOREDA -->

      <!-- CIKLUS -->
      <v-col xs="12" md="4">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ this.$t('ui.ciklus') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">PTP</th>
                    <th class="text-center">{{ $t('ui.ciklus') }}</th>
                  </tr>
                </thead>
                <tbody>
                <tr v-for="(ciklus, index) in config.RASPORED_CIKLUS" :key="index">
                  <td v-if="config.DEVICE_TYPE=='T-2000'" class="text-center">{{index}} <v-btn icon @click="makePTPActive(index)"><v-icon color="green">play_arrow</v-icon></v-btn></td>
                  <td v-if="config.DEVICE_TYPE=='T-1'" class="text-center">{{index+1}}</td>
                  <td class="text-center">
                    <v-text-field
                      type="number"
                      v-model="config.RASPORED_CIKLUS[index]"
                    ></v-text-field>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- /CIKLUS -->
    </v-row>

    <v-row v-if="T1_PROGRAMI">
      <!-- TABLICA PROMJENA PROGRAMA -->
      <v-col xs="12">
        <app-t1-tablica-promjena-programa-card :programi="T1_PROGRAMI"></app-t1-tablica-promjena-programa-card>
      </v-col>
      <!-- /TABLICA PROMJENA PROGRAMA -->
    </v-row>

    <form id="upload_backup_form">
      <input
        type="file"
        id="upload_backup_input"
        ref="uploadBackup"
        accept=".json"
        hidden
        v-on:change="handleBackupUpload()"
      />
    </form>

    <app-confirm-action-dialog
      :open-dialog="openDeleteDialog"
      :headline="$t('ui.confirm_delete')"
      :text="$t('ui.really_delete_items')"
      @userAction="confirmDeleteStavkaActionHandler"
    >
    </app-confirm-action-dialog>

  </v-container>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'
import ConfigMixin from '@/mixins/ConfigMixin'
import SupervisorMixin from '@/mixins/SupervisorMixin'

export default {
  name: 'RasporedPage',
  mixins: [ConfigMixin, AuthMixin, T1Mixin, SupervisorMixin],
  data () {
    return {
      loading: false,
      configMeta: {
        RASPORED: {
          type: Array
        },
        RASPORED_CIKLUS: {
          type: Array
        }
      },
      config: {
        RASPORED: null,
        RASPORED_CIKLUS: null,
        DEVICE_TYPE: null
      },
      raspored: [],
      stavka: {
        program: 0,
        dan: 'radni_dan',
        vrijeme: '16:00'
      },
      openDeleteDialog: false,
      removeStavka: null,
      T1_VPROG_SUPPORTED: false,
      T1_PROGRAMI: null
    }
  },
  filters: {
    danText: function (value, dani) {

      if (!value) return ''
      value = value.toString()

      for (let i=0; i < dani.length; i++) {
        let dan = dani[i]
        if (dan.id === value) {
          return dan.name
        }
      }

      return ''
    }
  },
  computed: {
    programi () {
      if (this.config.DEVICE_TYPE === 'T-2000') {
        return this.$lodash.range(0, 9)
      } else if (this.T1_VPROG_SUPPORTED === true) {
        return this.$lodash.range(1, this.T1_PROGRAMI[1])
      }

      return this.$lodash.range(0, 64)

    },
    dani () {
      return [
        {'id': 'radni_dan', 'name': this.$t('ui.radni_dan')},
        {'id': 'subota', 'name': this.$t('ui.subota')},
        {'id': 'nedjelja', 'name': this.$t('ui.nedjelja')}
      ]
    },
    rasporedSupported () {
      if (this.config.DEVICE_TYPE === 'T-2000') {
        return true
      } else if (this.config.DEVICE_TYPE === 'T-1' && this.T1_VPROG_SUPPORTED === true) {
        return true
      }

      return false
    }
  },
  methods: {
    init () {
      this.initConfigData()
        .then(this.gett1ProgramsIfSupported)
    },
    gett1ProgramsIfSupported() {
      return new Promise((resolve, reject) => {
        if (this.config.DEVICE_TYPE === 'T-1') {
          this.t1getVprogConfig()
          .then((T1_PROGRAMI) => {
            this.T1_PROGRAMI = T1_PROGRAMI
            this.T1_VPROG_SUPPORTED = true

            if (!this.config.RASPORED_CIKLUS) {
              let i = this.T1_PROGRAMI[1]
              let n = 1
              this.config.RASPORED_CIKLUS = []

              // t-1 zadnji program gasi signale, ignoriraj ga
              while (n < i) {
                this.config.RASPORED_CIKLUS.push(0)
                n += 1
              }
            }


          })
          .catch((error) => {
            if (error === 'NOT_SUPPORTED') {
              this.T1_VPROG_SUPPORTED = false
            }
            reject()
          })
        } else {
          // ako nije t-1 i nema ciklusa za programe, stavi default
          if (!this.config.RASPORED_CIKLUS) {
            this.config.RASPORED_CIKLUS = [60, 95, 80, 95, 60, 60, 60, 60, 60]
          }
          resolve()
        }
      })
    },
    openConfirmDeleteDialog (stavka) {
      this.openDeleteDialog = true
      this.removeStavka = stavka
    },
    confirmDeleteStavkaActionHandler (actionResult) {
        this.openDeleteDialog = false

      if (actionResult === true && this.removeStavka) {
        this.remove(this.removeStavka)
      }
    },
    add () {
      let pos = this.raspored.length
      let stavka = {pos: pos}
      Object.assign(stavka, this.stavka)
      this.raspored.push(stavka)
    },
    makePTPActive (ptp) {
      this.raspored = [
        {
          'dan': 'radni_dan',
          'pos': 0,
          'program': ptp,
          'vrijeme': '00:00'
        },
        {
          'dan': 'subota',
          'pos': 1,
          'program': ptp,
          'vrijeme': '00:00'
        },
        {
          'dan': 'nedjelja',
          'pos': 2,
          'program': ptp,
          'vrijeme': '00:00'
        }
      ]
      this.submit()
    },
    saveData() {
      return new Promise((resolve, reject) => {
        let promises = []

        promises.push(this.createConfig({
          'key': 'RASPORED',
          'value': this.raspored
        }))

        promises.push(this.createConfig({
          'key': 'RASPORED_CIKLUS',
          'value': this.config.RASPORED_CIKLUS
        }))

        Promise.all(promises)
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    submit () {
      this.loading = true

      this.saveData()
        .then(() => {
          if (this.config.DEVICE_TYPE === 'T-1') {
            return this.startSupervisorJob('t1_cu_kontroler')
          } else if (this.config.DEVICE_TYPE === 'T-2000') {
            return this.startSupervisorJob('t2000_nulta_sekunda_sync')
          }
        })
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'error',
            text: this.$t('ui.save_error')
          })
        })
        .finally(() => {
          this.loading = false
          this.init()
        })
    },
    remove (item) {
      let index = this.$lodash.findIndex(this.raspored, function(o) { return o.pos == item.pos })
      if (index > -1) {
        this.raspored.splice(index, 1)
      }

      for (let i = 0; i < this.raspored.length; i++) {
        this.raspored[i].pos = i
      }
      this.removeStavka = null
    },
    itemChangePos (item, direction) {
      let index = this.$lodash.findIndex(this.raspored, function(o) { return o.pos == item.pos })
      let zamjeniMjestoSa = this.raspored[index+direction]
      zamjeniMjestoSa.pos = item.pos

      item.pos = item.pos + direction
      this.raspored[index] = zamjeniMjestoSa
      this.raspored[index+direction] = item
    },
    downloadBackup () {
      this.loading = true
      this.getConfigByKeys(['RASPORED','RASPORED_CIKLUS'])
        .then((response) => {
          let file = new Blob([JSON.stringify(response.data)], {type: 'text/plain'})
          let a = document.createElement('a'), url = URL.createObjectURL(file)
          a.href = url
          a.download = 'raspored.json'
          document.body.appendChild(a)
          a.click()
          setTimeout(function() {
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)
          }, 0)
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectFilesToUpload () {
      var uploadInput = this.jQuery('#upload_backup_input')
      uploadInput.trigger('click')
    },
    saveRasporedJSON (data) {
      let promises = []

      for (let i=0; i < data.rows.length; i++) {
        let row = data.rows[i]
        promises.push(this.createConfig({
          'key': row.key,
          'value': row.value
        }))
      }

      Promise.all(promises)
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'error',
            text: this.$t('ui.save_error')
          })
        })
        .finally(() => {
          this.jQuery('#upload_backup_input').val(null)
          this.loading = false
          this.init()
        })
    },
    handleBackupUpload () {
      this.loading = true
      this.uploadFiles = this.$refs.uploadBackup.files
      const reader = new FileReader()
      reader.addEventListener('load', (event) => {
        try {
          let data = JSON.parse(event.target.result)
          this.saveRasporedJSON(data)
        } catch(e) {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'error',
            text: e
          })
          this.jQuery('#upload_backup_input').val(null)
          this.loading = false
          this.init()
        }
      })
      reader.readAsText(this.uploadFiles[0])
    }
  },
  watch: {
    config: {
      handler: function () {
        this.raspored = this.config.RASPORED
      },
      deep: true
    }
  },
  created () {
    this.init()
  }
}
</script>
