<template>
  <v-container fluid grid-list-md style="background-color: #fff;">
    <v-row>
      <v-col xs="12">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ this.$t('ui.ciklus') }}</span>
            </v-toolbar-title>
            <v-btn
              icon
              @click="add()">
              <v-icon>add_circle</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="remove()">
              <v-icon>remove_circle</v-icon>
            </v-btn>
            <v-btn @click="restart()">RESTART</v-btn>
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12">
        <app-t2000-ciklus
          v-if="trafficController.broj_grupa > 0"
          :traffic-controller="trafficController"
          :events="events"
          :rezolucija="rezolucija"
          ref="ciklus"
        ></app-t2000-ciklus>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import ConfigMixin from '@/mixins/ConfigMixin'

export default {
  name: 't2000CiklusPage',
  mixins: [validationMixin, ConfigMixin],
  data () {
    return {
      loading: false,
      rezolucija: 5,
      greske: [],
      trafficController: {
        broj_grupa: 24,
        broj_detektora: 0
      }
    }
  },
  computed: {
    events () {
      return {
        stanje_signalnih_grupa: this.$store.getters['t2000/stanjeSignala']
      }
    }
  },
  methods: {
    init () {

    },
    restart () {
      this.$refs['ciklus'].init()
    },
    add () {
      this.rezolucija = this.rezolucija + 1
      if (this.rezolucija > 5 ) {
        this.rezolucija = 5
      }
    },
    remove () {
      this.rezolucija = this.rezolucija - 1
      if (this.rezolucija <= 0 ) {
        this.rezolucija = 1
      }
    }
  },
  created () {
    this.init()
  }
}
</script>
