<template>
  <v-container fluid grid-list-md>
    <v-row>
      <v-col xs="12">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ this.$t('ui.manual_control') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              v-if="manualControl === 'YES'"
              color="primary"
              @click="disableManualControl"
            >DISABLE</v-btn>
            <v-btn
              v-if="manualControl === 'NO'"
              color="primary"
              @click="enableManualControl"
            >ENABLE</v-btn>
          </v-toolbar>
        </v-card>
      </v-col>
      <v-progress-linear
        v-show="loading"
        color="primary"
        indeterminate
      ></v-progress-linear>
    </v-row>
    <v-row>
      <v-col xs="12" lg="4">
        <v-btn
          color="primary"
          @click="resetRMY"
          :disabled="doPowerCycleDisabled"
          block
        >RESET RMY</v-btn>
      </v-col>
      <v-col xs="12" lg="4">
        <v-btn
          color="red"
          @click="shutdownRMY"
          block
        >SHUTDOWN RMY</v-btn>
      </v-col>
      <v-col xs="12" lg="4">
        <v-btn
          color="green"
          @click="powerOnRMY"
          block
        >POWER ON RMY</v-btn>
      </v-col>
      <v-col xs="12" lg="4">
        <v-select
          label="ATMS CONTROL"
          :items="atmsControlStates"
          v-model="enableAtmsControl"
          item-text="label"
          item-value="id"
        ></v-select>
      </v-col>
      <v-col xs="12" lg="4">
        <v-select
          label="PHASE FREEZE CONTROL"
          :items="atmsControlStates"
          v-model="enablePhaseFreezeControl"
          item-text="label"
          item-value="id"
        ></v-select>
      </v-col>
      <v-col xs="12" lg="4">
        <v-btn
          :disabled="loading"
          color="primary"
          @click="saveAtmsControlState"
        >SAVE</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="manualControl==='YES'">
      <v-col xs="12" lg="6">
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>CURRENT PHASE</td>
                <td>{{ currentPhase }}</td>
              </tr>
              <tr>
                <td>FIX TO PHASE</td>
                  <td>{{ fixTelventPhase }}</td>
              </tr>
              <tr>
                <td>ATMS CONTROL</td>
                  <td>{{ atmsControl }}</td>
              </tr>
              <tr>
                <td>
                  <v-select
                    :items="phases"
                    item-text="name"
                    item-value="name"
                    v-model="selectedPhase"
                  ></v-select>
                </td>
                <td>
                  <v-btn
                    :disabled="loading || !selectedPhase"
                    color="primary"
                    @click="submit"
                  >FIX PHASE</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <app-confirm-action-dialog
        :open-dialog="openConfirmResetDialog"
        headline="CONFIRM"
        text="RESET RMY?"
        @userAction="doResetRMY"
      >
    </app-confirm-action-dialog>
    <app-confirm-action-dialog
        :open-dialog="openConfirmShutdownDialog"
        headline="CONFIRM"
        text="SHUTDOWN RMY?"
        @userAction="doShutdownRMY"
      >
    </app-confirm-action-dialog>
    <app-confirm-action-dialog
        :open-dialog="openConfirmPowerOnDialog"
        headline="CONFIRM"
        text="POWER ON RMY?"
        @userAction="doPowerOnRMY"
      >
    </app-confirm-action-dialog>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import ConfigMixin from '@/mixins/ConfigMixin'
import TelventMixin from '@/mixins/TelventMixin'

export default {
  name: 'TelventManualControlPage',
  mixins: [validationMixin, ConfigMixin, TelventMixin],
  data () {
    return {
      configMeta: {
        TELVENT_PHASES: {
          type: Array
        }
      },
      config: {
        TELVENT_PHASES: [],
        ENABLE_ATMS_CONTROL: '0',
        ENABLE_PHASE_ZREEZE_CONTROL: '0'
      },
      loading: false,
      phases: [{name: 'CANCEL'}, {name: 'INTERMITTENT'}, {name: 'DISABLE ATMS CONTROL'}],
      selectedPhase: 'CANCEL',
      fixPhase: false,
      selectedPhasesLength: '1',
      phasesLength: [{name: 'SHORT', value: '1'}, {name: 'LONG', value: '0'}],
      kickWdogId: null,
      openConfirmResetDialog: false,
      openConfirmShutdownDialog: false,
      openConfirmPowerOnDialog: false,
      atmsControlStates: [
        {
          label: 'ON',
          id: '1'
        },
        {
          label: 'OFF',
          id: '0'
        }
      ]
    }
  },
  methods: {
    init () {
      this.loading = true
      this.initConfigData()
        .then(() => {
          this.phases = [{name:'CANCEL'}, {name: 'INTERMITTENT'}, {name: 'DISABLE ATMS CONTROL'}].concat(this.config.TELVENT_PHASES)
        })
        .finally(() => {
          this.loading = false
        })
    },
    submit () {
      this.loading = true
      this.TelventFixPhase({phase: this.selectedPhase, quick: this.selectedPhasesLength})
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'error',
            text: this.$t('ui.error')
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    enableManualControl () {
      this.selectedPhase = 'CANCEL'
      this.TelventFixPhase({'telvent_manual_control_enabled': '1'})
        .then(() => {
          this.kickWdogId = setInterval(() => {
            this.TelventFixPhase({'telvent_manual_control_enabled': '1'})
          }, 900)
        })
    },
    disableManualControl () {
      if (this.kickWdogId) {
        clearInterval(this.kickWdogId)
      }
      this.selectedPhase = 'CANCEL'
      this.TelventFixPhase({'telvent_manual_control_enabled': '0'})
      this.TelventFixPhase({phase: 'CANCEL'})
    },
    resetRMY () {
      this.openConfirmResetDialog = true
    },
    doResetRMY (actionResult) {
      this.openConfirmResetDialog = false
      if (actionResult === true) {
        this.TelventFixPhase({'telvent_do_power_cycle': '1'})
      }
    },
    shutdownRMY () {
      this.openConfirmShutdownDialog = true
    },
    TELVENT_SHUTDOWN (value) {
      this.loading = true

        this.createConfig({
          'key': 'TELVENT_SHUTDOWN',
          'value': value
        })
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'error',
            text: this.$t('ui.save_error')
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    doShutdownRMY (actionResult) {
      this.openConfirmShutdownDialog = false
      if (actionResult === true) {
        this.TELVENT_SHUTDOWN('1')
      }
    },
    powerOnRMY () {
      this.openConfirmPowerOnDialog = true
    },
    doPowerOnRMY (actionResult) {
      this.openConfirmPowerOnDialog = false
      if (actionResult === true) {
        this.TELVENT_SHUTDOWN('0')
      }
    },
    saveAtmsControlState () {
      this.loading = true
      this.createConfig({
        'key': 'ENABLE_ATMS_CONTROL',
        'value': this.config.ENABLE_ATMS_CONTROL
      })
      .then(() => {
        this.createConfig({
          'key': 'ENABLE_PHASE_ZREEZE_CONTROL',
          'value': this.config.ENABLE_PHASE_ZREEZE_CONTROL
        })
      })
      .then(() => {
        this.TelventFixPhase({'reload': '1'})
      })
      .then(() => {
        this.$EventBus.$emit('AppSnackbar/show', {
          color: 'success',
          text: this.$t('ui.save_success')
        })
      })
      .catch(() => {
        this.$EventBus.$emit('AppSnackbar/show', {
          color: 'error',
          text: this.$t('ui.error')
        })
      })
      .finally(() => {
        this.loading = false
      })
    }
  },
  computed: {
    currentPhase () {
      let cp = this.$store.getters['telvent/currentPhase']
      if (cp) {
        return cp
      }

      return '...'
    },
    fixTelventPhase () {
      return this.$store.getters['telvent/fixTelventPhase']
    },
    atmsControl () {
      let pl = this.$store.getters['telvent/atmsControl']

      if (pl === '1') {
        return 'YES'
      } else {
        return 'NO'
      }
    },
    manualControl () {
      let pl = this.$store.getters['telvent/manualControl']

      if (pl === '1') {
        return 'YES'
      } else {
        return 'NO'
      }
    },
    doPowerCycle () {
      return this.$store.getters['telvent/doPowerCycle']
    },
    doPowerCycleDisabled () {
      if (this.doPowerCycle === '1') {
        return true
      }

      return false
    },
    enableAtmsControl: {
      get () {
        if (this.config.ENABLE_ATMS_CONTROL === '1') {
          return { 'id': '1', 'label': 'ON' }
        }
        return { 'id': '0', 'label': 'OFF' }
      },
      set (newValue) {
        if (newValue == '1') {
          this.config.ENABLE_ATMS_CONTROL = '1'
        } else {
          this.config.ENABLE_ATMS_CONTROL = '0'
        }
      }
    },
    enablePhaseFreezeControl: {
      get () {
        if (this.config.ENABLE_PHASE_ZREEZE_CONTROL === '1') {
          return { 'id': '1', 'label': 'ON' }
        }
        return { 'id': '0', 'label': 'OFF' }
      },
      set (newValue) {
        if (newValue == '1') {
          this.config.ENABLE_PHASE_ZREEZE_CONTROL = '1'
        } else {
          this.config.ENABLE_PHASE_ZREEZE_CONTROL = '0'
        }
      }
    }
  },
  created () {
    this.init()
  },
  destroyed () {
    if (this.kickWdogId) {
      clearInterval(this.kickWdogId)
    }
  }
}
</script>
