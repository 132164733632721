<template>
  <v-container fluid grid-list-md>

    <v-row>
      <v-col xs="12">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ this.$t('ui.nominalne_vrijednosti_snaga') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="isAuthenticated" @click="submit" color="primary" :disabled="saveDisabled">{{ this.$t('ui.save') }}</v-btn>
          </v-toolbar>
          <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>
          <v-card-text>
            <v-row>
              <v-col xs="12" md="2">
                <v-combobox
                  v-model="selectedSigs"
                  :items="sigoviZaOdabrati"
                  label="SIG"
                  item-text="name"
                  item-value="id"
                  multiple
                ></v-combobox>
              </v-col>
            </v-row>

            <v-tabs
              v-model="activeTab"
              color="grey darken-1"
              slider-color="primary"
            >
              <v-tab v-for="tab in tabs" :key="tab.id">
                {{tab.label}}
              </v-tab>

              <v-tab-item key="nominalne_vrijednosti_100">
                <v-col xs="12">
                  <app-t1-nominalne-vrijednosti-snaga-full :sigovi="sigovi"></app-t1-nominalne-vrijednosti-snaga-full>
                </v-col>
              </v-tab-item>

              <v-tab-item key="nominalne_vrijednosti_dimming">
                <v-col xs="12">
                  <app-t1-nominalne-vrijednosti-snaga-dimming :sigovi="sigovi"></app-t1-nominalne-vrijednosti-snaga-dimming>
                </v-col>
              </v-tab-item>

            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'

export default {
  name: 't1NominalneVrijednostiSnagaPage',
  mixins: [T1Mixin, AuthMixin],
  data () {
    return {
      loading: false,
      brojGrupa: 0,
      selectedSigs: [],
      snageSignala: [],
      nominalneSnageSignalaFull: [],
      nominalneSnageSignalaDimming: [],
      activeTab: null,
      tabs: [
        {
          id: 'nominalne_vrijednosti_100',
          label: '100%'
        },
        {
          id: 'nominalne_vrijednosti_dimming',
          label: 'Dimming'
        }
      ]
    }
  },
  computed: {
    saveDisabled () {
      if (this.loading) return true
      return false
    },
    brojSigova () {
      return Math.ceil(this.brojGrupa/4)
    },
    sigoviZaOdabrati () {
      let s = []
      for (let i = 0; i < this.brojSigova; i++) {
        s.push({id: i, name: `SIG ${i+1}`})
      }

      return s
    },
    sigovi () {
      let s = []
      let odabraniSigovi = []
      let sig = {}
      let grupe = []
      let g = null
      let nominalneSnageSignalaFull = []
      let nominalneSnageSignalaDimming = []

      // Napravi objekte za grupe, koliko ih ima
      for (let g = 1; g <= this.brojGrupa; g++) {
        if (typeof this.nominalneSnageSignalaFull[g-1] === 'undefined') {
          nominalneSnageSignalaFull = []
        } else {
          nominalneSnageSignalaFull = this.nominalneSnageSignalaFull[g-1]
        }

        if (typeof this.nominalneSnageSignalaDimming[g-1] === 'undefined') {
          nominalneSnageSignalaDimming = []
        } else {
          nominalneSnageSignalaDimming = this.nominalneSnageSignalaDimming[g-1]
        }

        grupe.push({
          'grupaId': g,
          snage: this.snageSignala[g-1],
          signali: [1,1,1,1],
          savedSnageFull: nominalneSnageSignalaFull,
          savedSnageDimming: nominalneSnageSignalaDimming
        })
      }

      // Napravi objekte za sigove i dodaj im pripadajuce grupe
      for (let i = 0; i < this.brojSigova; i++) {
        sig = {id: i, name: `SIG ${i+1}`, grupe: []}
        for (let n = 1; n <=4; n++ ) {
          g = grupe.shift()
          if (g) {
            sig.grupe.push(g)
          }

        }
        s.push(sig)
      }

      // Prođi sve sigove i ako su u odabranim sigovima dodaj ih u listu koju vracamo dalje
      for (let i = 0; i < s.length; i++) {
        for (let n = 0; n < this.selectedSigs.length; n++) {
          if (this.selectedSigs[n].id === s[i].id) {
            odabraniSigovi.push(s[i])
          }
        }
      }

      return odabraniSigovi
    },
    stanjeSignala () {
      return this.$store.getters['t1/stanjeSignala']
    }
  },
  methods: {
    init () {
      this.initT1Data()
      this.getNominalneSnageSignala()
    },
    initT1Data () {
      this.loading = true
      var promises = []

      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, this.t1.BROJ_GRUPA]))

      Promise.all(promises)
        .then(([BROJ_GRUPA]) => {
          this.brojGrupa = BROJ_GRUPA[0]
          if (this.brojGrupa) {
            this.selectedSigs = this.sigoviZaOdabrati
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    getSnageSignala () {
      let promises = []
      for (let sig = 0; sig < this.brojSigova; sig++) {
        promises.push(this.t1Write([this.t1.SIG(sig), this.t1.READ, this.t1.SIG_SNAGE]))
      }

      Promise.all(promises)
        .then((answers) => {

          this.snageSignala = []

          for (let sig = 0; sig < this.brojSigova; sig++) {
            let c = this.$lodash.chunk(answers[sig], 4)
            let kcrv = c[0]
            let crv = c[1]
            let zut = c[2]
            let zel = c[3]

            this.snageSignala.push([kcrv[0], crv[0], zut[0], zel[0]])
            this.snageSignala.push([kcrv[1], crv[1], zut[1], zel[1]])
            this.snageSignala.push([kcrv[2], crv[2], zut[2], zel[2]])
            this.snageSignala.push([kcrv[3], crv[3], zut[3], zel[3]])
          }

        })
    },
    getNominalneSnageSignala () {
      this.loading = true
      let promises = []
      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x1c]))
      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x1d]))

      Promise.all(promises)
        .then(([FULL, DIMMING]) => {
          this.nominalneSnageSignalaFull = this.$lodash.chunk(FULL, 4)
          this.nominalneSnageSignalaDimming = this.$lodash.chunk(DIMMING, 4)
        })
        .finally(() => {
          this.loading = false
        })
    },
    submit () {
      this.loading = true
      let promises = []
      let grupa = null
      let signalId = null
      let signalValue = null

      // SPREMI NOMINALNE SNAGE FULL
      for (let i = 0; i < this.sigovi.length; i++) {
        for (let g = 0; g < this.sigovi[i].grupe.length; g++) {
          grupa = this.sigovi[i].grupe[g]
          for (let gi = 0; gi < grupa.savedSnageFull.length; gi++) {
            signalId = gi+1
            signalValue = parseInt(grupa.savedSnageFull[gi])
            promises.push(this.t1Write([this.t1.CPS, this.t1.WRITE, 0x1f, grupa.grupaId, signalId, signalValue]))
          }
        }
      }

      // SPREMI NOMINALNE SNAGE DIMMING
      for (let i = 0; i < this.sigovi.length; i++) {
        for (let g = 0; g < this.sigovi[i].grupe.length; g++) {
          grupa = this.sigovi[i].grupe[g]
          for (let gi = 0; gi < grupa.savedSnageDimming.length; gi++) {
            signalId = gi+1
            signalValue = parseInt(grupa.savedSnageDimming[gi])
            promises.push(this.t1Write([this.t1.CPS, this.t1.WRITE, 0x20, grupa.grupaId, signalId, signalValue]))
          }
        }
      }

      Promise.all(promises)
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'error',
            text: this.$t('ui.error')
          })
        })
        .finally(() => {
          this.init()
        })

    }
  },
  created () {
    this.init()
    this.getSnageSignalaId = setInterval(this.getSnageSignala, 900)
  },
  destroyed () {
    if (this.getSnageSignalaId) {
      clearInterval(this.getSnageSignalaId)
    }
  }
}
</script>
