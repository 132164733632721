import $ws from '@/lib/ws'

export default {
  data () {
    return {

    }
  },
  methods: {
    EtxManualControl (config) {
      return this.$api.post('/etx/manual-control/', config, {baseURL: this.$store.getters['app/TRAFFIC_CONTROLLER_API_URI']})
    },
    EtxGetNavItems () {
      return [
        {
          router_link: '/etx/tlocrt',
          text: this.$t('ui.tlocrt'),
          show: true
        },
        {
          router_link: '/etx/ciklus',
          text: this.$t('ui.ciklus'),
          show: true
        },
        {
          router_link: '/etx/programs-crud',
          text: this.$t('ui.programs'),
          show: true
        },
        {
          router_link: '/etx/schedule',
          text: this.$t('ui.raspored'),
          show: true
        },
        {
          router_link: '/etx/manual-control',
          text: this.$t('ui.manual_control'),
          show: true
        }
      ]
    },
    EtxHandleWsConnection () {
      // var messageItems = [
      //  'stanjeSignala', 'currentPhase', 'fixEtxPhase', 'atmsControl', 'manualControl',
      //  'currentProgram', 'ciklus', 'offset', 'statusnaRijec', 'outsideSecond', 'internalSecond',
      //  'doPowerCycle'
      //]

      var messageItems = [
        'stanjeSignala',
        'etxPlan',
        'etxStage',
        'manualControl',
        'ETX_INTERMITTENCE_ON',
        'fixEtxPhase',
        'internalSecond',
        'offset',
        'ciklus',
        'outsideSecond'
      ]

      var i, n, poruka, duzina, rezultat, vrijemeZadnjePoruke, dif
      var trafficController = {}
      var defaulttrafficController = {
        serBr: [],
        stanjeSignala: [],
        greske: [],
        statusnaRijec: [],
        brojGrupa: [],
        stanjeDetektora: [],
        currentPhase: [],
        fixEtxPhase: [],
        atmsControl: [],
        manualControl: [],
        currentProgram: [],
        ciklus: [],
        offset: [],
        program: null,
        outsideSecond: [],
        internalSecond: [],
        doPowerCycle: [],
        etxPlan: [],
        etxCiklus: [],
        etxCiklusSekunda: [],
        etxStage: [],
        etxSekunda: [],
        Etxcommunicating: false
      }

      const ws = $ws()

      this.$websocket = ws(`${this.$store.getters['app/TRAFFIC_CONTROLLER_WS_URI']}/etx/stanje/`)
      this.$websocket.socket.binaryType = 'arraybuffer'

      this.$websocket.onOpen(() => {
        console.log('ws connected')
        this.$store.commit('etx/changeReadyState', this.$websocket.socket.readyState)
      })

      this.$websocket.onMessage((message) => {
        vrijemeZadnjePoruke = new Date().getTime()
        poruka = Array.from(new Uint8Array(message.data))

        for (i = 0; i < messageItems.length; i++) {
          duzina = poruka.shift()
          rezultat = []

          for (n = 0; n < duzina; n++) {
            rezultat.push(poruka.shift())
          }
          trafficController[messageItems[i]] = rezultat
        }

        this.$store.commit('etx/set', trafficController)
      })

      this.$websocket.onError((error) => {
        console.error(error)
        this.$store.commit('etx/changeReadyState', this.$websocket.socket.readyState)
        this.$store.commit('etx/set', defaulttrafficController)
      })

      this.$websocket.onClose(() => {
        console.log('Closed!')
        this.$store.commit('etx/changeReadyState', this.$websocket.socket.readyState)
        this.$store.commit('etx/set', defaulttrafficController)
      })

      this.t1ResetStateWdogId = setInterval(() => {
        dif = (new Date().getTime() - vrijemeZadnjePoruke) / 1000
        if (dif >= 5) {
          // Poruka od uređaja nije stigla 5 sekunde, resetiraj stanje uređaja
          this.$store.commit('etx/set', defaulttrafficController)
        }
      }, 900)
    }
  }
}
