<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">{{ $t('ui.programski_rad') }} - direkt</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="isAuthenticated" @click="promjeniAktivniProgram()" color="primary" :disabled="saveDisabled">{{ $t('ui.save') }}</v-btn>
    </v-toolbar>
    <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr></tr>
            <tr></tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ $t('ui.trenutni_ptp') }}: {{trenutniPTP}}</td>
              <td>{{ $t('ui.mod_rada') }}: {{modRada}}</td>
            </tr>
            <tr>
              <td>{{ $t('ui.promjeni_aktivni_program')}}</td>
              <td>
                <v-select
                  :disabled="!isAuthenticated"
                  :items="podrzaniProgrami"
                  item-text="label"
                  item-value="id"
                  v-model="odabraniProgram"
                ></v-select>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'

export default {
  name: 't1ProgramskiRadCardOld',
  mixins: [T1Mixin, AuthMixin],
  data () {
    return {
      loading: false,
      odabraniProgram: null,
      VPROG_CONFIG: null,
      getT1DataId: null,
      VPROG_NOT_SUPPORTED: false
    }
  },
  computed: {
    saveDisabled () {
      if (this.loading) return true
      return false
    },
    podrzaniProgrami () {
      if (!this.VPROG_CONFIG) return []

      let programi = [
        {'id': 0, 'label': 'Treptanje'},
        {'id': 255, 'label': 'Automatski'}
      ]

      for (let i = 1; i < this.VPROG_CONFIG[1]+1; i++) {
        if (i === this.VPROG_CONFIG[1])  {
          programi.push({'id': i, 'label': this.$t('ui.ugasi_signale')})
        } else {
          programi.push({'id': i, 'label': `Program ${i}`})
        }

      }

      return programi
    },
    modRada () {
      if (!this.VPROG_CONFIG) return ''

      if (parseInt(this.VPROG_CONFIG[3]) != 255) {
        return this.$t('ui.rucni_odabir')
      }

      return this.$t('ui.automatski')
    },
    vProgAktivan () {
      if (!this.VPROG_CONFIG) return ''
      return parseInt(this.VPROG_CONFIG[0])
    },
    trenutniPTP () {
      if (!this.VPROG_CONFIG) return ''
      if (this.VPROG_CONFIG[2]) {
        return parseInt(this.VPROG_CONFIG[2])
      }
      return ''
    },
    aktivanProgram () {
      if (!this.VPROG_CONFIG) return ''
      return parseInt(this.VPROG_CONFIG[3])
    }
  },
  methods: {
    init () {
      this.initT1Data()
    },
    getVprogConfig () {
      this.t1Write([this.t1.CPS, this.t1.READ, 0x47])
        .then((VPROG_CONFIG) => {
          if (VPROG_CONFIG[0] == 102) {
            this.VPROG_CONFIG = null
            this.VPROG_NOT_SUPPORTED = true
          } else {
            this.VPROG_CONFIG = VPROG_CONFIG
          }
        })
    },
    initT1Data () {
      this.loading = true
      let promises = []

      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x47]))

      Promise.all(promises)
        .then(([VPROG_CONFIG]) => {
          if (VPROG_CONFIG[0] == 102) {
            this.VPROG_CONFIG = null
            this.VPROG_NOT_SUPPORTED = true
          } else {
            this.VPROG_CONFIG = VPROG_CONFIG
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    promjeniAktivniProgram () {
      this.loading = true
      this.t1Write([this.t1.CPS, this.t1.WRITE, 0x47, this.odabraniProgram])
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'red',
            text: this.$t('ui.save_error')
          })
        })
        .finally(() => {
          this.loading = false
          this.init()
        })
    }
  },
  created () {
    this.init()
    this.getT1DataId = setInterval(this.getVprogConfig, 900)
  },
  destroyed () {
    if (this.getT1DataId) {
      clearInterval(this.getT1DataId)
    }
  }
}
</script>
