export default {
  computed: {
    isAuthenticated () {
      return this.$store.getters['app/isAuthenticated']
    },
    isSuperUser () {
      return this.$store.getters['app/isSuperUser']
    }
  },
  methods: {
  }
}
