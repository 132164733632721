<template>
  <div>
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>
          <span class="text-xl-left">{{ $t('ui.gpio_settings') }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="isAuthenticated" @click="add" class="mr-5" color="primary">{{ this.$t('ui.add') }}</v-btn>
        <v-btn v-if="isAuthenticated" @click="submit" color="primary">{{ this.$t('ui.save') }}</v-btn>
      </v-toolbar>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">{{ $t('ui.hrvatski') }}</th>
                <th class="text-left">{{ $t('ui.slovenski') }}</th>
                <th class="text-left">{{ $t('ui.gpio_adresa') }}</th>
                <th class="text-left">{{ $t('ui.timeout') }}</th>
                <th class="text-left">{{ $t('ui.actions') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(gpio, index) in config.APP_GPIO_SETTINGS" :key="index">
                <td>
                  <v-text-field
                    v-model="gpio.label.hr"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="gpio.label.si"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="gpio.addr"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="gpio.timeout"
                  ></v-text-field>
                </td>
                <td>
                  <v-btn
                    v-if="isAuthenticated"
                    class="mr-15"
                    icon
                    @click="openConfirmDeleteDialog(index)">
                    <v-icon color="red">delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-row>
      <v-col xs="12" md="6">
        <app-gpio-switch-card></app-gpio-switch-card>
      </v-col>
    </v-row>

    <app-confirm-action-dialog
      :open-dialog="openDeleteDialog"
      :headline="$t('ui.confirm_delete')"
      :text="$t('ui.really_delete_items')"
      @userAction="confirmDeleteStavkaActionHandler"
    >
    </app-confirm-action-dialog>
  </div>
</template>

<script>
import AuthMixin from '@/mixins/AuthMixin'
import ConfigMixin from '@/mixins/ConfigMixin'

export default {
  name: 'GpioCrudCard',
  mixins: [ConfigMixin, AuthMixin],
  data () {
    return {
      loading: false,
      openDeleteDialog: false,
      removeStavkaIndex: null,
      configMeta: {
        APP_GPIO_SETTINGS: {
          type: Array
        }
      },
      config: {
        APP_GPIO_SETTINGS: []
      }
    }
  },
  computed: {
  },
  methods: {
    init () {
      return new Promise((resolve, reject) => {
        this.initConfigData()
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    getNewGpioRow () {
      return {
        label: {
          hr: '',
          si: ''
        },
        addr: 'DO1',
        timeout: 5
      }
    },
    openConfirmDeleteDialog (index) {
      this.openDeleteDialog = true
      this.removeStavkaIndex = index
    },
    confirmDeleteStavkaActionHandler (actionResult) {
        this.openDeleteDialog = false

      if (actionResult === true) {
        this.remove(this.removeStavkaIndex)
      }
    },
    add () {
      this.config.APP_GPIO_SETTINGS.push(this.getNewGpioRow())
    },
    remove (index) {
      if (index > -1) {
        this.config.APP_GPIO_SETTINGS.splice(index, 1)
      }

      this.removeStavkaIndex = null
    },
    submit () {
      this.loading = true

      this.createConfig({
        'key': 'APP_GPIO_SETTINGS',
        'value': this.config.APP_GPIO_SETTINGS
      })
      .then(() => {
        this.$EventBus.$emit('AppSnackbar/show', {
          color: 'success',
          text: this.$t('ui.save_success')
        })
      })
      .then(this.init)
      .catch(() => {
        this.$EventBus.$emit('AppSnackbar/show', {
          color: 'error',
          text: this.$t('ui.save_error')
        })
      })
      .finally(() => {
        this.loading = false
      })
    }
  },
  created () {
    this.loading = true
    this.init()
    .finally(() => {
      this.loading = false
    })
  }
}
</script>
