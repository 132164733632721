<template>
  <v-container fluid grid-list-md>

    <v-row>
      <v-col xs="12">
        <app-t1-read-write-card></app-t1-read-write-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col xs="12">
        <app-gpio-switch-card></app-gpio-switch-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import AuthMixin from '@/mixins/AuthMixin'
export default {
  name: 't1RazvojnaKonzolaPage',
  mixins: [AuthMixin],
  created () {
    if (!this.isSuperUser) {
      this.$router.push({
        name: 'AppPage'
      })

      return false
    }
  }
}
</script>
