<template>
  <v-container fluid grid-list-md>

    <v-layout row wrap>
      <v-flex xs12 md6>

        <v-card>

          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ $t('ui.razvojna_konzola') }}</span>
            </v-toolbar-title>
          </v-toolbar>

        <v-card-text>
          <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>
                  POSTAVI U STANJE
                </td>
                <td>
                  <v-select
                    :items="stanja"
                    item-text="name"
                    item-value="id"
                    v-model="postaviUStanje"
                  ></v-select>
                  <v-progress-linear
                    v-show="stavljamUStanje"
                    color="primary"
                    indeterminate
                  ></v-progress-linear>
                </td>
                <td>
                  <!--<v-btn icon @click="pokreniPostavljanjeStanja" class="red"><v-icon color="white">play_arrow</v-icon></v-btn>-->
                </td>
              </tr>
              <tr>
                <td>
                  AKCIJA
                </td>
                <td>
                  <v-select
                    :items="akcije"
                    item-text="name"
                    item-value="id"
                    v-model="postaviAction"
                  ></v-select>
                </td>
                <td>
                  <v-btn icon @click="doAction" class="red"><v-icon color="white">play_arrow</v-icon></v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>


        </v-card-text>
        </v-card>

      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
//import axios from 'axios'
import S1Mixin from '@/mixins/S1Mixin'
export default {
  name: 'S1DevPage',
  mixins: [S1Mixin],
  data () {
    return {
      loading: false,
      stavljamUStanje: false,
      pokreniStanje: false,
      postaviUStanje: null,
      stanjeIntervalId: null,
      postaviUStanjeLoaded: true,
      postaviAction: false,
      stanja: ['', 'CRVENO', 'ZELENO', 'TRZ'],
      akcije: ['OFF', 'RESET']
    }
  },
  methods: {
    pokreniPostavljanjeStanja () {
      if (this.postaviUStanje) {
        this.pokreniStanje = true
      } else {
        this.pokreniStanje = false
      }
    },
    postaviStanje (stanje) {
      this.stavljamUStanje = true
      this.postaviUStanjeLoaded = false
      this.s1Write(stanje)
        .finally(() => {
          this.postaviUStanjeLoaded = true
          this.stavljamUStanje = false
        })
    },
    doAction () {
      this.pokreniStanje = false
      this.s1Write(this.postaviAction)
    }
  },
  created () {
    this.stanjeIntervalId = setInterval(() => {
      if (this.postaviUStanje && this.postaviUStanjeLoaded) {
        this.postaviStanje(this.postaviUStanje)
      }
    }, 900)
  },
  destroyed () {
    clearInterval(this.stanjeIntervalId)
  }
}
</script>
