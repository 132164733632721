<template>
  <v-container fluid grid-list-sm>
    <v-col xs="12">
    <v-row>
        <v-tabs
          v-model="activeTab"
          color="grey darken-1"
          slider-color="primary"
        >
          <v-tab v-for="tab in tabs" :key="tab.id">
            {{tab.label}}
          </v-tab>

          <v-tab-item key="statistika_card">
            <v-col xs="12">
              <app-statistika-card></app-statistika-card>
            </v-col>
          </v-tab-item>

          <v-tab-item key="bus_priority">
            <v-col xs="12">
              <app-bus-priority-stats-card></app-bus-priority-stats-card>
            </v-col>
          </v-tab-item>

          <v-tab-item key="statistika_backend">
            <v-col xs="12">
              <v-row>
                <v-col xs="12" lg="6">
                  <app-statistika-backend-card></app-statistika-backend-card>
                </v-col>
                <v-col xs="12" lg="6">
                  <app-supervisor-job-log-card supervisor-config-key="statistika_backend"></app-supervisor-job-log-card>
                </v-col>
              </v-row>
            </v-col>
          </v-tab-item>

        </v-tabs>
    </v-row>
    </v-col>
  </v-container>
</template>

<script>
export default {
  name: 'StatistikaPage',
  data () {
    return {
      activeTab: null,
      tabs: [
        {
          id: 'statistika_card',
          label: this.$t('ui.statistika')
        },
        {
          id: 'bus_priority',
          label: this.$t('ui.bus_priority')
        },
        {
          id: 'statistika_backend',
          label: this.$t('ui.statistika_backend')
        }
      ]
    }
  }
}
</script>
