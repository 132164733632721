var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',[_c('v-col',{attrs:{"xs":"12"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"text-xl-left"},[_vm._v(_vm._s(this.$t('ui.detektorska_logika')))])]),_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","icon":"","disabled":_vm.loading},on:{"click":function($event){return _vm.init()}}},[_c('v-icon',[_vm._v("refresh")])],1),(_vm.isAuthenticated)?_c('v-btn',{attrs:{"disabled":_vm.loading,"color":"primary"},on:{"click":_vm.submit}},[_vm._v(_vm._s(this.$t('ui.submit')))]):_vm._e()],1),_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"color":"primary","indeterminate":""}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.parametri,"items-per-page":_vm.limit,"sort-by":['pripadajucaSg', 'pripadajuciDet'],"multi-sort":"","hide-default-footer":"","item-key":"id"},scopedSlots:_vm._u([{key:"item.zove",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{model:{value:(item.zove),callback:function ($$v) {_vm.$set(item, "zove", $$v)},expression:"item.zove"}})]}},{key:"item.produzava",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{model:{value:(item.produzava),callback:function ($$v) {_vm.$set(item, "produzava", $$v)},expression:"item.produzava"}})]}},{key:"item.memorija",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{model:{value:(item.memorija),callback:function ($$v) {_vm.$set(item, "memorija", $$v)},expression:"item.memorija"}})]}},{key:"item.odgoda",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{model:{value:(item.odgoda),callback:function ($$v) {_vm.$set(item, "odgoda", $$v)},expression:"item.odgoda"}})]}},{key:"item.interval",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{model:{value:(item.interval),callback:function ($$v) {_vm.$set(item, "interval", $$v)},expression:"item.interval"}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }