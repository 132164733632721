var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('ui.stalne_najave')))]),_c('v-spacer')],1),_c('v-card-text',[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"color":"primary","indeterminate":""}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.detektori,"items-per-page":_vm.limit,"sort-by":"detektor","hide-default-footer":"","item-key":"id"},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.statusi,"label":_vm.$t('ui.status')},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.ima_stalnu_najavu",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.imaStalnuNajavu,"label":_vm.$t('ui.ima_stalnu_najavu')},model:{value:(item.ima_stalnu_najavu),callback:function ($$v) {_vm.$set(item, "ima_stalnu_najavu", $$v)},expression:"item.ima_stalnu_najavu"}})]}},{key:"item.u_stalnoj_najavi",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.uStalnojNajavi,"label":_vm.$t('ui.u_stalnoj_najavi')},model:{value:(item.u_stalnoj_najavi),callback:function ($$v) {_vm.$set(item, "u_stalnoj_najavi", $$v)},expression:"item.u_stalnoj_najavi"}})]}},{key:"item.u_kvaru_trazi",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.uKvaru,"label":_vm.$t('ui.u_kvaru')},model:{value:(item.u_kvaru_trazi),callback:function ($$v) {_vm.$set(item, "u_kvaru_trazi", $$v)},expression:"item.u_kvaru_trazi"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }