<template>
  <div>
    <v-switch inset v-model="toggleSwitch" :disabled="loading" :label="label" @change="toggleSwitchChanged()"></v-switch>
    <app-confirm-action-dialog
      :open-dialog="openConfirmStateChangeDialog"
      :headline="`${headline}`"
      :text="`${label}`"
      @userAction="confirmStateChangeHandler"
    >
    </app-confirm-action-dialog>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'GpioSwitchHandler',
  props: {
    headline: {
      type: String,
      default: function () {
        return 'GPIO'
      }
    },
    label: {
      type: String,
      default: function () {
        return 'GPIO'
      }
    },
    url: {
      type: String,
      default: function () {
        return ''
      }
    },
    gpioAddr: {
      type: String,
      default: function () {
        return ''
      }
    },
    timeout: {
      type: Number,
      default: function () {
        return 10
      }
    }
  },
  data () {
    return {
      loading: false,
      toggleSwitch: false,
      openConfirmStateChangeDialog: false
    }
  },
  methods: {
    toggleSwitchChanged () {
      if (!this.toggleSwitch) return false

      this.openConfirmStateChangeDialog = true
    },
    confirmStateChangeHandler (actionResult) {
      if (!this.toggleSwitch) return false
      if (!actionResult) {
        this.toggleSwitch = false
        this.openConfirmStateChangeDialog = false
      } else {
        this.openConfirmStateChangeDialog = false
        this.toggleGPIO()
      }
    },
    toggleGPIO () {
      if (!this.toggleSwitch) return false

      this.loading = true
      axios.post(this.url, {
        'gpio': this.gpioAddr,
        'timeout': this.timeout
      })
      .catch(() => {
        this.$EventBus.$emit('AppSnackbar/show', {
          color: 'red',
          text: this.$t('ui.error')
        })
      })
      .finally(() => {
        this.loading = false
        this.toggleSwitch = false
      })

    }
  }
}
</script>
