<template>
  <form>

    <v-flex xs12>
      <v-combobox
        v-model="settings.device"
        :items="devices"
        label="DEVICE"
        :error-messages="deviceErrors"
        @input="$v.settings.device.$touch()"
        @blur="$v.settings.device.$touch()"
        required
        disabled
      ></v-combobox>
    </v-flex>

    <v-flex xs12>
      <v-select
        v-model="settings.ip_address_type"
        :items="ipAddressTypes"
        :label="this.$t('ui.network')"
      ></v-select>
    </v-flex>

    <v-flex xs12 v-if="settings.ip_address_type != 'dhcp'">
      <v-text-field
        v-model="settings.ip_address"
        label="IP address"
      ></v-text-field>
    </v-flex>

    <v-flex xs12 v-if="settings.ip_address_type != 'dhcp'">
      <v-text-field
        v-model="settings.netmask"
        label="Net mask"
      ></v-text-field>
    </v-flex>

    <v-flex xs12 v-if="settings.ip_address_type != 'dhcp'">
      <v-text-field
        v-model="settings.gateway"
        label="Gateway"
      ></v-text-field>
    </v-flex>

    <v-flex xs12>
      <v-combobox
        v-model="settings.dns"
        :items="dns"
        label="DNS"
        multiple
      ></v-combobox>
    </v-flex>

  </form>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'NetworkSettingsForm',
  mixins: [validationMixin],
  props: {
    settings: {
      type: Object,
      default: function () {
        return {
          label: 'lan0',
          device: 'eth0',
          ip_address_type: 'static',
          ip_address: '192.168.0.21',
          gateway: '192.168.0.1',
          netmask: '255.255.255.0',
          dns: ['1.1.1.1', '8.8.8.8', '208.67.220.220']
        }
      }
    },
    devices: {
      type: Array,
      default: function () {
        return ['eth0', 'eth1']
      }
    }
  },
  data () {
    return {
      ipAddressTypes: ['static', 'dhcp'],
      dns: ['1.1.1.1', '8.8.8.8', '208.67.220.220']
    }
  },
  validations: {
    settings: {
      device: {
        required
      }
    }
  },
  computed: {
    deviceErrors () {
      const errors = []
      if (!this.$v.settings.device.$dirty) return errors
      !this.$v.settings.device.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    isFormValid () {
      return !this.$v.$invalid
    }
  },
  watch: {
    isFormValid: {
      handler: function (newValidSate) {
        this.$emit('isConfigFormValid', newValidSate)
      }
    }
  },
  methods: {

  }
}
</script>
