<template>
  <v-container fluid grid-list-md>
    <v-row>
      <v-col xs="12">
        <app-t1-tolerancija-2-card></app-t1-tolerancija-2-card>
      </v-col>

      <v-col xs="12">
        <app-supervisor-job-log-card supervisor-config-key="t1_tolerancija_2_handler"></app-supervisor-job-log-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Toleracija2Page'
}
</script>
