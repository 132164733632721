<template>
  <v-container fluid grid-list-md>

    <v-layout row wrap>
      <v-flex xs12 md6>
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ this.$t('ui.delete') }}</span>
            </v-toolbar-title>
          </v-toolbar>

          <v-progress-linear
            v-show="loading"
            color="primary"
            indeterminate
          ></v-progress-linear>

          <v-card-text>
            <form>

              <v-flex xs12>
                <v-text-field
                  :label="this.$t('ui.code')"
                  v-model="config.key"
                  disabled
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-textarea
                  :label="this.$t('ui.value')"
                  v-model="config.value"
                  disabled
                ></v-textarea>
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  :label="this.$t('ui.description')"
                  v-model="config.description"
                  disabled
                ></v-text-field>
              </v-flex>

             </form>
          </v-card-text>
          <v-divider class="mt-2"></v-divider>
          <v-card-actions>
            <v-btn
              @click="submit"
              color="red"
              :disabled="submitAvailable ? false : true"
            >{{ this.$t('ui.delete') }}</v-btn>
          </v-card-actions>

        </v-card>
      </v-flex>
    </v-layout>

  </v-container>
</template>

<script>
import ConfigMixin from '@/mixins/ConfigMixin'

export default {
  name: 'ConfigDeletePage',
  mixins: [ConfigMixin],
  props: ['config_id'],
  data () {
    return {
      loading: false,
      config: {
        key: '',
        value: '',
        description: ''
      }
    }
  },
  computed: {
    submitAvailable () {
      if (this.loading) {
        return false
      }

      return true
    }
  },
  methods: {
    submit: function () {

      this.loading = true

      this.deleteConfig(this.config.id)
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'ConfigPage'
            })
          }, 1000)
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'red',
            text: this.$t('ui.error')
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created: function () {
    this.loading = true

    this.getConfig(this.config_id)
      .then((response) => {
        this.config = response.data
      })
      .finally(() => {
        this.loading = false
      })
  }
}
</script>
