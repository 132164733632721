<template>
  <v-icon :color="color">fiber_manual_record</v-icon>
</template>

<script>
export default {
  name: 'StatusDot',
  props: {
    status: {
      type: String,
      default: function () {
        return 'warning'
      }
    },
    activeColor: {
      type: String,
      default: function () {
        return 'green darken-2'
      }
    },
    warningColor: {
      type: String,
      default: function () {
        return 'amber darken-2'
      }
    },
    inactiveColor: {
      type: String,
      default: function () {
        return 'red darken-2'
      }
    }
  },
  computed: {
    color: function () {
      if (this.status === 'active') {
        return this.activeColor
      } else if (this.status === 'warning') {
        return this.warningColor
      } else {
        return this.inactiveColor
      }
    }
  }
}
</script>
