var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"xs":"12"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"text-xl-left"},[_vm._v(_vm._s(this.$t('ui.cameras')))])]),_c('v-spacer'),(_vm.isAuthenticated)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.addCamera}},[_vm._v(_vm._s(this.$t('ui.add')))]):_vm._e(),(_vm.isAuthenticated)?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"error"},on:{"click":_vm.openConfirmResetDialog}},[_vm._v(_vm._s(this.$t('ui.reset_cams')))]):_vm._e(),(_vm.isAuthenticated)?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","loading":_vm.resetCamerasBackendLoading},on:{"click":_vm.resetCamerasBackend}},[_vm._v(_vm._s(this.$t('ui.reset_backend')))]):_vm._e(),(_vm.isAuthenticated)?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"green","loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(_vm._s(this.$t('ui.submit')))]):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.cameraTableHeaders,"items":_vm.cameras,"loading":_vm.loading,"items-per-page":_vm.limit,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.snap_url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":("" + (item.snap_url))}},[_vm._v(_vm._s(item.snap_url))])]}},{key:"item.live_url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":("" + (item.live_url))}},[_vm._v(_vm._s(item.live_url))])]}},{key:"item.xroads_url",fn:function(ref){
var item = ref.item;
return [(item.xroads_ip)?_c('p',[_vm._v(_vm._s(item.xroads_ip)+":"+_vm._s(item.xroads_port))]):_vm._e()]}},{key:"item.snap",fn:function(ref){
var item = ref.item;
return [_c('app-camera-tile',{attrs:{"camera":item,"show-toolbar":true,"play":_vm.cameraPlay}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isAuthenticated)?_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.doEditCamera(item)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("edit")])],1):_vm._e()]}}])},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"color":"primary","indeterminate":""}})],1)],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"xs":"12","md":"6"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"text-xl-left"},[_vm._v("ZONE")])]),_c('v-spacer'),(_vm.isAuthenticated)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.addZone}},[_vm._v(_vm._s(this.$t('ui.add')))]):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.cameraZoneTableHeaders,"items":_vm.camerasZones,"loading":_vm.loading,"items-per-page":_vm.limit,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isAuthenticated)?_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.doEditZone(item)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("edit")])],1):_vm._e()]}}])},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"color":"primary","indeterminate":""}})],1)],1)],1),_c('v-col',{attrs:{"xs":"12","md":"6"}},[_c('traffic-controller-svg',{attrs:{"traffic-controller":_vm.trafficController,"stanje-signala":_vm.stanjeSignala,"stanje-detektora":_vm.stanjeDetektora,"detektor-click-enabled":_vm.detektorClickEnabled},on:{"detektorClicked":_vm.detektorClickedHandler}})],1),_c('app-camera-edit-dialog',{attrs:{"open-dialog":_vm.openCameraDialog,"camera":_vm.editCamera,"cameraTypes":_vm.cameraTypes,"cameraStreamTypes":_vm.cameraStreamTypes},on:{"userAction":_vm.handleCameraEditDialogUserAction}}),_c('app-camera-zone-edit-dialog',{attrs:{"open-dialog":_vm.openCameraZoneDialog,"cameras":_vm.cameras,"edit-camera":_vm.editCamera,"zone":_vm.editZone,"detectors":_vm.detectors,"zones":_vm.zones},on:{"userAction":_vm.handleCameraZoneEditDialogUserAction}}),_c('app-detektor-zone-edit-dialog',{ref:"detektorZoneEditDialog",attrs:{"zones":_vm.zones,"cameras":_vm.cameras,"context-cameras":_vm.showDetektorZoneCameras,"detektor":_vm.odabraniDetektor,"open-dialog":_vm.openDetektorZoneDialog},on:{"userAction":_vm.handleDetektorZoneEditDialogUserAction}},[_vm._v(" > ")]),_c('app-confirm-action-dialog',{attrs:{"open-dialog":_vm.openConfirmResetCamsDialog,"headline":_vm.$t('ui.confirm_reset_cams'),"text":_vm.$t('ui.really_reset_cams')},on:{"userAction":_vm.resetDialogUserActionHandler}}),_c('app-confirm-action-dialog',{attrs:{"open-dialog":_vm.openConfirmDeleteCamDialog,"headline":_vm.$t('ui.confirm_delete'),"text":_vm.$t('ui.really_delete_items')},on:{"userAction":_vm.deleteCamDialogActionHandler}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }