export default {
  methods: {
    getBrojGrupa () {
      return new Promise((resolve, reject) => {
        this.t1Write([this.t1.CPS, this.t1.READ, this.t1.BROJ_GRUPA])
          .then((BROJ_GRUPA) => {
            resolve(parseInt(BROJ_GRUPA[0]))
          })
          .catch((error) => {
            console.error('getBrojGrupa ERROR')
            console.error(error)
            reject(error)
          })
      })
    },
    getSignaliSnage (brojGrupa) {
      // Vraca izmjerene snage signala
      return new Promise((resolve, reject) => {
        let brojSigova = Math.ceil(brojGrupa/4)
        let promises = []
        for (let sig = 0; sig < brojSigova; sig++) {
          promises.push(this.t1Write([this.t1.SIG(sig), this.t1.READ, this.t1.SIG_SNAGE]))
        }

        Promise.all(promises)
          .catch((error) => {
            console.error('getSignaliSnage ERROR')
            console.error(error)
            reject(error)
          })
          .then((answers) => {
            let snageSignala = []

            for (let sig = 0; sig < brojSigova; sig++) {
              let c = this.$lodash.chunk(answers[sig], 4)
              let kcrv = c[0]
              let crv = c[1]
              let zut = c[2]
              let zel = c[3]

              snageSignala.push([kcrv[0], crv[0], zut[0], zel[0]])
              snageSignala.push([kcrv[1], crv[1], zut[1], zel[1]])
              snageSignala.push([kcrv[2], crv[2], zut[2], zel[2]])
              snageSignala.push([kcrv[3], crv[3], zut[3], zel[3]])
            }
            resolve(snageSignala)
          })
      })
    },
    getDetektoriPragovi () {
      // T-1 DETEKTORI PRAGOVI: [[prag1 prag2], [prag1, prag2], ...]
      return new Promise((resolve, reject) => {

        this.t1Write([this.t1.CPS, this.t1.READ, 0x2b])
          .then((DETEKTORI_PRAGOVI) => {

            let detektoriPragovi = []

            if (DETEKTORI_PRAGOVI.length == 3 && DETEKTORI_PRAGOVI[0] == 170 && DETEKTORI_PRAGOVI[1] == 3 && DETEKTORI_PRAGOVI[2] == 235 ) {
              // T-1 NE PODRŽAVA FUNKCIJU
              console.warn('getDetektoriPragovi T-1 NE PODRŽAVA')
              resolve([])
            } else {
              let _detektoriPragovi = this.$lodash.chunk(DETEKTORI_PRAGOVI, 4)
              for (let i=0; i < _detektoriPragovi.length; i++) {
                let [prag0_1, prag0_2, prag1_1, prag1_2] = _detektoriPragovi[i]
                let prag1 = (prag0_2*256)+prag0_1
                let prag2 = (prag1_2*256)+prag1_1
                detektoriPragovi.push([prag1, prag2])
              }
              resolve(detektoriPragovi)
            }
          })
          .catch((error) => {
            console.error('getDetektoriPragovi ERROR')
            console.error(error)
            reject(error)
          })
      })
    },
    saveDetektorPrag1 (detektorId, prag1) {
      // detektorId krece od 1
      return new Promise((resolve, reject) => {
        let value = parseInt(prag1)
        let high = parseInt(value/256)
        let low = value - high*256

        this.t1Write([this.t1.CPS, this.t1.WRITE, 0x2c, detektorId, low, high])
          .then((ANSWER) => {
            resolve(ANSWER)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveDetektorPrag2 (detektorId, prag2) {
      return new Promise((resolve, reject) => {
        let value = parseInt(prag2)
        let high = parseInt(value/256)
        let low = value - high*256

        this.t1Write([this.t1.CPS, this.t1.WRITE, 0x2d, detektorId, low, high])
          .then((ANSWER) => {
            resolve(ANSWER)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getDetektoriRezolucijaRebalans () {
      // T-1 DETEKTORI REZOLUCIJA I REBALANS: [[rezolucija, rebalans], [rezolucija, rebalans], ...]
      return new Promise((resolve, reject) => {

        let rezolucijaRebalans = []

        this.t1Write([this.t1.CPS, this.t1.READ, 0x2e])
          .then((REZOLUCIJA_REBALANS) => {
            if (REZOLUCIJA_REBALANS.length == 3 && REZOLUCIJA_REBALANS[0] == 170 && REZOLUCIJA_REBALANS[1] == 3 && REZOLUCIJA_REBALANS[2] == 235 ) {
              console.warn('getDetektoriRezolucijaRebalans T-1 NE PODRŽAVA')
              resolve([])
            } else {
              let _rezolucijaRebalans = this.$lodash.chunk(REZOLUCIJA_REBALANS, 4)
              for (let i=0; i < _rezolucijaRebalans.length; i++) {
                let [rebalans_1, rebalans_2, rezolucija_1, rezolucija_2] = _rezolucijaRebalans[i]
                let rezolucija = (parseInt(rezolucija_2)*256)+parseInt(rezolucija_1)
                let rebalans = parseFloat(((parseInt(rebalans_2)*256)+parseInt(rebalans_1))/10.0)
                rezolucijaRebalans.push([rezolucija, rebalans])
              }
              resolve(rezolucijaRebalans)
            }
          })
          .catch((error) => {
            console.error('getDetektoriRezolucijaRebalans ERROR')
            console.error(error)
            reject(error)
          })
      })
    },
    saveDetektorRezolucija (detektorId, rezolucija) {
      // detektoriId procinje od 1
      return new Promise((resolve, reject) => {
        let value = parseInt(rezolucija)
        let high = parseInt(value/256)
        let low = value - high*256

        this.t1Write([this.t1.CPS, this.t1.WRITE, 0x30, detektorId, low, high])
          .then((ANSWER) => {
            resolve(ANSWER)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveDetektorRebalans (detektorId, rebalans) {
      // detektoriId procinje od 1
      return new Promise((resolve, reject) => {
        let value = parseInt(rebalans)*10
        let high = parseInt(value/256)
        let low = value - high*256

        this.t1Write([this.t1.CPS, this.t1.WRITE, 0x2f, detektorId, low, high])
          .then((ANSWER) => {
            resolve(ANSWER)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getDetektoriNoise () {
      // T-1 DETEKTORI NOISE: [noise, noise, noise, ...]
      return new Promise((resolve, reject) => {

        let detektoriNoise = []

        this.t1Write([this.t1.CPS, this.t1.READ, 0x50])
          .then((NOISE) => {
            if (NOISE.length == 3 && NOISE[0] == 170 && NOISE[1] == 3 && NOISE[2] == 235 ) {
              console.warn('getDetektoriNoise T-1 NE PODRŽAVA')
              resolve([])
            } else {
              let _noise = this.$lodash.chunk(NOISE, 2)
              for (let i=0; i < _noise.length; i++) {
                let noiseLow = parseInt(_noise[i][0])
                let noiseHigh = parseInt(_noise[i][1])
                let noise = (noiseHigh*256)+noiseLow
                if (!Number.isInteger(noise) || Number.isNaN(noise)) {
                  noise = 0
                }
                detektoriNoise.push(noise)
              }
              resolve(detektoriNoise)
            }
          })
          .catch((error) => {
            console.error('getDetektoriNoise ERROR')
            console.error(error)
            reject(error)
          })
      })
    },
    saveDetektorNoise (detektorId, noise) {
      // detektorId procinje od 1
      return new Promise((resolve, reject) => {
        if (!Number.isInteger(detektorId) || !Number.isInteger(noise)) {
          console.log([detektorId, noise])
          reject(this.$t('ui.error_expecting_integer_as_noise_param'))
          return
        }

        let value = parseInt(noise)
        let high = parseInt(value/256)
        let low = value - high*256

        this.t1Write([this.t1.CPS, this.t1.WRITE, 0x50, detektorId, low, high])
          .then((ANSWER) => {
            resolve(ANSWER)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getStabilnostPetlje () {
      return new Promise((resolve, reject) => {
        this.t1Write([this.t1.CPS, this.t1.READ, 0x51])
          .then((ANSWER) => {
            let detektoriStabilnostPetlje = []
            if (ANSWER.length == 3 && ANSWER[0] == 170 && ANSWER[1] == 3 && ANSWER[2] == 235 ) {
              console.warn('getStabilnostPetlje T-1 NE PODRŽAVA')
              resolve([])
            } else {
              let _answer = this.$lodash.chunk(ANSWER, 2)
              for (let i=0; i < _answer.length; i++) {
                let answerLow = parseInt(_answer[i][0])
                let answerHigh = parseInt(_answer[i][1])
                let stabilnostPetlje = (answerHigh*256)+answerLow
                if (!Number.isInteger(stabilnostPetlje) || Number.isNaN(stabilnostPetlje)) {
                  stabilnostPetlje = 0
                }
                detektoriStabilnostPetlje.push(stabilnostPetlje)
              }
              resolve(detektoriStabilnostPetlje)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getKoeficijentStabilnostiPetlje () {
      return new Promise((resolve, reject) => {
        this.t1Write([this.t1.CPS, this.t1.READ, 0x52])
          .then((ANSWER) => {
            let detektoriKoeficijentStabilnostiPetlje = []
            if (ANSWER.length == 3 && ANSWER[0] == 170 && ANSWER[1] == 3 && ANSWER[2] == 235 ) {
              console.warn('getKoeficijentStabilnostiPetlje T-1 NE PODRŽAVA')
              resolve([])
            } else {
              let _answer = this.$lodash.chunk(ANSWER, 2)
              for (let i=0; i < _answer.length; i++) {
                let answerLow = parseInt(_answer[i][0])
                let answerHigh = parseInt(_answer[i][1])
                let koefStabilnostPetlje = (answerHigh*256)+answerLow
                if (!Number.isInteger(koefStabilnostPetlje) || Number.isNaN(koefStabilnostPetlje)) {
                  koefStabilnostPetlje = 0
                }
                detektoriKoeficijentStabilnostiPetlje.push(koefStabilnostPetlje)
              }
              resolve(detektoriKoeficijentStabilnostiPetlje)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveKoeficijentStabilnostiPetlje (detektorId, koef) {
      // detektorId procinje od 1
      return new Promise((resolve, reject) => {
        if (!Number.isInteger(detektorId) || !Number.isInteger(koef)) {
          console.log([detektorId, koef])
          reject(this.$t('ui.error_expecting_integer_as_koef_stabilnost_petlje_param'))
          return
        }

        let value = parseInt(koef)
        let high = parseInt(value/256)
        let low = value - high*256

        this.t1Write([this.t1.CPS, this.t1.WRITE, 0x52, detektorId, low, high])
          .then((ANSWER) => {
            resolve(ANSWER)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getDetektoriLogika () {
      return this.t1Write([this.t1.CPS, this.t1.READ, 0x25])
    },
    getDetektoriOdgoda () {
      return this.t1Write([this.t1.CPS, this.t1.READ, 0x23])
    },
    saveDetektorskaLogikaOdgoda (logikaId, value) {
      // logikaId = redni broj detektorske logike
      // value ocekujem da je vec * 10
      return this.t1Write([this.t1.CPS, this.t1.WRITE, 0x24, logikaId, value])
    },
    getDetektoriInterval () {
      return this.t1Write([this.t1.CPS, this.t1.READ, 0x26])
    },
    saveDetektorskaLogikaInterval (logikaId, value) {
      // logikaId = redni broj detektorske logike
      // value ocekujem da je vec * 10
      return this.t1Write([this.t1.CPS, this.t1.WRITE, 0x27, logikaId, value])
    },
    saveDetektorskaLogikaTraziZeleno (logikaId, value) {
      // logikaId = redni broj detektorske logike
      // value ocekujem da je 0 ili 1
      return this.t1Write([this.t1.CPS, this.t1.WRITE, 0x33, logikaId, value])
    },
    saveDetektorskaLogikaMemorija (logikaId, value) {
      // logikaId = redni broj detektorske logike
      // value ocekujem da je 0 ili 1
      return this.t1Write([this.t1.CPS, this.t1.WRITE, 0x2a, logikaId, value])
    },
    saveDetektorskaLogikaProduzava (logikaId, value) {
      // logikaId = redni broj detektorske logike
      // value ocekujem da je 0 ili 1
      return this.t1Write([this.t1.CPS, this.t1.WRITE, 0x35, logikaId, value])
    },
    getSignaliSnageDozvoljenoOdstupanjePrag1 (brojGrupa) {
      // T-1 DOZVOLJENO ODSTUPANJE PRAG 1: [[30, 30, 30, 30], [30, 30, 30, 30], ....]
      return new Promise((resolve, reject) => {
        this.t1Write([this.t1.CPS, this.t1.READ, 0x1e])
          .then((ANSWER) => {
            resolve(this.$lodash.chunk(ANSWER, 4).slice(0, brojGrupa))
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveSignaliSnageDozvoljenoOdstupanjePrag1 (grupaId, signal, vrijednost) {
      // signal values: 1-crv, 2-kcrv, 3-žut, 4-zel
      return this.t1Write([this.t1.CPS, this.t1.WRITE, 0x21, grupaId, signal, vrijednost])
    },
    getSignaliSnageDozvoljenoOdsupanjePrag2 (brojGrupa) {
      // T-1 DOZVOLJENO ODSTUPANJE PRAG 2: [[100, 100, 100, 100], [100, 100, 100, 100], ...]
      return new Promise((resolve, reject) => {
        this.$api.get(`/config/?key=T1_DOZVOLJENO_ODSTUPANJE_PRAG_2`, {baseURL: this.$store.getters['app/APP_API_URI']})
          .then((response) => {
            let grupe = JSON.parse(response.data.value).slice(0, brojGrupa)

            // Prođi sve vrijednosti i pretvori ih u broj
            for (let i = 0; i < grupe.length; i ++) {
              for (let j = 0; j < grupe[i].length; j++) {
                grupe[i][j] = parseInt(grupe[i][j])
              }
            }

            resolve(grupe)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getNominalneSnageSignalaFull (brojGrupa) {
      // T-1 NOMINALNE SNAGE SIGNALA FULL ( Bez dimminga ) [[200, 200, 200, 200], [200, 200, 200, 200], ...]
      return new Promise((resolve, reject) => {
        this.t1Write([this.t1.CPS, this.t1.READ, 0x1c])
          .then((ANSWER) => {
            resolve(this.$lodash.chunk(ANSWER, 4).slice(0, brojGrupa))
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveNominalneSnageSignalaFull (grupaId, signalId, signalValue) {
      // SPREMI T-1 NOMINALNE SNAGE SIGNALA FULL ( Bez dimminga )
      return new Promise((resolve, reject) => {
        this.t1Write([this.t1.CPS, this.t1.WRITE, 0x1f, grupaId, signalId, signalValue])
          .then((ANSWER) => {
            resolve(ANSWER)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getNominalneSnageSignalaDimming(brojGrupa) {
      // T-1 NOMINALNE SNAGE SIGNALA DIMMING ( u dimming modu ) [[100, 100, 100, 100], [100, 100, 100, 100], ...]
      return new Promise((resolve, reject) => {
        this.t1Write([this.t1.CPS, this.t1.READ, 0x1d])
          .then((ANSWER) => {
            resolve(this.$lodash.chunk(ANSWER, 4).slice(0, brojGrupa))
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveNominalneSnageSignalaDimming (grupaId, signalId, signalValue) {
      // SPREMI T-1 NOMINALNE SNAGE SIGNALA U DIMMING MODU
      return new Promise((resolve, reject) => {
        this.t1Write([this.t1.CPS, this.t1.WRITE, 0x20, grupaId, signalId, signalValue])
          .then((ANSWER) => {
            resolve(ANSWER)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getSignaliSnageGain (brojGrupa) {
      // T-1 GAIN DATA: vraca gain po sigovima?
      return new Promise((resolve, reject) => {
        let brojSigova = Math.ceil(brojGrupa/4)
        let promises = []
        let gainData = []

        for (let i = 0; i < brojSigova; i++) {
          promises.push(this.t1Write([this.t1.CPS, this.t1.READ, this.t1.GAIN, this.t1.SIG(i)]))
        }

        Promise.all(promises)
          .catch((error) => {
            reject(error)
          })
          .then((answers) => {
            for (let i = 0; i < brojSigova; i++) {
              gainData.push(answers[i])
            }
            resolve(gainData)
          })
      })
    },
    saveSignaliSnageGain (sigAddr, gainData, defaultGain) {
      // T-1 SREMI GAIN ZA SIG
      // sigAddr: 0xF0, 0xF1, 0xF2
      // gainData: [A1, A2, A1, A2, A1, A2, A1, A2] -> 4 Grupe za SIG, A1, A2
      // defaultGain: [A1, A2]
      return this.t1Write([this.t1.CPS, this.t1.WRITE, this.t1.GAIN, sigAddr,
        gainData[0], gainData[1],
        gainData[2], gainData[3],
        gainData[4], gainData[5],
        gainData[6], gainData[7],
        defaultGain[0], defaultGain[1]])
    },
    getZelenaVremenaSignalnihGrupa () {
      // ZELENA VREMENA SIGNALNIH GRUPA: [[min, max], [min, max], [min, max], ....]
      return new Promise((resolve, reject) => {
        let promises = []
        let grupe = []

        // MINIMALNA VREMENA ZELENIH VREMENA
        promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x0c]))

        // MAKSIMALNA VREMENA ZELENIH VREMENA
        promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x0d]))

        Promise.all(promises)
          .then(([MIN_ZELENA, MAX_ZELENA]) => {
            let minVremena = this.$lodash.chunk(MIN_ZELENA, 2)

            for (let i = 0; i < minVremena.length; i++) {
              let minVrijeme = (minVremena[i][0]+256*minVremena[i][1])/10.0
              grupe.push([minVrijeme, null])
            }

            let maxVremena = this.$lodash.chunk(MAX_ZELENA, 2)

            for (let i = 0; i < maxVremena.length; i++) {
              let maxVrijeme = (maxVremena[i][0]+256*maxVremena[i][1])/10.0
              grupe[i][1] = maxVrijeme
            }

            resolve(grupe)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveZelenaVremenaSignalnihGrupa (zelenaVremena) {
      return new Promise((resolve, reject) => {
        let promises = []
        let value = null
        let high = null
        let low = null

        for (let grupaId = 0; grupaId < zelenaVremena.length; grupaId++) {
          // MINIMALNO ZELENO
          value = parseFloat(zelenaVremena[grupaId][0])*10
          value = value.toFixed(0)
          high = parseInt(value/256)
          low = value - high*256
          promises.push(this.t1Write([this.t1.CPS, this.t1.WRITE, 0x13, grupaId+1, low, high]))

          // MAKSIMALNO ZELENO
          value = parseFloat(zelenaVremena[grupaId][1])*10
          value = value.toFixed(0)
          high = parseInt(value/256)
          low = value - high*256
          promises.push(this.t1Write([this.t1.CPS, this.t1.WRITE, 0x14, grupaId+1, low, high]))
        }

        Promise.all(promises)
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getBrojLokalnihPrograma () {
      // Vraca koiliko ima lokalnih programa,
      // npr. 3
      return new Promise((resolve, reject) => {
        this.t1Write([this.t1.CPS, this.t1.READ, 0x46])
          .then((LOKALNI_PROGRAMI) => {
            resolve(LOKALNI_PROGRAMI[0])
          })
          .catch((error) => {
            reject(error)
          })
      })

    },
    getLokalniProgramMaxZelenaVremena (lokalniProgram) {
      // lokalniProgram = 1,2,3,4
      // Vraca maksimalna zelena vremena za odabrani program
      // [1,5,6,....]
      return new Promise((resolve, reject) => {
        this.t1Write([this.t1.CPS, this.t1.READ, 0x45, lokalniProgram])
          .then((ANSWER) => {
            let grupe = this.$lodash.chunk(ANSWER, 2)
            let vremena = []

            for (let i = 0; i < grupe.length; i++) {
              vremena.push((grupe[i][0]+256*grupe[i][1])/10.0)
            }

            resolve(vremena)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveLokalniProgramVremena (lokalniProgram, vremena) {
      return new Promise((resolve, reject) => {
        let promises = []
        let low = null
        let high = null
        let value = null

        for (let i = 0; i < vremena.length; i++) {
          value = parseFloat(vremena[i])*10
          value = value.toFixed(0)
          high = parseInt(value/256)
          low = value - high*256
          promises.push(this.t1Write([this.t1.CPS, this.t1.WRITE, 0x44, lokalniProgram, i+1, low, high]))
        }

        Promise.all(promises)
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getMaksimalnaZelenaVremenaSignalnihGrupa(brojGrupa) {
      // Vraca Maksimalna zelena vremena za sve programe
      // [BROJ_GRUPA, MAX_ZELENO_VRIJEME_GRUPA_1_PROGRAM_1, MAX_ZELENO_VRIJEME_GRUPA_2_PROGRAM_1, MAX_ZELENO_VRIJEME_GRUPA_1_PROGRAM_2, MAX_ZELENO_VRIJEME_GRUPA_2_PROGRAM_2,...]
      return new Promise((resolve, reject) => {
          this.getBrojLokalnihPrograma()
            .then((BROJ_LOKALNIH_PROGRAMA) => {

              let maksimalnaZelenaVremenaPromises = []
              let maksimalnaZelenaVremena = [brojGrupa]

              for (let program = 1; program <= BROJ_LOKALNIH_PROGRAMA; program++) {
                maksimalnaZelenaVremenaPromises.push(this.getLokalniProgramMaxZelenaVremena(program))
              }

              Promise.all(maksimalnaZelenaVremenaPromises)
                .then(() => {

                  for (let i = 0; i < maksimalnaZelenaVremenaPromises.length; i++) {
                    maksimalnaZelenaVremenaPromises[i]
                      .then((vremena) => {

                        for (let j = 0; j < vremena.length; j++) {
                          maksimalnaZelenaVremena.push(vremena[j])
                        }

                      })
                  }

                  resolve(maksimalnaZelenaVremena)
                })
                .catch((error) => {
                  reject(error)
                })
            })
      })
    },
    getLDRPostavke () {
      // Vraca trenutne postavke dimmera i vrijednost sa LDR-a
      return new Promise((resolve, reject) => {

        this.t1Write([this.t1.CPS, this.t1.READ, 0x38])
          .then((LDR) => {
            resolve({
              prag: LDR[0]+256*LDR[1],
              hister: LDR[2]+256*LDR[3],
              trelax: LDR[4]+256*LDR[5],
              val: LDR[6]+256*LDR[7]
            })
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveLDRPostavke (LDR) {
      // Sprema LDR postavke
      // Ocekuje objekt npr.:
      // {
      //    prag: 50,
      //    hister: 10,
      //    trelax: 50
      // }

      return new Promise((resolve, reject) => {

        let pragHigh = parseInt(LDR.prag/256)
        let pragLow = parseInt(LDR.prag) - pragHigh*256
        let histerHigh = parseInt(LDR.hister/256)
        let histerLow = parseInt(LDR.hister) - histerHigh*256
        let trelaxHigh = parseInt(LDR.trelax/256)
        let trelaxLow = parseInt(LDR.trelax) - trelaxHigh*256

        this.t1Write([this.t1.CPS, this.t1.WRITE, 0x38, parseInt(pragLow), parseInt(pragHigh), parseInt(histerLow), parseInt(histerHigh), parseInt(trelaxLow), parseInt(trelaxHigh)])
          .then((ANSWER) => {
            resolve(ANSWER)
          })
          .catch((error) => {
            reject(error)
          })

      })
    },
    getTemperaturaCPS () {
      return new Promise((resolve, reject) => {

        this.t1Write([this.t1.CPS, this.t1.READ, 0x0a])
          .then((ANSWER) => {
            let temp = []

            for (let i = 0; i < ANSWER.length; i++) {
              temp.push(String.fromCharCode(ANSWER[i]))
            }

            temp = temp.join('')

            resolve(`${temp} °C`)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getNapon () {
      return new Promise((resolve, reject) => {

        this.t1Write([this.t1.CPS, this.t1.READ, 0x37])
          .then((ANSWER) => {
            let o = {
              'u1': `${ANSWER[0]/10.0} VDC`,
              'u2': `${ANSWER[1]/10.0} VDC`,
              'dimmerStatus': this.$t('ui.ok_dimmer_iskljucen')
            }

            if (ANSWER[3] === 1) {
              o['dimmerStatus'] = this.$t('ui.ok_dimmer_ukljucen')
            }

            resolve(o)
          })
          .catch((error) => {
            console.error(`ERROR: getNapon`)
            console.error(error)
            reject(error)
          })
      })
    },
    getUptime () {
      return new Promise((resolve, reject) => {
        this.t1Write([this.t1.CPS, this.t1.READ, 0x19])
          .then((ANSWER) => {
            let dayLow =  ANSWER[3]
            let dayHigh =  ANSWER[4]
            let day = dayHigh*256+dayLow

            resolve({
              's': ANSWER[0].toString().padStart(2, '0'),
              'm': ANSWER[1].toString().padStart(2, '0'),
              'h': ANSWER[2].toString().padStart(2, '0'),
              'd': day.toString().padStart(2, '0')
            })
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getCpsTime () {
      return new Promise((resolve, reject) => {
        this.t1Write([this.t1.CPS, this.t1.READ, 0x01])
          .then((ANSWER) => {
            let weekdays = [
              null,
              this.$t('ui.ponedjeljak'),
              this.$t('ui.utorak'),
              this.$t('ui.srijeda'),
              this.$t('ui.četvrtak'),
              this.$t('ui.petak'),
              this.$t('ui.subota'),
              this.$t('ui.nedjelja')
            ]

            resolve({
              'h': ANSWER[0].toString().padStart(2, '0'),
              'm': ANSWER[1].toString().padStart(2, '0'),
              's': ANSWER[2].toString().padStart(2, '0'),
              'date': ANSWER[3].toString().padStart(2, '0'),
              'month': ANSWER[4].toString().padStart(2, '0'),
              'year': ANSWER[5]+2000,
              'weekday': weekdays[ANSWER[6]]
            })
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    setCpsTime (setTime) {
      return new Promise((resolve, reject) => {
        this.t1Write([this.t1.CPS, this.t1.WRITE, 0x01, setTime.hour, setTime.minute, setTime.second, setTime.day, setTime.month, setTime.year, setTime.weekday])
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getClientTime (inputDate) {
      let nowObj = new Date()

      if (inputDate) {
        nowObj = inputDate
      }

      var now = {
        hour: nowObj.getHours(),
        minute: nowObj.getMinutes(),
        second: nowObj.getSeconds(),
        year: nowObj.getFullYear()-2000,
        month: nowObj.getMonth()+1,
        day: nowObj.getDate(),
        weekday: nowObj.getDay()
      }

      if(now.weekday === 0) {
        now.weekday = 7
      }

      return now
    },
    resetToFactoryDefaults () {
      return new Promise((resolve, reject) => {
        this.t1Write([this.t1.CPS, this.t1.WRITE, 0x36, 13])
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getAktivniPTPNaziv (ANSWER) {
      let ptp = parseInt(ANSWER[11])
      if (ptp === 253 ) {
        return this.$t('ui.nije_predviđeno')
      } else if (ptp === 254 ) {
        return this.$t('ui.greska_koordinacije_LOKALNI_RAD')
      } else if (ptp === 255 ) {
        return this.$t('ui.nije_ukljuceno')
      } else {
        return ptp
      }
    },
    parseCiklusInfo (ANSWER) {
      let ciklusInfo = null
      if (ANSWER.length == 3 && ANSWER[0] == 170 && ANSWER[1] == 3 && ANSWER[2] == 235) {
        console.warn('getSekundaOffset T-1 NE PODRŽAVA')
      } else {
        ciklusInfo = {
          'ciklus': parseInt(ANSWER[12]),
          'aktivniPTP': parseInt(ANSWER[11]),
          'aktivniPTPNaziv': this.getAktivniPTPNaziv(ANSWER),
          'offset': 0,
          'sekundaCiklusa': 0,
          'internaSekundaCiklusaMinusOffset': 0,
          'vanjskaSekundaCiklusa': 0
        }

        // OFFSET
        if (Number.isInteger(ciklusInfo['aktivniPTP']) && ciklusInfo['aktivniPTP'] < 253) {
          ciklusInfo['offset'] = parseInt(ANSWER[ciklusInfo['aktivniPTP']])
        }

        // SEKUNDA CIKLUSA
        let sekundaCiklusa = parseInt(ANSWER[9]) + ciklusInfo['offset']
        if (sekundaCiklusa >= ciklusInfo['ciklus']) {
          sekundaCiklusa = sekundaCiklusa - ciklusInfo['ciklus']
        }
        ciklusInfo['sekundaCiklusa'] = sekundaCiklusa

        // INTERNA SEKUNDA CIKLUSA - OFFSET
        if (ciklusInfo['sekundaCiklusa'] < ciklusInfo['offset']) {
          ciklusInfo['internaSekundaCiklusaMinusOffset'] = ciklusInfo['ciklus'] + ciklusInfo['sekundaCiklusa'] - ciklusInfo['offset']
        } else {
          ciklusInfo['internaSekundaCiklusaMinusOffset'] = ciklusInfo['sekundaCiklusa'] - ciklusInfo['offset']
        }

        // VANJSKA SEKUNDA CIKLUSA
        ciklusInfo['vanjskaSekundaCiklusa'] = parseInt(ANSWER[10]) + ciklusInfo['offset']

        if (ciklusInfo['vanjskaSekundaCiklusa'] >= ciklusInfo['ciklus']) {
          ciklusInfo['vanjskaSekundaCiklusa'] = ciklusInfo['vanjskaSekundaCiklusa'] - ciklusInfo['ciklus']
        }
      }

      return ciklusInfo
    },
    getCiklusInfo (ANSWER) {
      return new Promise((resolve, reject) => {
        if (ANSWER) {
          let parsed = this.parseCiklusInfo(ANSWER)
          if (parsed) {
            resolve(parsed)
          } else {
            reject('OFFSET NIJE PODRŽAN')
          }
        } else {
          this.t1Write([this.t1.CPS, this.t1.READ, 0x4b])
            .then((ANSWER) => {
              let parsed = this.parseCiklusInfo(ANSWER)
              if (parsed) {
                resolve(parsed)
              } else {
                reject('OFFSET NIJE PODRŽAN')
              }
            })
            .catch((error) => {
              reject(error)
            })
        }
      })
    },
    t1BackupSettingsDownloadCSV (brojGrupa) {
      return new Promise((resolve, reject) => {
        let prag1Row = ["prag1"]
        let prag2Row = ["prag2"]
        let nominalno1Row = ["nominalno100"]
        let nominalnoDimRow = ["nominalnoDimming"]
        let gainDataRow = ["gain"]
        let detektoriPragovi1Row = ["detektoriPragovi1"]
        let detektoriPragovi2Row = ["detektoriPragovi2"]
        let detektoriRezolucijaRow = ["detektoriRezolucija"]
        let detektoriRebalansRow = ["detektoriRebalans"]
        let detektoriNoiseRow = ["detektoriNoise"]
        let detektoriLogikaRow = ["detektoriLogika"]
        let detektoriOdgodaRow = ["detektoriOdgoda"]
        let detektoriIntervalRow = ["detektoriInterval"]
        let zelenaVremenaSignalnihGrupaRow = ["zelenaVremenaSignalnihGrupa"]
        let maksimalnaZelenaVremenaSignalnihGrupaRow = ["maksimalnaZelenaVremenaSignalnihGrupa"]
        let ldrRow = ["ldrPostavke"]
        let nmcoGeneralDataRows = []
        let nmcoGrupeDataRows = []

        let promises = [
          this.getSignaliSnageDozvoljenoOdstupanjePrag1(brojGrupa),
          this.getSignaliSnageDozvoljenoOdsupanjePrag2(brojGrupa),
          this.getNominalneSnageSignalaFull(brojGrupa),
          this.getNominalneSnageSignalaDimming(brojGrupa),
          this.getSignaliSnageGain(brojGrupa),
          this.getDetektoriPragovi(),
          this.getDetektoriRezolucijaRebalans(),
          this.getDetektoriNoise(),
          this.getDetektoriLogika(),
          this.getDetektoriOdgoda(),
          this.getDetektoriInterval(),
          this.getZelenaVremenaSignalnihGrupa(),
          this.getMaksimalnaZelenaVremenaSignalnihGrupa(brojGrupa),
          this.getLDRPostavke(),
          this.getNmcoPtpGeneralData(),
          this.getNmcoPtpGrupeData(brojGrupa)
        ]

        Promise.all(promises)
          .catch((error) => {
            console.error('t1BackupSettingsDownloadCSV ERROR')
            console.error(error)
            reject(error)
          })
          .then(([
            DOZVOLJENO_ODSTUPANJE_PRAG_1, DOZVOLJENO_ODSTUPANJE_PRAG_2,
            NOMINALNE_SNAGE_SIGNALA_FULL, NOMINALNE_SNAGE_SIGNALA_DIMMING,
            SNAGE_SIGNALA_GAIN, DETEKTORI_PRAGOVI, DETEKTORI_REZOLUCIJA_REBALANS,
            DETEKTORI_NOISE, DETEKTORI_LOGIKA, DETEKTORI_ODGODA, DETEKTORI_INTERVAL,
            ZELENA_VREMENA_SIGNALNIH_GRUPA, MAKSIMALNA_VREMENA_SIGNALNIH_GRUPA, LDR,
            NMCO_GENERAL_DATA, NMCO_PTP_GRUPE_DATA
          ]) => {

            for (let i = 0; i < DOZVOLJENO_ODSTUPANJE_PRAG_1.length; i++) {
              prag1Row.push(parseInt(DOZVOLJENO_ODSTUPANJE_PRAG_1[i][0]))
              prag1Row.push(parseInt(DOZVOLJENO_ODSTUPANJE_PRAG_1[i][1]))
              prag1Row.push(parseInt(DOZVOLJENO_ODSTUPANJE_PRAG_1[i][2]))
              prag1Row.push(parseInt(DOZVOLJENO_ODSTUPANJE_PRAG_1[i][3]))
            }

            for (let i = 0; i < DOZVOLJENO_ODSTUPANJE_PRAG_2.length; i++) {
              prag2Row.push(parseInt(DOZVOLJENO_ODSTUPANJE_PRAG_2[i][0]))
              prag2Row.push(parseInt(DOZVOLJENO_ODSTUPANJE_PRAG_2[i][1]))
              prag2Row.push(parseInt(DOZVOLJENO_ODSTUPANJE_PRAG_2[i][2]))
              prag2Row.push(parseInt(DOZVOLJENO_ODSTUPANJE_PRAG_2[i][3]))
            }

            for (let i = 0; i < NOMINALNE_SNAGE_SIGNALA_FULL.length; i++) {
              nominalno1Row.push(parseInt(NOMINALNE_SNAGE_SIGNALA_FULL[i][0]))
              nominalno1Row.push(parseInt(NOMINALNE_SNAGE_SIGNALA_FULL[i][1]))
              nominalno1Row.push(parseInt(NOMINALNE_SNAGE_SIGNALA_FULL[i][2]))
              nominalno1Row.push(parseInt(NOMINALNE_SNAGE_SIGNALA_FULL[i][3]))
            }

            for (let i = 0; i < NOMINALNE_SNAGE_SIGNALA_DIMMING.length; i++) {
              nominalnoDimRow.push(parseInt(NOMINALNE_SNAGE_SIGNALA_DIMMING[i][0]))
              nominalnoDimRow.push(parseInt(NOMINALNE_SNAGE_SIGNALA_DIMMING[i][1]))
              nominalnoDimRow.push(parseInt(NOMINALNE_SNAGE_SIGNALA_DIMMING[i][2]))
              nominalnoDimRow.push(parseInt(NOMINALNE_SNAGE_SIGNALA_DIMMING[i][3]))
            }

            for (let i = 0; i < SNAGE_SIGNALA_GAIN.length; i++) {
              for (let j = 0; j < SNAGE_SIGNALA_GAIN[i].length; j++) {
                gainDataRow.push(SNAGE_SIGNALA_GAIN[i][j])
              }
            }

            for (let i=0; i < DETEKTORI_PRAGOVI.length; i++) {
              let [prag1, prag2] = DETEKTORI_PRAGOVI[i]
              detektoriPragovi1Row.push(prag1)
              detektoriPragovi2Row.push(prag2)
            }

            for (let i=0; i < DETEKTORI_REZOLUCIJA_REBALANS.length; i++) {
              let [rezolucija, rebalans] = DETEKTORI_REZOLUCIJA_REBALANS[i]
              detektoriRezolucijaRow.push(rezolucija)
              detektoriRebalansRow.push(rebalans)
            }

            for (let i=0; i < DETEKTORI_NOISE.length; i++) {
              detektoriNoiseRow.push(DETEKTORI_NOISE[i])
            }

            for (let i=0; i < DETEKTORI_LOGIKA.length; i++) {
              detektoriLogikaRow.push(DETEKTORI_LOGIKA[i])
            }

            for (let i=0; i < DETEKTORI_ODGODA.length; i++) {
              detektoriOdgodaRow.push(DETEKTORI_ODGODA[i])
            }

            for (let i=0; i < DETEKTORI_INTERVAL.length; i++) {
              detektoriIntervalRow.push(DETEKTORI_INTERVAL[i])
            }

            for (let i=0; i < ZELENA_VREMENA_SIGNALNIH_GRUPA.length; i++) {
              zelenaVremenaSignalnihGrupaRow.push(ZELENA_VREMENA_SIGNALNIH_GRUPA[i])
            }

            for (let i=0; i < MAKSIMALNA_VREMENA_SIGNALNIH_GRUPA.length; i++) {
              maksimalnaZelenaVremenaSignalnihGrupaRow.push(MAKSIMALNA_VREMENA_SIGNALNIH_GRUPA[i])
            }

            ldrRow.push(LDR.prag)
            ldrRow.push(LDR.hister)
            ldrRow.push(LDR.trelax)

            for (let i=0; i < NMCO_GENERAL_DATA.length; i++) {
              let nmcoGeneralData = NMCO_GENERAL_DATA[i]
              let ptp = nmcoGeneralData['ptp']
              if (ptp === 0) {
                ptp = 9
              }
              nmcoGeneralDataRows.push([
                `NMCO_GENERAL_DATA_${ptp}`,
                nmcoGeneralData['cycle'],
                nmcoGeneralData['offset'],
                nmcoGeneralData['ftc'],
                nmcoGeneralData['trz'],
                nmcoGeneralData['signals_off']
              ])
            }

            // groupBy
            // NMCO_PTP_GRUPE_DATA

            let gr = this.$lodash.groupBy(NMCO_PTP_GRUPE_DATA, 'ptp')
            console.log(gr)
            for (let ptp = 1; ptp <=9; ptp++) {
              let grupe = gr[ptp]
              console.log(grupe)
              let row = [`NMCO_GRUPE_DATA_${ptp}`]
              let rowString = []
              for (let j=0; j<grupe.length; j++) {
                let grupa = grupe[j]
                rowString.push(`${grupa['start']};${grupa['stop']};${grupa['zahtjev']};${grupa['privilegija']}`)
              }
              row.push(rowString.join(','))
              nmcoGrupeDataRows.push(row)
            }

          })
          .then(() => {
            let redovi = [
              prag1Row, nominalno1Row, nominalnoDimRow, gainDataRow,
              prag2Row, detektoriPragovi1Row, detektoriPragovi2Row, detektoriRezolucijaRow,
              detektoriRebalansRow, detektoriNoiseRow, detektoriLogikaRow, detektoriOdgodaRow,
              detektoriIntervalRow, zelenaVremenaSignalnihGrupaRow, maksimalnaZelenaVremenaSignalnihGrupaRow,
              ldrRow
            ]

            for (let i=0; i < nmcoGeneralDataRows.length; i++) {
              redovi.push(nmcoGeneralDataRows[i])
            }

            for (let i=0; i < nmcoGrupeDataRows.length; i++) {
              redovi.push(nmcoGrupeDataRows[i])
            }

            let csv = redovi.map(e => e.join(",")).join('\r\n')
            let serBr = this.$store.getters['t1/serBr']

            let file = new Blob([csv], {type: 'text/plain'})
            let a = document.createElement('a'), url = URL.createObjectURL(file)
            a.href = url
            a.download = `${serBr}_T1_POSTAVKE.csv`
            document.body.appendChild(a)
            a.click()
            setTimeout(function() {
              document.body.removeChild(a)
              window.URL.revokeObjectURL(url)
            }, 0)
            resolve()
          })
      })
    },
    t1HandleCVSBackupUpload (csvFile) {

      return new Promise((resolve, reject) => {

        const reader = new FileReader()

        reader.addEventListener('load', (event) => {

          reader.removeEventListener('load', this)

          let csvData = event.target.result.split('\r\n')

          let promises = []

          for (let i = 0; i < csvData.length; i++) {
            let row = csvData[i].split(',')
            let id = row.shift()
            if (id === 'prag1') {
              // TOLERANCIJA 1
              let grupe = this.$lodash.chunk(row, 4)
              for (let grupaId = 0; grupaId < grupe.length; grupaId++) {
                let grupa = grupe[grupaId]
                for (let signal = 0; signal < grupa.length; signal++) {
                  let prom1se = this.saveSignaliSnageDozvoljenoOdstupanjePrag1(grupaId+1, signal+1, parseInt(grupa[signal]))
                  promises.push(prom1se)
                }
              }
            } else if (id === 'prag2') {
              // TOLERANCIJA 2
              let prag2 = []
              while (row.length > 0) {
                prag2.push([
                  parseInt(row.shift()), parseInt(row.shift()), parseInt(row.shift()), parseInt(row.shift())
                ])
              }

              let _promise = this.createConfig({
                'key': 'T1_DOZVOLJENO_ODSTUPANJE_PRAG_2',
                'value': prag2
              })
              promises.push(_promise)

            } else if (id === 'nominalno100') {
              let grupe = this.$lodash.chunk(row, 4)
              for (let grupaId = 0; grupaId < grupe.length; grupaId++) {
                let grupa = grupe[grupaId]
                for (let signal = 0; signal < grupa.length; signal++) {
                  let prom1se = this.saveNominalneSnageSignalaFull(grupaId+1, signal+1, parseInt(grupa[signal]))
                  promises.push(prom1se)
                }
              }
            } else if (id === 'nominalnoDimming') {
              let grupe = this.$lodash.chunk(row, 4)
              for (let grupaId = 0; grupaId < grupe.length; grupaId++) {
                let grupa = grupe[grupaId]
                for (let signal = 0; signal < grupa.length; signal++) {
                  let prom1se = this.saveNominalneSnageSignalaDimming(grupaId+1, signal+1, parseInt(grupa[signal]))
                  promises.push(prom1se)
                }
              }
            } else if (id === 'gain') {
              let gain = this.$lodash.chunk(row, 10)

              for (let i = 0; i < gain.length; i++) {
                let sigGrupeGain = [parseInt(gain[i][0]), parseInt(gain[i][1]), parseInt(gain[i][2]), parseInt(gain[i][3]), parseInt(gain[i][4]), parseInt(gain[i][5]), parseInt(gain[i][6]), parseInt(gain[i][7])]
                let sigDefaultGain = [parseInt(gain[i][8]), parseInt(gain[i][9])]
                promises.push(this.saveSignaliSnageGain(this.t1.SIG(i), sigGrupeGain, sigDefaultGain))
              }
            } else if (id === 'detektoriPragovi1') {
              for (let detektorId = 0; detektorId < row.length; detektorId++) {
                let prag1 = row[detektorId]
                promises.push(this.saveDetektorPrag1(detektorId+1, prag1))
              }
            } else if (id === 'detektoriPragovi2') {
              for (let detektorId = 0; detektorId < row.length; detektorId++) {
                let prag2 = row[detektorId]
                promises.push(this.saveDetektorPrag2(detektorId+1, prag2))
              }
            } else if (id === 'detektoriRezolucija') {
              for (let detektorId = 0; detektorId < row.length; detektorId++) {
                let detektorRezolucija = row[detektorId]
                promises.push(this.saveDetektorRezolucija(detektorId+1, detektorRezolucija))
              }
            } else if (id === 'detektoriRebalans') {
              for (let detektorId = 0; detektorId < row.length; detektorId++) {
                let detektorRebalans = row[detektorId]
                promises.push(this.saveDetektorRebalans(detektorId+1, detektorRebalans))
              }
            } else if (id === 'detektoriNoise') {
              for (let detektorId = 0; detektorId < row.length; detektorId++) {
                let detektorNoise = row[detektorId]
                promises.push(this.saveDetektorNoise(detektorId+1, parseInt(detektorNoise)))
              }
            } else if (id === 'detektoriLogika') {
              let _detektoriLogika = this.$lodash.chunk(row, 4)

              for (let id = 0; id < _detektoriLogika.length; id++) {
                // eslint-disable-next-line
                let [_, funkcija, sg, brDet] = _detektoriLogika[id]
                promises.push(this.saveDetektorskaLogikaTraziZeleno(id+1, parseInt(funkcija) & 0x01))
                promises.push(this.saveDetektorskaLogikaProduzava(id+1, parseInt(funkcija) & 0x02))
                promises.push(this.saveDetektorskaLogikaMemorija(id+1, parseInt(funkcija) & 0x08))
              }
            } else if (id === 'detektoriOdgoda') {

              for (let id = 0; id < row.length; id++) {
                promises.push(this.saveDetektorskaLogikaOdgoda(id+1, row[id]))
              }

            } else if (id === 'detektoriInterval') {

              for (let id = 0; id < row.length; id++) {
                promises.push(this.saveDetektorskaLogikaInterval(id+1, row[id]))
              }

            } else if (id === 'zelenaVremenaSignalnihGrupa') {
              promises.push(this.saveZelenaVremenaSignalnihGrupa(this.$lodash.chunk(row, 2)))
            } else if (id === 'maksimalnaZelenaVremenaSignalnihGrupa') {
              let brojGrupa = row.shift()
              let programiMaxVremena = this.$lodash.chunk(row, brojGrupa)
              for (let i = 0; i < programiMaxVremena.length; i++) {
                promises.push(this.saveLokalniProgramVremena(i +1 , programiMaxVremena[i]))
              }
            } else if (id === 'ldrPostavke') {
              let [prag, hister, trelax] = row
              promises.push(this.saveLDRPostavke({'prag': prag, 'hister': hister, 'trelax': trelax}))
            }
          }

          Promise.all(promises)
            .then(() => {
              resolve()
            })
            .catch((error) => {
              reject(error)
            })

        })

        reader.readAsText(csvFile)

      })
    },
    t1getVprogConfig () {
      return new Promise((resolve, reject) => {

        this.t1Write([this.t1.CPS, this.t1.READ, 0x47])
            .then((VPROG_CONFIG) => {
              if (VPROG_CONFIG[0] == 102 || VPROG_CONFIG[0] == 255) {
                reject('NOT_SUPPORTED')
              } else {
                // Drugi bit oznacava koliko T-1 ima programa
                // zadnji program je uvijek 'GASI SIGNALE'
                resolve(VPROG_CONFIG)
              }
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    t1getNaziviPrograma (VPROG_CONFIG) {

      return new Promise((resolve, reject) => {
        this.getConfigByKey('NAZIVI_PROGRAMA')
          .then((response) => {
            let programi = []
            let _programi = JSON.parse(response.data.value)

            for (let i = 0; i < _programi.length; i++) {
              programi.push({
                'id': i,
                'label': _programi[i]
              })
            }

            programi.push({'id': 255, 'label': 'Automatski'})

            resolve(programi)
          })
          .catch(() => {
            // Vjerojatno nema definiranih naziva programa
            // vrati default nazive
            if (!VPROG_CONFIG) {
              reject('VPROG_CONFIG ne postoji!')
            } else {
              let programi = [
                {'id': 0, 'label': 'Treptanje'},
                {'id': 255, 'label': 'Automatski'}
              ]

              // Drugi bit oznacava koliko T-1 ima programa,
              // zadnji program je uvijek 'GASI SIGNALE'
              for (let i = 1; i < VPROG_CONFIG[1]+1; i++) {
                if (i === VPROG_CONFIG[1])  {
                  programi.push({'id': i, 'label': this.$t('ui.ugasi_signale')})
                } else {
                  programi.push({'id': i, 'label': `Program ${i}`})
                }
              }
              resolve(programi)
            }
          })
      })
    },
    t1getPodrzaniProgrami () {

      return new Promise((resolve, reject) => {
        let VPROG_CONFIG = null

        this.t1getVprogConfig()
          .then((aVPROG_CONFIG) => {
            VPROG_CONFIG = aVPROG_CONFIG
          })
          .then(() => {
            this.t1getNaziviPrograma(VPROG_CONFIG)
              .then((programi) => {
                resolve(programi)
              })
              .catch((error) => {
                reject(error)
              })
          })
          .catch((error) => {
            // Ovo ce uloviti ako ne može komunicirati sa T-1 backendom
            // onda ne postoji VPROG_CONFIG
            // i onda ne možemo dalje ništa jer ne znamo koliko T-1 ima programa.
            reject(error)
          })
      })

    }
  }
}
