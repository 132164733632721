<template>
<div id="signali-holder" style="overflow: auto;">
  <canvas id="ciklus_canvas"></canvas>
</div>
</template>

<script>
export default {
  name: 't1Ciklus',
  props: {
    events: {
      type: Object,
      default: function () {
        return {}
      }
    },
    trafficController: {
      type: Object,
      default: function () {
        return {}
      }
    },
    rezolucija: {
      type: Number,
      default: 1
    },
    grupaLabelColor: {
      type: String,
      default: '#0000ff'
    },
    detektorLabelColor: {
      type: String,
      default: '#0967b2'
    },
    tickColor: {
      type: String,
      default: '#7A7A7A'
    },
    sekundaFont: {
      type: String,
      default: '10pt Calibri'
    },
    grupaFont: {
      type: String,
      default: '15pt Calibri'
    },
    trapSettings: {
      type: Object,
      default: null
    },
    trapSet: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      x: 40,
      y: 0,
      t: 0,
      n: 1,
      korak: 1,
      toggler: 0,
      ciklusCanvas: null,
      ctx: null,
      renderTickIntervalId: null,
      aktivniMCO: null,
      prevEvents: null,
      trapped: false,
      grupeBrid: [],
      delayExpired: false
    }
  },
  computed: {
    statusnaRijec () {
      return this.$store.getters['t1/statusnaRijec']
    }
  },
  watch: {
    trapSet (val) {
      if (val === false) {
        this.grupeBrid = []
        this.trapped = false
      }
    },
    events: function () {

      if (!this.trapSet) {
        this.renderSignalPlan()
        return
      }

      if (!this.prevEvents) {
        this.prevEvents = this.events
      }

      for (let i = 0; i < this.trapSettings.postavke.length; i++) {
        let postavka = this.trapSettings.postavke[i]
        // index krece od 0, a u postavkama su grupe 1,2,3...
        let grupa = postavka.grupa-1
        // CRVENO, ŽUTO, ZELENO
        let zeljenoStanjeboja = [0, 0, 0]

        for (let k = 0; k < postavka.boje.length; k++) {
          if (postavka.boje[k] === 0) {
            // CRVENO
            zeljenoStanjeboja[0] = 1
          } else if (postavka.boje[k] === 1) {
            // ŽUTO
            zeljenoStanjeboja[1] = 1
          } else if (postavka.boje[k] === 2) {
            // ZELENO
            zeljenoStanjeboja[2] = 1
          }
        }

        // console.log(zeljenoStanjeboja)

        let prevStanjeSignalnihGrupa = [0,0,0]
        prevStanjeSignalnihGrupa[0] = this.prevEvents.stanje_signalnih_grupa[grupa][0]
        prevStanjeSignalnihGrupa[1] = this.prevEvents.stanje_signalnih_grupa[grupa][1]
        prevStanjeSignalnihGrupa[2] = this.prevEvents.stanje_signalnih_grupa[grupa][2]

        let stanjeSignalnihGrupa = [0,0,0]
        stanjeSignalnihGrupa[0] = this.events.stanje_signalnih_grupa[grupa][0]
        stanjeSignalnihGrupa[1] = this.events.stanje_signalnih_grupa[grupa][1]
        stanjeSignalnihGrupa[2] = this.events.stanje_signalnih_grupa[grupa][2]

        if (!this.grupeBrid[grupa] && ((!this.$lodash.isEqual(prevStanjeSignalnihGrupa, stanjeSignalnihGrupa)) && (this.$lodash.isEqual(stanjeSignalnihGrupa, zeljenoStanjeboja)))) {
          // ako nije bio brid, a sada je brid, stavi da je brid
          this.grupeBrid[grupa] = 1
        } else if ((this.grupeBrid[grupa] && this.grupeBrid[grupa] === 1) && (this.$lodash.isEqual(prevStanjeSignalnihGrupa, stanjeSignalnihGrupa)) && (!this.$lodash.isEqual(stanjeSignalnihGrupa, zeljenoStanjeboja))) {
          // ako je bio brid, a sada vise nije, stavi da grupa nema brid
          this.grupeBrid[grupa] = 0
        }
      }

      if (!this.trapped && this.trapSettings.uvjet === 'OR') {
        if (this.grupeBrid.includes(1)) {
          this.trapped = true
        } else {
          this.trapped = false
        }
      } else if (!this.trapped && this.trapSettings.uvjet === 'AND') {
        if ( this.grupeBrid.includes(1) && !this.grupeBrid.includes(0)) {
          this.trapped = true
        } else {
          this.trapped = false
        }
      }

      this.prevEvents = this.events

      if (this.trapped && this.trapSettings.delay > 0 && this.delaySet !== true) {

        this.delaySet = true
        this.delayExpired = false

        setTimeout(() => {
          this.delayExpired = true
        }, this.trapSettings.delay*1000)
      }

      if (this.trapped && !this.trapSettings.delay) {
        this.renderSignalPlan()
      } else if (this.trapped && this.trapSettings.delay > 0 && this.delayExpired === true) {
        this.renderSignalPlan()
      }
    }
  },
  methods: {
    init () {
      this.delaySet = false
      this.delayExpired = false

      this.aktivniMCO = null
      if (this.renderTickIntervalId) {
        clearInterval(this.renderTickIntervalId)
      }
      this.x = 40
      this.y = 0
      this.t = 0
      this.n = 1
      this.korak = 1
      this.toggler = 0

      this.ciklusCanvas = document.getElementById('ciklus_canvas')
      this.ciklusCanvas.width  = 5000
      this.ciklusCanvas.height = this.trafficController.broj_grupa*30+100
      this.ctx = this.ciklusCanvas.getContext('2d')
      this.nacrtajGrupeText()
      this.renderTickIntervalId = setInterval(this.renderTick, 1000)
    },
    nacrtajGrupeText () {
      // Prva linija 5s
      this.ctx.strokeStyle = this.tickColor
      this.ctx.moveTo(41, 1)
      this.ctx.lineTo(41, (this.trafficController.broj_grupa*30))
      this.ctx.stroke()

      // Nacrtaj 0 sekundu
      this.ctx.font = this.sekundaFont
      this.ctx.fillStyle = this.grupaLabelColor
      this.ctx.fillText(this.t, this.x+4, (this.trafficController.broj_grupa*30))
      this.t = this.t + 5

      // Grupe labels
      this.ctx.font = this.grupaFont
      this.ctx.fillStyle = this.grupaLabelColor
      let redak = 15
      for (let j = 1; j <= this.trafficController.broj_grupa; j++) {
        if (j < 10) {
          this.ctx.fillText(j, 5, redak)
        } else {
          this.ctx.fillText(j, 0, redak)
        }
        redak = redak + 30
      }
    },
    renderSignalPlan () {
      if (this.korak === 0.5) {
        this.toggler = this.toggler + 1
        if (this.toggler > 1) {
          this.toggler = 0
          return
        }
      }

      let y_crveno = 0
      let y_zuto = 0
      let y_zeleno = 0
      let crveno = 0
      let zuto = 0
      let zeleno = 0
      let line_len = (this.trafficController.broj_grupa*30)+25

      if (this.statusnaRijec.cu && !(this.program in [253, 254, 255]) && this.aktivniMCO != this.events.mcoIme) {
        this.aktivniMCO = this.events.mcoIme
        this.ctx.save()
        this.ctx.translate(this.x, line_len+25)
        this.ctx.rotate(-Math.PI/2)
        this.ctx.font = "10pt Calibri"
        this.ctx.textAlign = "center"
        this.ctx.fillStyle = '#000'
        this.ctx.fillText(this.events.mcoIme, -15, 4)
        this.ctx.restore()

        this.ctx.beginPath()
        this.ctx.strokeStyle = '#000'
        this.ctx.moveTo(this.x, (this.trafficController.broj_grupa*30)+3)
        this.ctx.lineTo(this.x, line_len)
        this.ctx.stroke()
        this.ctx.closePath()
      }

      this.x = this.x + this.rezolucija
      for (let i = 0; i < this.trafficController.broj_grupa; i++) {
        this.y = i * 30
        y_crveno = this.y
        y_zuto = this.y + 5
        y_zeleno = this.y + 10
        crveno = this.events.stanje_signalnih_grupa[i][0]
        zuto = this.events.stanje_signalnih_grupa[i][1]
        zeleno = this.events.stanje_signalnih_grupa[i][2]
        // crveno
        if ( crveno == 1) {
          this.ctx.fillStyle="#F62817"
        } else {
          this.ctx.fillStyle="#F2F2F2"
        }
        this.ctx.fillRect(this.x, y_crveno, this.rezolucija, 5)

        // zuto
        if (zuto == 1) {
          this.ctx.fillStyle="#FDD017"
        } else {
          this.ctx.fillStyle="#F2F2F2"
        }
        this.ctx.fillRect(this.x, y_zuto, this.rezolucija, 5)

        // zeleno
        if (zeleno == 1) {
          this.ctx.fillStyle="#8FC33A"
        } else {
          this.ctx.fillStyle="#F2F2F2"
        }
        this.ctx.fillRect(this.x, y_zeleno, this.rezolucija, 5)

      }
    },
    renderTick () {
      if (this.trapSet && !this.trapped) {
        return false
      } else if (this.trapSet && this.trapped && this.trapSettings.delay > 0 && this.delayExpired !== true) {
        return false
      }

      if (this.n < 5) {
        this.ctx.beginPath()
        this.ctx.strokeStyle = '#FFFFFF'
        this.ctx.moveTo(this.x, 0)
        this.ctx.lineTo(this.x, this.trafficController.broj_grupa * 30)
        this.ctx.stroke()
        this.ctx.closePath()
      } else {
        this.ctx.beginPath()
        this.ctx.strokeStyle = this.tickColor
        this.ctx.moveTo(this.x, 0)
        this.ctx.lineTo(this.x, (this.trafficController.broj_grupa * 30)-10)
        this.ctx.stroke()
        this.ctx.closePath()
        this.ctx.font = this.sekundaFont
        this.ctx.fillStyle = this.grupaLabelColor
        this.ctx.fillText(this.t, this.x + 2, this.trafficController.broj_grupa * 30)
        this.t = this.t + 5
        this.n = 0
      }
      if (this.n >= 5) {
        this.n = 0
      }
      this.n = this.n + 1
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    if (this.renderTickIntervalId) {
      clearInterval(this.renderTickIntervalId)
    }
  }
}
</script>
