<template>
  <v-container fluid grid-list-md>
    <v-row>
      <v-col xs="12" md="6">
        <app-t1-detektori-jedinice-card :detektori="detektori"></app-t1-detektori-jedinice-card>
      </v-col>
      <!--<v-col xs="12" md="6">
        <app-t1-detektori-impuls-card></app-t1-detektori-impuls-card>
      </v-col>-->
      <v-col xs="12" md="6">
        <app-t1-detektori-pragovi-card></app-t1-detektori-pragovi-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'

export default {
  name: 't1DetektoriJedinicePage',
  mixins: [T1Mixin],
  data () {
    return {
      gettingT1Data: false,
      loading: false,
      DETEKTORI_JEDINICE: null,
      DETEKTORI_IMPULS: null,
      DETEKTORI_PRAGOVI: null,
      getT1DataId: null
    }
  },
  computed: {
    detektori () {
      if (!this.DETEKTORI_JEDINICE || !this.DETEKTORI_IMPULS) return []
      let id = 0
      let d = null
      let impuls = null
      let color = ''
      let jedinice = 0
      let percentValue = 0
      let impulsPercentValue = 0
      let _detektoriJedinice = this.$lodash.chunk(this.DETEKTORI_JEDINICE, 2)
      let _detektoriPragovi = null
      if (this.DETEKTORI_PRAGOVI) {
        _detektoriPragovi = this.$lodash.chunk(this.DETEKTORI_PRAGOVI, 4)
      }
      let detObjs = []
      let o = null

      for (let i = 0; i < this.DETEKTORI_IMPULS.length; i++) {
        id = id + 1
        impuls = this.DETEKTORI_IMPULS[i]
        jedinice = null
        percentValue = null
        color = '#E0E0E0'

        o = {
          'id': id,
          'jedinice': jedinice,
          'percentValue': percentValue,
          'color': color,
          'impuls': impuls,
          'impulsPercentValue': impulsPercentValue,
          'pragovi': null
        }

        if (i >= 0 && i < _detektoriPragovi.length) {
          let [prag0_1, prag0_2, prag1_1, prag1_2] = _detektoriPragovi[i]

          o.pragovi = {
            'prag1': (prag0_2*256)+prag0_1,
            'prag2': (prag1_2*256)+prag1_1
          }
        }

        if (i >= 0 && i < _detektoriJedinice.length) {
          d = _detektoriJedinice[i]
          jedinice = (d[1]*256)+d[0]
          if (o.pragovi) {
            percentValue = (jedinice / o.pragovi.prag1)*100
          } else {
            percentValue = (jedinice / 65535)*100
          }
        }

        impulsPercentValue = 0

        if (impuls > 0 ) {
          impulsPercentValue = 100
        }

        if (impuls) {
          color = '#3399FF'
        } else {
          color = '#E0E0E0'
        }

        o['jedinice'] = jedinice
        o['percentValue'] = percentValue
        o['color'] = color
        o['impuls'] = impuls
        o['impulsPercentValue'] = impulsPercentValue

        detObjs.push(o)
      }

      return detObjs
    }
  },
  methods: {
    getT1Data () {
      this.gettingT1Data = true
      let promises = []
      this.loading = true
      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x41]))
      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x22]))
      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x2b]))

      Promise.all(promises)
        .then(([DETEKTORI_JEDINICE, DETEKTORI_IMPULS, DETEKTORI_PRAGOVI]) => {
          if (DETEKTORI_JEDINICE.length == 3 && DETEKTORI_JEDINICE[0] == 170 && DETEKTORI_JEDINICE[1] == 3 && DETEKTORI_JEDINICE[2] == 235 ) {
            this.DETEKTORI_JEDINICE = []
          } else {
            this.DETEKTORI_JEDINICE = DETEKTORI_JEDINICE
          }

          if (DETEKTORI_PRAGOVI.length == 3 && DETEKTORI_PRAGOVI[0] == 170 && DETEKTORI_PRAGOVI[1] == 3 && DETEKTORI_PRAGOVI[2] == 235 ) {
            this.DETEKTORI_PRAGOVI = []
          } else {
            this.DETEKTORI_PRAGOVI = DETEKTORI_PRAGOVI
          }

          if (DETEKTORI_IMPULS.length == 3 && DETEKTORI_IMPULS[0] == 170 && DETEKTORI_IMPULS[1] == 3 && DETEKTORI_IMPULS[2] == 235 ) {
            this.DETEKTORI_IMPULS = []
          } else {
            this.DETEKTORI_IMPULS = DETEKTORI_IMPULS
          }

        })
        .finally(() => {
          this.loading = false
          this.gettingT1Data = false
        })
    }
  },
  created () {
    this.getT1DataId = setInterval(() => {
      if (!this.gettingT1Data) {
        this.getT1Data()
      }
    }, 1)
  },
  destroyed () {
    if (this.getT1DataId) {
      clearInterval(this.getT1DataId)
    }
  }
}
</script>
