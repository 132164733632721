<template>
  <v-container fluid grid-list-md>

    <v-row>
      <v-col xs="12">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ $t('ui.zelena_vremena_signalnih_grupa') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="isAuthenticated" @click="submit" color="primary" :disabled="saveDisabled">{{ $t('ui.save') }}</v-btn>
          </v-toolbar>
          <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t('ui.signalna_grupa') }}
                    </th>
                    <th class="text-left">
                      {{ $t('ui.minimalno_zeleno_vrijeme') }}
                    </th>
                    <th class="text-left">
                      {{ $t('ui.maksimalno_zeleno_vrijeme') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(index, grupa) in brojGrupa" :key="index">
                    <td>{{grupa+1}}</td>
                    <td><v-text-field v-model="zelenaVremena[grupa][0]" :disabled="loading"></v-text-field></td>
                    <td><v-text-field v-model="zelenaVremena[grupa][1]" :disabled="loading"></v-text-field></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'

export default {
  name: 't1ZelenaVremenaPage',
  mixins: [T1Mixin, AuthMixin],
  data () {
    return {
      loading: false,
      brojGrupa: 0,
      zelenaVremena: []
    }
  },
  computed: {
    saveDisabled () {
      if (this.loading) return true
      return false
    }
  },
  methods: {
    init () {
      this.loading = true

      this.initT1Data()
        .finally(() => {
          this.loading = false
        })
    },
    initT1Data () {
      return new Promise((resolve, reject) => {
        let promises = []
        promises.push(this.getBrojGrupa())
        promises.push(this.getZelenaVremenaSignalnihGrupa())
        Promise.all(promises)
          .then(([BROJ_GRUPA, ZELENA_VREMENA_SIGNALNIH_GRUPA]) => {
            this.brojGrupa = BROJ_GRUPA
            this.zelenaVremena = ZELENA_VREMENA_SIGNALNIH_GRUPA
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    submit () {
      this.loading = true

      this.saveZelenaVremenaSignalnihGrupa(this.zelenaVremena)
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'red',
            text: this.$t('ui.save_error')
          })
        })
        .finally(() => {
          this.init()
        })
    }
  },
  created () {
    this.init()
  }
}
</script>
