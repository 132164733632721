<template>
  <v-card v-if="ciklus">
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">{{ $t('ui.offset') }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td><strong>Interna sekunda ciklusa - offset</strong></td>
              <td>{{ciklus.sekundaCiklusa}} - {{ciklus.offset}} = {{ciklus.internaSekundaCiklusaMinusOffset}}</td>
            </tr>
            <tr>
              <td><strong>Vanjska sekunda ciklusa</strong></td>
              <td>{{ciklus.vanjskaSekundaCiklusa}}</td>
            </tr>
            <tr>
              <td><strong>Aktivni PTP</strong></td>
              <td>{{programi[ciklus.aktivniPTPNaziv] || ciklus.aktivniPTPNaziv}}</td>
            </tr>
            <tr>
              <td><strong>Offset</strong></td>
              <td>{{ciklus.offset}}</td>
            </tr>
            <tr>
              <td><strong>Ciklus</strong></td>
              <td>{{ciklus.ciklus}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'

export default {
  name: 't1OffsetCard',
  mixins: [T1Mixin],
  props: ['programi'],
  data () {
    return {
      loading: false,
      ciklus: null,
      gettingCiklus: false
    }
  },
  methods: {
    doGetCiklusInfo () {
      this.loading = true
      this.gettingCiklus = true
      this.getCiklusInfo(this.$store.getters['t1/ciklus'])
        .then((ciklus) => {
          this.ciklus = ciklus
        })
        .catch(() => {
          this.ciklus = null
        })
        .finally(() => {
          this.loading = false
          this.gettingCiklus = false
        })
    }
  },
  created () {
    this.getCiklusInfoId = setInterval(() => {
      if (!this.gettingCiklus) {
        this.doGetCiklusInfo()
      }
    }, 100)
  },
  destroyed () {
    if (this.getCiklusInfoId) {
      clearInterval(this.getCiklusInfoId)
    }
  }
}
</script>
