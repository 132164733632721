<template>
  <v-container fluid grid-list-md>

    <v-row>
      <v-col xs="12" lg="6">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ $t('ui.detektorska_ploca') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="toggleSalji">
              <v-icon>{{saljiIcon}}</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container grid>
              <v-row>
                <v-col>
                  <v-select
                    v-model="odabraniDetektori"
                    :items="detektori"
                    item-text="id"
                    multiple
                    return-object
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  lg="2"
                  style="text-align:center"
                  @click="handleDetektor(detektor)"
                  class="clickable"
                  v-for="detektor in odabraniDetektori" :key="detektor.id"
                >
                  <v-sheet
                    rounded="sm"
                    min-height="25"
                    color="grey"
                  >
                  <strong style="color:#00000099">{{detektor.id}}</strong>

                  <v-progress-linear
                    :value="detektor.percentValue"
                    :color="detektor.color"
                    height="75"
                  >
                  </v-progress-linear>
                  </v-sheet>

                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col xs="12" md="6">
        <traffic-controller-svg
          :traffic-controller="trafficController"
          :stanje-signala="stanjeSignala"
          :stanje-detektora="stanjeDetektora"
        >
        </traffic-controller-svg>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'
import ConfigMixin from '@/mixins/ConfigMixin'

export default {
  name: 'DetektorskaPlocaPage',
  mixins: [ConfigMixin, AuthMixin, T1Mixin],
  data () {
    return {
      loading: false,
      detektori: [],
      odabraniDetektori: [],
      config: {
        DEVICE_CODE: null,
        DEVICE_TYPE: null,
        TLOCRT: null,
        TLOCRT_COLOR: '#fff'
      },
      salji: true,
      sendingDetktoriId: null,
      sendingToT1: false
    }
  },
  computed: {
    saljiIcon () {
      if (this.salji) {
        return 'pause'
      }

      return 'play_arrow'
    },
    trafficController () {
      return {
        code: this.config.DEVICE_CODE,
        type: this.config.DEVICE_TYPE,
        svg_link: `${this.$store.getters['app/APP_API_URI']}/tlocrt/?t=${this.t}`,
        svg_background_color: this.config.TLOCRT_COLOR
      }
    },
    statusnaRijec () {
      return this.$store.getters['t1/statusnaRijec']
    },
    stanjeSignala () {
      return this.$store.getters['t1/stanjeSignala']
    },
    stanjeDetektora () {
      return this.$store.getters['t1/stanjeDetektora']
    },
    detektoriZaT1 () {
      let d = []
      let detektor = null

      for (let i = 0; i < this.odabraniDetektori.length; i++) {

        detektor = this.odabraniDetektori[i]

        if (detektor.stanje) {
          d.push(detektor.id+64)
        } else {
          d.push(detektor.id)
        }
      }

      return d
    }
  },
  methods: {
    init () {
      return new Promise((resolve, reject) => {

        this.generirajDetektore()
        this.dohvatiT1Detektore()
          .then(this.initConfigData)
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    dohvatiT1Detektore () {
      return new Promise((resolve, reject) => {
        this.t1Write([this.t1.CPS, this.t1.READ, this.t1.DETEKTORSKI_IMPULS])
        .then((DETEKTORI_IMPULS) => {
          this.odabraniDetektori = []

          for (let i = 0; i < DETEKTORI_IMPULS.length; i++) {
            this.odabraniDetektori.push({
              'id': i+1,
              'stanje': false,
              'percentValue': 100,
              'color': '#E0E0E0'
            })
          }
        })
        .catch(() => {
          reject()
        })
        .then(() => {
          resolve()
        })
      })
    },
    generirajDetektore () {
      this.detektori = []

      for (let i = 0; i < 24; i++) {
        this.detektori.push({
          'id': i + 1,
          'stanje': false,
          'percentValue': 100,
          'color': '#E0E0E0'
        })
      }
    },
    handleDetektor (detektor) {
      detektor.stanje = !detektor.stanje
      if (detektor.stanje) {
        detektor.color = '#3399FF'
      } else {
        detektor.color = '#E0E0E0'
      }
    },
    toggleSalji () {
      this.salji = !this.salji
    },
    send () {
      this.sendingToT1 = true
      this.t1Write([this.t1.CPS, this.t1.WRITE, this.t1.SET_DETEKTORI, this.detektoriZaT1.length, ...this.detektoriZaT1])
        .finally(() => {
          this.sendingToT1 = false
        })
    }
  },
  created () {
    if (!this.isSuperUser) {
      this.$router.push({
        name: 'AppPage'
      })

      return false
    }
    this.loading = true
    this.init()
      .finally(() => {
        this.loading = false

        this.sendingDetktoriId = setInterval(() => {
          if (!this.sendingToT1 && this.salji) {
            this.send()
          }
        }, 900)

      })
  },
  destroyed () {
    if (this.sendingDetktoriId) {
      clearInterval(this.sendingDetktoriId)
    }
  }
}
</script>
