<template>
  <v-container fluid grid-list-md>
    <v-row>
      <v-col xs="12" md="4">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">WATCHDOG</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="doStartSupervisorJob(supervisorConfigKey)">
              <v-icon>play_arrow</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="doStopSupervisorJob(supervisorConfigKey)">
              <v-icon>pause</v-icon>
            </v-btn>
          </v-toolbar>

          <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>

          <v-card-text>
            <v-col xs12>
              <v-select
                :items="supervisorJobStatusItems"
                item-text="name"
                item-value="id"
                v-model="config.WATCHDOG_KICKER_ENABLED"
              ></v-select>
            </v-col>

            <v-col xs12>
              <v-combobox
                v-model="config.WATCHDOG_KICK_COMMAND"
                :items="commands"
                :label="$t('ui.commands')"
              ></v-combobox>
            </v-col>

            <v-col>
              <v-text-field
                v-model="config.WATCHDOG_KICK_SLEEP"
                :label="$t('ui.sleep')"
                ></v-text-field>
            </v-col>
          </v-card-text>

          <v-card-actions>
            <v-btn
              :disabled="submitAvailable ? false : true"
              @click="submit"
              color="primary"
            >{{ this.$t('ui.submit') }}</v-btn>
            <v-spacer></v-spacer>
            {{supervisorJobStatusText}}
          </v-card-actions>

        </v-card>
      </v-col>

      <v-col xs="12" md="8">
        <app-supervisor-job-log-card :supervisor-config-key="supervisorConfigKey"></app-supervisor-job-log-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ConfigMixin from '@/mixins/ConfigMixin'
import SupervisorMixin from '@/mixins/SupervisorMixin'

export default {
  name: 'WatchDogPage',
  mixins: [ConfigMixin, SupervisorMixin],
  data () {
    return {
      supervisorConfigKey: 'watchdog_kicker',
      loading: false,
      config: {
        WATCHDOG_KICKER_ENABLED: true,
        WATCHDOG_KICK_COMMAND: 'npe ~DO1',
        WATCHDOG_KICK_SLEEP: 0.9
      },
      commands: [
        'npe ~DO1',
        '/opt/trafsignal/bin/kontron_cm3/kick_wdog'
      ]
    }
  },
  computed: {
    isFormValid () {
      return true
    },
    submitAvailable () {
      if (!this.loading && this.isFormValid) {
        return true
      }
      return false
    },
    jobAutoStart () {
      if (this.config.WATCHDOG_KICKER_ENABLED === '1') {
        return true
      }

      return false
    },
    supervisorTemplate () {
      return `[program:${this.supervisorConfigKey}]
command=/opt/.virtualenvs/trafconnect_v2/bin/python /opt/trafsignal/apps/watchdog_kicker.py --kick_cmd="${this.config.WATCHDOG_KICK_COMMAND}" --sleep=${this.config.WATCHDOG_KICK_SLEEP}
directory=/opt/trafsignal/apps
environment=PYTHONPATH="/opt"
priority=1
autostart=${this.jobAutoStart}
autorestart=${this.jobAutoStart}
startretries=999
user=root
stdout_logfile_maxbytes=10KB
stdout_logfile_backups=0
stderr_logfile_maxbytes=10KB
stderr_logfile_backups=0
stdout_capture_maxbytes=10KB
`
    }
  },
  methods: {
    init () {
      this.initConfigData()
    },
    submit () {
      this.loading = true
      var promises = []

      promises.push(this.createConfig({
        'key': 'WATCHDOG_KICKER_ENABLED',
        'value': this.config.WATCHDOG_KICKER_ENABLED
      }))

      promises.push(this.createConfig({
        'key': 'WATCHDOG_KICK_COMMAND',
        'value': this.config.WATCHDOG_KICK_COMMAND
      }))

      promises.push(this.createConfig({
        'key': 'WATCHDOG_KICK_SLEEP',
        'value': this.config.WATCHDOG_KICK_SLEEP
      }))

      promises.push(this.createSupervisorConfig({
        'key': this.supervisorConfigKey,
        'config': this.supervisorTemplate
      }))

      Promise.all(promises)
        .then(() => {

          // svi upiti su gotovi
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })

        })
        .finally(() => {
          this.loading = false
          this.init()
        })
    }
  },
  created () {
    this.init()
    this.getJobStatusIntervalId = setInterval(() => {
      if (!this.statusUpdating) {
        this.updateSupervisorJobStatus(this.supervisorConfigKey)
      }
    }, 5000)
  },
  destroyed () {
    if (this.getJobStatusIntervalId) {
      clearInterval(this.getJobStatusIntervalId)
    }
  }
}
</script>
