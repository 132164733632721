<template>
  <v-container fluid grid-list-md>

    <v-row>
      <v-col xs="12">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ this.$t('ui.ciklus') }}</span>
            </v-toolbar-title>
            <v-btn
              icon
              @click="add()">
              <v-icon>add_circle</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="remove()">
              <v-icon>remove_circle</v-icon>
            </v-btn>
            <v-btn @click="restart()">RESTART</v-btn>
            <v-btn
              icon
              @click="toggleTrapSet()"
              :disabled="isToggleTrapSetDisabled">
              <v-icon>{{trapSetIcon}}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, i) in availableActions"
                  :key="i"
                  link
                  @click="item.action(...item.actionArgs)"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>

    <v-row >
      <v-col xs="12" style="background-color: #fff;">
        <app-t1-ciklus
          v-if="trafficController.broj_grupa > 0"
          :traffic-controller="trafficController"
          :events="events"
          :rezolucija="rezolucija"
          :trap-settings="savedTrapSettings"
          :trap-set="trapSet"
          ref="ciklus"
        ></app-t1-ciklus>
      </v-col>
    </v-row>

    <v-row>
      <v-col xs="12" md="6">
        <app-t1-offset-card :programi="naziviPrograma"></app-t1-offset-card>
      </v-col>
      <v-col xs="12" md="6">
        <app-t1-edit-offset-card :programi="naziviPrograma"></app-t1-edit-offset-card>
      </v-col>
    </v-row>

    <v-dialog v-model="trapDialogOpen" persistent max-width="1024px">
      <v-container fluid>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col xs="12" md="2">
                <v-select
                  :items="grupe"
                  label="Grupa"
                  v-model="trapDetails.grupa"
                ></v-select>
              </v-col>
              <v-col xs="12" md="2">
                <v-select
                  :items="boje"
                  label="Boje"
                  item-value="id"
                  item-text="label"
                  v-model="trapDetails.boje"
                  multiple
                ></v-select>
              </v-col>
              <v-col xs="12" md="2">
                <v-select
                  :items="uvjeti"
                  item-value="id"
                  item-text="label"
                  label="Uvjet"
                  v-model="trapSettings.uvjet"
                ></v-select>
              </v-col>
              <v-col xs="12" md="2">
                <v-text-field
                  v-model="trapSettings.delay"
                  label="Delay"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col xs="12" md="2">
                <v-btn color="primary" block @click="addTrapDetails()" :disabled="isDodajTrapDisabled">
                {{this.$t('ui.add')}}
                </v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">GRUPA</th>
                    <th class="text-left">BOJE</th>
                    <th class="text-left">{{ $t('ui.actions') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(trap, index) in trapSettings.postavke" :key="index">
                    <td style="width:10%">
                      <v-select
                        :items="grupe"
                        label="Grupa"
                        v-model="trap.grupa"
                      ></v-select>
                    </td>
                    <td>
                      <!--<span v-for="(boja, bojaIndex) in trap.boje" :key="bojaIndex">
                        <div v-if="boja===0" style="width:16px;height:16px;background-color:red"></div>
                        <div v-if="boja===1" style="width:16px;height:16px;background-color:yellow"></div>
                        <div v-if="boja===2" style="width:16px;height:16px;background-color:green"></div>
                      </span>-->
                      <v-select
                        :items="boje"
                        item-value="id"
                        item-text="label"
                        v-model="trap.boje"
                        multiple
                      ></v-select>
                    </td>
                    <td>
                      <v-btn
                        class="mr-15"
                        icon
                        @click="deleteTrapSetting(index)">
                        <v-icon color="red">delete</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        v-if="index>0"
                        @click="trapSettingChangePos(index, -1)">
                        <v-icon>keyboard_arrow_up</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        v-if="index<trapSettings.postavke.length-1"
                        @click="trapSettingChangePos(index, 1)">
                        <v-icon>keyboard_arrow_down</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="trapDialogClose()">
              {{this.$t('ui.cancel')}}
            </v-btn>
            <v-btn color="green darken-1" @click="saveTrapSettings()">
              {{this.$t('ui.save')}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-dialog>

  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import ConfigMixin from '@/mixins/ConfigMixin'
import T1Mixin from '@/mixins/T1Mixin'

export default {
  name: 't1CiklusPage',
  mixins: [validationMixin, ConfigMixin, T1Mixin],
  data () {
    return {
      loading: false,
      rezolucija: 2,
      greske: [],
      trafficController: {
        broj_grupa: 0,
        broj_detektora: 0
      },
      actions: [],
      OFFSET: null,
      getOffsetId: false,
      trapDialogOpen: false,
      naziviPrograma: [],
      trapSet: false,
      trapSettings: {
        uvjet: 'OR',
        delay: 0.0,
        postavke: []
      },
      savedTrapSettings: null,
      trapDetails: {
        grupa: null,
        boje: []
      },
      boje: [
        {
          'id': 0,
          'label': 'Crveno'
        },
        {
          'id': 1,
          'label': 'Žuto'
        },
        {
          'id': 2,
          'label': 'Zeleno'
        },
      ],
      uvjeti: [
        {
          'id': 'OR',
          'label': 'OR'
        },
        {
          'id': 'AND',
          'label': 'AND'
        }
      ]
    }
  },
  computed: {
    grupe () {
      let _grupe = []
      for (let i = 1; i <= this.trafficController.broj_grupa; i++) {
        _grupe.push(i)
      }

      return _grupe
    },
    trapSetIcon () {
      if (this.trapSet) {
        return 'pause'
      }

      return 'play_arrow'
    },
    availableActions () {
      let actions = []
      let action = null
      for (let i = 0; i < this.actions.length; i++) {
        action = this.actions[i]
        if (action.needsAuth === true) {
          if (this.isAuthenticated) {
            actions.push(action)
          }
        } else {
          actions.push(action)
        }
      }

      return actions
    },
    isDodajTrapDisabled () {
      if (this.trapDetails.grupa && this.trapDetails.boje.length >= 0) return false
      return true
    },
    events () {
      return {
        stanje_signalnih_grupa: this.$store.getters['t1/stanjeSignala'],
        program: this.$store.getters['t1/program'],
        mcoIme: this.$store.getters['t1/mcoIme']
      }
    },
    isToggleTrapSetDisabled () {
      if (this.savedTrapSettings && this.savedTrapSettings.postavke.length > 0) {
        return false
      }

      return true
    }
  },
  methods: {
    init () {
      this.loading = true
      this.actions = [
        {'title': this.$t('ui.trap_settings'), 'action': this.doOpenTrapDialog, 'actionArgs': [], 'needsAuth': false },
      ]
      this.initT1Data()
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'error',
            text: this.$t('ui.error')
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    initT1Data () {
      return new Promise((resolve, reject) => {
        this.getBrojGrupa()
          .then((BROJ_GRUPA) => {
            this.trafficController.broj_grupa = BROJ_GRUPA
            resolve()
          })
          .then(() => {
            this.t1getPodrzaniProgrami()
              .then((programi) => {
                let program = null
                this.naziviPrograma = []

                for (let i = 0; i < programi.length; i++) {
                  program = programi[i]
                  if (program.id < 255) {
                    this.naziviPrograma[program.id] = program.label
                  }
                }
              })
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    restart () {
      if (this.trapSet) {
        this.trapSet = false
        setTimeout(() => {
          this.trapSet = true
          this.$refs['ciklus'].init()
        }, 200)
        return false
      }
      this.$refs['ciklus'].init()
    },
    add () {
      this.rezolucija = this.rezolucija + 1
      if (this.rezolucija > 5 ) {
        this.rezolucija = 5
      }
    },
    remove () {
      this.rezolucija = this.rezolucija - 1
      if (this.rezolucija <= 0 ) {
        this.rezolucija = 1
      }
    },
    getOffset () {
      this.gettingOffset = true
      this.t1Write([this.t1.CPS, this.t1.READ, 0x4b])
        .then((offset) => {
          if (offset.length === 3 && offset[0] === 170 && offset[1] === 3 && offset[2] === 235) {
            this.OFFSET = null
          } else {
            this.OFFSET = offset
          }
        })
        .finally(() => {
          this.gettingOffset = false
        })
    },
    toggleTrapSet () {
      this.trapSet = !this.trapSet
      if (this.trapSet) {
        this.restart()
      }
    },
    doOpenTrapDialog () {
      if (this.savedTrapSettings) {
        this.trapSettings = JSON.parse(JSON.stringify(this.savedTrapSettings))
      }
      this.trapDialogOpen = true
    },
    addTrapDetails () {
      this.trapSettings.postavke.push(this.trapDetails)
      this.trapDetails = {
        grupa: null,
        boje: []
      }
    },
    deleteTrapSetting (trapIndex) {
      if (trapIndex > -1) {
        this.trapSettings.postavke.splice(trapIndex, 1)
      }
    },
    trapSettingChangePos (trapIndex, direction) {
      // MORAM RADITI LUDE STVARI DA BI NAPRAVIO RE RENDER
      // JER VUE NE VIDI PROMJENU AKO OSTANE ISTI BROJ ELEMENATA U LISTI
      let zamjeniMjesto = JSON.parse(JSON.stringify(this.trapSettings[trapIndex]))
      let zamjeniMjestoSa = JSON.parse(JSON.stringify(this.trapSettings[trapIndex+direction]))

      this.trapSettings[trapIndex] = zamjeniMjestoSa
      this.trapSettings[trapIndex+direction] = zamjeniMjesto
      let oldtrapSettings = JSON.parse(JSON.stringify(this.trapSettings))

      this.trapSettings = []
      this.trapSettings = oldtrapSettings
    },
    saveTrapSettings () {
      this.savedTrapSettings = JSON.parse(JSON.stringify(this.trapSettings))
      this.trapDialogOpen = false
    },
    trapDialogClose () {
      this.trapSettings = {
        uvjet: 'OR',
        delay: 0.0,
        postavke: []
      }
      this.trapDialogOpen = false
    }
  },
  created () {
    this.init()
  }
}
</script>
