<template>
  <v-container fluid grid-list-md>
    <v-row>
      <v-col xs="12">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">ETX {{ this.$t('ui.programs') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              @click="submit"
              color="primary"
            >{{ this.$t('ui.submit') }}</v-btn>
            <v-btn dark icon @click="add()"><v-icon>add</v-icon></v-btn>
            <v-btn
              dark
              icon
              @click="init()"
              :disabled="loading"
            >
              <v-icon>refresh</v-icon>
            </v-btn>
          </v-toolbar>
          <v-progress-linear
            v-show="loading"
            color="primary"
            indeterminate
          ></v-progress-linear>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="program in ETX_PROGRAMS" :key="program.id" xs="12" lg="6">
        <app-etx-program-edit-card
          :program="program"
          @userAction="userActionHandler"></app-etx-program-edit-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import ConfigMixin from '@/mixins/ConfigMixin'
import EtxMixin from '@/mixins/EtxMixin'

export default {
  name: 'EtxProgramsPage',
  mixins: [validationMixin, ConfigMixin, EtxMixin],
  data () {
    return {
      configMeta: {
        ETX_PROGRAMS: {
          type: Array
        },
        ETX_RASPORED: {
          type: Array
        }
      },
      config: {
        ETX_PROGRAMS: [],
        ETX_RASPORED: []
      },
      loading: false,
      ETX_PROGRAMS: []
    }
  },
  methods: {
    init () {
      this.loading = true
      this.initConfigData()
        .then((data) => {
          this.ETX_PROGRAMS = JSON.parse(JSON.stringify(data.ETX_PROGRAMS))
        })
        .finally(() => {
          this.loading = false
        })
    },
    uniqueId (prefix, idCounter) {
      var id = ++idCounter
      return prefix + id
    },
    getNewProgramData () {
      var idCounter = 1

      for (var i = 0; i < this.ETX_PROGRAMS.length; i++) {
        var program = this.ETX_PROGRAMS[i]
        var x = program.id.split('_')
        idCounter = parseInt(x[x.length - 1])
      }

      return {
        id: this.uniqueId('programId_', idCounter),
        name: `${this.$t('ui.program')} ${idCounter}`,
        cycle: 60,
        offset: 0,
        phases: []
      }
    },
    add () {
      this.ETX_PROGRAMS.push(this.getNewProgramData())
    },
    submit () {
      this.loading = true



      for (let i = 0; i < this.ETX_PROGRAMS.length; i++) {
        let program = this.ETX_PROGRAMS[i]

        for (let j = 0; j < this.config.ETX_RASPORED.length; j++) {
          let raspored = this.config.ETX_RASPORED[j]

          if (program.id === raspored.programId && program.name !== raspored.program) {
            raspored.program = program.name
          }
        }
      }

      this.createConfig({
        'key': 'ETX_PROGRAMS',
        'value': this.ETX_PROGRAMS
      })
      .then(() => {
        this.createConfig({
          'key': 'ETX_RASPORED',
          'value': this.config.ETX_RASPORED
        })
      })
      .then(() => {
        this.EtxManualControl({'reload': '1'})
      })
      .then(() => {
        this.$EventBus.$emit('AppSnackbar/show', {
          color: 'success',
          text: this.$t('ui.save_success')
        })
      })
      .catch(() => {
        this.$EventBus.$emit('AppSnackbar/show', {
          color: 'error',
          text: this.$t('ui.error')
        })
      })
      .finally(() => {
        this.init()
      })
    },
    userActionHandler (action) {
      if (action.name === 'delete') {
        this.ETX_PROGRAMS.splice(this.ETX_PROGRAMS.indexOf(action.program), 1)
      }
    }
  },
  created () {
    this.init()
  }
}
</script>
