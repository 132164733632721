export default {
  data: function () {
    return {
      systemTime: null,
      systemTimeUpdating: null,
      timeDateCtlStatus: null,
      setNtpEnabled: false,
      ntpEnabled: false,
      SYSTEM_TIME_Y: null,
      SYSTEM_TIME_M: null,
      SYSTEM_TIME_D: null,
      SYSTEM_TIME_HH: null,
      SYSTEM_TIME_MM: null,
      SYSTEM_TIME_SS: null
    }
  },
  methods: {
    setSystemTime (config) {
      return this.$api.post(`/system-time/`, config, {baseURL: this.$store.getters['app/APP_API_URI']})
    },
    getSystemTime (config) {
      let timezones = config.timezones || 0
      return this.$api.get(`/system-time/?timezones=${timezones}`, {baseURL: this.$store.getters['app/APP_API_URI']})
    },
    getSystemTimezones () {
      return new Promise((resolve, reject) => {
        this.getSystemTime({'timezones': 1})
          .then((response) => {
            resolve({
              'current_time_zone': response.data.current_time_zone,
              'timezones': response.data.timezones,
              'ntp_enabled': response.data.ntp_enabled,
            })
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    refreshSystemTime (config) {
      if (!config) {
        config = {}
      }
      this.systemTimeUpdating = true
      this.getSystemTime(config)
        .then((response) => {
          this.SYSTEM_TIME = response.data.timestamp
          this.timeDateCtlStatus = response.data.status
          this.ntpEnabled = response.data.ntp_enabled
        })
        .catch(() => {
          this.systemTime = null
          this.timeDateCtlStatus = null
        })
        .finally(() => {
          this.systemTimeUpdating = false
        })
    }
  },
  computed: {
    SYSTEM_TIME: {
      get () {
        return {
          'y': this.SYSTEM_TIME_Y,
          'm': this.SYSTEM_TIME_M,
          'd': this.SYSTEM_TIME_D,
          'hh': this.SYSTEM_TIME_HH,
          'mm': this.SYSTEM_TIME_MM,
          'ss': this.SYSTEM_TIME_SS
        }
      },
      set (value) {
        let x = new Date(value * 1000)
        this.SYSTEM_TIME_Y = new Intl.DateTimeFormat('hr', { year: 'numeric' }).format(x)
        this.SYSTEM_TIME_M = new Intl.DateTimeFormat('hr', { month: '2-digit' }).format(x)
        this.SYSTEM_TIME_D = new Intl.DateTimeFormat('hr', { day: '2-digit' }).format(x)
        this.SYSTEM_TIME_HH = new Intl.DateTimeFormat('hr', { hour: '2-digit' }).format(x)
        this.SYSTEM_TIME_MM = new Intl.DateTimeFormat('hr', { minute: '2-digit' }).format(x)
        this.SYSTEM_TIME_SS = new Intl.DateTimeFormat('hr', { second: '2-digit' }).format(x)
      }
    },
    NTP_ENABLED: {
      get () {
        if (this.ntpEnabled) {
          return this.$t('ui.yes')
        } else {
          return this.$t('ui.no')
        }
      },
      set (value) {
        this.setNtpEnabled = value
      }
    }
  }
}
