<template>
  <v-container fluid grid-list-md>
    <v-row>
      <v-col xs="12">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ this.$t('ui.detektorska_logika') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              dark
              icon
              @click="init()"
              :disabled="loading"
            >
              <v-icon>refresh</v-icon>
            </v-btn>
            <v-btn
              v-if="isAuthenticated"
              @click="submit"
              :disabled="loading"
              color="primary"
            >{{ this.$t('ui.submit') }}</v-btn>
          </v-toolbar>
          <v-progress-linear
            v-show="loading"
            color="primary"
            indeterminate
          ></v-progress-linear>

          <v-data-table
            :headers="headers"
            :items="parametri"
            :items-per-page="limit"
            :sort-by="['pripadajucaSg', 'pripadajuciDet']"
            multi-sort
            hide-default-footer
            item-key="id"
            class="elevation-1"
          >
            <template v-slot:item.zove="{ item }">
              <v-checkbox v-model="item.zove"></v-checkbox>
            </template>

            <template v-slot:item.produzava="{ item }">
              <v-checkbox v-model="item.produzava"></v-checkbox>
            </template>

            <template v-slot:item.memorija="{ item }">
              <v-checkbox v-model="item.memorija"></v-checkbox>
            </template>

            <template v-slot:item.odgoda="{ item }">
              <v-text-field
                v-model="item.odgoda"
              ></v-text-field>
            </template>

            <template v-slot:item.interval="{ item }">
              <v-text-field
                v-model="item.interval"
              ></v-text-field>
            </template>

          </v-data-table>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'

export default {
  name: 't1DetektoriLogikaPage',
  mixins: [T1Mixin, AuthMixin],
  data () {
    return {
      loading: false,
      DETEKTORI_LOGIKA: null,
      DETEKTORI_ODGODA: null,
      DETEKTORI_INTERVAL: null,
      parametri: [],
      limit: 999
    }
  },
  computed: {
    headers () {
      return [
        { text: this.$t('ui.signalna_grupa'), value: 'pripadajucaSg', align: 'center' },
        { text: this.$t('ui.detektor'), value: 'pripadajuciDet', align: 'center' },
        { text: this.$t('ui.trazi_zeleno'), value: 'zove', align: 'center' },
        { text: this.$t('ui.produzava_zeleno'), value: 'produzava', align: 'center' },
        { text: this.$t('ui.memorija'), value: 'memorija', align: 'center' },
        { text: this.$t('ui.odgoda_najave'), value: 'odgoda', align: 'center' },
        { text: this.$t('ui.interval_izmedju_vozila'), value: 'interval', align: 'center' }
      ]
    }
  },
  methods: {
    init () {
      this.getT1Data()
    },
    submit () {
      this.loading = true
      let promises = []
      let parametar = null

      for (let i=0; i < this.parametri.length; i++) {
        parametar = this.parametri[i]
        // odgoda
        promises.push(this.saveDetektorskaLogikaOdgoda(parametar.id, parseFloat(parametar.odgoda)*10))

        // interval
        promises.push(this.saveDetektorskaLogikaInterval(parametar.id, parseFloat(parametar.interval)*10))

        // Traži zeleno
        if (parametar.zove) {
          parametar.zove = 1
        } else {
          parametar.zove = 0
        }
        promises.push(this.saveDetektorskaLogikaTraziZeleno(parametar.id, parseInt(parametar.zove)))

        // Memorija
        if (parametar.memorija) {
          parametar.memorija = 1
        } else {
          parametar.memorija = 0
        }
        promises.push(this.saveDetektorskaLogikaMemorija(parametar.id, parseInt(parametar.memorija)))

        // Produzava
        if (parametar.produzava) {
          parametar.produzava = 1
        } else {
          parametar.produzava = 0
        }
        promises.push(this.saveDetektorskaLogikaProduzava(parametar.id, parseInt(parametar.produzava)))
      }

      Promise.all(promises)
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'red',
            text: this.$t('ui.save_error')
          })
        })
        .finally(() => {
          this.init()
        })
    },
    getT1Data () {
      this.parametri = []
      this.gettingT1Data = true
      let promises = []
      this.loading = true
      // parametri logika
      promises.push(this.getDetektoriLogika())
      // odgoda
      promises.push(this.getDetektoriOdgoda())
      // interval
      promises.push(this.getDetektoriInterval())

      Promise.all(promises)
        .then(([DETEKTORI_LOGIKA, DETEKTORI_ODGODA, DETEKTORI_INTERVAL]) => {
          this.DETEKTORI_LOGIKA = DETEKTORI_LOGIKA
          this.DETEKTORI_ODGODA = DETEKTORI_ODGODA
          this.DETEKTORI_INTERVAL = DETEKTORI_INTERVAL

          let _detektoriLogika = this.$lodash.chunk(this.DETEKTORI_LOGIKA, 4)

          let id = 0
          for (let i=0; i < _detektoriLogika.length; i++) {
            id = id + 1
            // eslint-disable-next-line
            let [_, funkcija, sg, brDet] = _detektoriLogika[i]
            let zove = funkcija & 0x01
            if (zove > 0) {
              zove = true
            } else {
              zove = false
            }

            let produzava = funkcija & 0x02
            if (produzava > 0) {
              produzava = true
            } else {
              produzava = false
            }

            let blokira = (funkcija & 0x04)
            let memorija = funkcija & 0x08
            if (memorija > 0) {
              memorija = true
            } else {
              memorija = false
            }

            let pripadajucaSg  = sg & 0x7F
            let pripadajuciDet = brDet

            this.parametri.push({
              'id': id,
              'zove': zove,
              'produzava': produzava,
              'blokira': blokira,
              'memorija': memorija,
              'pripadajucaSg': pripadajucaSg,
              'pripadajuciDet': pripadajuciDet,
              'odgoda': parseFloat(this.DETEKTORI_ODGODA[i]/10),
              'interval': parseFloat(this.DETEKTORI_INTERVAL[i]/10)
            })
          }

        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    this.init()
  }
}
</script>

<style scoped>
td .v-input {
  max-width: 25%;
  margin-right: auto;
  margin-left: auto;
}
</style>
