<template>
  <v-container fluid grid-list-sm>
    <v-row dense>
      <!-- TLOCRT -->
      <v-col xs="12" :lg="tlocrtLargeDimm">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ this.$t('ui.tlocrt') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                {{$t('ui.background_color')}}
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in colors" :key="index" @click="saveColor(item.value)">
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              v-if="isAuthenticated"
              icon
              :disabled="loading"
              @click="selectSVGToUpload()">
              <v-icon>add_box</v-icon>
            </v-btn>
            <v-btn
              v-if="isAuthenticated"
              icon
              @click="openConfirmDeleteDialog()"
              class="mx-0">
              <v-icon color="red">delete</v-icon>
            </v-btn>
          </v-toolbar>
          <v-progress-linear v-show="loading" color="primary" indeterminate ></v-progress-linear>
          <traffic-controller-svg
            v-if="config.DEVICE_CODE"
            :traffic-controller="trafficController"
            :stanje-signala="stanjeSignala"
            :stanje-detektora="stanjeDetektora"
          >
          </traffic-controller-svg>

          <app-confirm-action-dialog
            :open-dialog="openDeleteDialog"
            :headline="$t('ui.confirm_delete')"
            :text="$t('ui.really_delete_items')"
            @userAction="confirmDeleteLayoutActionHandler"
          >
          </app-confirm-action-dialog>

          <form id="upload_svg_form">
            <input
              type="file"
              id="upload_svg_input"
              accept="image/svg+xml"
              ref="upload_svg"
              hidden
              v-on:change="handleSVGUpload()"
            />
          </form>

        </v-card>
      </v-col>
      <!-- /TLOCRT -->

      <!-- KAMERE -->
      <v-col xs="12" lg="6" v-if="config.CAMERAS_CONFIG">
        <v-row>
          <v-col lg="4" xs="12" style="max-width:350px;" v-for="camera in videoStreams" v-bind:key="camera.code">
            <app-camera-tile :camera="camera" :title="camera.name"></app-camera-tile>
          </v-col>
        </v-row>
      </v-col>
      <!-- /KAMERE -->
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'
import ConfigMixin from '@/mixins/ConfigMixin'

export default {
  name: 'TlocrtPage',
  mixins: [validationMixin, ConfigMixin, T1Mixin, AuthMixin],
  data () {
    return {
      loading: false,
      colorLoading: false,
      openDeleteDialog: false,
      file: false,
      showToolbar: true,
      config: {
        DEVICE_CODE: null,
        DEVICE_TYPE: null,
        TLOCRT: null,
        TLOCRT_COLOR: '#fff',
        CAMERAS_CONFIG: []
      },
      configMeta: {
        CAMERAS_CONFIG: {
          type: Array
        }
      },
      layers: [],
      colors: [
        { name: 'Tamna', value: '#424242' },
        { name: 'Bijela', value: '#fff' }
      ],
      t: Date.now()
    }
  },
  computed: {
    trafficController () {
      return {
        code: this.config.DEVICE_CODE,
        type: this.config.DEVICE_TYPE,
        svg_link: `${this.$store.getters['app/APP_API_URI']}/tlocrt/?t=${this.t}`,
        svg_background_color: this.config.TLOCRT_COLOR
      }
    },
    statusnaRijec () {
      return this.$store.getters['t1/statusnaRijec']
    },
    stanjeSignala () {
      return this.$store.getters['t1/stanjeSignala']
    },
    stanjeDetektora () {
      return this.$store.getters['t1/stanjeDetektora']
    },
    tlocrtLargeDimm () {
      if (this.config.CAMERAS_CONFIG.length > 0) return 6

      return 12
    },
    videoStreams () {
      return this.config.CAMERAS_CONFIG.filter((camera) => {
        if (camera.stream_video === true) {
          return camera
        }
      })
    }
  },
  methods: {
    init () {
      this.config.DEVICE_CODE = null
      this.config.TLOCRT = null
      this.t = Date.now()
      this.initConfigData()
    },
    openConfirmDeleteDialog () {
      this.openDeleteDialog = true
    },
    confirmDeleteLayoutActionHandler (actionResult) {
      this.openDeleteDialog = false

      if (actionResult === true) {
        this.config.TLOCRT = ''
        this.submit()
      }
    },
    selectSVGToUpload () {
      this.t = Date.now()
      this.$refs['upload_svg'].click()
    },
    async handleSVGUpload () {
      const file = this.$refs.upload_svg.files[0]
      if (!file) return
      this.config.TLOCRT = await file.text()
      this.submit()
    },
    submit () {
      this.loading = true
      var promises = []

      promises.push(this.createConfig({
        'key': 'TLOCRT',
        'value': this.config.TLOCRT
      }))

      promises.push(this.createConfig({
        'key': 'TLOCRT_COLOR',
        'value': this.config.TLOCRT_COLOR
      }))

      Promise.all(promises)
        .then(() => {

          // svi upiti su gotovi
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })

        })
        .finally(() => {
          this.t = Date.now()
          this.jQuery('#upload_svg_input').val(null)
          this.loading = false
          this.init()
        })
    },
    saveColor (color) {
      this.loading = true

      this.createConfig({
        'key': 'TLOCRT_COLOR',
        'value': color
      })
        .then(() => {

          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })

        })
        .catch(() => {

          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'error',
            text: this.$t('ui.save_error')
          })

        })
        .finally(() => {
          this.loading = false
          this.init()
        })

    }
  },
  created () {
    this.init()
  }
}
</script>
