import env from '../env'

export const T2000Store = {
  namespaced: true,
  state: {
    uri: env.T2000_WS_URI,
    readyState: 0,
    readyStateConstants: {
      'CONNECTING': 0,
      'OPEN': 1,
      'CLOSING': 2,
      'CLOSED': 3,
      'RECONNECT_ABORTED': 4
    },
    serBr: [],
    stanjeSignala: [],
    greske: [],
    statusnaRijec: [],
    brojGrupa: [],
    stanjeDetektora: [],
    t2000Communicating: false,
    OPIS_ALARMA: {
      'hr': [
          '',
          'Programska greška',
          'Memorijska greška',
          'Logička greška',
          'Greška struje crvenih žarulja',
          'Greška detektora',
          'Greška koordinacije',
          'Greška ?',
          'Greška upravljačke ploče',
          '',
          '',
          'Greška struje crvenih žarulja'
      ],
      'si': [
          '',
          'Napaka programa',
          'Napaka pomnilnika',
          'Logična napaka',
          'Napaka rdeče žarnice',
          'Napaka detektorja',
          'Napaka koordinacije',
          'Napaka ?',
          'Napaka nadzorne plošče',
          '',
          '',
          'Napaka rdeče žarnice'
      ]
    }
  },
  getters: {
    READY_STATE (state) {
      return state.readyState
    },
    isConnected (state) {

      if (state.readyState === 1) {
        return true
      }

      return false
    },
    trafficController (state) {
      return state.trafficController
    },
    statusnaRijec (state) {
      return {
        abc: 0,
        anf: state.statusnaRijec[0] & 2,
        cu: state.statusnaRijec[0] & 128,
        ru: state.statusnaRijec[0] & 32,
        sc: state.statusnaRijec[0] & 64,
        su: state.statusnaRijec[0] & 8,
        trz: state.statusnaRijec[0] & 4,
        vru: state.statusnaRijec[0] & 16
      }
    },
    isCommOk (state) {
      return state.t2000Communicating
    },
    stanjeSignala (state) {
      let _stanjeSignala = []
      let x = []

      for (let i = 0; i < state.stanjeSignala.length; i++) {
        x.push((state.stanjeSignala[i]).toString(2).padStart(8, '0'))
      }

      x = x.join('')
      // CRVENE
      let rezRed = ''
      let crveno3 = x.slice(16, 24)
      for (let i = 0; i < crveno3.length; i++) {
        rezRed = crveno3[i] + rezRed
      }

      let crveno2 = x.slice(8, 16)
      for (let i = 0; i < crveno2.length; i++) {
        rezRed = crveno2[i] + rezRed
      }

      let crveno1 = x.slice(0, 8)
      for (let i = 0; i < crveno1.length; i++) {
        rezRed = crveno1[i] + rezRed
      }

      // ZUTE
      let rezYel = ''
      let zuto3 = x.slice(40, 48)
      for (let i = 0; i < zuto3.length; i++) {
        rezYel = zuto3[i] + rezYel
      }

      let zuto2 = x.slice(32, 40)
      for (let i = 0; i < zuto2.length; i++) {
        rezYel = zuto2[i] + rezYel
      }

      let zuto1 = x.slice(24, 32)
      for (let i = 0; i < zuto1.length; i++) {
        rezYel = zuto1[i] + rezYel
      }

      // ZELENE
      let rezGreen = ''
      let zeleno3 = x.slice(64, 72)
      for (let i = 0; i < zeleno3.length; i++) {
        rezGreen = zeleno3[i] + rezGreen
      }
      let zeleno2 = x.slice(56, 64)
      for (let i = 0; i < zeleno2.length; i++) {
        rezGreen = zeleno2[i] + rezGreen
      }
      let zeleno1 = x.slice(48, 56)
      for (let i = 0; i < zeleno1.length; i++) {
        rezGreen = zeleno1[i] + rezGreen
      }


      for (let i = 0; i < 24; i++) {
        _stanjeSignala.push([parseInt(rezRed[i]), parseInt(rezYel[i]), parseInt(rezGreen[i])])
      }
      return _stanjeSignala
    },
    greske: (state) => (lang) =>{
      if (!lang) lang = 'hr'

      let opisAlarms = []

      for (let i=9; i > 0; i--) {
        if (state.greske[0] & 2 ** (i - 1)) {
          opisAlarms.push(state.OPIS_ALARMA[lang][i])
        }
      }
      return opisAlarms
    },
    stanjeDetektora (state) {
      let stanjeDetektora = []
      let x = ''
      for (let i = 0; i < state.stanjeDetektora.length; i++) {
        x = (state.stanjeDetektora[i]).toString(2).split('').reverse()
        for (let j = 0; j < x.length; j++) {
          stanjeDetektora.push(parseInt(x[j]))
        }
      }
      return stanjeDetektora
    },
    program (state) {
      return state.program[0]
    }
  },
  mutations: {
    changeReadyState (state, newReadyState) {
      state.readyState = newReadyState
    },
    setUri (state, uri) {
      state.uri = uri
    },
    set (state, trafficController) {
      state.t2000Communicating = true
      for (const [key, value] of Object.entries(trafficController)) {
        state[key] = value
      }
    }
  }
}
