<template>
  <v-container fluid grid-list-md>
    <v-row>
      <v-col xs="12" lg="4">
        <traffic-controller-svg
          v-if="config.DEVICE_CODE"
          :traffic-controller="trafficController"
          :stanje-signala="stanjeSignala"
          :stanje-detektora="stanjeDetektora"
        >
        </traffic-controller-svg>
      </v-col>
      <v-col xs="12" lg="8">
        <app-detektori-stalne-najave-card></app-detektori-stalne-najave-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ConfigMixin from '@/mixins/ConfigMixin'
export default {
  mixins: [ConfigMixin],
  name: 'DetektoriStalneNajavePage',
  data () {
    return {
      loading: false,
      config: {
        DEVICE_CODE: null,
        DEVICE_TYPE: null,
        TLOCRT: null,
        TLOCRT_COLOR: '#fff'
      },
      layers: [],
      t: Date.now()
    }
  },
  computed: {
    trafficController () {
      return {
        code: this.config.DEVICE_CODE,
        type: this.config.DEVICE_TYPE,
        svg_link: `${this.$store.getters['app/APP_API_URI']}/tlocrt/?t=${this.t}`,
        svg_background_color: this.config.TLOCRT_COLOR
      }
    },
    statusnaRijec () {
      return this.$store.getters['t1/statusnaRijec']
    },
    stanjeSignala () {
      return this.$store.getters['t1/stanjeSignala']
    },
    stanjeDetektora () {
      return this.$store.getters['t1/stanjeDetektora']
    }
  },
  methods: {
    init () {
      this.config.DEVICE_CODE = null
      this.config.TLOCRT = null
      this.t = Date.now()
      this.initConfigData()
    }
  },
  created () {
    this.init()
  }
}
</script>
