<template>
  <v-dialog
    v-model="openDialog"
    persistent
    max-width="600"
  >
    <v-card>
      <v-card-title class="headline" primary-title>{{headline}}</v-card-title>

      <v-card-text>
        {{text}}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="red darken-1"
          @click="userAction(false)"
        >
          {{this.$t('ui.no')}}
        </v-btn>

        <v-btn
          color="green darken-1"
          @click="userAction(true)"
        >
          {{this.$t('ui.yes')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmActionDialog',
  props: ['openDialog', 'headline', 'text'],
  methods: {
    userAction: function (actionResult) {
      this.$emit('userAction', actionResult)
    }
  }
}
</script>
