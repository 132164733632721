<template>
  <div>
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>
          <span class="text-xl-left">{{ $t('ui.gpio') }}</span>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr v-for="(gpio, index) in config.APP_GPIO_SETTINGS" :key="index">
                <td>
                  <app-gpio-switch-handler
                    :headline="gpio.label.hr"
                    :label="gpio.label.hr"
                    :url="gpioUrl"
                    :gpio-addr="gpio.addr"
                    :timeout="gpio.timeout">
                  </app-gpio-switch-handler>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import AuthMixin from '@/mixins/AuthMixin'
import ConfigMixin from '@/mixins/ConfigMixin'

export default {
  name: 'GpioSwitchCard',
  mixins: [ConfigMixin, AuthMixin],
  data () {
    return {
      loading: false,
      gettingGpioStates: false,
      getGpioStatesId: null,
      configMeta: {
        APP_GPIO_SETTINGS: {
          type: Array
        }
      },
      config: {
        APP_GPIO_SETTINGS: []
      },
      gpioState: []
    }
  },
  computed: {
    gpioUrl () {
      return `${this.$store.getters['app/APP_API_URI']}/gpio/`
    }
  },
  methods: {
    init () {
      return new Promise((resolve, reject) => {
        this.initConfigData()
          .then(() => {
            for (let i = 0; i < this.config.APP_GPIO_SETTINGS.length; i++) {
              this.gpioState[i] = false
            }
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    getGpioStates () {
      return new Promise((resolve, reject) => {

        if (this.gettingGpioStates) {
          reject()
        }

        this.gettingGpioStates = true

        let gpio = null
        let promise = null
        let promises = []

        for (let i = 0; i < this.config.APP_GPIO_SETTINGS.length; i++) {
          gpio = this.config.APP_GPIO_SETTINGS[i]
          promise = axios.get(`/gpio/?gpio=${gpio.addr}`, {baseURL: this.$store.getters['app/APP_API_URI']})
          promises.push(promise)
        }

        Promise.all(promises)
          .then((answers) => {
            for (let i = 0; i < this.config.APP_GPIO_SETTINGS.length; i++) {
              this.gpioState[i] = answers[i].data.state
            }
            resolve()
          })
          .catch(() => {
            reject()
          })
          .finally(() => {
            this.gettingGpioStates = false
          })
      })
    }
  },
  created () {
    this.loading = true
    this.init()
    .finally(() => {
      this.loading = false
    })
  }
}
</script>
