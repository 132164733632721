<template>
  <v-card>
    <v-toolbar flat dense p0 v-show="showToolbar">
      <v-toolbar-title>{{title}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        class="ml-2"
        :loading="loading"
        :disabled="loading"
        plain
        color="secondary"
      >
      </v-btn>
      <v-btn
        icon
        @click="toggleVideoPlay()">
        <v-icon>{{cameraPlayIcon}}</v-icon>
      </v-btn>
    </v-toolbar>
    <app-camera-mjpeg-stream v-if="camera.stream_type === 'mjpeg'" :camera="camera" :controls="controls"></app-camera-mjpeg-stream>
    <app-camera-http-stream v-if="camera.stream_type === 'rtsp' || camera.stream_type === 'frame_snap'" :camera="camera" :controls="controls"></app-camera-http-stream>
  </v-card>
</template>

<script>
export default {
  name: 'CameraTile',
  props: {
    camera: {
      type: Object
    },
    title: {
      type: String,
      default () {
        return ''
      }
    },
    showToolbar: {
      type: Boolean,
      default: function () {
        return true
      }
    }
  },
  data () {
    return {
      loading: false,
      cameraPlay: false
    }
  },
  computed: {
    playVideo () {
      return this.cameraPlay
    },
    cameraPlayIcon () {
      if (this.playVideo) {
        return 'pause'
      }
      return 'play_arrow'
    },
    controls () {
      return {
        'playVideo': this.playVideo
      }
    }
  },
  methods: {
    toggleVideoPlay () {
      this.cameraPlay = !this.cameraPlay
    }
  }
}
</script>
