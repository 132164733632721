<template>
    <div class="canvas-holder">
      <canvas :id="canvasId" v-show="controls.playVideo"></canvas>
    </div>
</template>

<script>
export default {
  name: 'CameraHttpStream',
  props: {
    camera: {
      type: Object
    },
    controls: {
      type: Object
    }
  },
  data () {
    return {
      date: new Date(),
      image: new Image(),
      canvasId: this.$lodash.uniqueId('canvas_'),
      canvas: null,
      ctx: null,
      getSnapSrcId: null,
      cameraPlay: false,
      loaded: false,
      has_cached: false,
      loading: false,
      lastRequest: null
    }
  },
  mounted () {
    this.canvas = document.getElementById(this.canvasId)
    this.ctx = this.canvas.getContext('2d')

    this.image.onload = this.snapLoaded // draw when image has loaded
    this.image.onerror = this.snapError
    this.setSnapSrc(true)
  },
  created () {
    this.cameraPlay = this.controls.playVideo
    this.isImgRequestingId = setInterval(() => {
      let now = new Date().getTime() / 1000
      if (this.lastRequest && now - this.lastRequest < 1.5) {
        this.loading = true
      } else {
        this.loading = false
      }
    }, 100)
  },
  destroyed () {
    // clearInterval(this.getSnapInterval)
    this.image.removeAttribute('src')
    clearTimeout(this.getSnapSrcId)
    clearInterval(this.isImgRequestingId)
  },
  methods: {
    setStatus (status) {
      this.status = status
    },
    setSnapSrc (cache) {
      this.lastRequest = new Date().getTime() / 1000
      if (cache) {
        this.image.src = `${this.$store.getters['app/CAMERAS_FEED_SERVER_API_URI']}?code=${this.camera.code}`
      } else {
        this.image.src = `${this.$store.getters['app/CAMERAS_FEED_SERVER_API_URI']}?code=${this.camera.code}&t=${new Date().getTime()}`
        if (this.has_cached) {
          this.image.src = `${this.image.src}&hc=1`
        }
      }
    },
    getSnap () {
      this.getSnapSrcId = setTimeout(this.setSnapSrc, 900 + (Math.random() * (100 - 200) + 200))
    },
    snapLoaded () {
      this.loaded = true
      this.has_cached = true

      // use the intrinsic size of image in CSS pixels for the canvas element
      // this.canvas.width = this.image.naturalWidth
      // this.canvas.height = this.image.naturalHeight
      this.canvas.width = 320
      this.canvas.height = 240
      this.ctx.drawImage(this.image, 0, 0, 320, 240)

      if (this.controls.playVideo) {
        this.getSnap()
      }

    },
    snapError () {
      if (this.controls.playVideo) {
        this.getSnap()
      }
    }
  },
  watch: {
    controls (controlsState) {
      if (controlsState.playVideo === true) {
        this.setSnapSrc()
      }
    }
  }
}
</script>
