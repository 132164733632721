<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">{{ config.name }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        @click="doStartSupervisorJob(supervisorConfigKey)">
        <v-icon>play_arrow</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="doStopSupervisorJob(supervisorConfigKey)">
        <v-icon>pause</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="openConfirmDeleteDialog(supervisorConfigKey)">
        <v-icon color="red">delete</v-icon>
      </v-btn>
    </v-toolbar>
    <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>
    <v-card-text>
      <form>
        <v-flex xs12>
          <v-select
            :items="supervisorJobStatusItems"
            item-text="name"
            item-value="id"
            v-model="config.enabled"
          ></v-select>
        </v-flex>
        <v-flex xs12>
          <v-text-field
            v-model="config.name"
            :label="$t('ui.name')"
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-combobox
            v-model="config.api_url"
            :items="apiUrls"
            label="API URL"
          ></v-combobox>
        </v-flex>
        <v-flex xs12>
          <v-select
            :label="$t('ui.traffic_controller_type')"
            :items="trafficControllerTypes"
            v-model="config.device_type"
            item-text="name"
            item-value="id"
            :menu-props="{maxHeight:'400'}"
            persistent-hint
          ></v-select>
        </v-flex>
        <v-flex xs12>
          <v-text-field
            v-model="config.device_code"
            :label="$t('ui.code')"
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-combobox
            v-model="config.device_backend_url"
            :items="deviceUrls"
            label="DEVICE BACKEND URL"
          ></v-combobox>
        </v-flex>

        <v-flex xs12>
          <v-select
            :items="acStatusCheckItems"
            item-text="name"
            item-value="id"
            v-model="config.ac_status_check"
            :label="$t('ui.ac_status_check')"
          ></v-select>
        </v-flex>

        <v-flex xs12>
          <v-combobox
            v-model="config.ac_status_url"
            :items="acStatusUrls"
            label="AC STATUS URL"
          ></v-combobox>
        </v-flex>

        <v-flex xs12>
          <v-combobox
            v-model="config.tick_s"
            :items="tick_s"
            label="TICK"
          ></v-combobox>
        </v-flex>

        <v-flex xs12>
          <v-text-field
            v-model="config.supervisor_key"
            label="Supervisor key"
            disabled
          ></v-text-field>
        </v-flex>
      </form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      {{supervisorJobStatusText}}
    </v-card-actions>

    <app-confirm-action-dialog
      :open-dialog="openDeleteDialog"
      :headline="$t('ui.confirm_delete')"
      :text="$t('ui.really_delete_items')"
      @userAction="confirmActionDialogUserActionHandler"
    >
    </app-confirm-action-dialog>

  </v-card>
</template>

<script>
import ConfigMixin from '@/mixins/ConfigMixin'
import SupervisorMixin from '@/mixins/SupervisorMixin'

export default {
  name: 'app-centar-card',
  mixins: [ConfigMixin, SupervisorMixin],
  data () {
    return {
      loading: false,
      openDeleteDialog: false,
      trafficControllerTypes: [
        {
          id: 'T-1',
          name: 'T-1'
        },
        {
          id: 'T-2000',
          name: 'T-2000'
        },
        {
          id: 'Telvent',
          name: 'Telvent'
        },
        {
          id: 'ETX',
          name: 'ETX'
        }
      ],
      apiUrls: [
        'http://10.5.0.240:8080/api/v2/tce/',
        'http://10.27.4.50/api/v2/tce/',
        'http://192.168.53.51/api/v2/tce/',
        'http://46.101.124.108/api/v2/tce/'
      ],
      deviceUrls: [
        'http://127.0.0.1:8888/t-1/za-centar/',
        'http://127.0.0.1:8888/t-2000/za-centar/',
        'http://127.0.0.1:8888/telvent/za-centar/',
        'http://127.0.0.1:8888/etx/za-centar/'
      ],
      acStatusCheckItems: [
        { 'id': '0', 'name': this.$t('ui.not_active') },
        { 'id': '1', 'name': this.$t('ui.active') }
      ],
      acStatusUrls: [
        'http://127.0.0.1:6001/ac-status/'
      ],
      tick_s: [
        1,
        3,
        5
      ]
    }
  },
  props: {
    config: {
      type: Object,
      default: function () {
        return {
          'name': 'Centar',
          'enabled': '1',
          'supervisor_key': 'notify_centar_',
          'api_url': 'http://46.101.124.108/api/v2/tce/',
          'device_type': 'T-1',
          'device_code': 'K000',
          'device_backend_url': 'http://127.0.0.1:8888/t-1/za-centar/',
          'template': '',
          'tick_s': 1
        }
      }
    }
  },
  computed: {
    jobAutoStart () {
      if (this.config.enabled === '1') {
        return true
      }

      return false
    },
    supervisorTemplate () {
      return `[program:${this.config.supervisor_key}]
command=/opt/.virtualenvs/trafconnect_v2/bin/python /opt/trafsignal/apps/notify_centar/app.py --api_url=${this.config.api_url} --device_type=${this.config.device_type} --device_code=${this.config.device_code} --device_backend_url=${this.config.device_backend_url} --ac_status_check=${this.config.ac_status_check} --ac_status_url=${this.config.ac_status_url} --tick_s=${this.config.tick_s}
directory=/opt/trafsignal/apps/notify_centar
environment=PYTHONPATH="/opt"
priority=1
autostart=${this.jobAutoStart}
autorestart=${this.jobAutoStart}
startretries=999
user=root
stdout_logfile_maxbytes=10KB
stdout_logfile_backups=0
stderr_logfile_maxbytes=10KB
stderr_logfile_backups=0
stdout_capture_maxbytes=10KB
`
    },
    supervisorConfigKey () {
      return this.config.supervisor_key
    }
  },
  watch: {
    config: {
      handler: function () {
        this.config.template = this.supervisorTemplate
      },
      deep: true
    }
  },
  methods: {
    openConfirmDeleteDialog () {
      this.openDeleteDialog = true
    },
    confirmActionDialogUserActionHandler (actionResult) {
      this.openDeleteDialog = false
      var self = this
      if (actionResult === true) {
        this.$parent.$emit('supervisor-job-deleted', {
          key: self.supervisorConfigKey
        })
      }
    }
  },
  created () {
    this.getJobStatusIntervalId = setInterval(() => {
      if (!this.statusUpdating) {
        this.updateSupervisorJobStatus(this.supervisorConfigKey)
      }
    }, 5000)
  },
  destroyed () {
    if (this.getJobStatusIntervalId) {
      clearInterval(this.getJobStatusIntervalId)
    }
  }
}
</script>
