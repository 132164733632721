<template>
  <v-container fluid grid-list-md>

    <v-layout row wrap>
      <v-flex xs12 md4>
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ this.$t('ui.mobilni_internet') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="doStartSupervisorJob(supervisorConfigKey)">
              <v-icon>play_arrow</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="doStopSupervisorJob(supervisorConfigKey)">
              <v-icon>pause</v-icon>
            </v-btn>
          </v-toolbar>

          <v-progress-linear
            v-show="loading"
            color="primary"
            indeterminate
          ></v-progress-linear>

          <v-card-text>
            <form>

              <v-flex xs12>
                <v-select
                  :items="supervisorJobStatusItems"
                  item-text="name"
                  item-value="id"
                  v-model="config.CELLULAR_INTERNET_ENABLED"
                ></v-select>
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  v-model="config.CELLULAR_INTERNET_MODEM_PATH"
                  label="PATH"
                  :error-messages="modemPathErrors"
                  @input="$v.config.CELLULAR_INTERNET_MODEM_PATH.$touch()"
                  @blur="$v.config.CELLULAR_INTERNET_MODEM_PATH.$touch()"
                  required
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-combobox
                  v-model="config.CELLULAR_INTERNET_MODEM_MODEL"
                  :items="modemModels"
                  label="MODEM"
                  :error-messages="modemModelErrors"
                  @input="$v.config.CELLULAR_INTERNET_MODEM_MODEL.$touch()"
                  @blur="$v.config.CELLULAR_INTERNET_MODEM_MODEL.$touch()"
                  required
                ></v-combobox>
              </v-flex>

              <v-flex xs12>
                <v-combobox
                  v-model="config.CELLULAR_INTERNET_APN"
                  :items="apns"
                  label="APN"
                ></v-combobox>
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  v-model="config.CELLULAR_INTERNET_PIN"
                  label="PIN"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  v-model="config.CELLULAR_INTERNET_APN_USERNAME"
                  :label="$t('ui.username')"
                  :error-messages="usernameErrors"
                  @input="$v.config.CELLULAR_INTERNET_APN_USERNAME.$touch()"
                  @blur="$v.config.CELLULAR_INTERNET_APN_USERNAME.$touch()"
                  required
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  v-model="config.CELLULAR_INTERNET_APN_PASSWORD"
                  :label="$t('ui.password')"
                  :error-messages="passwordErrors"
                  @input="$v.config.CELLULAR_INTERNET_APN_PASSWORD.$touch()"
                  @blur="$v.config.CELLULAR_INTERNET_APN_PASSWORD.$touch()"
                  required
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-combobox
                  v-model="config.CELLULAR_INTERNET_PING_HOSTS"
                  :items="hosts"
                  label="PING"
                  :error-messages="pingErrors"
                  @input="$v.config.CELLULAR_INTERNET_PING_HOSTS.$touch()"
                  @blur="$v.config.CELLULAR_INTERNET_PING_HOSTS.$touch()"
                  required
                  multiple
                ></v-combobox>
              </v-flex>

              <v-flex xs12>
                <v-combobox
                  v-model="config.CELLULAR_CONNECTION_ENABLER_DNS_SERVERS"
                  :items="dns"
                  label="DNS"
                  :error-messages="dnsErrors"
                  @input="$v.config.CELLULAR_CONNECTION_ENABLER_DNS_SERVERS.$touch()"
                  @blur="$v.config.CELLULAR_CONNECTION_ENABLER_DNS_SERVERS.$touch()"
                  required
                  multiple
                ></v-combobox>
              </v-flex>

              <v-flex xs12>
                <v-combobox
                  v-model="config.CELLULAR_INTERNET_HALT_COMMAND"
                  :items="haltCmds"
                  label="HALT"
                  :error-messages="haltCmdErrors"
                  @input="$v.config.CELLULAR_INTERNET_HALT_COMMAND.$touch()"
                  @blur="$v.config.CELLULAR_INTERNET_HALT_COMMAND.$touch()"
                  required
                ></v-combobox>
              </v-flex>

            </form>
          </v-card-text>

          <v-card-actions>
            <v-btn
              :disabled="submitAvailable ? false : true"
              @click="submit"
              color="primary"
            >{{ this.$t('ui.submit') }}</v-btn>
            <v-spacer></v-spacer>
            {{supervisorJobStatusText}}
          </v-card-actions>

        </v-card>
      </v-flex>
      <v-flex xs12 md8>
        <app-supervisor-job-log-card :supervisor-config-key="supervisorConfigKey"></app-supervisor-job-log-card>
      </v-flex>

      <v-flex xs12 md2>
        <v-card>
          <v-card-text>
            <ul>
              <li>VIP</li>
              <li>apn: data.vip.hr</li>
              <li>username: 38591</li>
              <li>password: 38591</li>
            </ul>
          </v-card-text>
        </v-card>
      </v-flex>

      <v-flex xs12 md2>
        <v-card>
          <v-card-text>
            <ul>
              <li>Hrvatski Telekom</li>
              <li>apn: internet.ht.hr</li>
              <li>username: internet</li>
              <li>password: internet</li>
            </ul>
          </v-card-text>
        </v-card>

      </v-flex>
    </v-layout>

  </v-container>
</template>

<script>
import ConfigMixin from '@/mixins/ConfigMixin'
import SupervisorMixin from '@/mixins/SupervisorMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CellularInternetPage',
  mixins: [ConfigMixin, SupervisorMixin, validationMixin],
  data () {
    return {
      supervisorConfigKey: 'cellular_connection_enabler',
      loading: false,
      configMeta: {
        CELLULAR_INTERNET_PING_HOSTS: {
          type: Array
        },
        CELLULAR_CONNECTION_ENABLER_DNS_SERVERS: {
          type: Array
        }
      },
      config: {
        CELLULAR_INTERNET_ENABLED: false,
        CELLULAR_INTERNET_APN: null,
        CELLULAR_INTERNET_APN_USERNAME: null,
        CELLULAR_INTERNET_APN_PASSWORD: null,
        CELLULAR_INTERNET_PIN: '',
        CELLULAR_INTERNET_PING_HOSTS: null,
        CELLULAR_CONNECTION_ENABLER_SUPERVISOR_CONFIG: null,
        CELLULAR_CONNECTION_ENABLER_DNS_SERVERS: [],
        CELLULAR_INTERNET_HALT_COMMAND: 'halt',
        CELLULAR_INTERNET_MODEM_PATH: '/dev/3g-modem',
        CELLULAR_INTERNET_MODEM_MODEL: 'zte_npe_9500'
      },
      apns: [
        'data.vip.hr',
        'semafor-drsc.si',
        'internet.ht.hr'
      ],
      modemModels: [
        'zte_npe_9500',
        'dlink_dwm_157'
      ],
      hosts: [
        '46.101.124.108',
        '1.1.1.1',
        '8.8.8.8',
        '208.67.222.222',
        '10.27.4.50'
      ],
      dns: [
        '1.1.1.1',
        '8.8.8.8',
        '208.67.222.222',
        '10.27.0.1'
      ],
      haltCmds: [
        'halt',
        'reboot'
      ]
    }
  },
  validations: {
    config: {
      CELLULAR_INTERNET_ENABLED: {
        required
      },
      CELLULAR_INTERNET_APN: {
        required
      },
      CELLULAR_INTERNET_APN_USERNAME: {
        required
      },
      CELLULAR_INTERNET_APN_PASSWORD: {
        required
      },
      CELLULAR_INTERNET_PING_HOSTS: {
        required
      },
      CELLULAR_CONNECTION_ENABLER_DNS_SERVERS: {
        required
      },
      CELLULAR_INTERNET_HALT_COMMAND: {
        required
      },
      CELLULAR_INTERNET_MODEM_PATH: {
        required
      },
      CELLULAR_INTERNET_MODEM_MODEL: {
        required
      }
    }
  },
  computed: {
    isFormValid () {
      return !this.$v.$invalid
    },
    submitAvailable () {
      if (!this.loading && this.isFormValid) {
        return true
      }
      return false
    },
    modemModelErrors () {
      const errors = []
      if (!this.$v.config.CELLULAR_INTERNET_MODEM_MODEL.$dirty) return errors
      !this.$v.config.CELLULAR_INTERNET_MODEM_MODEL.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    modemPathErrors () {
      const errors = []
      if (!this.$v.config.CELLULAR_INTERNET_MODEM_PATH.$dirty) return errors
      !this.$v.config.CELLULAR_INTERNET_MODEM_PATH.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    apnErrors () {
      const errors = []
      if (!this.$v.config.CELLULAR_INTERNET_APN.$dirty) return errors
      !this.$v.config.CELLULAR_INTERNET_APN.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    usernameErrors () {
      const errors = []
      if (!this.$v.config.CELLULAR_INTERNET_APN_USERNAME.$dirty) return errors
      !this.$v.config.CELLULAR_INTERNET_APN_USERNAME.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    passwordErrors () {
      const errors = []
      if (!this.$v.config.CELLULAR_INTERNET_APN_PASSWORD.$dirty) return errors
      !this.$v.config.CELLULAR_INTERNET_APN_PASSWORD.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    pingErrors () {
      const errors = []
      if (!this.$v.config.CELLULAR_INTERNET_PING_HOSTS.$dirty) return errors
      !this.$v.config.CELLULAR_INTERNET_PING_HOSTS.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    dnsErrors () {
      const errors = []
      if (!this.$v.config.CELLULAR_CONNECTION_ENABLER_DNS_SERVERS.$dirty) return errors
      !this.$v.config.CELLULAR_CONNECTION_ENABLER_DNS_SERVERS.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    haltCmdErrors () {
      const errors = []
      if (!this.$v.config.CELLULAR_INTERNET_HALT_COMMAND.$dirty) return errors
      !this.$v.config.CELLULAR_INTERNET_HALT_COMMAND.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    jobAutoStart () {
      if (this.config.CELLULAR_INTERNET_ENABLED === '1') {
        return true
      }

      return false
    },
    pingHosts () {
      if (!this.config.CELLULAR_INTERNET_PING_HOSTS) {
        return this.hosts.join(' ')
      }

      return this.config.CELLULAR_INTERNET_PING_HOSTS.join(' ')
    },
    setDnsServers () {
      if (!this.config.CELLULAR_CONNECTION_ENABLER_DNS_SERVERS) {
        return this.dns.join(' ')
      }

      return this.config.CELLULAR_CONNECTION_ENABLER_DNS_SERVERS.join(' ')
    },
    cellularConnectionEnablerTemplate () {
      return `[program:${this.supervisorConfigKey}]
command=/opt/.virtualenvs/trafconnect_v2/bin/python /opt/trafsignal/apps/cellular_enabler.py --apn=${this.config.CELLULAR_INTERNET_APN} --apn_username=${this.config.CELLULAR_INTERNET_APN_USERNAME} --apn_password=${this.config.CELLULAR_INTERNET_APN_PASSWORD} --pin=${this.config.CELLULAR_INTERNET_PIN} --modem_path=${this.config.CELLULAR_INTERNET_MODEM_PATH} --modem_model=${this.config.CELLULAR_INTERNET_MODEM_MODEL} --ping_hosts ${this.pingHosts} --dns_servers ${this.setDnsServers} --halt_cmd ${this.config.CELLULAR_INTERNET_HALT_COMMAND}
directory=/opt/trafsignal/apps
environment=PYTHONPATH="/opt"
priority=1
autostart=${this.jobAutoStart}
autorestart=${this.jobAutoStart}
startretries=999
user=root
stdout_logfile_maxbytes=10KB
stdout_logfile_backups=0
stderr_logfile_maxbytes=10KB
stderr_logfile_backups=0
stdout_capture_maxbytes=10KB
`
    }
  },
  methods: {
    init () {
      this.initConfigData()
    },
    submit () {
      this.loading = true
      var promises = []

      promises.push(this.createConfig({
        'key': 'CELLULAR_INTERNET_ENABLED',
        'value': this.config.CELLULAR_INTERNET_ENABLED
      }))

      promises.push(this.createConfig({
        'key': 'CELLULAR_INTERNET_APN',
        'value': this.config.CELLULAR_INTERNET_APN
      }))

      promises.push(this.createConfig({
        'key': 'CELLULAR_INTERNET_APN_USERNAME',
        'value': this.config.CELLULAR_INTERNET_APN_USERNAME
      }))

      promises.push(this.createConfig({
        'key': 'CELLULAR_INTERNET_APN_PASSWORD',
        'value': this.config.CELLULAR_INTERNET_APN_PASSWORD
      }))

      promises.push(this.createConfig({
        'key': 'CELLULAR_INTERNET_PIN',
        'value': this.config.CELLULAR_INTERNET_PIN
      }))

      promises.push(this.createConfig({
        'key': 'CELLULAR_INTERNET_PING_HOSTS',
        'value': this.config.CELLULAR_INTERNET_PING_HOSTS
      }))

      promises.push(this.createConfig({
        'key': 'CELLULAR_INTERNET_HALT_COMMAND',
        'value': this.config.CELLULAR_INTERNET_HALT_COMMAND
      }))

      promises.push(this.createConfig({
        'key': 'CELLULAR_CONNECTION_ENABLER_DNS_SERVERS',
        'value': this.config.CELLULAR_CONNECTION_ENABLER_DNS_SERVERS
      }))

      promises.push(this.createConfig({
        'key': 'CELLULAR_INTERNET_MODEM_MODEL',
        'value': this.config.CELLULAR_INTERNET_MODEM_MODEL
      }))

      promises.push(this.createConfig({
        'key': 'CELLULAR_INTERNET_MODEM_PATH',
        'value': this.config.CELLULAR_INTERNET_MODEM_PATH
      }))

      promises.push(this.createConfig({
        'key': 'CELLULAR_CONNECTION_ENABLER_SUPERVISOR_CONFIG',
        'value': this.cellularConnectionEnablerTemplate
      }))

      promises.push(this.createSupervisorConfig({
        'key': this.supervisorConfigKey,
        'config': this.cellularConnectionEnablerTemplate
      }))

      Promise.all(promises)
        .then(() => {

          // svi upiti su gotovi
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: self.$t('ui.save_success')
          })

        })
        .finally(() => {
          this.loading = false
          this.init()
        })
    }
  },
  created () {
    this.init()
    this.getJobStatusIntervalId = setInterval(() => {
      if (!this.statusUpdating) {
        this.updateSupervisorJobStatus(this.supervisorConfigKey)
      }
    }, 5000)
  },
  destroyed () {
    if (this.getJobStatusIntervalId) {
      clearInterval(this.getJobStatusIntervalId)
    }
  }
}
</script>
