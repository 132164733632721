<template>
  <v-container fluid grid-list-sm>

    <v-row dense>

      <v-col xs="12" v-if="cameras.length < 1">
        <v-alert
          :value="cameras.length < 1"
          color="primary"
          icon="warning"
        >
        {{ this.$t('ui.no_defined_cameras') }}
        </v-alert>
      </v-col>

      <v-col md3 xs12 style="max-width:330px;" v-for="camera in videoStreams" v-bind:key="camera.code">
        <app-camera-tile :camera="camera" :title="camera.name"></app-camera-tile>
      </v-col>

    </v-row>

  </v-container>
</template>

<script>
import ConfigMixin from '@/mixins/ConfigMixin'

export default {
  name: 'CameraFeedPage',
  mixins: [ConfigMixin],
  data () {
    return {
      cameras: []
    }
  },
  computed: {
    videoStreams () {
      return this.cameras.filter((camera) => {
        if (camera.stream_video === true) {
          return camera
        }
      })
    }
  },
  methods: {
    init () {
      this.loading = true

      this.getConfigByKey('CAMERAS_CONFIG')
        .then((response) => {
          this.cameras = JSON.parse(response.data.value)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    this.init()
  }
}
</script>
