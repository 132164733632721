<template>
  <v-card>

    <h3 class="text-center">
      {{grupaId}}
    </h3>

    <v-card-text>
      <v-row class="font-weight-black" v-bind:style="{ background: CRVENO_BOJA, verticalAlign: 'middle' }">
        <v-col>
          <span>C</span>
        </v-col>
        <v-col>
          <v-text-field v-if="snage" disabled :value="snage[1]"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field v-model="savedSnage[0]"></v-text-field>
        </v-col>
        <v-col v-if="unit">
          <v-text-field :value="unit" disabled></v-text-field>
        </v-col>
      </v-row>
      <v-row class="font-weight-black" v-bind:style="{ background: KONTROLIRANO_CRVENO_BOJA }">
        <v-col>
          <span>CK</span>
        </v-col>
        <v-col>
          <v-text-field v-if="snage" disabled :value="snage[0]"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field v-model="savedSnage[1]"></v-text-field>
        </v-col>
        <v-col v-if="unit">
          <v-text-field :value="unit" disabled></v-text-field>
        </v-col>
      </v-row>
      <v-row class="font-weight-black" v-bind:style="{ background: ZUTO_BOJA }">
        <v-col>
          <span>Ž</span>
        </v-col>
        <v-col>
          <v-text-field v-if="snage" disabled :value="snage[2]"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field v-model="savedSnage[2]"></v-text-field>
        </v-col>
        <v-col v-if="unit">
          <v-text-field :value="unit" disabled></v-text-field>
        </v-col>
      </v-row>
      <v-row class="font-weight-black" v-bind:style="{ background: ZELENO_BOJA }">
        <v-col>
          <span>Z</span>
        </v-col>
        <v-col>
          <v-text-field v-if="snage" disabled :value="snage[3]"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field v-model="savedSnage[3]"></v-text-field>
        </v-col>
        <v-col v-if="unit">
          <v-text-field :value="unit" disabled></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'app-kutija-snage-editable-card',
  data () {
    return {
      AKTIVNO_CRVENO_BOJA: '#F62817',
      AKTIVNO_KONTROLIRANO_CRVENO_BOJA: '#990012',
      AKTIVNO_ZUTO_BOJA: '#FDD017',
      AKTIVNO_ZELENO_BOJA: '#8FC33A',
      NIJE_AKTIVNO_BOJA: '#363636'
    }
  },
  props: ['grupaId', 'signali', 'snage', 'savedSnage', 'unit'],
  computed: {
    CRVENO_BOJA () {
      if (this.signali && this.signali[0] === 1) return this.AKTIVNO_CRVENO_BOJA
      return this.NIJE_AKTIVNO_BOJA
    },
    KONTROLIRANO_CRVENO_BOJA () {
      if (this.signali && this.signali[0] === 1) return this.AKTIVNO_KONTROLIRANO_CRVENO_BOJA
      return this.NIJE_AKTIVNO_BOJA
    },
    ZUTO_BOJA () {
      if (this.signali && this.signali[1] === 1) return this.AKTIVNO_ZUTO_BOJA
      return this.NIJE_AKTIVNO_BOJA
    },
    ZELENO_BOJA () {
      if (this.signali && this.signali[2] === 1) return this.AKTIVNO_ZELENO_BOJA
      return this.NIJE_AKTIVNO_BOJA
    }
  }
}
</script>
