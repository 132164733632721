<template>
  <img v-if="controls.playVideo" :src="camera.stream_url" width="320" height="240">
</template>

<script>
export default {
  name: 'CameraMjpegStream',
  props: {
    camera: {
      type: Object
    },
    controls: {
      type: Object
    }
  }
}
</script>
