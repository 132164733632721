<template>
  <v-card>
    <v-toolbar flat dense>
      <v-toolbar-title>{{title}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        @click="togglePauseState">
        <v-icon>{{playControlIcon}}</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="toggleLoop">
        <v-icon>{{loopIcon}}</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <textarea style="white-space: pre-wrap; width: 100%; color: white; min-height: 512px" v-model="tail.output">
      </textarea>
    </v-card-text>
  </v-card>
</template>

<script>
import SupervisorMixin from '@/mixins/SupervisorMixin'

export default {
  name: 'app-supervisor-job-log-card',
  mixins: [SupervisorMixin],
  data () {
    return {
      tail: {
        output: ''
      },
      pause: true,
      tailRunning: false,
      loop: false
    }
  },
  props: {
    supervisorConfigKey: {
      type: String,
      default: null
    },
    supervisorOutput: {
      type: String,
      default: 'stderr'
    },
    title: {
      type: String,
      default: 'LOG'
    },
    rows: {
      type: Number,
      default: 20
    }
  },
  computed: {
    playControlIcon () {
      if (this.pause) {
        return 'play_arrow'
      }

      return 'pause'
    },
    loopIcon () {
      if (this.loop) {
        return 'loop'
      }

      return 'arrow_downward'
    }
  },
  methods: {
    togglePauseState () {
      this.pause = !this.pause
    },
    toggleLoop () {
      this.loop = !this.loop
    },
    doTailSupervisorJob () {
      this.tailRunning = true
      this.tailSupervisorJob(this.supervisorConfigKey, this.supervisorOutput)
        .then((response) => {
          if (this.loop) {
            this.tail.output = this.tail.output + response.data.output
          } else {
            this.tail.output = response.data.output
          }
        })
        .finally(() => {
          this.tailRunning = false
        })
    }
  },
  created () {
    this.tailSupervisorJobId = setInterval(() => {
      if (!this.tailRunning && !this.pause) {
        this.doTailSupervisorJob()
      }
    }, 5000)
  },
  destroyed () {
    if (this.tailSupervisorJobId) {
      clearInterval(this.tailSupervisorJobId)
    }
  }
}
</script>
