<template>
  <v-container fluid grid-list-md>

    <v-layout row wrap>
      <v-flex xs12 md4>
        <v-card>
          <v-toolbar flat dense>
            <v-toolbar-title>
              <span class="text-xl-left">GPS</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="doStartSupervisorJob(supervisorConfigKey)">
              <v-icon>play_arrow</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="doStopSupervisorJob(supervisorConfigKey)">
              <v-icon>pause</v-icon>
            </v-btn>
          </v-toolbar>

          <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>

          <v-card-text>
            <form>

              <v-flex xs12>
                <v-select
                  :items="supervisorJobStatusItems"
                  item-text="name"
                  item-value="id"
                  v-model="config.GPS_ENABLED"
                ></v-select>
              </v-flex>

              <v-flex xs12>
                <v-select
                  :items="gpsDeviceTypes"
                  item-text="name"
                  item-value="id"
                  v-model="config.GPS_DEVICE_TYPE"
                  :label="$t('ui.gps_device_type')"
                ></v-select>
              </v-flex>

              <v-flex xs12>
                <v-combobox
                  v-model="config.GPS_DEVICE_BAUD_RATE"
                  :items="baudRates"
                  label="BAUD RATE"
                ></v-combobox>
              </v-flex>

              <v-flex xs12>
                <v-combobox
                  v-model="config.GPS_DEVICE_PORT"
                  :items="ports"
                  label="PORT"
                ></v-combobox>
              </v-flex>

            </form>
          </v-card-text>

          <v-card-actions>
            <v-btn
              :disabled="submitAvailable ? false : true"
              @click="submit"
              color="primary"
            >{{ this.$t('ui.submit') }}</v-btn>
            <v-spacer></v-spacer>
            {{supervisorJobStatusText}}
          </v-card-actions>

        </v-card>
      </v-flex>
      <v-flex xs12 md8>
        <app-supervisor-job-log-card :supervisor-config-key="supervisorConfigKey"></app-supervisor-job-log-card>
      </v-flex>
    </v-layout>

  </v-container>
</template>

<script>
import ConfigMixin from '@/mixins/ConfigMixin'
import SupervisorMixin from '@/mixins/SupervisorMixin'
import { validationMixin } from 'vuelidate'

export default {
  name: 'GpsCard',
  mixins: [ConfigMixin, SupervisorMixin, validationMixin],
  data () {
    return {
      supervisorConfigKey: 'gps',
      loading: false,
      config: {
        GPS_ENABLED: false,
        GPS_DEVICE_PORT: null,
        GPS_SYNC_WITH_T1: false,
        GPS_DEVICE_TYPE: null,
        GPS_DEVICE_BAUD_RATE: null,
      },
      gpsDeviceTypes: [
        {
          'id': 'ZTE_ME3630_U1A',
          'name': 'ZTE ME3630 U1A'
        },
        {
          'id': 'GENERIC_USB_GPS_DEVICE',
          'name': 'Generic USB GPS'
        }
      ],
      ports: [
        '/dev/gps'
      ],
      baudRates: [110, 300, 600, 1200, 2400, 4800, 9600, 14400, 19200, 38400, 57600, 115200, 128000, 256000]
    }
  },
  computed: {
    isFormValid () {
      return true
      // return !this.$v.$invalid
    },
    submitAvailable () {
      if (!this.loading && this.isFormValid) {
        return true
      }
      return false
    },
    jobAutoStart () {
      if (this.config.GPS_ENABLED === '1') {
        return true
      }

      return false
    },
    supervisorTemplate () {
      return `[program:${this.supervisorConfigKey}]
command=/opt/.virtualenvs/trafconnect_v2/bin/python /opt/trafsignal/apps/gps/app.py --gps_type ${this.config.GPS_DEVICE_TYPE} --gps_port ${this.config.GPS_DEVICE_PORT} --gps_port_baud_rate ${this.config.GPS_DEVICE_BAUD_RATE}
directory=/opt/trafsignal/apps/gps
environment=PYTHONPATH="/opt"
priority=1
autostart=${this.jobAutoStart}
autorestart=${this.jobAutoStart}
startretries=999
user=root
stdout_logfile_maxbytes=10KB
stdout_logfile_backups=0
stderr_logfile_maxbytes=10KB
stderr_logfile_backups=0
stdout_capture_maxbytes=10KB
    `
    }
  },
  methods: {
    init () {
      this.initConfigData()
    },
    submit () {
      this.loading = true
      var promises = []

      promises.push(this.createConfig({
        'key': 'GPS_ENABLED',
        'value': this.config.GPS_ENABLED
      }))

      promises.push(this.createConfig({
        'key': 'GPS_DEVICE_TYPE',
        'value': this.config.GPS_DEVICE_TYPE
      }))

      promises.push(this.createConfig({
        'key': 'GPS_DEVICE_BAUD_RATE',
        'value': this.config.GPS_DEVICE_BAUD_RATE
      }))

      promises.push(this.createConfig({
        'key': 'GPS_DEVICE_PORT',
        'value': this.config.GPS_DEVICE_PORT
      }))

      promises.push(this.createSupervisorConfig({
        'key': this.supervisorConfigKey,
        'config': this.supervisorTemplate
      }))

      Promise.all(promises)
        .then(() => {

          // svi upiti su gotovi
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })

        })
        .finally(() => {
          this.loading = false
          this.init()
        })
    }
  },
  created () {
    this.init()
    this.getJobStatusIntervalId = setInterval(() => {
      if (!this.statusUpdating) {
        this.updateSupervisorJobStatus(this.supervisorConfigKey)
      }
    }, 5000)
  },
  destroyed () {
    if (this.getJobStatusIntervalId) {
      clearInterval(this.getJobStatusIntervalId)
    }
  }
}
</script>
