<template>
    <v-dialog v-model="openDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline" primary-title>{{headline}}</v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <v-select
                :items="locales"
                :label="this.$t('ui.languages')"
                item-text="label"
                item-value="locale"
                v-model="locale"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" xs="12" md="6">
              <v-text-field
                v-model="APP_HOST_ADDRESS"
                label="IP ADRESA/HOSTNAME APLIKACIJE"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" md="6">
              <v-text-field
                  v-model="APP_HOST_PORT"
                  label="PORT APLIKACIJE"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" xs="12" md="6">
              <v-text-field
                  v-model="TRAFFIC_CONTROLLER_HOST_ADDRESS"
                  label="IP ADRESA/HOSTNAME SEMAFORSKOG UREĐAJA"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" md="6">
              <v-text-field
                  v-model="TRAFFIC_CONTROLLER_HOST_PORT"
                  label="PORT SEMAFORSKOG UREĐAJA"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" xs="12" md="6">
              <v-text-field
                  v-model="CAMERAS_FEED_SERVER_HOST"
                  label="IP ADRESA/HOSTNAME VIDEO SERVERA"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  v-model="CAMERAS_FEED_SERVER_PORT"
                  label="PORT VIDEO SERVERA"
              ></v-text-field>
            </v-col>
          </v-row>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="openDialog = false">
            {{this.$t('ui.cancel')}}
          </v-btn>

          <v-btn color="green darken-1" @click="save()">
            {{this.$t('ui.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'AppSettingsDialog',
  mixins: [],
  props: ['headline', 'config'],
  data () {
    return {
      openDialog: false,
      newConfig: {

      }
    }
  },
  computed: {
    locales () {
      return this.$store.getters['app/locales']
    },
    locale: {
      get () {
        return this.$store.getters['app/locale']
      },
      set (newValue) {
        this.$store.commit('app/setLocal', {locale: newValue})
      }
    },
    APP_HOST_ADDRESS: {
      get () {
        return this.config.APP_HOST_ADDRESS
      },
      set (newValue) {
        this.newConfig.APP_HOST_ADDRESS = newValue
      }
    },
    APP_HOST_PORT: {
      get () {
        return this.config.APP_HOST_PORT
      },
      set (newValue) {
        this.newConfig.APP_HOST_PORT = newValue
      }
    },
    TRAFFIC_CONTROLLER_HOST_ADDRESS: {
      get () {
        return this.config.TRAFFIC_CONTROLLER_HOST_ADDRESS
      },
      set (newValue) {
        this.newConfig.TRAFFIC_CONTROLLER_HOST_ADDRESS = newValue
      }
    },
    TRAFFIC_CONTROLLER_HOST_PORT: {
      get () {
        return this.config.TRAFFIC_CONTROLLER_HOST_PORT
      },
      set (newValue) {
        this.newConfig.TRAFFIC_CONTROLLER_HOST_PORT = newValue
      }
    },
    CAMERAS_FEED_SERVER_HOST: {
      get () {
        return this.config.CAMERAS_FEED_SERVER_HOST
      },
      set (newValue) {
        this.newConfig.CAMERAS_FEED_SERVER_HOST = newValue
      }
    },
    CAMERAS_FEED_SERVER_PORT: {
      get () {
        return this.config.CAMERAS_FEED_SERVER_PORT
      },
      set (newValue) {
        this.newConfig.CAMERAS_FEED_SERVER_PORT = newValue
      }
    }
  },
  methods: {
    userAction: function (actionResult) {
      this.$emit('userAction', actionResult)
    },
    save: function () {
      this.loading = true
      this.$store.commit('app/setLocal', this.newConfig)
      this.$i18n.locale = this.locale
      this.loading = false
      this.$emit('userAction', 'cancel')
    }
  },
  created () {
    this.$EventBus.$on('AppSettingsDialog/show', () => {
      this.openDialog = true
    })
  }
}
</script>
