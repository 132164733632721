<template>
  <v-container fluid grid-list-md>

    <v-layout row wrap>
      <v-flex xs12 md4>
        <v-card>
          <v-toolbar flat dense>
            <v-toolbar-title>
              <span class="text-xl-left">{{ $t('ui.system') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              dark
              icon
              @click="init()"
              :disabled="loading"
            >
              <v-icon>refresh</v-icon>
            </v-btn>
            <v-btn
              :disabled="submitAvailable ? false : true"
              @click="submit"
              color="primary"
            >{{ this.$t('ui.submit') }}</v-btn>
          </v-toolbar>

          <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>

          <v-card-text>
            <v-form v-model="systemTimeFormValid" ref="systemTimeForm">
              <v-flex xs12>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>{{ $t('ui.system_time') }}</td>
                        <td>{{SYSTEM_TIME.d}}{{SYSTEM_TIME.m}}{{SYSTEM_TIME.y}} {{SYSTEM_TIME.hh}}:{{SYSTEM_TIME.mm}}:{{SYSTEM_TIME.ss}}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('ui.ntp_enabled') }}</td>
                        <td>
                          <v-select :items="ntpItems" v-model="isNtpEnabled">
                          </v-select>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ $t('ui.time_zone') }}</td>
                        <td>
                          <v-autocomplete
                            :items="systemTimezones"
                            v-model="currentSystemTimeZone"
                          ></v-autocomplete>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {{ $t('ui.change_system_time') }}
                        </td>
                        <td>
                          <v-text-field
                            v-model="changeSystemTimeTo"
                            hint="dd.mm.yyyy hh:mm:ss"
                            :rules="changeSystemTimeRules"
                            clearable
                            persistent-hint></v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-flex>
              <!--<v-flex xs12>
                <h3>Provjeri NTP servere u:</h3>
                <ul>
                  <li>/etc/systemd/timesyncd.conf</li>
                  <li>/etc/ntp.conf</li>
                </ul>

                <h3>DRI</h3>
                <ul><li>10.27.0.1</li></ul>

                <h3>INTERNET</h3>
                <ul>
                  <li>0.debian.pool.ntp.org</li>
                  <li>1.debian.pool.ntp.org</li>
                  <li>2.debian.pool.ntp.org</li>
                  <li>3.debian.pool.ntp.org</li>
                </ul>
              </v-flex>-->
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12 md8>
        <v-card>
          <v-toolbar flat dense>
            <v-toolbar-title>
              <span class="text-xl-left">{{ $t('ui.help') }}</span>
            </v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <p>
              <strong>CHRONY</strong>
            </p>
            <ul>
              <li>/etc/chrony/chrony.conf</li>
            </ul>
            <p>
              <code>
              # SHM0 from gpsd is the NMEA data at 4800bps, so is not very accurate <br>
              refclock SHM 0  delay 0.5 refid GPS <br>
              # SHM1 from gpsd (if present) is from the kernel PPS_LDISC <br>
              # module.  It includes PPS and will be accurate to a few ns <br>
              refclock SHM 1 offset 0.0 delay 0.1 refid GPS+ <br>
              </code>
            </p>
            <ul>
              <li>COMMANDS:</li>
            </ul>
            <p>
              <code>
              sudo chronyc -h localhost -a makestep <br>
              sudo chronyc -h localhost -a sources <br>
              </code>
            </p>
            <p>
              <strong>UDEV</strong>
            </p>
            <p><code>/etc/udev/rules.d/<br>
            SUBSYSTEM=="tty", ATTRS{idVendor}=="19d2", ATTRS{idProduct}=="1476", ENV{ID_USB_INTERFACE_NUM}=="01", SYMLINK+="gps"<br>
            SUBSYSTEM=="tty", ATTRS{idVendor}=="067b", ATTRS{idProduct}=="2303", SYMLINK+="usb-gps"
            </code></p>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout row wrap>
      <app-gps-card></app-gps-card>
    </v-layout>

  </v-container>
</template>

<script>
import SystemMixin from '@/mixins/SystemMixin'

export default {
  name: 'SystemTimePage',
  mixins: [SystemMixin],
  data () {
    return {
      loading: false,
      systemTimeFormValid: true,
      currentSystemTimeZone: '',
      systemTimezones: [],
      changeSystemTimeTo: '',
      ntpItems: [{
        'text': this.$t('ui.yes'),
        'value': true
      },
      {
        'value': false,
        'text': this.$t('ui.no')
      }],
      isNtpEnabled: false,
      changeSystemTimeRules: [
        value => {
          if (!value) return true
          const pattern = /\d{2}.\d{2}.\d{4}\s\d{2}:\d{2}:\d{2}/
          return pattern.test(value) || this.$t('ui.invalid_format')
        }
      ]
    }
  },
  computed: {
    isFormValid () {
      return this.systemTimeFormValid
    },
    submitAvailable () {
      if (!this.loading && this.isFormValid) {
        return true
      }
      return false
    },
    isChangeSystemTimeDisabled () {
      if (this.isNtpEnabled) return true
      return false
    }
  },
  methods: {
    init () {
      this.loading = true
      let timezones = 1
      if (this.systemTimezones.length > 0) {
        timezones = 0
      }
      this.getSystemTime({'timezones': timezones})
        .then((response) => {
          if (timezones) {
            this.systemTimezones = response.data.timezones
          }
          this.currentSystemTimeZone = response.data.current_time_zone
          this.isNtpEnabled = response.data.ntp_enabled
          this.SYSTEM_TIME = response.data.timestamp
          this.timeDateCtlStatus = response.data.status
        })
        .finally(() => {
          this.loading = false
        })
    },
    submit () {

      this.loading = true

      let ntp_enabled = '0'

      if (this.isNtpEnabled) {
        ntp_enabled = '1'
      }

      this.setSystemTime({'ntp_enabled': ntp_enabled, 'set_timezone': this.currentSystemTimeZone, 'set_time_date': this.changeSystemTimeTo})
        .then(this.init)
    }
  },
  created () {
    this.init()
    /*this.getSystemTimeIntervalId = setInterval(() => {
      if (!this.systemTimeUpdating) {
        this.refreshSystemTime()
      }
    }, 900)*/
  },
  destroyed () {
    /*if (this.getSystemTimeIntervalId) {
      clearInterval(this.getSystemTimeIntervalId)
    }*/
  }
}
</script>
