<template>
  <v-container fluid grid-list-md>

    <v-row>
      <v-col xs="12" md="2">
        <v-btn block @click="resetSerNo()" color="primary" :disabled="saveDisabled">{{ $t('ui.resetiraj_serijski_broj') }}</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col xs="12" md="6">
        <app-t1-upload-programa></app-t1-upload-programa>
      </v-col>
      <v-col xs="12" md="6">
        <app-file-tail-card file-path="/tmp/t1_programator.log"></app-file-tail-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'

export default {
  name: 't1PosaljiProgramPage',
  mixins: [T1Mixin, AuthMixin],
  data () {
    return {
      loading: false
    }
  },
  computed: {
    saveDisabled () {
      if (this.loading) return true
      return false
    }
  },
  methods: {
    resetSerNo () {
      let serNo = 0
      let serNoHigh = serNo / 256
      let serNoLow = serNo - serNoHigh * 256
      this.t1Write([0x50, 0x10, 0x1c, serNoLow, serNoHigh])
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'red',
            text: this.$t('ui.save_error')
          })
        })
        .finally(() => {
          this.loading = false
        })

    }
  },
  created () {
    if (!this.isSuperUser) {
      this.$router.push({
        name: 'AppPage'
      })

      return false
    }
  }
}
</script>
