import Vue from 'vue'
// import App from './App.vue'
import vuetify from './plugins/vuetify';

// Helpers
import axios from 'axios'
import _ from 'lodash'
import $ from 'jquery'
import VueMoment from 'vue-moment'
import * as d3 from 'd3'
import { sha256 } from 'js-sha256'

import App from './App'
import router from './router'
import VueI18n from 'vue-i18n'

import env from './env'
import { EventBus } from './services/EventBus'
import messages from './i18n'
import store from './store'

// Components
import InlineSvg from './lib/vue-inline-svg'
import TrafficControllerSvg from '@/components/TrafficControllerSvg'
import NotificationSnackBar from '@/components/NotificationSnackBar'
import SupervisorJobLogCard from '@/components/SupervisorJobLogCard'
import ConfigForm from '@/components/ConfigForm'
import SupervisorConfigCard from '@/components/SupervisorConfigCard'
import CentarCard from '@/components/CentarCard'
import WireguardCard from '@/components/WireguardCard'
import CamerasCRUD from '@/components/CamerasCRUD'
import CamerasHandlerCard from '@/components/CamerasHandlerCard'
import CamerasFeedServerCard from '@/components/CamerasFeedServerCard'
import TrafficControllerConfigCard from '@/components/TrafficControllerConfigCard'
import KutijaSnageCard from '@/components/KutijaSnageCard'
import KutijaSnageEditableCard from '@/components/KutijaSnageEditableCard'
import FileTailCard from '@/components/FileTailCard'
import StatistikaCard from '@/components/StatistikaCard'
import StatistikaBackendCard from '@/components/StatistikaBackendCard'
import DetektoriStalneNajaveCard from '@/components/DetektoriStalneNajaveCard'
import CameraTile from '@/components/CameraTile'
import StatusDot from '@/components/StatusDot'
import AppAuthDialog from '@/components/AppAuthDialog'
import GpioCrudCard from '@/components/GpioCRUDCard'
import GpioSwitchHandler from '@/components/GpioSwitchHandler'
import GpioSwitchCard from '@/components/GpioSwitchCard'
import GpsCard from '@/components/GpsCard'
import CameraSnapTile from '@/components/CameraSnapTile'
import CameraMjpegStream from '@/components/CameraMjpegStream'
import CameraHttpStream from '@/components/CameraHttpStream'
import NetworkSettingsForm from '@/components/NetworkSettingsForm'
import PhaseEditCard from '@/components/PhaseEditCard'
// T-1 Components
import t1Ciklus from '@/components/t1/Ciklus'
import t1NominalneVrijednostiSnagaFullTab from '@/components/t1/NominalneVrijednostiSnagaFullTab'
import t1NominalneVrijednostiSnagaDimmingTab from '@/components/t1/NominalneVrijednostiSnagaDimmingTab'
import t1ProgramskiRadCard from '@/components/t1/ProgramskiRadCard'
import t1MaksimalnoZelenoCard from '@/components/t1/MaksimalnoZelenoCard'
import t1GpioCard from '@/components/t1/GpioCard'
import t1PostavkeCard from '@/components/t1/PostavkeCard'
import t1DimmerCard from '@/components/t1/DimmerCard'
import t1PinCard from '@/components/t1/PinCard'
import t1AkustickiSignaliCard from '@/components/t1/AkustickiSignaliCard'
import t1GreskeCard from '@/components/t1/GreskeCard'
import t1UploadProgramaCard from '@/components/t1/UploadProgramaCard'
import t1DetektoriJediniceCard from '@/components/t1/DetektoriJediniceCard'
import t1DetektoriImpulsCard from '@/components/t1/DetektoriImpulsCard'
import t1DetektoriPragoviCard from '@/components/t1/DetektoriPragoviCard'
import t1OffsetCard from '@/components/t1/OffsetCard'
import t1EditOffsetCard from '@/components/t1/EditOffsetCard'
import t1PostavkeSnagaSignalaGrupe from '@/components/t1/PostavkeSnagaSignalaGrupe'
import t1CustomPragDialog from '@/components/t1/CustomPragDialog'
import t1Tolerancija2Card from '@/components/t1/Tolerancija2Card'
import t1ReadWriteCard from '@/components/t1/ReadWriteCard'
import t1TablicaPromjenaProgramaCard from '@/components/t1/TablicaPromjenaProgramaCard'
import t1NaziviProgramaCard from '@/components/t1/NaziviProgramaCard'
import t1ProgramskiRadCardOld from '@/components/t1/ProgramskiRadCardOld'
import t1SyncCard from '@/components/t1/SyncTimeCard'
import t1UrefCard from '@/components/t1/UrefCard'
import BusPriorityStatsCard from '@/components/t1/BusPriorityStatsCard'
import McoProgramForm from '@/components/t1/McoProgramForm'

// T-2000 Components
import t2000Ciklus from '@/components/t2000/Ciklus'
// Telvent
import ProgramEditCard from '@/components/telvent/ProgramEditCard'
// ETX
import EtxProgramEditCard from '@/components/etx/ProgramEditCard'

// Dialogs
import ConfirmActionDialog from '@/components/ConfirmActionDialog'
import CameraEditDialog from '@/components/CameraEditDialog'
import CameraZoneEditDialog from '@/components/CameraZoneEditDialog'
import AppSettingsDialog from '@/components/AppSettingsDialog'
import DetektorZoneEditDialog from '@/components/DetektorZoneEditDialog'
import moment from 'moment';

import('vuetify/dist/vuetify.min.css')
import('@/assets/app.css')
import('@/assets/fonts/roboto_regular/roboto-regular.css')
import('@/assets/fonts/material-icons.css')

window.isIP = new RegExp(/(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/)

// Register components
Vue.component('inline-svg', InlineSvg)
Vue.component('traffic-controller-svg', TrafficControllerSvg)
Vue.component('app-notification-snackbar', NotificationSnackBar)
Vue.component('app-config-form', ConfigForm)
Vue.component('app-supervisor-config-card', SupervisorConfigCard)
Vue.component('app-centar-card', CentarCard)
Vue.component('app-supervisor-job-log-card', SupervisorJobLogCard)
Vue.component('app-confirm-action-dialog', ConfirmActionDialog)
Vue.component('app-wireguard-card', WireguardCard)
Vue.component('app-camera-edit-dialog', CameraEditDialog)
Vue.component('app-camera-zone-edit-dialog', CameraZoneEditDialog)
Vue.component('app-detektor-zone-edit-dialog', DetektorZoneEditDialog)
Vue.component('app-cameras-crud', CamerasCRUD)
Vue.component('app-cameras-handler-card', CamerasHandlerCard)
Vue.component('app-cameras-feed-server-card', CamerasFeedServerCard)
Vue.component('app-traffic-controller-config-card', TrafficControllerConfigCard)
Vue.component('app-settings-dialog', AppSettingsDialog)
Vue.component('app-kutija-snage-card', KutijaSnageCard)
Vue.component('app-kutija-snage-editable-card', KutijaSnageEditableCard)
Vue.component('app-file-tail-card', FileTailCard)
Vue.component('app-statistika-card', StatistikaCard)
Vue.component('app-statistika-backend-card', StatistikaBackendCard)
Vue.component('app-detektori-stalne-najave-card', DetektoriStalneNajaveCard)
Vue.component('app-camera-tile', CameraTile)
Vue.component('app-status-dot', StatusDot)
Vue.component('app-auth-dialog', AppAuthDialog)
Vue.component('app-gpio-crud-card', GpioCrudCard)
Vue.component('app-gpio-switch-handler', GpioSwitchHandler)
Vue.component('app-gpio-switch-card', GpioSwitchCard)
Vue.component('app-gps-card', GpsCard)
Vue.component('app-camera-snap-tile', CameraSnapTile)
Vue.component('app-camera-mjpeg-stream', CameraMjpegStream)
Vue.component('app-camera-http-stream', CameraHttpStream)
Vue.component('app-network-settings-form', NetworkSettingsForm)
Vue.component('app-phase-edit-card', PhaseEditCard)
Vue.component('app-bus-priority-stats-card', BusPriorityStatsCard)
// T-1
Vue.component('app-t1-ciklus', t1Ciklus)
Vue.component('app-t1-nominalne-vrijednosti-snaga-full', t1NominalneVrijednostiSnagaFullTab)
Vue.component('app-t1-nominalne-vrijednosti-snaga-dimming', t1NominalneVrijednostiSnagaDimmingTab)
Vue.component('app-t1-programski-rad-card', t1ProgramskiRadCard)
Vue.component('app-t1-programski-rad-card-old', t1ProgramskiRadCardOld)
Vue.component('app-t1-maksimalno-zeleno-card', t1MaksimalnoZelenoCard)
Vue.component('app-t1-gpio-card', t1GpioCard)
Vue.component('app-t1-postavke-card', t1PostavkeCard)
Vue.component('app-t1-dimmer-card', t1DimmerCard)
Vue.component('app-t1-pin-card', t1PinCard)
Vue.component('app-t1-akusticki-signali-card', t1AkustickiSignaliCard)
Vue.component('app-t1-greske-card', t1GreskeCard)
Vue.component('app-t1-upload-programa', t1UploadProgramaCard)
Vue.component('app-t1-detektori-jedinice-card', t1DetektoriJediniceCard)
Vue.component('app-t1-detektori-impuls-card', t1DetektoriImpulsCard)
Vue.component('app-t1-detektori-pragovi-card', t1DetektoriPragoviCard)
Vue.component('app-t1-offset-card', t1OffsetCard)
Vue.component('app-t1-edit-offset-card', t1EditOffsetCard)
Vue.component('app-t1-postavke-snaga-signala-grupe', t1PostavkeSnagaSignalaGrupe)
Vue.component('app-t1-custom-prag-dialog', t1CustomPragDialog)
Vue.component('app-t1-tolerancija-2-card', t1Tolerancija2Card)
Vue.component('app-t1-read-write-card', t1ReadWriteCard)
Vue.component('app-t1-tablica-promjena-programa-card', t1TablicaPromjenaProgramaCard)
Vue.component('app-t1-nazivi-programa-card', t1NaziviProgramaCard)
Vue.component('app-t1-sync-time-card', t1SyncCard)
Vue.component('app-t1-uref-card', t1UrefCard)
Vue.component('app-t1-mco-program-form', McoProgramForm)
// T-2000
Vue.component('app-t2000-ciklus', t2000Ciklus)
// Telvent
Vue.component('app-telvent-program-edit-card', ProgramEditCard)
// ETX
Vue.component('app-etx-program-edit-card', EtxProgramEditCard)

Vue.prototype.$env = env
Vue.prototype.$lodash = _
Vue.prototype.jQuery = $
Vue.prototype.$EventBus = EventBus
Vue.prototype.d3 = d3
Vue.prototype.sha256 = sha256
Vue.prototype.$moment = moment


Vue.prototype.$api = axios.create({
  // baseURL: env.APP_API_URI,
  // timeout: 10000,
  headers: {
    get: {
      accept: 'application/json'
    }
  }
})

Vue.prototype.$t1 = axios.create({
  //baseURL: env.T1_URI,
  headers: {
    get: {
      accept: 'application/json'
    }
  }
})

Vue.prototype.$chunkString = function (str, length) {
  return str.match(new RegExp('.{1,' + length + '}', 'g'))
}

Vue.prototype.$reverseString = function (s) {
  return s.split('').reverse().join('')
}

Vue.config.productionTip = false
Vue.use(VueI18n)
Vue.use(VueMoment)

const i18n = new VueI18n({
  locale: env.DEFAULT_LANGUAGE || 'hr', // set locale
  messages // set locale messages
})

new Vue({
  vuetify,
  router,
  store,
  i18n,
  template: '<App/>',
  render: h => h(App)
}).$mount('#app')
