<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">{{ this.$t('ui.statistika') }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        dark
        icon
        @click="init()"
        :disabled="loading"
      >
        <v-icon>refresh</v-icon>
      </v-btn>
      <v-menu
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, i) in availableActions"
            :key="i"
            link
            @click="item.action()"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-progress-linear
      v-show="loading"
      color="primary"
      indeterminate
    ></v-progress-linear>

    <v-data-table
      :headers="headers"
      :items="statistika"
      :search="search"
      :items-per-page="limit"
      hide-default-footer
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:item.vrijeme="{ item }">
        {{ item.vrijeme | moment("DD.MM.YYYY HH:mm:ss") }}
      </template>

      <template v-slot:item.gpios_state="{ item }">
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr v-for="gpio in item.gpios_state" :key="gpio.id">
                <td>{{gpio.label}}</td>
                <td>{{gpio.status | gpioStatusText}}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!--<ul v-for="gpio in item.gpios_state" :key="gpio.id">
          <li>{{gpio.label}}: {{gpio.status}}</li>
        </ul>-->
      </template>

      <template v-slot:item.greske="{ item }">
        <ul v-for="greska in item.greske" :key="greska.kod">
          <li>{{greska.opis}}</li>
        </ul>
      </template>

      <template v-slot:item.napon="{ item }">
        <ul>
          <li v-if="item.napon[1]">{{item.napon[1]}}</li>
          <li v-if="item.napon[2]">{{item.napon[2]}}</li>
        </ul>
      </template>

      <template v-slot:item.statusna_rijec="{ item }">
        <ul>
          <li v-if="item.statusna_rijec.su">{{$t('ui.signali_ukljuceni')}}</li>
          <li v-if="item.statusna_rijec.cu">{{$t('ui.centralno_upravljanje')}}</li>
          <li v-if="item.statusna_rijec.vru">{{$t('ui.vremensko_upravljanje')}}</li>
          <li v-if="item.statusna_rijec.ru">{{$t('ui.rucno_upravljanje')}}</li>
          <li v-if="item.statusna_rijec.sc">{{$t('ui.sve_crveno')}}</li>
          <li v-if="item.statusna_rijec.trz">{{$t('ui.treptanje_zutog')}}</li>
          <li v-if="item.statusna_rijec.anf">{{$t('ui.greska')}}</li>
        </ul>
      </template>
    </v-data-table>

    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        total-visible="25"
      ></v-pagination>
    </div>

    <app-confirm-action-dialog
        :open-dialog="openConfirmDeleteStatistikaDialog"
        :headline="$t('ui.confirm_delete')"
        :text="$t('ui.really_delete_items')"
        @userAction="deleteStatistikaDialogActionHandler"
      >
    </app-confirm-action-dialog>

  </v-card>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'

export default {
  name: 'StatistikaCard',
  mixins: [T1Mixin, AuthMixin],
  data () {
    return {
      page: 1,
      loading: false,
      search: '',
      dan: '',
      statistika: [],
      count: 0,
      offset: 0,
      limit: 10,
      pager: null,
      openConfirmDeleteStatistikaDialog: false,
      actions: [
        {'title': this.$t('ui.delete_statistika'), 'action': this.openConfirmDeleteStatistikaDialogM, 'needsAuth': true }
      ]
    }
  },
  filters: {
    gpioStatusText: function (value) {
      if (!value) return 'OFF'
      if (value > 0) return 'ON'
    }
  },
  watch: {
    page () {
      this.getStatistika()
    }
  },
  computed: {
    availableActions () {
      let actions = []
      let action = null
      for (let i = 0; i < this.actions.length; i++) {
        action = this.actions[i]
        if (action.needsAuth === true) {
          if (this.isAuthenticated) {
            actions.push(action)
          }
        } else {
          actions.push(action)
        }
      }

      return actions
    },
    pageCount () {
      if (!this.pager) return 1

      return this.pager.totalPages
    },
    headers () {
      return [
        {
          text: this.$t('ui.date_time'),
          align: 'start',
          sortable: true,
          value: 'vrijeme',
        },
        {
          text: this.$t('ui.day'),
          value: 'dan',
          filter: value => {
            if (!this.dan) return true

            return value != this.dan
          },
        },
        { text: this.$t('ui.status'), value: 'statusna_rijec', sortable: false },
        { text: this.$t('ui.cps_temperature'), value: 'temperatura_cps' },
        { text: this.$t('ui.napon'), value: 'napon' },
        { text: 'Dimmer', value: 'dimmer' },
        { text: this.$t('ui.errors'), value: 'greske' },
        { text: 'GPIO', value: 'gpios_state' }
      ]
    }
  },
  methods: {
    init () {
      this.page = 1
      this.getStatistika()
    },
    openConfirmDeleteStatistikaDialogM () {
      this.openConfirmDeleteStatistikaDialog = true
    },
    deleteStatistikaDialogActionHandler (actionResult) {
      this.openConfirmDeleteStatistikaDialog = false
      if (actionResult === true) {
        this.deleteStatistika()
      }
    },
    deleteStatistika () {
      this.loading = true
      this.$api.delete(`/statistika/`, {baseURL: this.$store.getters['app/APP_API_URI']})
      .finally(() => {
        this.getStatistika()
      })
    },
    range(min, max, step) {
      step = step || 1
      let input = []
      for (var i = min; i <= max; i += step) {
          input.push(i);
      }
      return input
    },
    getPager (totalItems, currentPage, pageSize) {
      // default to first page
      currentPage = currentPage || 1

      // default page size is 10
      pageSize = pageSize || 10

      // calculate total pages
      let totalPages = Math.ceil(totalItems / pageSize)
      let startPage, endPage

      if (totalPages <= 10) {
        // less than 10 total pages so show all
        startPage = 1
        endPage = totalPages
      } else {
        // more than 10 total pages so calculate start and end pages
        if (currentPage <= 6) {
          startPage = 1
          endPage = 10
        } else if (currentPage + 4 >= totalPages) {
          startPage = totalPages - 9
          endPage = totalPages
        } else {
          startPage = currentPage - 5
          endPage = currentPage + 4
        }
      }

      // calculate start and end item indexes
      var startIndex = (currentPage - 1) * pageSize
      var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1)

      // create an array of pages to ng-repeat in the pager control
      var pages = this.range(startPage, endPage, 1)

      // return object with all pager properties required by the view
      return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
      }
    },
    getDan (dan) {
      switch(dan) {
        case 1:
          return this.$t('ui.ponedjeljak')
        case 2:
          return this.$t('ui.utorak')
        case 3:
          return this.$t('ui.srijeda')
        case 4:
          return this.$t('ui.četvrtak')
        case 5:
          return this.$t('ui.petak')
        case 6:
          return this.$t('ui.subota')
        case 7:
          return this.$t('ui.nedjelja')
        default:
          return ''
      }
    },
    parseTempCps (temperaturaCps) {
      if (!temperaturaCps) return ''

      let temp = []

      for (let i = 0; i < temperaturaCps.length; i++) {
        temp.push(String.fromCharCode(temperaturaCps[i]))
      }

      temp = temp.join('')

      return `${temp} °C`
    },
    parseNapon (napon) {
      if (!napon) return ''
      return [null, `${napon[0]/10.0} VDC`, `${napon[1]/10.0} VDC`]
    },
    parseDimmer (napon) {
      if (!napon) return ''

      if (napon[3] === 1) {
        return this.$t('ui.ok_dimmer_ukljucen')
      }

      return this.$t('ui.ok_dimmer_iskljucen')
    },
    parseGPIO (GPIOS, GPIO_STATE) {
      if (!GPIOS || !GPIO_STATE) return []

      let labels = []
      let gpios = []
      let b = [1, 2, 4, 8, 16, 32, 64, 128]

      for (let i = 0; i < GPIOS.length; i++) {
        labels.push(String.fromCharCode(GPIOS[i]))
      }

      labels = labels.join('')
      labels = labels.replaceAll('><', ';')
      labels = labels.replaceAll('<', '')
      labels = labels.split(';')

      for (let i = 0; i < labels.length; i++) {
        if (labels[i]) {
          gpios.push({'id': i, 'label': labels[i], 'status': GPIO_STATE & b[i]})
        }
      }

      return gpios
    },
    parseRow (row) {
      let parsed = {
        vrijeme: row.vrijeme,
        dan: this.getDan(parseInt(row.dan)),
        statusna_rijec: {
          abc: 0,
          anf: row.statusna_rijec[0] & 64,
          cu: row.statusna_rijec[0] & 16,
          ru: row.statusna_rijec[0] & 8,
          sc: row.statusna_rijec[0] & 4,
          su: row.statusna_rijec[0] & 1,
          trz: row.statusna_rijec[0] & 2,
          vru: row.statusna_rijec[0] & 32
        },
        temperatura_cps: this.parseTempCps(row.temperatura_cps),
        napon: this.parseNapon(row.napon),
        dimmer: this.parseDimmer(row.napon),
        greske: this.parseGreske(row.greske),
        gpios_state: this.parseGPIO(row.gpios, row.gpios_state)
      }

      return parsed
    },
    parseGreske (greske) {
      if (!greske) return []
      let kodovi = this.t1KodoviGresaka(greske)
      let greskeOpisi = []

      for (let i = 0; i < kodovi.length; i++) {
        let kod = kodovi[i]
        greskeOpisi.push({kod: kod, opis: this.t1OpisGreske(kod)})
      }

      return greskeOpisi
    },
    getStatistika () {
      this.loading = true
      this.statistika = []
      if (this.pager && (this.page < 1 || this.page > this.pager.totalPages)) {
        this.offset = 0
      } else {
        this.offset = ((this.page-1)*this.limit)
      }
      this.$api.get(`/statistika/?offset=${this.offset}&limit=${this.limit}`, {baseURL: this.$store.getters['app/APP_API_URI']})
      .then((response) => {
        this.count = response.data.count
        this.pager = this.getPager(response.data.count, this.page, this.limit)
        // this.statistika = response.data.rows

        for (let i = 0; i < response.data.rows.length; i++) {
          this.statistika.push(this.parseRow(response.data.rows[i]))
        }

      })
      .finally(() => {
        this.loading = false
      })
    }
  },
  created () {
    this.init()
  }
}
</script>
