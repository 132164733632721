<template>
  <v-container fluid grid-list-md>

    <v-row>
      <v-col xs="12">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ this.$t('ui.gain') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, i) in availableActions"
                  :key="i"
                  link
                  @click="item.action(...item.actionArgs)"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
          <v-progress-linear
            v-show="loading"
            color="primary"
            indeterminate
          ></v-progress-linear>
          <v-alert type="error" v-if="!varijabilniGainPodrzan">
            {{ this.$t('ui.variable_gain_not_supported') }}
          </v-alert>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col xs="12" md="2">
        <v-select
          label="SIG"
          :items="sigoviZaOdabrati"
          v-model="odabraniSig"
          item-text="name"
          item-value="id"
          @change="sigChange()"
        ></v-select>
      </v-col>
      <v-col xs="12" md="2">
        <v-btn v-if="isAuthenticated" :disabled="!varijabilniGainPodrzan" color="primary" @click="submit()">{{ this.$t('ui.save') }}</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-for="(g, index) in grupeGain" :key="index" xs="12" md="2">
        <v-card>
          <v-card-title>GRUPA {{sigGrupe[index]}}</v-card-title>
          <v-card-text>
            <v-select
              label="A1"
              :items="possibleGainValues"
              v-model="g[0]"
              :disabled="loading || !isAuthenticated"
            ></v-select>
            <v-select
              label="A2"
              :items="possibleGainValues"
              v-model="g[1]"
              :disabled="loading || !isAuthenticated"
            ></v-select>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col xs="12" md="2">
        <v-card>
          <v-card-title>DEFAULT GAIN</v-card-title>
          <v-card-text>
            <v-select
              label="A1"
              :items="possibleGainValues"
              v-model="defaultGain[0]"
              :disabled="loading || !isAuthenticated"
            ></v-select>
            <v-select
              label="A2"
              :items="possibleGainValues"
              v-model="defaultGain[1]"
              :disabled="loading || !isAuthenticated"
            ></v-select>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-for="sig in sigovi" :key="sig.id">
      <v-col v-for="grupa in sig.grupe" :key="grupa.grupa" xs="12" md="2">
        <app-kutija-snage-card :grupa-id="grupa.grupaId" :signali="grupa.signali" :snage="grupa.snage"></app-kutija-snage-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'
import ConfigMixin from '@/mixins/ConfigMixin'

export default {
  name: 't1GainPage',
  mixins: [validationMixin, ConfigMixin, T1Mixin, AuthMixin],
  data () {
    return {
      loading: false,
      gettingSignaliSnage: false,
      brojGrupa: 0,
      greske: [],
      odabraniSig: 0,
      snageSignala: [],
      odabraniSigSnage: [],
      gain: [],
      grupeGain: [],
      defaultGain: [],
      getSnageGrupaZaSigId: null,
      actions: [],
      possibleGainValues: [
        {
          'text': 'x1',
          'value': 0
        },
        {
          'text': 'x2',
          'value': 1
        },
        {
          'text': 'x4',
          'value': 2
        },
        {
          'text': 'x5',
          'value': 3
        },
        {
          'text': 'x8',
          'value': 4
        },
        {
          'text': 'x10',
          'value': 5
        },
        {
          'text': 'x16',
          'value': 6
        },
        {
          'text': 'x32',
          'value': 7
        }
      ]
    }
  },
  computed: {
    brojSigova () {
      return Math.ceil(this.brojGrupa/4)
    },
    varijabilniGainPodrzan () {
      if (!this.greske || this.greske.length < 3) return true
      if (this.greske[0] === 170 && this.greske[1] === 3 && this.greske[2] === 235) return true
      let kodovi = this.t1KodoviGresaka(this.greske)

      for (let i = 0; i < kodovi.length; i++) {
        let kod = kodovi[i]
        let _greska = this.$lodash.chunk(kod, 2)
        let greskaHex = []
        let greskaInt = []
        //debugger; // eslint-disable-line no-debugger

        // Ovdje dobivamo hex prikaz, eg iz f1400220 u f1 40 02 20
        for (let i = 0; i < _greska.length; i++) {
          greskaHex.push(_greska[i].join(''))
        }

        // Pretvori iz hex u int
        for (let i = 0; i < greskaHex.length; i++) {
          greskaInt.push(parseInt(greskaHex[i], 16))
        }

        let porodicaModula = greskaInt[0] & 0xF0
        if (porodicaModula == 0xF0) {
          let brojSiga = (greskaInt[0] & 0x0F)
          if (greskaInt[1] == 0x10 && brojSiga == this.odabraniSig) {
            return false
          }
        }
      }

      return true
    },
    sigoviZaOdabrati () {
      let s = []
      for (let i = 0; i < this.brojSigova; i++) {
        s.push({id: i, name: `SIG ${i+1}`})
      }

      return s
    },
    sigovi () {
      let s = []
      let odabraniSigovi = []
      let sig = {}
      let grupe = []
      let g = null

      for (let g = 1; g <= this.brojGrupa; g++) {
        grupe.push({'grupaId': g, snage: this.snageSignala[g-1], signali: this.stanjeSignala[g-1]})
      }

      for (let i = 0; i < this.brojSigova; i++) {
        sig = {id: i, name: `SIG ${i+1}`, grupe: []}
        for (let n = 1; n <=4; n++ ) {
          g = grupe.shift()
          if (g) {
            sig.grupe.push(g)
          }
        }
        s.push(sig)
      }

      for (let i = 0; i < s.length; i++) {
        if (this.odabraniSig === s[i].id) {
          odabraniSigovi.push(s[i])
        }
      }

      return odabraniSigovi
    },
    stanjeSignala () {
      return this.$store.getters['t1/stanjeSignala']
    },
    moguceGrupePoSigovima () {
      return this.$lodash.chunk(this.$lodash.range(1, 33), 4)
    },
    sigGrupe () {
      return this.moguceGrupePoSigovima[this.odabraniSig]
    },
    availableActions () {
      let actions = []
      let action = null
      for (let i = 0; i < this.actions.length; i++) {
        action = this.actions[i]
        if (action.needsAuth === true) {
          if (this.isAuthenticated) {
            actions.push(action)
          }
        } else {
          actions.push(action)
        }
      }

      return actions
    }
  },
  methods: {
    init () {
      this.initT1Data()
    },
    initT1Data () {
      this.loading = true
      var promises = []

      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, this.t1.BROJ_GRUPA]))
      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, this.t1.GRESKE]))
      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, this.t1.GAIN, this.t1.SIG(this.odabraniSig)]))

      Promise.all(promises)
        .then(([BROJ_GRUPA, GRESKE, GAIN]) => {
          this.brojGrupa = BROJ_GRUPA[0]
          this.greske = GRESKE
          this.gain = GAIN
          if (GAIN[0] == 102) {
            // nepoznati registar
            return false
          }
          let gains = this.$lodash.chunk(this.gain, 2)
          this.grupeGain = [gains[0], gains[1], gains[2], gains[3]]
          this.defaultGain = gains[4]
        })
        .finally(() => {
          this.loading = false
        })
    },
    getGainData () {
      this.loading = true
      this.t1Write([this.t1.CPS, this.t1.READ, this.t1.GAIN, this.t1.SIG(this.odabraniSig)])
        .then((gain) => {
          if (gain[0] == 102) {
            return false // nepoznati registar
          }
          this.gain = gain
          let gains = this.$lodash.chunk(this.gain, 2)
          this.grupeGain = [gains[0], gains[1], gains[2], gains[3]]
          this.defaultGain = gains[4]
        })
        .finally(() => {
          this.loading = false
        })
    },
    getSnageSignala () {
      if (this.gettingSignaliSnage) {
        console.warn('DOHVAT SNAGA SIGNALA TRAJE DULJE OD 900ms!')
        return false
      } else {

        this.gettingSignaliSnage = true

        this.getSignaliSnage(this.brojGrupa)
          .then((SIGNALI_SNAGE) => {
            this.snageSignala = SIGNALI_SNAGE
          })
          .finally(() => {
            this.gettingSignaliSnage = false
          })

      }
    },
    sigChange () {
      this.getGainData()
    },
    submit () {
      this.loading = true

      this.t1Write([this.t1.CPS, this.t1.WRITE, this.t1.GAIN, this.t1.SIG(this.odabraniSig),
        this.grupeGain[0][0], this.grupeGain[0][1],
        this.grupeGain[1][0], this.grupeGain[1][1],
        this.grupeGain[2][0], this.grupeGain[2][1],
        this.grupeGain[3][0], this.grupeGain[3][1],
        this.defaultGain[0], this.defaultGain[1]])
        .then(this.getGainData)
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    this.init()
    this.getSnageSignalaId = setInterval(this.getSnageSignala, 900)
    this.actions = [
      {'title': this.$t('ui.download_backup'), 'action': this.downloadBackup, 'actionArgs': [], 'needsAuth': false },
      {'title': this.$t('ui.restore_backup'), 'action': this.restoreFromBackup, 'actionArgs': [], 'needsAuth': true }
    ]
  },
  destroyed () {
    if (this.getSnageSignalaId) {
      clearInterval(this.getSnageSignalaId)
    }
  }
}
</script>
