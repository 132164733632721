<template>
  <v-card v-if="detektori.length>0" v-on:keyup.enter="submit">
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">{{ $t('ui.settings') }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="isAuthenticated" @click="submit" color="primary" :disabled="saveDisabled">{{ $t('ui.save') }}</v-btn>
    </v-toolbar>
    <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>
    <v-card-text>
      <v-expansion-panels>
        <v-expansion-panel v-for="detektor in detektori" :key="detektor.id">
          <v-expansion-panel-header>
            DETEKTOR {{detektor.id}}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-text-field
              v-model="detektor.prag1"
              :label="$t('ui.prag_1')"
              :disabled="loading"
            ></v-text-field>
            <v-text-field
              v-model="detektor.prag2"
              :label="$t('ui.prag_2')"
              :disabled="loading"
            ></v-text-field>
            <v-text-field
              v-model="detektor.rebalans"
              :label="$t('ui.rebalans')"
              :disabled="loading"
            ></v-text-field>
            <v-text-field
              v-model="detektor.rezolucija"
              :label="$t('ui.rezolucija')"
              :disabled="loading"
            ></v-text-field>
            <v-text-field
              v-model="detektor.noise"
              :label="$t('ui.noise')"
              :disabled="loading"
            ></v-text-field>
            <v-text-field
              v-model="detektor.stabilnostPetlje"
              :label="$t('ui.stabilnostPetlje')"
              disabled
            ></v-text-field>
            <v-text-field
              v-model="detektor.koeficijent_stabilnosti_petlje"
              :label="$t('ui.koeficijent_stabilnosti_petlje')"
              :disabled="loading"
            ></v-text-field>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import AuthMixin from '@/mixins/AuthMixin'
import T1Mixin from '@/mixins/T1Mixin'

export default {
  name: 't1DetektoriPragoviCard',
  mixins: [T1Mixin, AuthMixin],
  data () {
    return {
      loading: false,
      detektori: [],
      detektoriCopy : []
    }
  },
  computed: {
    saveDisabled () {
      if (this.loading) return true
      return false
    }
  },
  methods: {
    getT1Data () {
      return new Promise((resolve, reject) => {
        let promises = [
          this.getDetektoriPragovi(),
          this.getDetektoriRezolucijaRebalans(),
          this.getDetektoriNoise(),
          this.getStabilnostPetlje(),
          this.getKoeficijentStabilnostiPetlje()
        ]

        let detektori = []

        Promise.all(promises)
          .then(([DETEKTORI_PRAGOVI, REZOLUCIJA_REBALANS, NOISE, STABILNOST_PETLJE, KOEF_STABILNOSTI_PETLJE]) => {

            for (let i = 0; i < DETEKTORI_PRAGOVI.length; i++) {
              detektori.push({
                'id': i+1,
                'prag1': DETEKTORI_PRAGOVI[i][0],
                'prag2': DETEKTORI_PRAGOVI[i][1],
                'rezolucija': REZOLUCIJA_REBALANS[i][0],
                'rebalans': REZOLUCIJA_REBALANS[i][1],
                'noise': NOISE[i],
                'stabilnostPetlje': STABILNOST_PETLJE[i],
                'koeficijent_stabilnosti_petlje': KOEF_STABILNOSTI_PETLJE[i]
              })
            }

            resolve(detektori)
          })
          .catch((error) => {
            console.error(error)
            reject(error)
          })
      })
    },
    saveData () {
      return new Promise((resolve, reject) => {
        let promises = []
        let detektor = null
        let detektorCopy = null

        for (let i=0; i < this.detektori.length; i++) {
          detektor = this.detektori[i]
          detektorCopy = this.detektoriCopy[i]

          if (detektor.prag1 != detektorCopy.prag1) {
            promises.push(this.saveDetektorPrag1(detektor.id, detektor.prag1))
          }

          if (detektor.prag2 != detektorCopy.prag2) {
            promises.push(this.saveDetektorPrag2(detektor.id, detektor.prag2))
          }

          if (detektor.rezolucija != detektorCopy.rezolucija) {
            promises.push(this.saveDetektorRezolucija(detektor.id, detektor.rezolucija))
          }

          if (detektor.rebalans != detektorCopy.rebalans) {
            promises.push(this.saveDetektorRebalans(detektor.id, detektor.rebalans))
          }

          if (!detektor.noise) {
            // Ako nema definiran noise, noise = 0
            promises.push(this.saveDetektorNoise(detektor.id, 0))
          } else if (detektor.noise != detektorCopy.noise) {
            promises.push(this.saveDetektorNoise(detektor.id, parseInt(detektor.noise)))
          }

          if (!detektor.koeficijent_stabilnosti_petlje) {
            promises.push(this.saveKoeficijentStabilnostiPetlje(detektor.id, 0))
          } else if (detektor.koeficijent_stabilnosti_petlje != detektorCopy.koeficijent_stabilnosti_petlje) {
            promises.push(this.saveKoeficijentStabilnostiPetlje(detektor.id, parseInt(detektor.koeficijent_stabilnosti_petlje)))
          }
        }

        Promise.all(promises)
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    submit () {
      this.loading = true
      this.saveData()
        .then(this.getT1Data)
        .then((DETEKTORI) => {
          this.detektori = DETEKTORI
          this.detektoriCopy = JSON.parse(JSON.stringify(DETEKTORI))
        })
        .catch((error) => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'error',
            text: error
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    fetchStabilnostPetlje () {
      this.gettingStabilnostPetlje = true
      this.getStabilnostPetlje()
        .then((ANSWER) => {
          for (let i=0; i < this.detektori.length; i++) {
            let detektor = this.detektori[i]
            detektor['stabilnostPetlje'] = ANSWER[i]
          }
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.gettingStabilnostPetlje = false
        })
    }
  },
  created () {
    this.loading = true

    this.getT1Data()
      .then((DETEKTORI) => {
        this.detektori = DETEKTORI
        this.detektoriCopy = JSON.parse(JSON.stringify(DETEKTORI))
      })
      .finally(() => {
        this.loading = false
      })

    this.getStabilnostPetljeId = setInterval(() => {
      if (!this.gettingStabilnostPetlje) {
        this.fetchStabilnostPetlje()
      }
    }, 900)
  },
  destroyed () {
    if (this.getStabilnostPetljeId) {
      clearInterval(this.getStabilnostPetljeId)
    }
  }
}
</script>
