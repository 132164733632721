<template>
  <div>
    <v-main>
      <router-view></router-view>
    </v-main>

  </div>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import ConfigMixin from '@/mixins/ConfigMixin'

export default {
  name: 'DemoPage',
  mixins: [ConfigMixin, T1Mixin],
  data () {
    return {
      props: {
        source: String
      },
      showSnackbar: false,
      snackbarText: '',
      snackbarTimeout: 5000,
      snackbarColor: 'primary',
      config: {
        DEVICE_CODE: null,
        DEVICE_NAME: null,
        DEVICE_TYPE: null
      }
    }
  },
  computed: {
    authDisabled () {
      return true
    },
    appLocale () {
      return this.$store.getters['app/locale']
    },
    isWsConnectionActive () {
      return this.$store.getters['ws/isConnected']
    },
    mobileInternetStatusIcon () {
      // 'signal_cellular_4_bar' : 'signal_cellular_off'
      return false
    },
    gpsStatusIcon () {
      // 'gps_fixed' : 'gps_off'
      return false
    },
    wifiStatusIcon () {
      // 'signal_wifi_4_bar' : 'signal_wifi_off'
      return false
    },
    backendConnectionStatusIcon () {
      if (this.isWsConnectionActive) {
        return {
          icon: 'check_circle',
          color: 'green'
        }
      }

      return {
        icon: 'error',
        color: 'red'
      }
    },
    appTitle () {
        return `TrafConnect | ${this.config.DEVICE_CODE}, ${this.appConfig.DEVICE_NAME}, ${this.appConfig.DEVICE_TYPE}, ${this.serBr}, R ${this.revizijaSw}`
    },
    statusnaRijec () {
        return this.$store.getters['t1/statusnaRijec']
    },
    serBr () {
      return this.$store.getters['t1/serBr']
    },
    revizijaSw () {
      return this.$store.getters['t1/revizijaSw']
    },
    trafficControllerCommunicating () {
      return this.$store.getters['t1/isCommOk']
    },
    appConfig () {
      return this.$store.getters['app/config']
    },
    DEVICE_TYPE () {
      return this.$store.getters['app/config']['DEVICE_TYPE']
    },
    appSettingsConfig () {
      let local = this.$store.getters['app/local']
      return {
        APP_HOST_ADDRESS: local.APP_HOST_ADDRESS,
        APP_HOST_PORT: local.APP_HOST_PORT,
        TRAFFIC_CONTROLLER_HOST_ADDRESS: local.TRAFFIC_CONTROLLER_HOST_ADDRESS,
        TRAFFIC_CONTROLLER_HOST_PORT: local.TRAFFIC_CONTROLLER_HOST_PORT,
        CAMERAS_FEED_SERVER_HOST: local.CAMERAS_FEED_SERVER_HOST,
        CAMERAS_FEED_SERVER_PORT: local.CAMERAS_FEED_SERVER_PORT
      }
    }
  },
  methods: {
    init () {
      return new Promise((resolve, reject) => {
        this.initConfigData()
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    connectToTrafficController () {
      if (this.$websocket) {
        this.$websocket.closeAndDoNotReconnect()
      }

      if (this.t1ResetStateWdogId) {
        clearInterval(this.t1ResetStateWdogId)
      }

      this.t1HandleWsConnection()
    }
  },
  created () {
    this.$i18n.locale = this.$store.getters['app/locale']
    this.init()
      .then(() => {
        this.connectToTrafficController()
      })
  }
}
</script>
