<template>
  <v-container fluid grid-list-md>
    <v-row>
      <v-col xs="12">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ this.$t('ui.errors') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <p v-for="(item, index) in greske" v-bind:key="index">{{item}}</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import ConfigMixin from '@/mixins/ConfigMixin'
import T2000Mixin from '@/mixins/T2000Mixin'

export default {
  name: 't2000GreskePage',
  mixins: [validationMixin, ConfigMixin, T2000Mixin],
  data () {
    return {
    }
  },
  computed: {
    greske () {
      let f = this.$store.getters['t2000/greske']
      return f(this.$store.getters['app/locale'])
    }
  }
}
</script>
