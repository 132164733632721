<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">{{ $t('ui.pin') }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="syncPin" color="primary" :disabled="syncDisabled">{{$t('ui.promjeni_pin')}}</v-btn>
    </v-toolbar>
    <v-progress-linear v-show="syncing" color="primary" indeterminate></v-progress-linear>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>{{$t('ui.novi_pin')}}</td>
              <td>
                <v-text-field
                  type="password"
                  v-model="noviPin"
                  :error-messages="noviPinErrors"
                  @input="$v.noviPin.$touch()"
                  @blur="$v.noviPin.$touch()"
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <td>{{$t('ui.ponovi_novi_pin')}}</td>
              <td>
                <v-text-field
                  type="password"
                  v-model="noviPinOpet"
                  :error-messages="noviPinOpetErrors"
                  @input="$v.noviPinOpet.$touch()"
                  @blur="$v.noviPinOpet.$touch()"
                >
                </v-text-field>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, numeric } from 'vuelidate/lib/validators'
import T1Mixin from '@/mixins/T1Mixin'

export default {
  name: 't1PinCard',
  mixins: [T1Mixin, validationMixin],
  props: ['postavke'],
  data () {
    return {
      syncing: false,
      noviPin: '',
      noviPinOpet: ''
    }
  },
  validations: {
    noviPin: {
      required,
      numeric,
      minLength: minLength(4),
      maxLength: maxLength(4)
    },
    noviPinOpet: {
      required,
      numeric,
      minLength: minLength(4),
      maxLength: maxLength(4)
    }

  },
  computed: {
    syncDisabled () {
      if (this.syncing) return true
      if (!this.noviPin || !this.noviPinOpet) return true
      if (this.noviPinErrors.length > 0 || this.noviPinOpetErrors.length > 0) return true
      if (this.noviPin != this.noviPinOpet) return true

      return false
    },
    noviPinErrors () {
      const errors = []
      if (!this.$v.noviPin.$dirty) return errors
      !this.$v.noviPin.required && errors.push(this.$t('ui.field_required'))
      !this.$v.noviPin.numeric && errors.push(this.$t('ui.vrijednost_mora_biti_broj'))
      !this.$v.noviPin.minLength && errors.push(this.$t('ui.pin_se_mora_sastojati_od_4_znamenke'))
      !this.$v.noviPin.maxLength && errors.push(this.$t('ui.pin_se_mora_sastojati_od_4_znamenke'))
      return errors
    },
    noviPinOpetErrors () {
      const errors = []
      if (!this.$v.noviPinOpet.$dirty) return errors
      !this.$v.noviPinOpet.required && errors.push(this.$t('ui.field_required'))
      !this.$v.noviPinOpet.numeric && errors.push(this.$t('ui.vrijednost_mora_biti_broj'))
      !this.$v.noviPinOpet.minLength && errors.push(this.$t('ui.pin_se_mora_sastojati_od_4_znamenke'))
      !this.$v.noviPinOpet.maxLength && errors.push(this.$t('ui.pin_se_mora_sastojati_od_4_znamenke'))
      return errors
    }
  },
  methods: {
    syncPin () {
      this.syncing = true

      this.t1Write([this.t1.CPS, this.t1.WRITE, 0x04, parseInt(this.noviPin[0]), parseInt(this.noviPin[1]), parseInt(this.noviPin[2]), parseInt(this.noviPin[3])])
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'red',
            text: this.$t('ui.save_error')
          })
        })
        .finally(() => {
          this.syncing = false
        })
    }
  }
}
</script>
