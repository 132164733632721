<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">{{ $t('ui.settings') }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-progress-linear v-show="syncing" color="primary" indeterminate></v-progress-linear>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>{{$t('ui.temperatura_cps_modula')}}</td>
              <td v-if="postavke.TEMP">{{postavke.TEMP}}</td>
              <!--<td></td>-->
            </tr>
            <tr>
              <td>U1 (5VDC)</td>
              <td v-if="postavke.NAPON">{{postavke.NAPON.u1}}</td>
              <!--<td></td>-->
            </tr>
            <tr>
              <td>U2 (24VDC)</td>
              <td v-if="postavke.NAPON">{{postavke.NAPON.u2}}</td>
              <!--<td></td>-->
            </tr>
            <tr>
              <td>{{$t('ui.uline_napon_signala')}}</td>
              <td v-if="postavke.NAPON">{{postavke.NAPON.dimmerStatus}}</td>
              <!--<td></td>-->
            </tr>
            <tr v-if="postavke.UPTIME">
              <td>{{$t('ui.uptime')}}</td>
              <td>{{postavke.UPTIME.d}}d{{postavke.UPTIME.h}}h{{postavke.UPTIME.m}}m{{postavke.UPTIME.s}}s</td>
              <!--<td></td>-->
            </tr>
            <tr v-if="postavke.VRIJEME">
              <td>{{$t('ui.vrijeme_i_datum')}}</td>
              <td>
                <p>{{postavke.VRIJEME.h}}:{{postavke.VRIJEME.m}}:{{postavke.VRIJEME.s}}</p>
                <p>{{postavke.VRIJEME.date}}.{{postavke.VRIJEME.month}}.{{postavke.VRIJEME.year}}, {{postavke.VRIJEME.weekday}}</p>
              </td>
              <!--<td>
                <v-btn v-if="isAuthenticated" @click="syncTime" color="primary" :disabled="syncDisabled" block tile>{{$t('ui.sync_time')}}</v-btn>
              </td>-->
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-btn v-if="isAuthenticated" @click="ucitajIzvorneParametre" color="red" block :disabled="syncDisabled">{{$t('ui.ucitaj_izvorne_postavke')}}</v-btn>
    </v-card-text>
    <app-confirm-action-dialog
      :open-dialog="openConfirmDialog"
      :headline="$t('ui.potvrdi_ucitavanje_izvornih_parametara')"
      :text="$t('ui.zaista_ucitati_izvorne_postavke')"
      @userAction="confirmDialogHandler">
    </app-confirm-action-dialog>
  </v-card>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'

export default {
  name: 't1PostavkeCard',
  mixins: [T1Mixin, AuthMixin],
  props: ['postavke'],
  data () {
    return {
      syncing: false,
      openConfirmDialog: false
    }
  },
  computed: {
    syncDisabled () {
      if (this.syncing) return true
      return false
    }
  },
  methods: {
    syncTime () {
      this.syncing = true
      this.setCpsTime(this.getClientTime())
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'red',
            text: this.$t('ui.save_error')
          })
        })
        .finally(() => {
          this.syncing = false
        })
    },
    ucitajIzvorneParametre () {
      this.openConfirmDialog = true
    },
    doUcitajIzvorneParametre () {
      this.syncing = true
      this.resetToFactoryDefaults()
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'red',
            text: this.$t('ui.save_error')
          })
        })
        .finally(() => {
          this.syncing = false
        })
    },
    confirmDialogHandler (actionResult) {
      this.openConfirmDialog = false
      if (actionResult === true) {
        this.doUcitajIzvorneParametre()
      }
    }
  }
}
</script>
