export default {
  data () {
    return {
      supervisorJobStatus: -1,
      supervisorJobStatusItems: [
        { 'id': '0', 'name': this.$t('ui.not_active') },
        { 'id': '1', 'name': this.$t('ui.active') }
      ]
    }
  },
  methods: {
    getSupervisorConfig (configKey) {
      return this.$api.get(`/supervisor/config/?key=${configKey}`, {baseURL: this.$store.getters['app/APP_API_URI']})
    },
    createSupervisorConfig (config) {
      return this.$api.post(`/supervisor/config/?key=${config.key}`, {'config': config.config}, {baseURL: this.$store.getters['app/APP_API_URI']})
    },
    deleteSupervisorConfig (configKey) {
      return this.$api.delete(`/supervisor/config/?key=${configKey}`, {baseURL: this.$store.getters['app/APP_API_URI']})
    },
    getSupervisorJobStatus (configKey) {
      return this.$api.get(`/supervisor/job/?key=${configKey}`, {baseURL: this.$store.getters['app/APP_API_URI']})
    },
    startSupervisorJob (configKey) {
      return this.$api.get(`/supervisor/job/?key=${configKey}&action=start`, {baseURL: this.$store.getters['app/APP_API_URI']})
    },
    stopSupervisorJob (configKey) {
      return this.$api.get(`/supervisor/job/?key=${configKey}&action=stop`, {baseURL: this.$store.getters['app/APP_API_URI']})
    },
    tailSupervisorJob (configKey, output) {
      return this.$api.get(`/supervisor/job/?key=${configKey}&action=tail&output=${output}`, {baseURL: this.$store.getters['app/APP_API_URI']})
    },
    updateSupervisorJobStatus (configKey) {
      this.statusUpdating = true
      this.getSupervisorJobStatus(configKey)
        .then((response) => {
          this.supervisorJobStatus = response.data.status
        })
        .catch(() => {
          this.supervisorJobStatus = -1
          this.supervisorJobStatusText = this.$t('ui.unknown')
        })
        .finally(() => {
          this.statusUpdating = false
        })
    },
    doStartSupervisorJob (configKey) {
      this.loading = true
      this.startSupervisorJob(configKey)
        .then(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    doStopSupervisorJob (configKey) {
      this.loading = true
      this.stopSupervisorJob(configKey)
        .then(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    doDeleteSupervisorJob (configKey) {
      return new Promise((resolve, reject) => {
        this.stopSupervisorJob(configKey)
        .then(response => {
          this.deleteSupervisorConfig(configKey)
          .then(() => {
            resolve(response)
          })
          .catch(error => {
            reject(error.data)
          })
        })
        .catch(error => {
          reject(error.data)
        })
      })
    }
  },
  computed: {
    supervisorJobStatusText () {
      if (this.supervisorJobStatus === -1) {
        return this.$t('ui.unknown')
      } else if (this.supervisorJobStatus === 0) {
        return this.$t('ui.not_running')
      } else if (this.supervisorJobStatus === 1) {
        return this.$t('ui.running')
      } else if (this.supervisorJobStatus === 2) {
        return this.$t('ui.starting')
      }
    }
  }
}
