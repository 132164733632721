<template>
  <v-container fluid grid-list-md>
    <v-row>
      <v-col xs="12">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ this.$t('ui.phases') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              @click="submit"
              color="primary"
            >{{ this.$t('ui.submit') }}</v-btn>
            <v-btn dark icon @click="add()"><v-icon>add</v-icon></v-btn>
            <v-btn
              dark
              icon
              @click="init()"
              :disabled="loading"
            >
              <v-icon>refresh</v-icon>
            </v-btn>
          </v-toolbar>
          <v-progress-linear
            v-show="loading"
            color="primary"
            indeterminate
          ></v-progress-linear>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="phase in phases" :key="phase.id" xs="12" lg="4">
        <app-phase-edit-card :phase="phase" @userAction="userActionHandler"></app-phase-edit-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import ConfigMixin from '@/mixins/ConfigMixin'
import TelventMixin from '@/mixins/TelventMixin'

export default {
  name: 'PhasesPage',
  mixins: [validationMixin, ConfigMixin, TelventMixin],
  data () {
    return {
      configMeta: {
        TELVENT_PHASES: {
          type: Array
        },
        TELVENT_PROGRAMS: {
          type: Array
        }
      },
      config: {
        TELVENT_NR_SIGNAL_GROUPS: 24,
        TELVENT_PHASES: [],
        TELVENT_PROGRAMS: []
      },
      loading: false,
      phases: []
    }
  },
  methods: {
    init () {
      this.loading = true
      this.initConfigData()
        .then((data) => {
          this.config.TELVENT_NR_SIGNAL_GROUPS = parseInt(data.TELVENT_NR_SIGNAL_GROUPS)
          this.phases = this.config.TELVENT_PHASES
        })
        .finally(() => {
          this.loading = false
        })
    },
    uniqueId (prefix, idCounter) {
      var id = ++idCounter
      return prefix + id
    },
    getNewPhaseData () {
      var idCounter = 0

      for (var i = 0; i < this.phases.length; i++) {
        var phase = this.phases[i]
        var x = phase.id.split('_')
        idCounter = parseInt(x[x.length - 1])
      }

      return {
        id: this.uniqueId('phaseId_', idCounter),
        name: `${this.$t('ui.phase')} ${idCounter+1}`,
        signals: new Array(this.config.TELVENT_NR_SIGNAL_GROUPS).fill([0,0,0])
      }

    },
    add () {
      this.phases.push(this.getNewPhaseData())
    },
    submit () {
      this.loading = true

      for (let i = 0; i < this.phases.length; i++) {
        let phase = this.phases[i]

        for (let j = 0; j < this.config.TELVENT_PROGRAMS.length; j++) {
          let program = this.config.TELVENT_PROGRAMS[j]

          for (let k = 0; k < program.phases.length; k++) {
            let programPhase = program.phases[k]

            if (programPhase.id === phase.id && programPhase.name !== phase.name) {
              programPhase.name = phase.name
            }
          }
        }
      }

      this.createConfig({
        'key': 'TELVENT_PHASES',
        'value': this.phases
      })
      .then(() => {
        this.createConfig({
          'key': 'TELVENT_PROGRAMS',
          'value': this.config.TELVENT_PROGRAMS
        })
      })
      .then(() => {
        this.TelventFixPhase({'reload': '1'})
      })
      .then(() => {
        this.$EventBus.$emit('AppSnackbar/show', {
          color: 'success',
          text: this.$t('ui.save_success')
        })
      })
      .catch(() => {
        this.$EventBus.$emit('AppSnackbar/show', {
          color: 'error',
          text: this.$t('ui.error')
        })
      })
      .finally(() => {
        this.init()
      })

    },
    userActionHandler (action) {
      if (action.name === 'delete') {
        this.phases.splice(this.phases.indexOf(action.phase), 1)
      }
    }
  },
  created () {
    this.init()
  }
}
</script>
