<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>{{$t('ui.stalne_najave')}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <!--<v-btn
        v-if="isAuthenticated"
        @click="submit(true)"
        color="primary"
        :disabled="loading"
      >{{ this.$t('ui.submit') }}</v-btn>-->
    </v-toolbar>
    <v-card-text>
      <v-progress-linear
        v-show="loading"
        color="primary"
        indeterminate
      ></v-progress-linear>

      <v-data-table
        :headers="headers"
        :items="detektori"
        :items-per-page="limit"
        sort-by="detektor"
        hide-default-footer
        item-key="id"
        class="elevation-1"
      >
        <template v-slot:item.status="{ item }">
          <v-select
            :items="statusi"
            :label="$t('ui.status')"
            v-model="item.status"
          ></v-select>
        </template>

        <template v-slot:item.ima_stalnu_najavu="{ item }">
          <v-select
            :items="imaStalnuNajavu"
            :label="$t('ui.ima_stalnu_najavu')"
            v-model="item.ima_stalnu_najavu"
          ></v-select>
        </template>

        <template v-slot:item.u_stalnoj_najavi="{ item }">
          <v-select
            :items="uStalnojNajavi"
            :label="$t('ui.u_stalnoj_najavi')"
            v-model="item.u_stalnoj_najavi"
          ></v-select>
        </template>

        <template v-slot:item.u_kvaru_trazi="{ item }">
          <v-select
            :items="uKvaru"
            :label="$t('ui.u_kvaru')"
            v-model="item.u_kvaru_trazi"
          ></v-select>
        </template>

      </v-data-table>

    </v-card-text>
  </v-card>
</template>

<script>
import AuthMixin from '@/mixins/AuthMixin'
import ConfigMixin from '@/mixins/ConfigMixin'
import SupervisorMixin from '@/mixins/SupervisorMixin'

export default {
  name: 'DetektoriStalneNajaveCard',
  mixins: [ConfigMixin, SupervisorMixin, AuthMixin],
  props: ['cameras'],
  data () {
    return {
      loading: false,
      limit: 999,
      detektori: [],
      statusi: [
        {'value': 'aktivan', 'text': 'Aktivan'},
        {'value': 'nije_aktivan', 'text': 'Nije aktivan'}
      ],
      imaStalnuNajavu: [
        {'value': 'da', 'text': 'DA'},
        {'value': 'ne', 'text': 'NE'}
      ],
      uStalnojNajavi: [
        {'value': 'maksimalno', 'text': 'Traži maksimalno vrijeme'},
        {'value': 'minimalno', 'text': 'Traži minimalno vrijeme'}
      ],
      uKvaru: [
        {'value': 'maksimalno', 'text': 'Traži maksimalno vrijeme'},
        {'value': 'minimalno', 'text': 'Traži minimalno vrijeme'},
        {'value': 'nije_aktivan', 'text': 'Nije aktivan'}
      ],
      configMeta: {
        DETEKTORI_STALNE_NAJAVE: {
          type: Array
        }
      },
      config: {
        DETEKTORI_STALNE_NAJAVE: []
      }
    }
  },
  computed: {
    headers () {
      return [
        { text: this.$t('ui.detektor'), value: 'detektor', align: 'center' },
        { text: this.$t('ui.status'), value: 'status', align: 'center', sortable: false },
        { text: this.$t('ui.ima_stalnu_najavu'), value: 'ima_stalnu_najavu', align: 'center', sortable: false },
        { text: this.$t('ui.u_stalnoj_najavi'), value: 'u_stalnoj_najavi', align: 'center', sortable: false },
        { text: this.$t('ui.u_kvaru'), value: 'u_kvaru_trazi', align: 'center', sortable: false }
      ]
    }
  },
  watch: {
    cameras: {
      handler: function () {
        this.mergeConfigData()
      },
      deep: true
    }
  },
  methods: {
    init () {
      return new Promise((resolve, reject) => {
        this.config.DETEKTORI_STALNE_NAJAVE = []
        this.detektori = []
        this.initConfigData()
          .then(() => {
            this.mergeConfigData()
            resolve([])
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    mergeConfigData () {
      this.detektori = []
      let camera = null
      let detektor = null
      let detektori = []

      for (let i = 0; i < this.cameras.length; i++) {
        camera = this.cameras[i]

        for (let j = 0; j < camera.zones.length; j++) {
          detektori.push(camera.zones[j].detector)
        }
      }

      for (let i = 0; i < this.config.DETEKTORI_STALNE_NAJAVE.length; i++) {
        detektor = this.config.DETEKTORI_STALNE_NAJAVE[i]
        let detektorIndex = this.$lodash.findIndex(this.detektori, function (s) { return s.detektor === detektor.detektor })
        let postojiDetektorIndex = this.$lodash.findIndex(detektori, function (s) { return s === detektor.detektor })

        if (detektorIndex > -1) {
          this.detektori[detektorIndex] = detektor
        } else {
          if (postojiDetektorIndex >= 0) {
            this.detektori.push(detektor)
          }
        }
      }

      for (let i = 0; i < detektori.length; i++) {

        detektor = detektori[i]

        let detektorIndex = this.$lodash.findIndex(this.detektori, function (s) { return s.detektor === detektor })

        if (detektorIndex < 0) {
          this.detektori.push({
            'detektor': detektor,
            'status': 'aktivan',
            'ima_stalnu_najavu': 'ne',
            'u_stalnoj_najavi': 'maksimalno',
            'u_kvaru_trazi': 'maksimalno'
          })
        }
      }
    },
    submit (restartBackend) {
      this.createConfig({
        'key': 'DETEKTORI_STALNE_NAJAVE',
        'value': this.detektori
      })
      .then(() => {
        this.$EventBus.$emit('AppSnackbar/show', {
          color: 'success',
          text: this.$t('ui.save_success')
        })
      })
      .catch(() => {
        this.$EventBus.$emit('AppSnackbar/show', {
          color: 'error',
          text: this.$t('ui.save_error')
        })
      })
      .finally(() => {
        this.loading = true

        if (restartBackend) {
          this.stopSupervisorJob('cameras_handler')
            .then(() => {
              this.startSupervisorJob('cameras_handler')
            })
            .catch(() => {
              this.startSupervisorJob('cameras_handler')
            })
            .finally(() => {
              window.location.reload()
            })
        }
      })
    }
  },
  created () {
    this.init()
  }
}
</script>
