<template>
  <v-container fluid grid-list-md>

    <v-row>
      <v-col xs="12">
        <v-card>
          <v-toolbar flat dense>
            <v-toolbar-title>
              <span class="text-xl-left">Import LISA XML</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="submit" color="primary">{{ this.$t('ui.import') }}</v-btn>
          </v-toolbar>
        </v-card>
        <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>
      </v-col>
    </v-row>

    <v-row v-for="program in programi" :key="program.name">
      <v-col xs="12">
        <v-card>
          <v-toolbar flat dense :color="program.import ? '#272727' : 'red'">
            <v-toolbar-title>
              <span class="text-xl-left">{{ program.name }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-checkbox
              v-model="program.import"
              hide-details
            ></v-checkbox>
          </v-toolbar>
          <v-row>
            <v-col>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th><strong>{{ $t('ui.cycle') }}</strong></th>
                    <th><strong>{{ $t('ui.offset') }}</strong></th>
                    <th><strong>{{ $t('ui.fixed_time_control') }}</strong></th>
                    <th><strong>{{ $t('ui.amber_flash') }}</strong></th>
                    <th><strong>{{ $t('ui.signals_off') }}</strong></th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ program.cycle }}</td>
                      <td>{{ program.offset }}</td>
                      <td>
                        <v-select
                          v-model="program.ftc"
                          :items="yesnoSelect"
                        >
                        </v-select>
                      </td>
                      <td>
                        <v-select
                          v-model="program.trz"
                          :items="yesnoSelect"
                        >
                        </v-select>
                      </td>
                      <td>
                        <v-select
                          v-model="program.signals_off"
                          :items="yesnoSelect"
                        >
                        </v-select>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="4">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th><strong>{{ $t('ui.group') }}</strong></th>
                    <th><strong>{{ $t('ui.start') }}</strong></th>
                    <th><strong>{{ $t('ui.stop') }}</strong></th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="grupa in program.grupe" :key="grupa.name">
                      <td>{{grupa.name}}</td>
                      <td>{{grupa.start}}</td>
                      <td>{{grupa.stop}}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'

export default {
  name: 'McoProgramatorImportXmlPage',
  mixins: [T1Mixin],
  props: ['programsData'],
  data () {
    return {
      loading: false,
      BROJ_GRUPA: null,
      actions: [],
      programi: [],
      yesnoSelect: [
        {
          'text': this.$t('ui.yes'),
          'value': 1
        },
        {
          'text': this.$t('ui.no'),
          'value': 0
        }
      ]
    }
  },
  methods: {
    init () {
      this.programi = JSON.parse(JSON.stringify(this.programsData))
    },
    maybeNull(data) {
      if (data === 255) {
        return null
      }
      return data
    },
    getProgramObject (ptp, NMCO_DATA) {
      let program = {
        'ptp': ptp,
        'name': null,
        'cycle': this.maybeNull(NMCO_DATA[1]),
        'offset': this.maybeNull(NMCO_DATA[2]),
        'ftc': this.maybeNull(NMCO_DATA[3]),
        'trz': this.maybeNull(NMCO_DATA[4]),
        'signals_off': this.maybeNull(NMCO_DATA[5])
      }

      if (program.ftc === 0) {
        program.ftc = this.$t('ui.no')
      } else {
        program.ftc = this.$t('ui.yes')
      }

      if (program.trz === 0) {
        program.trz = this.$t('ui.no')
      } else {
        program.trz = this.$t('ui.yes')
      }

      if (program.signals_off === 0) {
        program.signals_off = this.$t('ui.no')
      } else {
        program.signals_off = this.$t('ui.yes')
      }

      return program
    },
    submit () {
      this.loading = true
      let promises = []

      let ptpAdresa = 1

      for (let i = 0; i < this.programi.length; i++) {

        let program = this.programi[i]

        if (program.import !== true) {
          continue
        }

        let nmcoOrderData = [
          this.t1.CPS,
          this.t1.WRITE,
          0x54,
          ptpAdresa,
          parseInt(program.cycle),
          parseInt(program.offset),
          parseInt(program.ftc),
          parseInt(program.trz),
          parseInt(program.signals_off)
        ]

        promises.push(this.t1Write(nmcoOrderData))

        for (let j = 0; j < program.grupe.length; j++) {
          let grupa = program.grupe[j]
          let cmd = [
            this.t1.CPS,
            this.t1.WRITE,
            0x53,
            parseInt(ptpAdresa),
            parseInt(grupa.name.match(/\d+/)[0]),
            parseInt(grupa.start),
            parseInt(grupa.stop),
            // zahtjev
            1,
            // privilegija
            0
          ]
          promises.push(this.t1Write(cmd))
        }

        ptpAdresa = ptpAdresa + 1
      }

      Promise.all(promises)
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })

          setTimeout(this.$router.push({
            name: 'McoProgramatorPage'
          }), 200)
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'error',
            text: this.$t('ui.save_error')
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    this.init()
  }
}
</script>
