<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">{{ program.name }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn dark icon @click="add()"><v-icon>add</v-icon></v-btn>
      <v-btn
        dark
        icon
        @click="userAction({'name': 'delete', 'program': program})"
      >
        <v-icon color="red">close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="program.name"
              :label="this.$t('ui.name')"
              :error-messages="nameErrors"
              @input="$v.program.name.$touch()"
              @blur="$v.program.name.$touch()"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="program.cycle"
              :label="this.$t('ui.cycle')"
              :error-messages="cycleErrors"
              @input="$v.program.cycle.$touch()"
              @blur="$v.program.cycle.$touch()"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="program.offset"
              :label="this.$t('ui.offset')"
              :error-messages="offsetErrors"
              @input="$v.program.offset.$touch()"
              @blur="$v.program.offset.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xs="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th><strong>{{ $t('ui.phase') }}</strong></th>
                  <th><strong>{{ $t('ui.start') }}</strong></th>
                </tr>
                </thead>
                <tbody>
                  <tr v-for="phase in program.phases" :key="phase.id">
                    <td>
                      <v-select
                        :items="phases"
                        item-text="name"
                        item-value="name"
                        v-model="phase.name"
                      ></v-select>
                    </td>
                    <td>
                      <v-text-field
                        v-model="phase.start"
                        :error-messages="offsetErrors"
                        @input="$v.program.offset.$touch()"
                        @blur="$v.program.offset.$touch()"
                      ></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>

    </v-card-text>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ProgramEditCard',
  mixins: [validationMixin],
  props: ['program', 'phases'],
  validations: {
    program: {
      name: {
        required
      },
      cycle: {
        required
      },
      offset: {
        required
      }
    }
  },
  computed: {
    nameErrors () {
      const errors = []
      if (!this.$v.program.name.$dirty) return errors
      !this.$v.program.name.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    cycleErrors () {
      const errors = []
      if (!this.$v.program.cycle.$dirty) return errors
      !this.$v.program.cycle.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    offsetErrors () {
      const errors = []
      if (!this.$v.program.offset.$dirty) return errors
      !this.$v.program.offset.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    isFormValid () {
      return !this.$v.$invalid
    }
  },
  methods: {
    uniqueId (prefix, idCounter) {
      var id = ++idCounter
      return prefix + id
    },
    getNewPhaseData () {
      var idCounter = 0

      for (var i = 0; i < this.program.phases.length; i++) {
        var phase = this.program.phases[i]
        var x = phase.id.split('_')
        idCounter = parseInt(x[x.length - 1])
      }

      return {
        id: this.uniqueId('phaseId_', idCounter),
        name: '',
        start: 0
      }
    },
    add () {
      this.program.phases.push(this.getNewPhaseData())
    },
    userAction: function (actionResult) {
      this.$emit('userAction', actionResult)
    }
  }
}
</script>
