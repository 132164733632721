<template>
    <v-snackbar
      :timeout="timeout"
      :color="color"
      :top="y === 'top'"
      :bottom="y === 'bottom'"
      :right="x === 'right'"
      :left="x === 'left'"
      v-model="snackbarVisible"
    >
      {{ text }}
      <v-btn dark text @click.native="snackbarVisible = false">{{ $t('ui.close') }}</v-btn>
    </v-snackbar>
</template>

<script>
export default {
  name: 'NotificationSnackBar',
  data () {
    return {
      snackbarVisible: false,
      timeout: 5000,
      color: 'info',
      x: '',
      y: 'top',
      text: ''
    }
  },
  created () {

    let self = this

    this.$EventBus.$on('AppSnackbar/show', function (payload) {
      self.snackbarVisible = true
      self.text = payload.text

      if (payload.timeout) {
        self.timeout = payload.timeout
      }

      if (payload.color) {
        self.color = payload.color
      }
    })

  },
  destroyed () {
    this.$EventBus.$off('AppSnackbar/show')
  }
}
</script>
