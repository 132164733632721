<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">{{ $t('ui.dimmer') }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="isAuthenticated" @click="syncDimmer" color="primary" :disabled="syncDisabled">{{$t('ui.save')}}</v-btn>
    </v-toolbar>
    <v-progress-linear v-show="syncing" color="primary" indeterminate></v-progress-linear>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>{{$t('ui.stanje_dimmera')}}</td>
              <td>{{dimmerStatus}}</td>
              <td></td>
            </tr>
            <tr>
              <td>{{$t('ui.LDR')}}</td>
              <td>{{dimmer.val}}</td>
              <td></td>
            </tr>
            <tr>
              <td>{{$t('ui.prag')}}</td>
              <td>{{dimmer.prag}}</td>
              <td><v-text-field v-model="dimmerSave.prag" :disabled="!isAuthenticated"></v-text-field></td>
            </tr>
            <tr>
              <td>{{$t('ui.histereza')}}</td>
              <td>{{dimmer.hister}}</td>
              <td><v-text-field v-model="dimmerSave.hister" :disabled="!isAuthenticated"></v-text-field></td>
            </tr>
            <tr>
              <td>{{$t('ui.vrijeme_relaksacije')}}</td>
              <td>{{dimmer.trelax}}</td>
              <td><v-text-field v-model="dimmerSave.trelax" :disabled="!isAuthenticated"></v-text-field></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'

export default {
  name: 't1DimmerCard',
  mixins: [T1Mixin, AuthMixin],
  props: ['postavke'],
  data () {
    return {
      syncing: false,
      dimmerSave: {
        prag: 0,
        hister: 0,
        trelax: 0
      }
    }
  },
  computed: {
    syncDisabled () {
      if (this.syncing) return true
      return false
    },
    dimmerStatus () {
      if (this.postavke && this.postavke.NAPON && this.postavke.NAPON.dimmerStatus) {
        return this.postavke.NAPON.dimmerStatus
      }

      return 'N/A'
    },
    dimmer () {
      if (!this.postavke || !this.postavke.LDR) {
        return {
          prag: 'N/A',
          hister: 'N/A',
          trelax: 'N/A',
          val: 'N/A'
        }
      }

      return {
        prag: this.postavke.LDR[0]+256*this.postavke.LDR[1],
        hister: this.postavke.LDR[2]+256*this.postavke.LDR[3],
        trelax: this.postavke.LDR[4]+256*this.postavke.LDR[5],
        val: this.postavke.LDR[6]+256*this.postavke.LDR[7]
      }
    }
  },
  methods: {
    syncDimmer () {
      this.syncing = true

      this.saveLDRPostavke(this.dimmerSave)
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'red',
            text: this.$t('ui.save_error')
          })
        })
        .finally(() => {
          this.syncing = false
        })
    }
  }
}
</script>
