<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>{{title}}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <p style="white-space: pre-wrap">{{tail.output}}</p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'app-file-tail-card',
  mixins: [],
  data () {
    return {
      tail: {
        output: ''
      },
      tailId: null,
      tailRunning: false
    }
  },
  props: {
    filePath: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: 'LOG'
    },
    rows: {
      type: Number,
      default: 20
    }
  },
  methods: {
    tailFile () {
      this.tailRunning = true
      this.$api.get(`/tail/?file=${this.filePath}`, {baseURL: this.$store.getters['app/APP_API_URI']})
        .then((response) => {
          this.tail = response.data
        })
        .finally(() => {
          this.tailRunning = false
        })
    }
  },
  created () {
    this.tailId = setInterval(() => {
      if (!this.tailRunning) {
        this.tailFile()
      }
    }, 1000)
  },
  destroyed () {
    if (this.tailId) {
      clearInterval(this.tailId)
    }
  }
}
</script>
