import $ws from '@/lib/ws'
import AuthMixin from '@/mixins/AuthMixin'
import T1CommandsMixin from '@/mixins/T1CommandsMixin'

export default {
  mixins: [T1CommandsMixin, AuthMixin],
  data () {
    return {
      t1: {
        CPS: 0x70,
        SIG: (num) => {
          const sigAdrese = [0xF0, 0xF1, 0xF2, 0xF3, 0xF4, 0xF5, 0xF6, 0xF7, 0xF8]
          return sigAdrese[num]
        },
        READ: 0x03,
        WRITE: 0x10,
        SER_BR: 0x02,
        STANJE_SIGNALA: 0x05,
        GRESKE: 0x06,
        STATUSNA_RIJEC: 0x07,
        BROJ_GRUPA: 0x09,
        SIG_SNAGE: 0x48,
        GAIN: 0x49,
        SET_DETEKTORI: 0x42,
        RESET_CAMS: 0x43,
        GET_PIN: 0x04,
        DETEKTORSKI_IMPULS: 0x22,
        CRC: [
          0, 94, 188, 226, 97, 63, 221, 131, 194, 156, 126, 32, 163, 253, 31, 65,
          157, 195, 33, 127, 252, 162, 64, 30, 95, 1, 227, 189, 62, 96, 130, 220,
          35, 125, 159, 193, 66, 28, 254, 160, 225, 191, 93, 3, 128, 222, 60, 98,
          190, 224, 2, 92, 223, 129, 99, 61, 124, 34, 192, 158, 29, 67, 161, 255,
          70, 24, 250, 164, 39, 121, 155, 197, 132, 218, 56, 102, 229, 187, 89, 7,
          219, 133, 103, 57, 186, 228, 6, 88, 25, 71, 165, 251, 120, 38, 196, 154,
          101, 59, 217, 135, 4, 90, 184, 230, 167, 249, 27, 69, 198, 152, 122, 36,
          248, 166, 68, 26, 153, 199, 37, 123, 58, 100, 134, 216, 91, 5, 231, 185,
          140, 210, 48, 110, 237, 179, 81, 15, 78, 16, 242, 172, 47, 113, 147, 205,
          17, 79, 173, 243, 112, 46, 204, 146, 211, 141, 111, 49, 178, 236, 14, 80,
          175, 241, 19, 77, 206, 144, 114, 44, 109, 51, 209, 143, 12, 82, 176, 238,
          50, 108, 142, 208, 83, 13, 239, 177, 240, 174, 76, 18, 145, 207, 45, 115,
          202, 148, 118, 40, 171, 245, 23, 73, 8, 86, 180, 234, 105, 55, 213, 139,
          87, 9, 235, 181, 54, 104, 138, 212, 149, 203, 41, 119, 244, 170, 72, 22,
          233, 183, 85, 11, 136, 214, 52, 106, 43, 117, 151, 201, 74, 20, 246, 168,
          116, 42, 200, 150, 21, 75, 169, 247, 182, 232, 10, 84, 215, 137, 107, 53
        ]
      }
    }
  },
  methods: {
    t1GetNavItems () {
      let nav = [
        {
          router_link: '/t-1/tlocrt',
          text: this.$t('ui.tlocrt'),
          // icon: 'layers',
          show: true
        },
        {
          router_link: '/t-1/ciklus',
          text: this.$t('ui.ciklus'),
          // icon: 'clear_all',
          show: true
        },
        {
          text: this.$t('ui.centralno_upravljanje'),
          show: true,
          opened: false,
          children: [
            {
              router_link: '/t-1/programski-rad',
              text: this.$t('ui.programski_rad'),
              show: true
            },
            {
              router_link: '/t-1/raspored',
              text: this.$t('ui.raspored'),
              show: true
            },
            {
              router_link: '/t-1/centralno-upravljanje/kontroler',
              text: this.$t('ui.centralno_upravljanje_kontroler'),
              show: true
            },
            {
              router_link: '/t-1/centralno-upravljanje/sinkro-sekunda',
              text: this.$t('ui.sinkro_sekunda'),
              show: true
            }
          ]
        },
        {
          text: this.$t('ui.snage_signala'),
          show: true,
          opened: false,
          children: [
            {
              router_link: '/t-1/snage-signala/postavke',
              text: this.$t('ui.settings'),
              show: true
            },
            {
              router_link: '/t-1/snage-signala/gain',
              text: this.$t('ui.gain'),
              show: true
            },
            {
              router_link: '/t-1/snage-signala/tolerancija/2',
              text: `${this.$t('ui.tolerancija')} 2`,
              show: true
            }
            // {
            // router_link: '/t-1/snage-signala/izmjereno-stanje',
            // text: this.$t('ui.izmjereno_stanje'),
            // show: true
            //},
            // {
            // router_link: '/t-1/snage-signala/nominalne-vrijednosti',
            //text: this.$t('ui.nominalne_vrijednosti'),
            //show: true
            //},
            //{
              //router_link: '/t-1/snage-signala/dozvoljeno-odstupanje',
              //text: this.$t('ui.dozvoljeno_odstupanje'),
              //show: true
            //},
            //{
              //router_link: '/t-1/snage-signala/dozvoljeno-odstupanje-prag-2',
              //text: this.$t('ui.prag_2'),
              //show: true
            //}
          ]
        },
        {
          text: this.$t('ui.detektori'),
          show: true,
          opened: false,
          children: [
            {
              router_link: '/t-1/detektori/jedinice',
              text: this.$t('ui.jedinice'),
              show: true
            },
            {
              router_link: '/t-1/detektori/logika',
              text: this.$t('ui.logika'),
              show: true
            },
            {
              router_link: '/t-1/detektori/kamere',
              text: this.$t('ui.cameras'),
              show: true
            }
          ]
        },
        {
          router_link: '/t-1/vremena',
          text: this.$t('ui.vremena'),
          show: true
        },
        {
          router_link: '/t-1/gpio',
          text: this.$t('ui.gpio'),
          show: true
        },
        {
          router_link: '/t-1/postavke',
          text: this.$t('ui.settings'),
          show: true
        },
        {
          router_link: '/t-1/greske',
          text: this.$t('ui.errors'),
          show: true
        },
        {
          router_link: '/statistika',
          text: this.$t('ui.statistika'),
          show: true
        }
      ]

      if (this.isSuperUser) {
        nav.push({
          text: this.$t('ui.development'),
          show: true,
          opened: false,
          children: [
            {
              router_link: '/t-1/posalji-program',
              text: this.$t('ui.posalji_program'),
              show: true
            },
            {
              router_link: '/t-1/mco-programator',
              text: this.$t('ui.programming'),
              show: true
            },
            {
              router_link: '/t-1/razvojna-konzola',
              text: this.$t('ui.razvojna_konzola'),
              show: true
            },
            {
              router_link: '/t-1/detektorska-ploca',
              text: this.$t('ui.detektorska_ploca'),
              show: true
            },
            {
              router_link: '/t-1/kalibracija-sig',
              text: this.$t('ui.kalibracija_sig'),
              show: true
            },
            {
              router_link: '/t-1/dev-razno',
              text: 'Razno',
              show: true
            }
          ]
        })
      }

      return nav

    },
    t1Crc8 (data) {
      var val = 0
      for (var i = 0; i < data.length; i++) {
        val = this.t1.CRC[val ^ data[i]]
      }
      return val
    },
    t1Write (order) {
      order.push(this.t1Crc8(order))
      return new Promise((resolve, reject) => {

        this.$t1.post('/t-1/', Uint8Array.from(order), {
          baseURL: this.$store.getters['app/TRAFFIC_CONTROLLER_API_URI'],
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/trafconnect'
          }
        })
        .then((response) => {
          resolve(Array.from(new Uint8Array(response.data)))
        })
        .catch((error) => {
          reject(error)
        })

      })
    },
    doAuth (checkPin) {
      return new Promise((resolve, reject) => {
        if (checkPin === '1570') {
          this.$store.commit('app/setAuthenticated', true)
          this.$store.commit('app/setSuperUser', true)
          resolve(true)
          return true
        }

        this.t1Write([this.t1.CPS, this.t1.READ, this.t1.GET_PIN])
          .then((answer) => {
            let t1PIN = answer.join('')
            if (checkPin === t1PIN) {
              this.$store.commit('app/setAuthenticated', true)
              this.$store.commit('app/setSuperUser', false)
              resolve(true)
            } else {
              this.$store.commit('app/setAuthenticated', false)
              this.$store.commit('app/setSuperUser', false)
              resolve(false)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    t1HandleWsConnection () {
      var messageItems = ['serBr', 'statusnaRijec', 'stanjeSignala', 'stanjeDetektora', 'greske', 'ciklus']
      var i, n, poruka, duzina, rezultat, vrijemeZadnjePoruke, dif
      var trafficController = {}
      var defaulttrafficController = {
        serBr: [],
        stanjeSignala: [],
        greske: [],
        statusnaRijec: [],
        brojGrupa: [],
        stanjeDetektora: [],
        ciklus: [],
        t1Communicating: false
      }

      const ws = $ws()

      this.$websocket = ws(`${this.$store.getters['app/TRAFFIC_CONTROLLER_WS_URI']}/t-1/stanje/`, null, {maxTimeout: 3000})
      this.$websocket.socket.binaryType = 'arraybuffer'

      this.$websocket.onOpen(() => {
        console.log('ws connected')
        this.$store.commit('t1/changeReadyState', this.$websocket.socket.readyState)
        let REMEMBER_ME = this.$store.getters['app/REMEMBER_ME']
        let PIN = this.$store.getters['app/PIN']
        if (REMEMBER_ME === true && PIN) {
          this.doAuth(PIN)
        } else {
          this.$store.commit('app/setAuthenticated', false)
        }
      })

      this.$websocket.onMessage((message) => {

        vrijemeZadnjePoruke = new Date().getTime()
        poruka = Array.from(new Uint8Array(message.data))

        for (i = 0; i < messageItems.length; i++) {
          duzina = poruka.shift()
          rezultat = []

          for (n = 0; n < duzina; n++) {
            rezultat.push(poruka.shift())
          }
          trafficController[messageItems[i]] = rezultat
        }

        trafficController['mco'] = null
        trafficController['program'] = null

        if (trafficController['stanjeSignala'] && trafficController['stanjeSignala'].length > 0) {
          trafficController['mco'] = trafficController['stanjeSignala'][trafficController['stanjeSignala'].length-1]
        }

        if (trafficController['stanjeSignala'] && trafficController['stanjeSignala'].length > 0) {
          trafficController['program'] = trafficController['ciklus'][12]
        }

        this.$store.commit('t1/set', trafficController)
      })

      this.$websocket.onError((error) => {
        console.error(error)
        this.$store.commit('t1/changeReadyState', this.$websocket.socket.readyState)
        this.$store.commit('t1/set', defaulttrafficController)
      })

      this.$websocket.onClose(() => {
        console.log('Closed!')
        this.$store.commit('t1/changeReadyState', this.$websocket.socket.readyState)
        this.$store.commit('t1/set', defaulttrafficController)
      })

      this.t1ResetStateWdogId = setInterval(() => {
        dif = (new Date().getTime() - vrijemeZadnjePoruke) / 1000
        if (dif >= 3) {
          // Poruka od uređaja nije stigla 3 sekunde, resetiraj stanje uređaja
          this.$store.commit('t1/set', defaulttrafficController)
        }
      }, 900)
    },
    t1KodoviGresaka (GRESKE) {
      if (!GRESKE) return []
      if (GRESKE.length === 3 && GRESKE[0] === 170 && GRESKE[1] === 3 && GRESKE[2] === 235) return []
      let duzina = null
      let greske = Array.from(GRESKE)
      let kodovi = []
      for (let i = 0; i < greske.length; i++) {
        duzina = greske.shift()
        let rezultat = []

        for (let n = 0; n < duzina; n++) {
          rezultat.push(greske.shift().toString(16).padStart(2, '0'))
        }

        kodovi.push(rezultat.join(''))
      }
      return kodovi
    },
    t1OpisGreske (kod) {
      if ('8000' === kod) {
        return this.$t('ui.greska_u_sustavu_za_detekciju')
      }

      let opis = ''
      // primjer: f140220
      // moramo dobiti f1 40 22 0
      // i pretvoriti to iz hex u int
      let _greska = this.$lodash.chunk(kod, 2)
      let greskaHex = []
      let greskaInt = []
      // let b = [1, 2, 4, 8, 16, 32, 64, 128]

      // Ovdje dobivamo hex prikaz, eg iz f1400220 u f1 40 02 20
      for (let i = 0; i < _greska.length; i++) {
        greskaHex.push(_greska[i].join(''))
      }

      // Pretvori iz hex u int
      for (let i = 0; i < greskaHex.length; i++) {
        greskaInt.push(parseInt(greskaHex[i], 16))
      }

      let porodicaModula = greskaInt[0] & 0xF0

      if (porodicaModula == 0xF0) {
        opis = opis + this.t1opisGreskeSIGa(greskaInt)
      } else if (porodicaModula == 0x70) {
        opis = opis + this.t1opisGreskeCPSa(greskaInt)
      } else if (porodicaModula == 0xA0) {
        opis = opis + this.t1opisGreskeDETa(greskaInt)
      } else if (porodicaModula == 0x60) {
        opis = opis + this.t1opisGreskeIOa(greskaInt)
      } else {
        if (greskaInt[0] == 0x81) {
          opis = opis + this.t1opisGreskeVirtualnogDetektora(greskaInt)
        }

      }

      return opis
    },
    t1opisGreskeVirtualnogDetektora (greska) {
      let opis = this.$t('ui.greska_pojedinog_virtualnog_detektora')

      let detektori = [
        greska[5] & 0b00000001,
        greska[5] & 0b00000010,
        greska[5] & 0b00000100,
        greska[5] & 0b00001000,
        greska[5] & 0b00010000,
        greska[5] & 0b00100000,
        greska[5] & 0b01000000,
        greska[5] & 0b10000000,

        greska[4] & 0b00000001,
        greska[4] & 0b00000010,
        greska[4] & 0b00000100,
        greska[4] & 0b00001000,
        greska[4] & 0b00010000,
        greska[4] & 0b00100000,
        greska[4] & 0b01000000,
        greska[4] & 0b10000000,

        greska[3] & 0b00000001,
        greska[3] & 0b00000010,
        greska[3] & 0b00000100,
        greska[3] & 0b00001000,
        greska[3] & 0b00010000,
        greska[3] & 0b00100000,
        greska[3] & 0b01000000,
        greska[3] & 0b10000000,

        greska[2] & 0b00000001,
        greska[2] & 0b00000010,
        greska[2] & 0b00000100,
        greska[2] & 0b00001000,
        greska[2] & 0b00010000,
        greska[2] & 0b00100000,
        greska[2] & 0b01000000,
        greska[2] & 0b10000000,

        greska[1] & 0b00000001,
        greska[1] & 0b00000010,
        greska[1] & 0b00000100,
        greska[1] & 0b00001000,
        greska[1] & 0b00010000,
        greska[1] & 0b00100000,
        greska[1] & 0b01000000,
        greska[1] & 0b10000000
      ]

      for (let i=0; i < detektori.length; i++) {
        if (detektori[i] > 0) {
          opis = `${opis}, D${i+1}`
        }
      }

      return opis
    },
    t1OpisGreskeVerifikacije (greska) {

      let brojSiga = (greska[0] & 0x0F) + 1
      let grupa = 0
      let opis = `, ${this.$t('ui.verifikacija')}`

      if (greska[3] & 0b00000011) {
        opis = `${opis}, ${this.$t('ui.crveno')} 1`
        if (greska[5] & 0b00000001) {
          opis = `${opis} - ${this.$t('ui.nema_signala')}`
        } else {
          opis = `${opis} - ${this.$t('ui.pojava_signala')}`
        }
      }

      if (greska[3] & 0b00000100) {
        opis = `${opis}, ${this.$t('ui.zuto')} 1`
        if (greska[5] & 0b00000010) {
          opis = `${opis} - ${this.$t('ui.nema_signala')}`
        } else {
          opis = `${opis} - ${this.$t('ui.pojava_signala')}`
        }
      }

      if (greska[3] & 0b00001000) {
        opis = `${opis}, ${this.$t('ui.zeleno')} 1`
        if (greska[5] & 0b00000100) {
          opis = `${opis} - ${this.$t('ui.nema_signala')}`
        } else {
          opis = `${opis} - ${this.$t('ui.pojava_signala')}`
        }
      }

      grupa = (brojSiga - 1) * 4 + 1
      if (greska[3] & 0b00000111) {
        opis = `${opis} (${this.$t('ui.grupa')} ${grupa})`
      }

      // --------------------

      grupa = (brojSiga - 1) * 4 + 2

      if (greska[3] & 0b00110000) {
        opis = `${opis}, ${this.$t('ui.crveno')} 2`
        if (greska[3] & 0b00000000) {
          opis = `${opis} (${this.$t('ui.pojava_signala')} ${grupa})`
        }
        if (greska[5] & 0b00001000) {
          opis = `${opis} - ${this.$t('ui.nema_signala')}`
        } else {
          opis = `${opis} - ${this.$t('ui.pojava_signala')}`
        }
      }

      if (greska[3] & 0b01000000) {
        opis = `${opis}, ${this.$t('ui.zuto')} 2`
        if (greska[3] & 0b00010000) {
          opis = `${opis} (${this.$t('ui.pojava_signala')} ${grupa})`
        }
        if (greska[5] & 0b00010000) {
          opis = `${opis} - ${this.$t('ui.nema_signala')}`
        } else {
          opis = `${opis} - ${this.$t('ui.pojava_signala')}`
        }
      }

      if (greska[3] & 0b10000000) {
        opis = `${opis}, ${this.$t('ui.zeleno')} 2`
        if (greska[3] & 0b00100000) {
          opis = `${opis} (${this.$t('ui.pojava_signala')} ${grupa})`
        }
        if (greska[5] & 0b00100000) {
          opis = `${opis} - ${this.$t('ui.nema_signala')}`
        } else {
          opis = `${opis} - ${this.$t('ui.pojava_signala')}`
        }
      }

      // --------------------
      grupa = (brojSiga - 1) * 4 + 3

      if (greska[2] & 0b00000011) {
        opis = `${opis}, ${this.$t('ui.crveno')} 3`
        if (greska[2] & 0b00000001) {
          opis = `${opis} (${this.$t('ui.pojava_signala')} ${grupa})`
        }
        if (greska[4] & 0b00000001) {
          opis = `${opis} - ${this.$t('ui.nema_signala')}`
        } else {
          opis = `${opis} - ${this.$t('ui.pojava_signala')}`
        }
      }

      if (greska[2] & 0b00000100) {
        opis = `${opis}, ${this.$t('ui.zuto')} 3`
        if (greska[2] & 0b00000010) {
          opis = `${opis} (${this.$t('ui.pojava_signala')} ${grupa})`
        }
        if (greska[4] & 0b00000010) {
          opis = `${opis} - ${this.$t('ui.nema_signala')}`
        } else {
          opis = `${opis} - ${this.$t('ui.pojava_signala')}`
        }
      }

      if (greska[2] & 0b00001000) {
        opis = `${opis}, ${this.$t('ui.zeleno')}  3`
        if (greska[2] & 0b00000100) {
          opis = `${opis} (${this.$t('ui.pojava_signala')} ${grupa})`
        }
        if (greska[4] & 0b00000100) {
          opis = `${opis} - ${this.$t('ui.nema_signala')}`
        } else {
          opis = `${opis} - ${this.$t('ui.pojava_signala')}`
        }
      }

      // --------------------
      grupa = (brojSiga - 1) * 4 + 4

      if (greska[2] & 0b00110000) {
        opis = `${opis}, ${this.$t('ui.crveno')} 4`
        if (greska[2] & 0b00001000) {
          opis = `${opis} (${this.$t('ui.pojava_signala')} ${grupa})`
        }
        if (greska[4] & 0b00001000) {
          opis = `${opis} - ${this.$t('ui.nema_signala')}`
        } else {
          opis = `${opis} - ${this.$t('ui.pojava_signala')}`
        }
      }

      if (greska[2] & 0b01000000) {
        opis = `${opis}, ${this.$t('ui.zuto')} 4`
        if (greska[2] & 0b00010000) {
          opis = `${opis} (${this.$t('ui.pojava_signala')} ${grupa})`
        }
        if (greska[4] & 0b00010000) {
          opis = `${opis} - ${this.$t('ui.nema_signala')}`
        } else {
          opis = `${opis} - ${this.$t('ui.pojava_signala')}`
        }
      }

      if (greska[2] & 0b10000000) {
        opis = `${opis}, ${this.$t('ui.zeleno')} 4`
        if (greska[2] & 0b00100000) {
          opis = `${opis} (${this.$t('ui.pojava_signala')} ${grupa})`
        }
        if (greska[4] & 0b00100000) {
          opis = `${opis} - ${this.$t('ui.nema_signala')}`
        } else {
          opis = `${opis} - ${this.$t('ui.pojava_signala')}`
        }
      }

      return opis
    },
    t1NedozvoljenaGreskaSnageOpis (greska) {
      let opis = `, ${this.$t('ui.nedozvoljena_greska_snage')}`
      let grupa = 0
      let brojSiga = (greska[0] & 0x0F) + 1
      if (greska[3] & 0b00000001) {
        opis = `${opis}, ${this.$t('ui.crveno')} 1`
      }
      if (greska[3] & 0b00000010) {
        opis = `${opis}, ${this.$t('ui.kontrolirano_crveno')} 1`
      }
      if (greska[3] & 0b00000100) {
        opis = `${opis}, ${this.$t('ui.zuto')} 1`
      }
      if (greska[3] & 0b00001000) {
        opis = `${opis}, ${this.$t('ui.zeleno')} 1`
      }
      grupa = (brojSiga - 1) * 4 + 1
      if (greska[3] & 0b00000001+0b00000010+0b00000100+0b00001000) {
        opis = `${opis} (${this.$t('ui.grupa')} ${grupa})`
      }

      if (greska[3] & 0b00010000) {
        opis = `${opis}, ${this.$t('ui.crveno')} 2`
      }
      if (greska[3] & 0b00100000) {
        opis = `${opis}, ${this.$t('ui.kontrolirano_crveno')} 2`
      }
      if (greska[3] & 0b01000000) {
        opis = `${opis}, ${this.$t('ui.zuto')} 2`
      }
      if (greska[3] & 0b10000000) {
        opis = `${opis}, ${this.$t('ui.zeleno')} 2`
      }

      grupa = (brojSiga - 1) * 4 + 2
      if (greska[3] & 0b00010000+0b00100000+0b01000000+0b10000000) {
        opis = `${opis} (${this.$t('ui.grupa')} ${grupa})`
      }

      if (greska[2] & 0b00000001) {
        opis = `${opis}, ${this.$t('ui.crveno')} 3`
      }
      if (greska[2] & 0b00000010) {
        opis = `${opis}, ${this.$t('ui.kontrolirano_crveno')} 3`
      }
      if (greska[2] & 0b00000100) {
        opis = `${opis}, ${this.$t('ui.zuto')} 3`
      }
      if (greska[2] & 0b00001000) {
        opis = `${opis}, ${this.$t('ui.zeleno')} 3`
      }
      grupa = (brojSiga - 1) * 4 + 3
      if (greska[2] & 0b00000001+0b00000010+0b00000100+0b00001000) {
        opis = `${opis} (${this.$t('ui.grupa')} ${grupa})`
      }

      if (greska[2] & 0b00010000) {
        opis = `${opis}, ${this.$t('ui.crveno')} 4`
      }
      if (greska[2] & 0b00100000) {
        opis = `${opis}, ${this.$t('ui.kontrolirano_crveno')} 4`
      }
      if (greska[2] & 0b01000000) {
        opis = `${opis}, ${this.$t('ui.zuto')} 4`
      }
      if (greska[2] & 0b10000000) {
        opis = `${opis}, ${this.$t('ui.zeleno')} 4`
      }

      grupa = (brojSiga - 1) * 4 + 4
      if (greska[2] & 0b00010000+0b00100000+0b01000000+0b10000000) {
        opis = `${opis} (${this.$t('ui.grupa')} ${grupa})`
      }

      return opis
    },
    t1DozvoljenaGreskaSnageOpis (greska) {
      let opis = `, ${this.$t('ui.dozvoljena_greska_snage')}`
      let grupa = 0
      let brojSiga = (greska[0] & 0x0F) + 1
      if (greska[3] & 0b00000001) {
        opis = `${opis}, ${this.$t('ui.crveno')} 1`
      }
      if (greska[3] & 0b00000010) {
        opis = `${opis}, ${this.$t('ui.kontrolirano_crveno')} 1`
      }
      if (greska[3] & 0b00000100) {
        opis = `${opis}, ${this.$t('ui.zuto')} 1`
      }
      if (greska[3] & 0b00001000) {
        opis = `${opis}, ${this.$t('ui.zeleno')} 1`
      }
      grupa = (brojSiga - 1) * 4 + 1

      if (greska[3] & 0b00000001+0b00000010+0b00000100+0b00001000) {
        opis = `${opis} (${this.$t('ui.grupa')} ${grupa})`
      }

      if (greska[3] & 0b00010000) {
        opis = `${opis}, ${this.$t('ui.crveno')} 2`
      }
      if (greska[3] & 0b00100000) {
        opis = `${opis}, ${this.$t('ui.kontrolirano_crveno')} 2`
      }
      if (greska[3] & 0b01000000) {
        opis = `${opis}, ${this.$t('ui.zuto')} 2`
      }
      if (greska[3] & 0b10000000) {
        opis = `${opis}, ${this.$t('ui.zeleno')} 2`
      }
      grupa = (brojSiga - 1) * 4 + 2
      if (greska[3] & 0b00010000+0b00100000+0b01000000+0b10000000) {
        opis = `${opis} (${this.$t('ui.grupa')} ${grupa})`
      }

      if (greska[2] & 0b00000001) {
        opis = `${opis}, ${this.$t('ui.crveno')} 3`
      }
      if (greska[2] & 0b00000010) {
        opis = `${opis}, ${this.$t('ui.kontrolirano_crveno')} 3`
      }
      if (greska[2] & 0b00000100) {
        opis = `${opis}, ${this.$t('ui.zuto')} 3`
      }
      if (greska[2] & 0b00001000) {
        opis = `${opis}, ${this.$t('ui.zeleno')} 3`
      }
      grupa = (brojSiga - 1) * 4 + 3
      if (greska[2] & 0b00000001+0b00000010+0b00000100+0b00001000) {
        opis = `${opis} (${this.$t('ui.grupa')} ${grupa})`
      }

      if (greska[2] & 0b00010000) {
        opis = `${opis}, ${this.$t('ui.crveno')} 4`
      }
      if (greska[2] & 0b00100000) {
        opis = `${opis}, ${this.$t('ui.kontrolirano_crveno')} 4`
      }
      if (greska[2] & 0b01000000) {
        opis = `${opis}, ${this.$t('ui.zuto')} 4`
      }
      if (greska[2] & 0b10000000) {
        opis = `${opis}, ${this.$t('ui.zeleno')} 4`
      }

      grupa = (brojSiga - 1) * 4 + 4
      if (greska[2] & 0b00010000+0b00100000+0b01000000+0b10000000) {
        opis = `${opis} (${this.$t('ui.grupa')} ${grupa})`
      }

      return opis
    },
    t1opisGreskeSIGa (greska) {
      let brojSiga = (greska[0] & 0x0F) + 1
      let opis = `${this.$t('ui.greska_siga_br')} ${brojSiga}`
      // [1, 2, 4, 8, 16, 32, 64, 128]

      if ((greska[1] & 0x08) == 0x08) {
        // greška verifikacije
        opis = opis + this.t1OpisGreskeVerifikacije(greska)
      }

      if ((greska[1] & 0x40) == 0x40) {
        // nedozvoljena greška snage
        opis = opis + this.t1NedozvoljenaGreskaSnageOpis(greska)
      }

      if ((greska[1] & 0x01) === 0x01) {
        // dozvoljena greška snage
        opis = opis + this.t1DozvoljenaGreskaSnageOpis(greska)
      }

      if (greska[1] == 0x10) {
        // Varijabilni GAIN nije podržan
        opis = `${opis} ${this.$t('ui.varijabilni_gain_nije_podrzan')}`
      }

      return opis
    },
    t1opisGreskeCPSa (greska) {
      let opis = ''

      if (greska[1] == 0x01) {
        opis = `${opis} ${this.$t('ui.napon_u1_je_van_tolerancije')}`
      }

      if (greska[1] == 0x02) {
        opis = `${opis} ${this.$t('ui.napon_u2_je_van_tolerancije')}`
      }

      if (greska[1] == 0x04) {
        opis = `${opis} ${this.$t('ui.nepodudarnost_kontrolnog_broja_cps_i_upp_modula')}`
      }

      if (greska[1] == 0x05) {
        opis = `${opis} ${this.$t('ui.neostvaren_zahtjev_signalne_grupe')} ${greska[2]} ${this.$t('ui.u_lokalnom_radu')}!`
      }

      if (greska[1] == 0x06) {
        opis = `${opis} ${this.$t('ui.neostvaren_zahtjev_signalne_grupe')} ${greska[2]} ${this.$t('ui.u_rezimu_centralnog_upravljanja')}!`
      }

      if (greska[1] == 0x07) {
        opis = `${opis} ${this.$t('ui.neispravna_kontrolna_suma_tablice_zaštitnih_vremena')}`
      }

      if (greska[1] == 0x08) {
        opis = `${opis} ${this.$t('ui.neispravan_slijed_signala_u_signalnoj_logici')}`
      }

      if (greska[1] == 0x09) {
        opis = `${opis} ${this.$t('ui.neispravna_kontrolna_suma_prometnih_podataka')}`
      }

      if (greska[1] == 0x0A) {
        opis = `${opis} ${this.$t('ui.neispravna_kontrolna_suma_boja_signala')}`
      }

      if (greska[1] == 0xFF) {
        opis = `${opis} ${this.$t('ui.aktivirana_je_sklopka_ssw')}`
      }

      return opis
    },
    t1opisGreskeDETa (greska) {
      var brojDeta = 0x0D - (greska[0] & 0x0F) + 1
      let opis = `${this.$t('ui.greska_det_br')} ${brojDeta}`

      if (greska[1] == 0x00) {
        opis = `${opis} ${this.$t('ui.modul_ne_odgovara_na_upite_trafbus_protokola')}`
      }

      if (greska[1] == 0x01) {
        opis = `${opis} ${this.$t('ui.greska_pojedinog_detektora')}`
        let b2 = this.$reverseString((greska[2]).toString(2).padStart(8, '0'))
        let b3 = this.$reverseString((greska[3]).toString(2).padStart(8, '0'))
        let b4 = this.$reverseString((greska[4]).toString(2).padStart(8, '0'))
        let b5 = this.$reverseString((greska[5]).toString(2).padStart(8, '0'))
        let y = [0,1,2,3,4,5,6,7]
        y.forEach((x) => {
          if (b2[x] == '1') {
            opis = `${opis} , [D${x+1}; `
            if (b4[x] == '1') {
              opis = opis +'f<<]'
            } else {
              opis = opis +'f=0]'
            }
          }

          if (b3[x] == '1') {
            opis = `${opis} , [D${x+9}; `
            if (b5[x] == '1') {
              opis = opis +'f<<]'
            } else {
              opis = opis +'f=0]'
            }
          }

        })
      }
      return opis
    },
    t1opisGreskeIOa (greska) {
      let brojIoModula = (greska[0] & 0x0F) + 1
      let opis = `${this.$t('ui.greska_io_modula_br')} ${brojIoModula}!`

      if (greska[1] == 0x00) {
        opis = `${opis} ${this.$t('ui.modul_ne_odgovara_na_upite_trafbus_protokola')}`
      }

      if (greska[1] == 0x05) {
        opis = `${opis} ${this.$t('ui.greska_linka_nema_odgovora')}`
      }

      if (greska[1] == 0x06) {
        opis = `${opis} ${this.$t('ui.greska_linka_nema_naredbe')}`
      }

      return opis
    },
    getNmcoPtpGeneralData () {
      return new Promise((resolve, reject) => {
        var promises = []

        promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x54, 1]))
        promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x54, 2]))
        promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x54, 3]))
        promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x54, 4]))
        promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x54, 5]))
        promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x54, 6]))
        promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x54, 7]))
        promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x54, 8]))
        promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x54, 9]))

        Promise.all(promises)
        .then(([NMCO_DATA_1, NMCO_DATA_2, NMCO_DATA_3, NMCO_DATA_4,
          NMCO_DATA_5, NMCO_DATA_6, NMCO_DATA_7, NMCO_DATA_8, NMCO_DATA_9]) => {

          let programi = []
          programi[0] = this.getNmcoProgramObject(0, NMCO_DATA_9)
          programi[1] = this.getNmcoProgramObject(1, NMCO_DATA_1)
          programi[2] = this.getNmcoProgramObject(2, NMCO_DATA_2)
          programi[3] = this.getNmcoProgramObject(3, NMCO_DATA_3)
          programi[4] = this.getNmcoProgramObject(4, NMCO_DATA_4)
          programi[5] = this.getNmcoProgramObject(5, NMCO_DATA_5)
          programi[6] = this.getNmcoProgramObject(6, NMCO_DATA_6)
          programi[7] = this.getNmcoProgramObject(7, NMCO_DATA_7)
          programi[8] = this.getNmcoProgramObject(8, NMCO_DATA_8)

          resolve(programi)
        })
        .catch((error) => {
          reject(error)
        })

      })
    },
    getNmcoProgramObject (ptp, NMCO_DATA) {
      return {
        'ptp':          ptp,
        'name':         null,
        'cycle':        NMCO_DATA[1],
        'offset':       NMCO_DATA[2],
        'ftc':          NMCO_DATA[3],
        'trz':          NMCO_DATA[4],
        'signals_off':  NMCO_DATA[5]
      }
    },
    getNmcoGrupaObject (ptp, grupa, NMCO_GRUPA_DATA) {
      return {
        'ptp':          ptp,
        'grupa':        grupa,
        'start':        NMCO_GRUPA_DATA[2],
        'stop':         NMCO_GRUPA_DATA[3],
        'zahtjev':      NMCO_GRUPA_DATA[4],
        'privilegija':  NMCO_GRUPA_DATA[5]
      }
    },
    getNmcoPtpGrupeData (BROJ_GRUPA) {

      return new Promise((resolve, reject) => {
        let nmcoGrupeData = []
        let ptps = [1,2,3,4,5,6,7,8,9]
        let promises = []

        for (let i = 0; i < ptps.length; i++) {
          let ptp = ptps[i]
          for (let grupaId = 1; grupaId <= 40; grupaId++) {
            nmcoGrupeData.push(this.getNmcoGrupaObject(ptp, grupaId, [255, 255, 255, 255, 255, 255]))
          }
        }

        for (let i = 0; i < ptps.length; i++) {
          let ptp = ptps[i]
          for (let grupaId = 1; grupaId <= BROJ_GRUPA; grupaId++) {
            let cmd = [this.t1.CPS, this.t1.READ, 0x53, ptp, grupaId]
            promises.push(this.t1Write(cmd))
          }
        }

        Promise.all(promises)
          .then((responses) => {
            for (let i = 0; i < ptps.length; i++) {
              let ptp = ptps[i]
              if (ptp === 9) {
                ptp = 0
              }
              for (let grupa = 1; grupa <= BROJ_GRUPA; grupa++) {
                let groupIndex = this.$lodash.findIndex(nmcoGrupeData, function (o) { return o.ptp === ptp && o.grupa === grupa })
                if (groupIndex > -1) {
                  nmcoGrupeData[groupIndex] = this.getNmcoGrupaObject(ptp, grupa, responses.shift())
                }
                //nmcoGrupeData.push(this.getNmcoGrupaObject(ptp, grupa, responses.shift()))
              }
            }
            resolve(nmcoGrupeData)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}
