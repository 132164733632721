<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">{{ $t('ui.edit_program_names') }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="isAuthenticated" @click="resetirajNazivePrograma(VPROG_CONFIG)" color="error" :disabled="saveDisabled">{{ $t('ui.reset_program_names') }}</v-btn>
      <v-btn v-if="isAuthenticated" @click="submit()" color="primary" :disabled="saveDisabled" class="ml-2">{{ $t('ui.save') }}</v-btn>
    </v-toolbar>
    <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>
    <v-card-text>
      <v-alert
          type="warning"
          v-if="RESET_WARNING"
        >
          {{this.$t('ui.reset_naziva_programa_warning')}}
      </v-alert>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <td>PTP</td>
              <td>{{ $t('ui.name') }}</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(naziv, index) in config.NAZIVI_PROGRAMA" :key="index">
              <td>{{index}}</td>
              <td><v-text-field v-model="config.NAZIVI_PROGRAMA[index]" :disabled="loading" ></v-text-field></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'
import ConfigMixin from '@/mixins/ConfigMixin'

export default {
  name: 't1NaziviProgramaCard',
  mixins: [ConfigMixin, T1Mixin, AuthMixin],
  data () {
    return {
      loading: false,
      odabraniProgram: null,
      VPROG_CONFIG: null,
      getT1DataId: null,
      VPROG_NOT_SUPPORTED: false,
      RESET_WARNING: false,
      configMeta: {
        NAZIVI_PROGRAMA: {
          type: Array
        },
        T1_RASPORED: {
          type: Array
        }
      },
      config: {
        NAZIVI_PROGRAMA: null,
        T1_RASPORED: null
      }
    }
  },
  computed: {
    saveDisabled () {
      if (this.loading) return true
      return false
    },
    podrzaniProgrami () {
      if (!this.VPROG_CONFIG) return []

      let programi = [
        {'id': 0, 'label': 'Treptanje'},
        {'id': 255, 'label': 'Automatski'}
      ]

      // Drugi bit oznacava koliko T-1 ima programa,
      // zadnji program je uvijek 'GASI SIGNALE'
      for (let i = 1; i < this.VPROG_CONFIG[1]+1; i++) {
        if (i === this.VPROG_CONFIG[1])  {
          programi.push({'id': i, 'label': this.$t('ui.ugasi_signale')})
        } else {
          programi.push({'id': i, 'label': `Program ${i}`})
        }
      }

      return programi
    }
  },
  methods: {
    init () {
      this.RESET_WARNING = false
      this.loading = true
      this.initConfigData()
        .then(this.t1getVprogConfig)
        .then((VPROG_CONFIG) => {
          this.VPROG_CONFIG = VPROG_CONFIG
          return this.provjeriNazivePrograma(VPROG_CONFIG)
        })
        .finally(() => {
          this.loading = false
        })
    },
    resetirajNazivePrograma (VPROG_CONFIG) {

      let kolikoPrograma = VPROG_CONFIG[1]

      this.config.NAZIVI_PROGRAMA = []
      let i = 0

      while (i <= kolikoPrograma) {

        if (i === 0 ) {
          this.config.NAZIVI_PROGRAMA.push('Treptanje')
        } else if (i === kolikoPrograma)  {
          this.config.NAZIVI_PROGRAMA.push('Ugasi signale')
        } else {
          this.config.NAZIVI_PROGRAMA.push(`Program ${i}`)
        }
        i += 1
      }

    },
    provjeriNazivePrograma (VPROG_CONFIG) {
      return new Promise((resolve) => {

        let kolikoPrograma = VPROG_CONFIG[1]

        if (!this.config.NAZIVI_PROGRAMA) {
          // Ako nema definiranih programa, stavi da su default nazivi!
          this.resetirajNazivePrograma(VPROG_CONFIG)
        } else if (this.config.NAZIVI_PROGRAMA.length-1 != kolikoPrograma) {
          // -1 jer je treptanje uvijek program 0
          // t-1 javlja koliko ima programa bez treptanja
          this.RESET_WARNING = true
        }

        resolve()
      })
    },
    submit () {
      this.loading = true
      let promises = []

      promises.push(this.createConfig({
        'key': 'NAZIVI_PROGRAMA',
        'value': this.config.NAZIVI_PROGRAMA
      }))

      for (let i = 0; i < this.config.NAZIVI_PROGRAMA.length; i++) {
        let noviNaziv = this.config.NAZIVI_PROGRAMA[i]

        for (let j = 0; j < this.config.T1_RASPORED.length; j++) {
          let raspored = this.config.T1_RASPORED[j]

          if (raspored.programId === i) {
            raspored.program = noviNaziv
          }
        }
      }

      promises.push(this.createConfig({
        'key': 'T1_RASPORED',
        'value': this.config.T1_RASPORED
      }))

      Promise.all(promises)
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .finally(() => {
          this.loading = false
          this.init()
        })

    }
  },
  created () {
    this.init()
  }
}
</script>
