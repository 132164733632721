<template>
  <v-card v-if="offseti">
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">{{ $t('ui.edit_offset') }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="setOffset()" color="primary" :disabled="loading">
        {{$t('ui.save')}}
      </v-btn>
    </v-toolbar>
    <v-progress-linear
      v-show="loading"
      color="primary"
      indeterminate
    ></v-progress-linear>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th>{{ $t('ui.program') }}</th>
            <th>{{ $t('ui.offset') }}</th>
            <th>{{ $t('ui.novi_offset') }}</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="offset in offseti" :key="offset.id">
              <td><strong>{{programi[offset.id] || offset.id}}</strong></td>
              <td><strong>{{offset.offset}}</strong></td>
              <td>
                <v-text-field v-model="offset.novi_offset" :disabled="loading"></v-text-field>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'

export default {
  name: 't1EditOffsetCard',
  mixins: [T1Mixin],
  props: ['programi'],
  data () {
    return {
      loading: false,
      offseti: null
    }
  },
  methods: {
    init () {
      this.getOffset()
    },
    getOffset () {
      this.loading = true
      this.t1Write([this.t1.CPS, this.t1.READ, 0x4b])
        .then((offset) => {

          if (offset.length === 3) {
            this.offseti = null
            return
          }

          this.offseti = [
            {
              id: 0,
              offset: offset[0],
              novi_offset: offset[0]
            },
            {
              id: 1,
              offset: offset[1],
              novi_offset: offset[1]
            },
            {
              id: 2,
              offset: offset[2],
              novi_offset: offset[2]
            },
            {
              id: 3,
              offset: offset[3],
              novi_offset: offset[3]
            },
            {
              id: 4,
              offset: offset[4],
              novi_offset: offset[4]
            },
            {
              id: 5,
              offset: offset[5],
              novi_offset: offset[5]
            },
            {
              id: 6,
              offset: offset[6],
              novi_offset: offset[6]
            },
            {
              id: 7,
              offset: offset[7],
              novi_offset: offset[7]
            },
            {
              id: 8,
              offset: offset[8],
              novi_offset: offset[8]
            }
          ]
        })
        .finally(() => {
          this.loading = false
        })
    },
    setOffset () {
      this.loading = true
      let message = [this.t1.CPS, this.t1.WRITE, 0x4b]
      let offset = null
      for (let i = 0; i < this.offseti.length; i++) {
        offset = this.offseti[i]
        message.push(parseInt(offset.novi_offset))
      }
      this.t1Write(message)
        .finally(() => {
          this.getOffset()
        })
    }
  },
  created () {
    this.init()
  }
}
</script>
