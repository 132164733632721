<template>
  <div v-if="trafficController" :style="styleObject">
    <v-progress-linear :active="loading" color="primary" :indeterminate="true"></v-progress-linear>
    <inline-svg
      :src="trafficController.svg_link"
      :transformSource="this.transformSvg"
      @loaded="svgLoaded($event)"
      @error="svgLoadError($event)"
    ></inline-svg>
  </div>
</template>

<script>
import $ from 'jquery'
import _ from 'lodash'

export default {
  name: 'TrafficControllerSvg',
  props: {
    trafficController: {
      type: Object,
      default: function () {
        return {}
      }
    },
    stanjeSignala: {
      type: Array,
      default: function () {
        return []
      }
    },
    stanjeDetektora: {
      type: Array,
      default: function () {
        return []
      }
    },
    selectedLayers: {
      type: Array,
      default: function () {
        return []
      }
    },
    detektorClickEnabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: true
    }
  },
  computed: {
    styleObject: function () {
      let color = '#424242'

      if (this.trafficController && this.trafficController.svg_background_color) {
        color = this.trafficController.svg_background_color
      }

      return {
        backgroundColor: color
      }

    }
  },
  methods: {
    pad: function (s, z) {
      s = '' + s
      return s.length < z ? this.pad('0' + s, z) : s
    },
    transformSvg: function (svg) {

      svg.setAttribute('width', '100%')
      svg.setAttribute('height', '100%')
      svg.style.maxHeight = '700px'

      return svg
    },
    svgLoaded: function () {

      this.loading = false

      var layers = $('[id^=LayerToShow]', this.$el)
      var updateLayers = []

      $.each(layers, function (i, v) {
        var $layer = $(v)
        var id = $layer.attr('id')
        var exploded = id.split('_')
        var label = ' '
        var _layer = {text: '', id: id, show: true}

        _.each(exploded, function (v, i) {
          if (i > 1) {
            label = label + v + ' '
            _layer['text'] = label
          }
        })

        if (exploded[1] === 'TRUE') {
          $layer.show()
          _layer['show'] = true
        } else {
          $layer.hide()
          _layer['show'] = false
        }
        updateLayers.push(_layer)
      })

      this.$emit('updateLayers', updateLayers)
      let self = this

      if (this.detektorClickEnabled) {
        let $detektori = $('g[id^="DETEKTOR"]', this.$el)
        let $detektor = null
        let detektorBroj = null

        for (let i = 0; i < $detektori.length; i++) {
          $detektor = $detektori[i]
          $($detektor).css({'cursor': 'pointer'})
          // Moram dodati fill, jer inace pointer ne funkcionira
          $($detektor).find(">:first-child").css({'fill': 'rgb(255 255 255 / 70%)'})
          $($detektor).click(function () {
            // Koji detektor je kliknut?
            detektorBroj = parseInt($(this).attr('id').replace( /^\D+/g, ''))
            self.$emit('detektorClicked', detektorBroj)
          })
        }
      }

    },
    svgLoadError: function () {
      this.loading = false
    }
  },
  watch: {
    stanjeSignala (grupe) {
      let $grupaEl

      if (!grupe || grupe.length === 0) {
        // Ako nema signala, makni postojece stanje signala
        $grupaEl = $('g[id^="GRUPA"]', this.$el)
        $grupaEl.show()
        $.each($grupaEl.children(), function (k, el) {
          var $el = $(el)

          $el.css({
            'fill': '',
            'stroke': '',
            'fill-opacity': '1',
            'transition': '0'
          })
        })
        return false
      }

      for (var i = grupe.length - 1; i >= 0; i--) {

        $grupaEl = $('#GRUPA' + this.pad(i + 1, 2), this.$el)
        var grupa = grupe[i]

        $grupaEl.show()
        $.each($grupaEl.children(), function (k, el) {
          var $el = $(el)

          $el.css({
            'fill': '',
            'stroke': '',
            'fill-opacity': '1',
            'transition': '0'
          })

          // zeleno
          if (grupa[2] === 1) {
            $el.css({
              'fill': 'green',
              'stroke': 'green',
              'fill-opacity': '1',
              'transition': '0.4s'
            })
          } else if (grupa[0] === 1 && grupa[1] === 1) {
            $el.css({
              'fill': 'yellow',
              'stroke': 'red',
              'fill-opacity': '1',
              'transition': '0.4s'
            })
          } else if (grupa[0] === 1) {
            $el.css({
              'fill': 'red',
              'stroke': 'red',
              'fill-opacity': '1',
              'transition': '0.4s'
            })
          } else if (grupa[1] === 1) {
            $el.css({
              'fill': 'yellow',
              'stroke': 'grey',
              'fill-opacity': '1',
              'transition': '0.4s'
            })
          }

        })
      }
    },
    stanjeDetektora (detektori) {
      if (!detektori) return

      for (var i = detektori.length - 1; i >= 0; i--) {
        var $detektor = $('#DETEKTOR' + this.pad(i + 1, 2), this.$el).children()

        $.each($detektor, function (k, el) {
          let $el = $(el)

          if (detektori[i]) {

            $el.css({
              'fill': '#0000FF',
              'fill-opacity': '0.3',
              'transition': '0.8s'
            })

          } else {
            $el.css({
              'fill-opacity': '0',
              'transition': '0.8s'
            })
          }

        })
      }
    },
    selectedLayers: {
      handler: function (_layers) {

        var layers = $('[id^=LayerToShow]', this.$el)
        $.each(layers, function (i, v) {
          var $layer = $(v)
          $layer.hide()
        })

        _.each(_layers, function (layer) {
          if (layer.show === true) {
            var layerId = '#' + layer.id
            $(layerId).show()
          }
        })
      },
      deep: true
    }
  }
}
</script>
