<template>
  <div>
    <v-row v-for="sig in sigovi" :key="sig.id">
      <v-col v-for="grupa in sig.grupe" :key="grupa.grupa" xs="12" md="2">
        <app-kutija-snage-editable-card :grupa-id="grupa.grupaId" :signali="grupa.signali" :snage="grupa.snage" :saved-snage="grupa.savedSnageFull"></app-kutija-snage-editable-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 't1NominalneVrijednostiSnagaFullTab',
  // mixins: [],
  props: ['sigovi'],
  data () {
    return {

    }
  }
}
</script>
