var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"text-xl-left"},[_vm._v(_vm._s(this.$t('ui.statistika')))])]),_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","icon":"","disabled":_vm.loading},on:{"click":function($event){return _vm.init()}}},[_c('v-icon',[_vm._v("refresh")])],1),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.availableActions),function(item,i){return _c('v-list-item',{key:i,attrs:{"link":""},on:{"click":function($event){return item.action()}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"color":"primary","indeterminate":""}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.statistika,"search":_vm.search,"items-per-page":_vm.limit,"hide-default-footer":"","item-key":"id"},scopedSlots:_vm._u([{key:"item.vrijeme",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.vrijeme,"DD.MM.YYYY HH:mm:ss"))+" ")]}},{key:"item.gpios_state",fn:function(ref){
var item = ref.item;
return [_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((item.gpios_state),function(gpio){return _c('tr',{key:gpio.id},[_c('td',[_vm._v(_vm._s(gpio.label))]),_c('td',[_vm._v(_vm._s(_vm._f("gpioStatusText")(gpio.status)))])])}),0)]},proxy:true}],null,true)})]}},{key:"item.greske",fn:function(ref){
var item = ref.item;
return _vm._l((item.greske),function(greska){return _c('ul',{key:greska.kod},[_c('li',[_vm._v(_vm._s(greska.opis))])])})}},{key:"item.napon",fn:function(ref){
var item = ref.item;
return [_c('ul',[(item.napon[1])?_c('li',[_vm._v(_vm._s(item.napon[1]))]):_vm._e(),(item.napon[2])?_c('li',[_vm._v(_vm._s(item.napon[2]))]):_vm._e()])]}},{key:"item.statusna_rijec",fn:function(ref){
var item = ref.item;
return [_c('ul',[(item.statusna_rijec.su)?_c('li',[_vm._v(_vm._s(_vm.$t('ui.signali_ukljuceni')))]):_vm._e(),(item.statusna_rijec.cu)?_c('li',[_vm._v(_vm._s(_vm.$t('ui.centralno_upravljanje')))]):_vm._e(),(item.statusna_rijec.vru)?_c('li',[_vm._v(_vm._s(_vm.$t('ui.vremensko_upravljanje')))]):_vm._e(),(item.statusna_rijec.ru)?_c('li',[_vm._v(_vm._s(_vm.$t('ui.rucno_upravljanje')))]):_vm._e(),(item.statusna_rijec.sc)?_c('li',[_vm._v(_vm._s(_vm.$t('ui.sve_crveno')))]):_vm._e(),(item.statusna_rijec.trz)?_c('li',[_vm._v(_vm._s(_vm.$t('ui.treptanje_zutog')))]):_vm._e(),(item.statusna_rijec.anf)?_c('li',[_vm._v(_vm._s(_vm.$t('ui.greska')))]):_vm._e()])]}}])}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":"25"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('app-confirm-action-dialog',{attrs:{"open-dialog":_vm.openConfirmDeleteStatistikaDialog,"headline":_vm.$t('ui.confirm_delete'),"text":_vm.$t('ui.really_delete_items')},on:{"userAction":_vm.deleteStatistikaDialogActionHandler}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }