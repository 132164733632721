<template>
  <v-container fluid grid-list-md>
    <v-row>
      <v-col>
        <v-alert
          type="error"
          v-if="NO_COMM"
        >
          GREŠKA U KOMUNIKACIJI
        </v-alert>
        <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>
      </v-col>
    </v-row>
    <v-row v-for="card in GONJEVA_SCADA_UI" :key="card.label">
      <v-col xs="12" md="6" v-for="uiItem in card.ui" :key="uiItem.label">
        <v-card>
          <v-toolbar flat dense>
            <v-toolbar-title>
              <span class="text-xl-left">{{ uiItem.label }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col v-for="item in uiItem.items" :key="item.label" :class="item.size">
                <v-btn
                  v-if="item.type=='gpio_on_off'"
                  :disabled="loading || NO_COMM"
                  :class="item.class"
                  :color="item.color"
                  @click="gpioToggle({
                    'ip': card.ip,
                    'gpio': item.gpio,
                    'timeout': item.timeout || 4
                  })">
                  {{ item.label }}
                </v-btn>
                <v-btn
                  v-if="item.type=='sample'"
                  :class="item.class"
                  :color="item.color"
                  :disabled="NO_COMM"
                  tile
                  block
                  x-large
                >
                  {{ item.label }}
                </v-btn>
                <v-spacer v-if="item.type=='spacer'"></v-spacer>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import RedisMixin from '@/mixins/RedisMixin'
import ConfigMixin from '@/mixins/ConfigMixin'

export default {
  name: 'GonjevaScadaPage',
  mixins: [ConfigMixin, RedisMixin],
  data () {
    return {
      loading: true,
      waiting: false,
      NO_COMM: false,
      GONJEVA_SCADA_STATE: {},
      GONJEVA_SCADA_UI: [],
      config: {
        GONJEVA_SCADA_UI: null
      }
    }
  },
  methods: {
    init () {
      this.initConfigData()
        .then(() => {
          this.GONJEVA_SCADA_UI = JSON.parse(this.config.GONJEVA_SCADA_UI)
        })
        .finally(() => {
          this.loading = false
        })
    },
    gpioToggle (config) {
      console.log(config)
      this.loading = true
      this.setRedisKeyValue({
        'GONJEVA_TOGGLE_GPIO': JSON.stringify(config)
      })
      .finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 2000)
      })
    },
    getStateData () {
      this.getRedisKey('GONJEVA_SCADA_STATE')
        .then((response) => {
          let NOW = (new Date()).getTime() / 1000
          //this.GONJEVA_SCADA_STATE = JSON.parse(response.data.value)
          let data = JSON.parse(response.data.value)
          this.GONJEVA_SCADA_STATE = []

          for (let i = 0; i < data.state.length; i++) {
            let item = data.state[i]
            let state = JSON.parse(item.state)
            this.GONJEVA_SCADA_STATE.push({
              'ip': item.ip,
              'gpios': state.gpios
            })
          }

          if (!data || !data.timestamp) {
            this.NO_COMM = true
          } else if (NOW - data.timestamp > 10) {
            this.NO_COMM = true
          } else {
            this.NO_COMM = false
          }

          for (let i = 0; i < this.GONJEVA_SCADA_UI.length; i++) {
            let location = this.GONJEVA_SCADA_UI[i]
            let locationIp = location.ip

            for (let j = 0; j < location.ui.length; j++) {
              let ui = location.ui[j]

              for (let k = 0; k < ui.items.length; k++) {
                let item = ui.items[k]

                if (item.type === 'sample') {

                  let gpioStatePerIp = this.$lodash.find(this.GONJEVA_SCADA_STATE, (y) => {
                    if (y.ip === locationIp) {
                      return y
                    }

                    return null
                  })

                  if (!gpioStatePerIp) {
                    return null
                  }

                  let gpioState = this.$lodash.find(gpioStatePerIp.gpios, (x) => {
                    if (x.gpio === item.gpio) {
                      return x
                    }

                    return null
                  })

                  if (gpioState !== null && gpioState.state === item.active_state) {
                    item.color = item.active_color
                  } else {
                    item.color = item.inactive_color
                  }

                }

              }
            }
          }

          let GONJEVA_SCADA_UI = JSON.parse(JSON.stringify(this.GONJEVA_SCADA_UI))
          this.GONJEVA_SCADA_UI = []
          this.GONJEVA_SCADA_UI = GONJEVA_SCADA_UI
        })
    }
  },
  created () {
    this.init()
    this.getStateDataId = setInterval(this.getStateData, 900)
  },
  destroyed () {
    if (this.getStateDataId) {
      clearInterval(this.getStateDataId)
    }
  }
}
</script>
