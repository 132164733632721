<template>
  <v-container fluid grid-list-sm>
    <v-row dense>
      <!-- TLOCRT -->
      <v-col xs="12" :lg="tlocrtLargeDimm">
        <v-card>
          <traffic-controller-svg
            v-if="config.DEVICE_CODE"
            :traffic-controller="trafficController"
            :stanje-signala="stanjeSignala"
            :stanje-detektora="stanjeDetektora"
          >
          </traffic-controller-svg>
        </v-card>
      </v-col>
      <!-- /TLOCRT -->

    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'
import ConfigMixin from '@/mixins/ConfigMixin'

export default {
  name: 'FlowTopViewPage',
  mixins: [validationMixin, ConfigMixin, T1Mixin, AuthMixin],
  data () {
    return {
      loading: false,
      colorLoading: false,
      openDeleteDialog: false,
      file: false,
      showToolbar: true,
      config: {
        DEVICE_CODE: null,
        DEVICE_TYPE: null,
        TLOCRT: null,
        TLOCRT_COLOR: '#fff',
        CAMERAS_CONFIG: []
      },
      configMeta: {
        CAMERAS_CONFIG: {
          type: Array
        }
      },
      layers: [],
      colors: [
        { name: 'Tamna', value: '#424242' },
        { name: 'Bijela', value: '#fff' }
      ],
      t: Date.now()
    }
  },
  computed: {
    trafficController () {
      return {
        code: this.config.DEVICE_CODE,
        type: this.config.DEVICE_TYPE,
        svg_link: `${this.$store.getters['app/APP_API_URI']}/tlocrt/?t=${this.t}`,
        svg_background_color: this.config.TLOCRT_COLOR
      }
    },
    statusnaRijec () {
      return this.$store.getters['t1/statusnaRijec']
    },
    stanjeSignala () {
      return this.$store.getters['t1/stanjeSignala']
    },
    stanjeDetektora () {
      return this.$store.getters['t1/stanjeDetektora']
    },
    tlocrtLargeDimm () {
      if (this.config.CAMERAS_CONFIG.length > 0) return 6

      return 12
    }
  },
  methods: {
    init () {
      this.config.DEVICE_CODE = null
      this.config.TLOCRT = null
      this.t = Date.now()
      this.initConfigData()
    },
    openConfirmDeleteDialog () {
      this.openDeleteDialog = true
    },
    confirmDeleteLayoutActionHandler (actionResult) {
      this.openDeleteDialog = false

      if (actionResult === true) {
        this.config.TLOCRT = ''
        this.submit()
      }
    },
    selectSVGToUpload () {
      this.t = Date.now()
      this.$refs['upload_svg'].click()
    },
    async handleSVGUpload () {
      const file = this.$refs.upload_svg.files[0]
      if (!file) return
      this.config.TLOCRT = await file.text()
      this.submit()
    },
    submit () {
      this.loading = true
      var promises = []

      promises.push(this.createConfig({
        'key': 'TLOCRT',
        'value': this.config.TLOCRT
      }))

      promises.push(this.createConfig({
        'key': 'TLOCRT_COLOR',
        'value': this.config.TLOCRT_COLOR
      }))

      Promise.all(promises)
        .then(() => {

          // svi upiti su gotovi
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })

        })
        .finally(() => {
          this.t = Date.now()
          this.jQuery('#upload_svg_input').val(null)
          this.loading = false
          this.init()
        })
    },
    saveColor (color) {
      this.loading = true

      this.createConfig({
        'key': 'TLOCRT_COLOR',
        'value': color
      })
        .then(() => {

          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })

        })
        .catch(() => {

          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'error',
            text: this.$t('ui.save_error')
          })

        })
        .finally(() => {
          this.loading = false
          this.init()
        })

    }
  },
  created () {
    this.init()
  }
}
</script>
