<template>

    <v-simple-table light dense class="postavke-snaga-signala">
      <template v-slot:default>
        <thead>
          <tr>
            <th style="width: 1%; border-bottom: none;" colspan="2"><span class="text-lg-h8">GRUPA/SIGNAL</span></th>
            <th style="width: 15%">
              <span>Izmjereno</span>
            </th>
            <th style="width: 15%">
              <span>100%</span>
            </th>
            <th style="width: 15%">
              <span>Dimming</span>
            </th>
            <th style="width: 15%">
              <span>Tolerancija 1</span>
            </th>
            <th style="width: 15%">
              <span>Tolerancija 2</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowspan="6" style="background-color: #BDBDBD;">
              <span class="text-lg-h4">{{grupaId}}</span>
            </td>
            <td style="background-color: #F62817"><span class="text-lg-h6">C</span></td>
            <td v-bind:style="{ background: CRVENO_BOJA }">{{snageSignala.c}}</td>
            <td><v-btn icon class="mx-0" @click="copyValue(snageSignala.c, 0, savedSnageFull)"><v-icon small>{{COPY_MJERENJE_ICON}}</v-icon></v-btn><input type="text" class="clickable" v-model="savedSnageFull[0]"/></td>
            <td><v-btn icon class="mx-0" @click="copyValue(snageSignala.c, 0, savedSnageDimm)"><v-icon small>{{COPY_MJERENJE_ICON}}</v-icon></v-btn><input type="text" class="clickable" v-model="savedSnageDimm[0]"/></td>
            <td>
              <input type="text" v-model="prag1[0]" class="clickable" :class="{ 'istakni-jako': ISTAKNI_PRAG_1_C }" v-on:keydown.tab.capture.prevent.stop="nextTab" /> %
            </td>
            <td>
              <input type="text" v-model="prag2[0]" class="clickable" :class="{ 'istakni-blago': ISTAKNI_PRAG_2_C }" /> %
            </td>
          </tr>
          <tr>
            <td style="background-color: #990012"><span class="text-lg-h6">CK</span></td>
            <td v-bind:style="{ background: KONTROLIRANO_CRVENO_BOJA }">{{snageSignala.ck}}</td>
            <td><v-btn icon class="mx-0" @click="copyValue(snageSignala.ck, 1, savedSnageFull)"><v-icon small>{{COPY_MJERENJE_ICON}}</v-icon></v-btn><input type="text" class="clickable" v-model="savedSnageFull[1]"/></td>
            <td><v-btn icon class="mx-0" @click="copyValue(snageSignala.ck, 1, savedSnageDimm)"><v-icon small>{{COPY_MJERENJE_ICON}}</v-icon></v-btn><input type="text" class="clickable" v-model="savedSnageDimm[1]"/></td>
            <td>
              <input type="text" v-model="prag1[1]" class="clickable" :class="{ 'istakni-jako': ISTAKNI_PRAG_1_CK }" /> %
            </td>
            <td>
              <input type="text" v-model="prag2[1]" class="clickable" :class="{ 'istakni-blago': ISTAKNI_PRAG_2_CK }" /> %
            </td>
          </tr>
          <tr>
            <td style="background-color: #ffe371"><span class="text-lg-h6">Ž</span></td>
            <td v-bind:style="{ background: ZUTO_BOJA }">{{snageSignala.ž}}</td>
            <td><v-btn icon class="mx-0" @click="copyValue(snageSignala.ž, 2, savedSnageFull)"><v-icon small>{{COPY_MJERENJE_ICON}}</v-icon></v-btn><input type="text" class="clickable" v-model="savedSnageFull[2]"/></td>
            <td><v-btn icon class="mx-0" @click="copyValue(snageSignala.ž, 2, savedSnageDimm)"><v-icon small>{{COPY_MJERENJE_ICON}}</v-icon></v-btn><input type="text" class="clickable" v-model="savedSnageDimm[2]"/></td>
            <td>
              <input type="text" v-model="prag1[2]" class="clickable" :class="{ 'istakni-jako': ISTAKNI_PRAG_1_Ž }" /> %
            </td>
            <td>
              <input type="text" v-model="prag2[2]" class="clickable" :class="{ 'istakni-blago': ISTAKNI_PRAG_2_Ž }" /> %
            </td>
          </tr>
          <tr>
            <td style="background-color: #8FC33A"><span class="text-lg-h6">Z</span></td>
            <td v-bind:style="{ background: ZELENO_BOJA }">{{snageSignala.z}}</td>
            <td><v-btn icon class="mx-0" @click="copyValue(snageSignala.z, 3, savedSnageFull)"><v-icon small>{{COPY_MJERENJE_ICON}}</v-icon></v-btn><input type="text" class="clickable" v-model="savedSnageFull[3]"/></td>
            <td><v-btn icon class="mx-0" @click="copyValue(snageSignala.z, 3, savedSnageDimm)"><v-icon small>{{COPY_MJERENJE_ICON}}</v-icon></v-btn><input type="text" class="clickable" v-model="savedSnageDimm[3]"/></td>
            <td>
              <input type="text" v-model="prag1[3]" class="clickable" :class="{ 'istakni-jako': ISTAKNI_PRAG_1_Z }" /> %
            </td>
            <td>
              <input type="text" v-model="prag2[3]" class="clickable" :class="{ 'istakni-blago': ISTAKNI_PRAG_2_Z }" /> %
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, between } from 'vuelidate/lib/validators'

export default {
  name: 'app-t1-postavke-snage-signala-grupe',
  props: ['grupaId', 'signali', 'snage', 'savedSnageFull', 'savedSnageDimm', 'prag1', 'prag2'],
  mixins: [validationMixin],
  data () {
    return {
      AKTIVNO_CRVENO_BOJA: '#F62817',
      AKTIVNO_KONTROLIRANO_CRVENO_BOJA: '#990012',
      AKTIVNO_ZUTO_BOJA: '#ffe371',
      AKTIVNO_ZELENO_BOJA: '#8FC33A',
      NIJE_AKTIVNO_BOJA: '#FFFFFF',
      JAKI_BORDER_COLOR: '#5E35B1',
      BLAZI_BORDER_COLOR: '#E1BEE7'
    }
  },
  validations: {
    nominalnaVrijednost: {
      required,
      between: between(0, 255)
    },
    prag: {
      required,
      between: between(0, 100)
    }
  },
  computed: {
    CRVENO_BOJA () {
      if (this.signali.length > 0 && this.signali[0] === 1) return this.AKTIVNO_CRVENO_BOJA
      return this.NIJE_AKTIVNO_BOJA
    },
    KONTROLIRANO_CRVENO_BOJA () {
      if (this.signali.length > 0 && this.signali[0] === 1) return this.AKTIVNO_KONTROLIRANO_CRVENO_BOJA
      return this.NIJE_AKTIVNO_BOJA
    },
    ZUTO_BOJA () {
      if (this.signali.length > 0 && this.signali[1] === 1) return this.AKTIVNO_ZUTO_BOJA
      return this.NIJE_AKTIVNO_BOJA
    },
    ZELENO_BOJA () {
      if (this.signali.length > 0 && this.signali[2] === 1) return this.AKTIVNO_ZELENO_BOJA
      return this.NIJE_AKTIVNO_BOJA
    },
    snageSignala () {
      if (!this.snage) {
        return {
          'c': 0,
          'ck': 0,
          'ž': 0,
          'z': 0
        }
      }

      return {
        'c': this.snage[1],
        'ck': this.snage[0],
        'ž': this.snage[2],
        'z': this.snage[3]
      }
    },
    COPY_MJERENJE_ICON () {
      return 'last_page'
    },
    ISTAKNI_PRAG_1_C () {
      if (this.prag1 && this.prag1.length > 0) {
        if (parseInt(this.prag1[0]) < 100) return true
      }

      return false
    },
    ISTAKNI_PRAG_1_CK () {
      if (this.prag1 && this.prag1.length > 0) {
        if (parseInt(this.prag1[1]) < 100) return true
      }
      return false
    },
    ISTAKNI_PRAG_1_Ž () {
      if (this.prag1 && this.prag1.length > 0) {
        if (parseInt(this.prag1[2]) < 100) return true
      }
      return false
    },
    ISTAKNI_PRAG_1_Z () {
      if (this.prag1 && this.prag1.length > 0) {
        if (parseInt(this.prag1[3]) < 100) return true
      }
      return false
    },
    ISTAKNI_PRAG_2_C () {
      if (this.prag2 && this.prag2.length > 0) {
        if (parseInt(this.prag2[0]) < 100) return true
      }
      return false
    },
    ISTAKNI_PRAG_2_CK () {
      if (this.prag2 && this.prag2.length > 0) {
        if (parseInt(this.prag2[1]) < 100) return true
      }
      return false
    },
    ISTAKNI_PRAG_2_Ž () {
      if (this.prag2 && this.prag2.length > 0) {
        if (parseInt(this.prag2[2]) < 100) return true
      }
      return false
    },
    ISTAKNI_PRAG_2_Z () {
      if (this.prag2 && this.prag2.length > 0) {
        if (parseInt(this.prag2[3]) < 100) return true
      }
      return false
    }
  },
  methods: {
    copyValue (from, index, list) {
      list[index] = from
    }
  }
}
</script>

<style scoped>
.postavke-snaga-signala th {
  background-color: #BDBDBD;
}

.postavke-snaga-signala input {
  text-align: center;
  width: 50%;
}

.postavke-snaga-signala th {
  text-align: center !important;
}

.postavke-snaga-signala td {
  text-align: center;
}

.istakni-jako {
  border: 3px solid #5E35B1;
  font-weight: 900;
}

.istakni-blago {
  border: 3px solid #E1BEE7;
  font-weight: 900;
}
</style>
