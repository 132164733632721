<template>
  <v-container fluid grid-list-md>

    <v-row>
      <v-col xs="12">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ this.$t('ui.dozvoljeno_odstupanje_prag_2') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="isAuthenticated" @click="submit" color="primary" :disabled="saveDisabled">{{ this.$t('ui.save') }}</v-btn>
          </v-toolbar>
          <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col xs="12" md="2">
        <v-combobox
          v-model="selectedSigs"
          :items="sigoviZaOdabrati"
          label="SIG"
          item-text="name"
          item-value="id"
          multiple
        ></v-combobox>
      </v-col>
      <v-col v-if="isAuthenticated">
        <v-btn-toggle>
          <v-btn @click="postaviSveNa(100)" color="primary" :disabled="saveDisabled">100%</v-btn>
          <v-btn @click="postaviSveNa(0)" color="primary" :disabled="saveDisabled">0%</v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <v-row v-for="sig in sigovi" :key="sig.id">
      <v-col v-for="grupa in sig.grupe" :key="grupa.grupa" xs="12" md="2">
        <app-kutija-snage-editable-card
          :grupa-id="grupa.grupaId"
          :signali="grupa.signali"
          :snage="grupa.snage"
          :saved-snage="grupa.saved"
          unit="%">
        </app-kutija-snage-editable-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import ConfigMixin from '@/mixins/ConfigMixin'
import AuthMixin from '@/mixins/AuthMixin'
import T1Mixin from '@/mixins/T1Mixin'

export default {
  name: 't1DozvoljenoOdstupanjeSnagaPrag2Page',
  mixins: [validationMixin, ConfigMixin, T1Mixin, AuthMixin],
  data () {
    return {
      loading: false,
      brojGrupa: 0,
      greske: [],
      selectedSigs: [],
      snageSignala: [],
      gain: [],
      grupeGain: [],
      defaultGain: [],
      dozvoljenoOdstupanje: [],
      getSnageGrupaZaSigId: null,
      config: {
        'T1_DOZVOLJENO_ODSTUPANJE_PRAG_2': null
      },
      configMeta: {
        T1_DOZVOLJENO_ODSTUPANJE_PRAG_2: {
          type: Array
        }
      }
    }
  },
  computed: {
    saveDisabled () {
      if (this.loading) return true
      return false
    },
    brojSigova () {
      return Math.ceil(this.brojGrupa/4)
    },
    sigoviZaOdabrati () {
      let s = []
      for (let i = 0; i < this.brojSigova; i++) {
        s.push({id: i, name: `SIG ${i+1}`})
      }

      return s
    },
    sigovi () {
      let s = []
      let odabraniSigovi = []
      let sig = {}
      let grupe = []
      let g = null
      let saved = []

      for (let g = 1; g <= this.brojGrupa; g++) {
        if (typeof this.dozvoljenoOdstupanje[g-1] === 'undefined') {
          saved = []
        } else {
          saved = this.dozvoljenoOdstupanje[g-1]
        }
        grupe.push({'grupaId': g, snage: this.snageSignala[g-1], signali: [1,1,1,1], saved: saved})
      }

      for (let i = 0; i < this.brojSigova; i++) {
        sig = {id: i, name: `SIG ${i+1}`, grupe: []}
        for (let n = 1; n <=4; n++ ) {
          g = grupe.shift()
          if (g) {
            sig.grupe.push(g)
          }
        }
        s.push(sig)
      }

      for (let i = 0; i < s.length; i++) {
        for (let n = 0; n < this.selectedSigs.length; n++) {
          if (this.selectedSigs[n].id === s[i].id) {
            odabraniSigovi.push(s[i])
          }
        }
      }

      return odabraniSigovi
    },
    stanjeSignala () {
      return this.$store.getters['t1/stanjeSignala']
    }
  },
  methods: {
    init () {
      this.loading = true

      this.initT1Data()
      .then(this.initConfigData)
      .then(() => {
        this.dozvoljenoOdstupanje = this.config.T1_DOZVOLJENO_ODSTUPANJE_PRAG_2

        if (!this.dozvoljenoOdstupanje) {
          this.dozvoljenoOdstupanje = []
          for (let i = 0; i < this.brojGrupa*4; i++) {
            this.dozvoljenoOdstupanje.push(0)
          }
          this.dozvoljenoOdstupanje = this.$lodash.chunk(this.dozvoljenoOdstupanje, 4)
        } else if (this.dozvoljenoOdstupanje.length < this.brojSigova*4) {
          while (this.dozvoljenoOdstupanje.length < this.brojSigova*4) {
            this.dozvoljenoOdstupanje.push([0,0,0,0])
          }
        }
      })
      .finally(() => {
        this.loading = false
      })
    },
    initT1Data () {
      return new Promise((resolve, reject) => {
        var promises = []

        promises.push(this.t1Write([this.t1.CPS, this.t1.READ, this.t1.BROJ_GRUPA]))

        Promise.all(promises)
          .then(([BROJ_GRUPA]) => {
            this.brojGrupa = BROJ_GRUPA[0]
            if (this.brojGrupa) {
              this.selectedSigs = this.sigoviZaOdabrati
            }
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getSnageSignala () {
      let promises = []
      for (let sig = 0; sig < this.brojSigova; sig++) {
        promises.push(this.t1Write([this.t1.SIG(sig), this.t1.READ, this.t1.SIG_SNAGE]))
      }

      Promise.all(promises)
        .then((answers) => {

          this.snageSignala = []

          for (let sig = 0; sig < this.brojSigova; sig++) {
            let c = this.$lodash.chunk(answers[sig], 4)
            let kcrv = c[0]
            let crv = c[1]
            let zut = c[2]
            let zel = c[3]

            this.snageSignala.push([kcrv[0], crv[0], zut[0], zel[0]])
            this.snageSignala.push([kcrv[1], crv[1], zut[1], zel[1]])
            this.snageSignala.push([kcrv[2], crv[2], zut[2], zel[2]])
            this.snageSignala.push([kcrv[3], crv[3], zut[3], zel[3]])
          }

        })
    },
    getDozvoljenoOdstupanje () {
      this.loading = true
      this.t1Write([this.t1.CPS, this.t1.READ, 0x1e])
        .then((answer) => {
          this.dozvoljenoOdstupanje = this.$lodash.chunk(answer, 4)
        })
        .finally(() => {
          this.loading = false
        })
    },
    postaviSveNa (vrijednost) {
      for (let i = 0; i < this.dozvoljenoOdstupanje.length; i++) {
        this.dozvoljenoOdstupanje[i] = [vrijednost, vrijednost, vrijednost, vrijednost]
      }
    },
    submit () {
      this.loading = true

      this.createConfig({
        'key': 'T1_DOZVOLJENO_ODSTUPANJE_PRAG_2',
        'value': this.dozvoljenoOdstupanje
      })
      .then(() => {
        this.$EventBus.$emit('AppSnackbar/show', {
          color: 'success',
          text: this.$t('ui.save_success')
        })
      })
      .catch(() => {
        this.$EventBus.$emit('AppSnackbar/show', {
          color: 'error',
          text: this.$t('ui.save_error')
        })
      })
      .finally(() => {
        this.init()
      })

    }
  },
  created () {
    this.init()
    this.getSnageSignalaId = setInterval(this.getSnageSignala, 900)
  },
  destroyed () {
    if (this.getSnageSignalaId) {
      clearInterval(this.getSnageSignalaId)
    }
  }
}
</script>
