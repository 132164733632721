<template>
  <v-dialog v-model="openDialog" persistent max-width="600px">
    <v-card>
      <v-card-title class="headline" primary-title>{{headline}}</v-card-title>

      <v-card-text>
        <v-alert
          type="error"
          v-if="wrongPin"
        >
          {{this.$t('ui.wrong_pin_entered')}}
        </v-alert>

        <v-text-field
          v-model="PIN"
          label="PIN"
          :autofocus="autofocus"
          v-on:keyup.enter="submit"
        ></v-text-field>

        <v-checkbox
          v-model="REMEMBER_ME"
          :label="$t('ui.remember_me')"
        ></v-checkbox>

        <v-btn @click="zaboraviMe" class="red">
          {{this.$t('ui.forget_me')}}
        </v-btn>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="userAction('cancel')">
          {{this.$t('ui.cancel')}}
        </v-btn>

        <v-btn
          :loading="loading"
          :disabled="loading"
          color="green darken-1"
          @click="submit()"
        >
          {{this.$t('ui.send')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'

export default {
  name: 'AppAuthDialog',
  mixins: [T1Mixin],
  props: ['openDialog', 'headline', 'config'],
  data () {
    return {
      PIN: '',
      autofocus: true,
      loading: false,
      wrongPin: false
    }
  },
  computed: {
    REMEMBER_ME: {
      get () {
        return this.$store.getters['app/REMEMBER_ME']
      },
      set (newValue) {
        this.$store.commit('app/setLocal', {REMEMBER_ME: newValue})
      }
    }
  },
  methods: {
    userAction: function (actionResult) {
      this.PIN = ''
      this.$emit('userAction', actionResult)
    },
    zaboraviMe () {
      this.$store.commit('app/setLocal', {REMEMBER_ME: false, PIN: null})
      this.doAuth(null)
        .then(() => {
          this.$emit('userAction', 'cancel')
        })
    },
    submit: function () {
      this.loading = true

      if (this.REMEMBER_ME) {
        this.$store.commit('app/setLocal', {REMEMBER_ME: true, PIN: this.PIN})
      } else {
        this.$store.commit('app/setLocal', {REMEMBER_ME: false, PIN: null})
      }

      this.doAuth(this.PIN)
        .then((pinOK) => {
          if (pinOK === true) {
            this.wrongPin = false
            this.PIN = ''
            this.$emit('userAction', 'cancel')
          } else {
            this.wrongPin = true
          }
        })
        .catch(() => {

        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
