<template>
  <v-app id="app-side-nav" dark>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'app'
}
</script>
