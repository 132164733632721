<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">{{ $t('ui.upload_programa') }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="promjeniAktivniProgram()" color="primary" :disabled="saveDisabled">{{ $t('ui.save') }}</v-btn>
    </v-toolbar>
    <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>
    <v-card-text>
      <v-col xs="12">
        <v-combobox
          v-model="COM_PORT"
          :items="COM_PORTS"
          label="COM PORT"
        ></v-combobox>
      </v-col>

      <v-col xs="12">
        <v-file-input
          v-model="uploadProgramFile"
          chips
          show-size
          counter
          truncate-length="255"
          accept=".bin"
        ></v-file-input>
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'

export default {
  name: 't1UploadProgramaCard',
  mixins: [T1Mixin],
  data () {
    return {
      loading: false,
      COM_PORTS: [],
      COM_PORT: null,
      uploadProgramFile: null
    }
  },
  computed: {
    saveDisabled () {
      if (this.loading) return true
      if (!this.uploadProgramFile) return true
      return false
    }
  },
  methods: {
    dohvatiComPort () {
      this.loading = true

      this.$api.get('/com-ports/', {
        baseURL: this.$store.getters['app/APP_API_URI'],
        headers: {
          'Content-Type': 'application/trafconnect'
        }
      })
      .then((response) => {
          this.COM_PORTS = response.data.com_ports
          if (this.COM_PORTS.length === 1) {
            this.COM_PORT = this.COM_PORTS[0]
          }
      })
      .finally(() => {
        this.loading = false
      })
    },
    uploadProgram (hashHex) {
      let formData = new FormData()
      formData.append(`files`, this.uploadProgramFile)

      this.$api.post(`/t-1/programator/?com_port=${this.COM_PORT}&hash=${hashHex}`,
        formData,
        {
            baseURL: this.$store.getters['app/APP_API_URI']
        }
      )
      .then(() => {
        this.$EventBus.$emit('AppSnackbar/show', {
          color: 'success',
          text: this.$t('ui.save_success')
        })
      })
      .catch(() => {
        this.$EventBus.$emit('AppSnackbar/show', {
          color: 'red',
          text: this.$t('ui.save_error')
        })
      })
      .finally(() => {
        this.loading = false
        // window.location.reload()
      })
    },
    promjeniAktivniProgram () {
      this.loading = true
      let a = new FileReader()
      let self = this
      a.readAsArrayBuffer(this.uploadProgramFile)
      a.onloadend = function () {
        const hashArray = self.sha256.update(a.result)
        const hashHex = hashArray.hex()
        self.uploadProgram(hashHex)
      }
    }
  },
  created () {
    this.dohvatiComPort()
  }
}
</script>
