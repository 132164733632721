/* eslint-disable */

import _ from 'lodash'

export default function $WebSocketProvider () {
  var Socket

  Socket = (Socket || window.WebSocket || window.MozWebSocket)

  function $createWebsocketBackend (url, protocols) {
    var match = /wss?:\/\//.exec(url)

    if (!match) {
      throw new Error('Invalid url provided')
    }

    if (protocols) {
      return new Socket(url, protocols)
    }

    return new Socket(url)
  }

  function $WebSocket (url, protocols, options) {

    if (!options && _.isObject(protocols) && !_.isArray(protocols)) {
      options = protocols
      protocols = undefined
    }

    this.protocols = protocols
    this.url = url || 'Missing URL'
    this.ssl = /(wss)/i.test(this.url)

    this.initialTimeout = options && options.initialTimeout || 500 // 500ms
    this.maxTimeout = options && options.maxTimeout || 1 * 60 * 1000 // 1 minute
    this.reconnectIfNotNormalClose = options && options.reconnectIfNotNormalClose || true
    this.binaryType = options && options.binaryType || 'arraybuffer'

    this._reconnectAttempts = 0
    this.sendQueue = []
    this.onOpenCallbacks = []
    this.onMessageCallbacks = []
    this.onErrorCallbacks = []
    this.onCloseCallbacks = []

    if (url) {
      this._connect()
    } else {
      this._setInternalState(0)
    }
  }

  $WebSocket.prototype._readyStateConstants = {
    'CONNECTING': 0,
    'OPEN': 1,
    'CLOSING': 2,
    'CLOSED': 3,
    'RECONNECT_ABORTED': 4
  }

  $WebSocket.prototype._normalCloseCode = 1000

  $WebSocket.prototype._reconnectableStatusCodes = [
    4000
  ]

  $WebSocket.prototype._connect = function _connect (force) {

    if (force || !this.socket || this.socket.readyState !== this._readyStateConstants.OPEN) {
      this.socket = $createWebsocketBackend(this.url, this.protocols)
      this.socket.binaryType = this.binaryType
      this.socket.onmessage = _.bind(this._onMessageHandler, this)
      this.socket.onopen = _.bind(this._onOpenHandler, this)
      this.socket.onerror = _.bind(this._onErrorHandler, this)
      this.socket.onclose = _.bind(this._onCloseHandler, this)
    }
  }

  $WebSocket.prototype.fireQueue = function fireQueue () {
    while (this.sendQueue.length && this.socket.readyState === this._readyStateConstants.OPEN) {
      var data = this.sendQueue.shift()

      this.socket.send(
        _.isString(data.message) || this.binaryType !== 'arraybuffer' ? data.message : JSON.stringify(data.message)
      )

      data.deferred.resolve()
    }
  }

  $WebSocket.prototype.notifyOpenCallbacks = function notifyOpenCallbacks (event) {
    for (var i = 0; i < this.onOpenCallbacks.length; i++) {
      this.onOpenCallbacks[i].call(this, event)
    }
  }

  $WebSocket.prototype.notifyCloseCallbacks = function notifyCloseCallbacks (event) {
    for (var i = 0; i < this.onCloseCallbacks.length; i++) {
      this.onCloseCallbacks[i].call(this, event)
    }
  }

  $WebSocket.prototype.notifyErrorCallbacks = function notifyErrorCallbacks (event) {
    for (var i = 0; i < this.onErrorCallbacks.length; i++) {
      this.onErrorCallbacks[i].call(this, event)
    }
  }

  $WebSocket.prototype.onOpen = function onOpen (cb) {
    this.onOpenCallbacks.push(cb)
    return this
  }

  $WebSocket.prototype.onClose = function onClose (cb) {
    this.onCloseCallbacks.push(cb)
    return this
  }

  $WebSocket.prototype.onError = function onError (cb) {
    this.onErrorCallbacks.push(cb)
    return this
  }

  $WebSocket.prototype.onMessage = function onMessage (callback, options) {
    if (!_.isFunction(callback)) {
      throw new Error('Callback must be a function')
    }

    if (options && _.isDefined(options.filter) && !_.isString(options.filter) && !(options.filter instanceof RegExp)) {
      throw new Error('Pattern must be a string or regular expression')
    }

    this.onMessageCallbacks.push({
      fn: callback,
      pattern: options ? options.filter : undefined,
      autoApply: options ? options.autoApply : true
    })
    return this
  }

  $WebSocket.prototype._onOpenHandler = function _onOpenHandler (event) {
    this._reconnectAttempts = 0
    this.notifyOpenCallbacks(event)
    this.fireQueue()
  }

  $WebSocket.prototype._onCloseHandler = function _onCloseHandler (event) {
    var self = this
    self.notifyCloseCallbacks(event)

    if ((this.reconnectIfNotNormalClose && event.code !== this._normalCloseCode) || this._reconnectableStatusCodes.indexOf(event.code) > -1) {
      this.reconnect()
    }
  }

  $WebSocket.prototype._onErrorHandler = function _onErrorHandler (event) {
    var self = this
    self.notifyErrorCallbacks(event)
  }

  $WebSocket.prototype._onMessageHandler = function _onMessageHandler (message) {
    var self = this
    var currentCallback
    for (var i = 0; i < self.onMessageCallbacks.length; i++) {
      currentCallback = self.onMessageCallbacks[i]
      applyAsyncOrDigest(currentCallback.fn, currentCallback.autoApply, message)
    }

    function applyAsyncOrDigest (callback, autoApply, args) {
      args = Array.prototype.slice.call(arguments, 2)
      callback.apply(self, args)
    }
  }

  $WebSocket.prototype.close = function close (force) {
    if (force || !this.socket.bufferedAmount) {
      this.socket.close()
    }

    return this
  }

  $WebSocket.prototype.closeAndDoNotReconnect = function close () {
    this.socket.close(1000)
  }

  $WebSocket.prototype.send = function send (data) {
    var deferred = new Promise()
    var self = this
    var promise = cancelableify(deferred.promise)

    if (self.readyState === self._readyStateConstants.RECONNECT_ABORTED) {
      deferred.reject('Socket connection has been closed')
    } else {

      self.sendQueue.push({
        message: data,
        deferred: deferred
      })

      self.fireQueue()
    }

    // Credit goes to @btford
    function cancelableify (promise) {
      promise.cancel = cancel
      var then = promise.then
      promise.then = function () {
        var newPromise = then.apply(this, arguments)
        return cancelableify(newPromise)
      }
      return promise
    }

    function cancel (reason) {

      self.sendQueue.splice(self.sendQueue.indexOf(data), 1)
      deferred.reject(reason)
      return self
    }

    return promise
  }

  $WebSocket.prototype.reconnect = function reconnect () {
    this.close()

    var backoffDelay = this._getBackoffDelay(++this._reconnectAttempts)

    var backoffDelaySeconds = backoffDelay / 1000
    console.log('Reconnecting in ' + backoffDelaySeconds + ' seconds')

    setTimeout(_.bind(this._connect, this), backoffDelay)

    return this
  }

  // Exponential Backoff Formula by Prof. Douglas Thain
  // http://dthain.blogspot.co.uk/2009/02/exponential-backoff-in-distributed.html
  $WebSocket.prototype._getBackoffDelay = function _getBackoffDelay (attempt) {
    var R = Math.random() + 1
    var T = this.initialTimeout
    var F = 2
    var N = attempt
    var M = this.maxTimeout

    return Math.floor(Math.min(R * T * Math.pow(F, N), M))
  }

  return function (url, protocols, options) {
    return new $WebSocket(url, protocols, options)
  }

}
