<template>
  <v-container fluid grid-list-md>

    <v-layout row wrap>
      <v-flex xs12 md4>
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>
              <span class="text-xl-left">T-2000 NULTA SEKUNDA SYNC</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="doStartSupervisorJob(supervisorConfigKey)">
              <v-icon>play_arrow</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="doStopSupervisorJob(supervisorConfigKey)">
              <v-icon>pause</v-icon>
            </v-btn>
          </v-toolbar>

          <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>

          <v-card-text>
            <form>

              <v-flex xs12>
                <v-select
                  :items="supervisorJobStatusItems"
                  item-text="name"
                  item-value="id"
                  v-model="config.T2000_NULTA_SEKUNDA_SYNC_ENABLED"
                ></v-select>
              </v-flex>

              <v-flex xs12>
                <v-combobox
                  v-model="config.T2000_NULTA_SEKUNDA_SYNC_PORT"
                  :items="ports"
                  label="PORT"
                  required
                  :error-messages="portErrors"
                  @input="$v.config.T2000_NULTA_SEKUNDA_SYNC_PORT.$touch()"
                  @blur="$v.config.T2000_NULTA_SEKUNDA_SYNC_PORT.$touch()"
                ></v-combobox>
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  v-model="config.T2000_NULTA_SEKUNDA_SYNC_OFFSET"
                  label="OFFSET"
                  required
                  :error-messages="offsetErrors"
                  @input="$v.config.T2000_NULTA_SEKUNDA_SYNC_OFFSET.$touch()"
                  @blur="$v.config.T2000_NULTA_SEKUNDA_SYNC_OFFSET.$touch()"
                ></v-text-field>
              </v-flex>

            </form>
          </v-card-text>

          <v-card-actions>
            <v-btn
              :disabled="submitAvailable ? false : true"
              @click="submit"
              color="primary"
            >{{ this.$t('ui.submit') }}</v-btn>
            <v-spacer></v-spacer>
            {{supervisorJobStatusText}}
          </v-card-actions>

        </v-card>
      </v-flex>
      <v-flex xs12 md8>
        <app-supervisor-job-log-card :supervisor-config-key="supervisorConfigKey"></app-supervisor-job-log-card>
      </v-flex>
    </v-layout>

  </v-container>
</template>

<script>
import ConfigMixin from '@/mixins/ConfigMixin'
import SupervisorMixin from '@/mixins/SupervisorMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 't2000NultaSekundaSyncPage',
  mixins: [ConfigMixin, SupervisorMixin, validationMixin],
  data () {
    return {
      supervisorConfigKey: 't2000_nulta_sekunda_sync',
      loading: false,
      config: {
        T2000_NULTA_SEKUNDA_SYNC_ENABLED: false,
        T2000_NULTA_SEKUNDA_SYNC_PORT: '/dev/ttySC0',
        T2000_NULTA_SEKUNDA_SYNC_OFFSET: 0,
        T2000_NULTA_SEKUNDA_SYNC_OFFSET_SUPERVISOR_CONFIG: null
      },
      ports: [
        '/dev/ttySC0'
      ]
    }
  },
  validations: {
    config: {
      T2000_NULTA_SEKUNDA_SYNC_PORT: {
        required
      },
      T2000_NULTA_SEKUNDA_SYNC_OFFSET: {
        required
      }
    }
  },
  computed: {
    isFormValid () {
      return !this.$v.$invalid
    },
    submitAvailable () {
      if (!this.loading && this.isFormValid) {
        return true
      }
      return false
    },
    portErrors () {
      const errors = []
      if (!this.$v.config.T2000_NULTA_SEKUNDA_SYNC_PORT.$dirty) return errors
      !this.$v.config.T2000_NULTA_SEKUNDA_SYNC_PORT.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    offsetErrors () {
      const errors = []
      if (!this.$v.config.T2000_NULTA_SEKUNDA_SYNC_OFFSET.$dirty) return errors
      !this.$v.config.T2000_NULTA_SEKUNDA_SYNC_OFFSET.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    jobAutoStart () {
      if (this.config.T2000_NULTA_SEKUNDA_SYNC_ENABLED === '1') {
        return true
      }

      return false
    },
    supervisorTemplate () {
      return `[program:${this.supervisorConfigKey}]
command=/opt/.virtualenvs/trafconnect_v2/bin/python /opt/trafsignal/apps/t2000_nulta_sekunda_sync.py --device_port=${this.config.T2000_NULTA_SEKUNDA_SYNC_PORT} --offset=${this.config.T2000_NULTA_SEKUNDA_SYNC_OFFSET}
directory=/opt/trafsignal/apps
environment=PYTHONPATH="/opt"
priority=1
autostart=${this.jobAutoStart}
autorestart=${this.jobAutoStart}
startretries=999
user=root
stdout_logfile_maxbytes=10KB
stdout_logfile_backups=0
stderr_logfile_maxbytes=10KB
stderr_logfile_backups=0
stdout_capture_maxbytes=10KB
`
    }
  },
  methods: {
    init () {
      this.initConfigData()
    },
    submit () {
      this.loading = true
      var promises = []

      promises.push(this.createConfig({
        'key': 'T2000_NULTA_SEKUNDA_SYNC_ENABLED',
        'value': this.config.T2000_NULTA_SEKUNDA_SYNC_ENABLED
      }))

      promises.push(this.createConfig({
        'key': 'T2000_NULTA_SEKUNDA_SYNC_PORT',
        'value': this.config.T2000_NULTA_SEKUNDA_SYNC_PORT
      }))

      promises.push(this.createConfig({
        'key': 'T2000_NULTA_SEKUNDA_SYNC_OFFSET',
        'value': this.config.T2000_NULTA_SEKUNDA_SYNC_OFFSET
      }))

      promises.push(this.createSupervisorConfig({
        'key': this.supervisorConfigKey,
        'config': this.supervisorTemplate
      }))

      Promise.all(promises)
        .then(() => {

          // svi upiti su gotovi
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: self.$t('ui.save_success')
          })

        })
        .finally(() => {
          this.loading = false
          this.init()
        })
    }
  },
  created () {
    this.init()
    this.getJobStatusIntervalId = setInterval(() => {
      if (!this.statusUpdating) {
        this.updateSupervisorJobStatus(this.supervisorConfigKey)
      }
    }, 5000)
  },
  destroyed () {
    if (this.getJobStatusIntervalId) {
      clearInterval(this.getJobStatusIntervalId)
    }
  }
}
</script>
