<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">{{ cardTitle }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        @click="doStartSupervisorJob(supervisorConfigKey)">
        <v-icon>play_arrow</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="doStopSupervisorJob(supervisorConfigKey)">
        <v-icon>pause</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="openConfirmDeleteDialog(supervisorConfigKey)">
        <v-icon color="red">delete</v-icon>
      </v-btn>
    </v-toolbar>
    <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>
    <v-card-text>
      <form>
        <v-flex xs12>
          <v-select
            :items="supervisorJobStatusItems"
            item-text="name"
            item-value="id"
            v-model="config.enabled"
          ></v-select>
        </v-flex>
        <v-flex xs12>
          <v-select
            label="ENDPOINT"
            :items="endpoints"
            v-model="config.endpoint"
            item-text="name"
            return-object
            :menu-props="{maxHeight:'400'}"
            v-on:change="endpoindChangedHandler(config)"
            persistent-hint
          ></v-select>
        </v-flex>
        <v-flex xs12>
          <v-text-field
            v-model="config.private_key"
            :label="$t('ui.private_key')"
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-text-field
            v-model="config.public_key"
            :label="$t('ui.public_key')"
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-text-field
            v-model="config.ip_address"
            :label="$t('ui.ip_address')"
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-combobox
            v-model="config.network_device"
            :items="networkDevices"
            label="NETWORK DEVICE"
          ></v-combobox>
        </v-flex>
        <v-flex xs12>
          <v-text-field
            v-model="config.supervisor_key"
            label="Supervisor key"
            disabled
          ></v-text-field>
        </v-flex>
      </form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      {{supervisorJobStatusText}}
    </v-card-actions>

    <app-confirm-action-dialog
      :open-dialog="openDeleteDialog"
      :headline="$t('ui.confirm_delete')"
      :text="$t('ui.really_delete_items')"
      @userAction="confirmActionDialogUserActionHandler"
    >
    </app-confirm-action-dialog>

  </v-card>
</template>

<script>
import ConfigMixin from '../mixins/ConfigMixin'
import SupervisorMixin from '../mixins/SupervisorMixin'
import WireguardMixin from '../mixins/WireguardMixin'

export default {
  name: 'app-wireguard-card',
  mixins: [ConfigMixin, SupervisorMixin, WireguardMixin],
  data () {
    return {
      loading: false,
      openDeleteDialog: false
    }
  },
  props: {
    config: {
      type: Object
    },
    endpoints: {
      type: Array
    },
    networkDevices: {
      type: Array
    }
  },
  computed: {
    jobAutoStart () {
      if (this.config.enabled === '1') {
        return true
      }

      return false
    },
    supervisorTemplate () {
      return `[program:${this.config.supervisor_key}]
command=/opt/.virtualenvs/trafconnect_v2/bin/python /opt/trafsignal/apps/wireguard_watchdog.py --wg_device ${this.config.supervisor_key} --wg_hosts ${this.config.endpoint.ping} --internet_hosts 46.101.124.108 1.1.1.1 8.8.8.8 1.0.0.1 8.8.4.4
directory=/opt/trafsignal
priority=9
autostart=${this.jobAutoStart}
autorestart=${this.jobAutoStart}
startretries=999
user=root
stdout_logfile_maxbytes=10KB
stdout_logfile_backups=0
stderr_logfile_maxbytes=10KB
stderr_logfile_backups=0
stdout_capture_maxbytes=10KB
`
    },
    wgTemplate () {
      return `[Interface]
PrivateKey = ${this.config.private_key}
Address = ${this.config.ip_address}/32

PostUp = iptables -A FORWARD -i %i -j ACCEPT; iptables -A FORWARD -o %i -j ACCEPT; iptables -t nat -A POSTROUTING -o ${this.config.network_device} -j MASQUERADE
PostDown = iptables -D FORWARD -i %i -j ACCEPT; iptables -D FORWARD -o %i -j ACCEPT; iptables -t nat -D POSTROUTING -o ${this.config.network_device} -j MASQUERADE

[Peer]
PublicKey = ${this.config.endpoint.public_key}
AllowedIPs = ${this.config.endpoint.allowed_ips}
Endpoint = ${this.config.endpoint.endpoint}
PersistentKeepalive = 25`
    },
    supervisorConfigKey () {
      return this.config.supervisor_key
    },
    cardTitle () {
      if (!this.config.ip_address && !this.config.endpoint.name) return ''
      if (!this.config.ip_address) return this.config.endpoint.name
      if (!this.config.endpoint.name) return this.config.ip_address

      return `${this.config.endpoint.name} | ${this.config.ip_address}`
    }
  },
  watch: {
    config: {
      handler: function () {
        this.config.supervisor_template = this.supervisorTemplate
        this.config.wg_template = this.wgTemplate
      },
      deep: true
    }
  },
  methods: {
    openConfirmDeleteDialog () {
      this.openDeleteDialog = true
    },
    confirmActionDialogUserActionHandler (actionResult) {
      this.openDeleteDialog = false
      if (actionResult === true) {
        this.$parent.$emit('supervisor-job-deleted', {
          key: this.supervisorConfigKey
        })
      }
    },
    endpoindChangedHandler (changed) {
      this.config.ip_address = changed.endpoint.ping
    }
  },
  created () {
    this.getJobStatusIntervalId = setInterval(() => {
      if (!this.statusUpdating) {
        this.updateSupervisorJobStatus(this.supervisorConfigKey)
      }
    }, 5000)
  },
  destroyed () {
    if (this.getJobStatusIntervalId) {
      clearInterval(this.getJobStatusIntervalId)
    }
  }
}
</script>
