import env from '../env'

export const T1Store = {
  namespaced: true,
  state: {
    uri: env.T1_WS_URI,
    readyState: 0,
    readyStateConstants: {
      'CONNECTING': 0,
      'OPEN': 1,
      'CLOSING': 2,
      'CLOSED': 3,
      'RECONNECT_ABORTED': 4
    },
    serBr: [],
    stanjeSignala: [],
    greske: [],
    statusnaRijec: [],
    brojGrupa: [],
    stanjeDetektora: [],
    t1Communicating: false,
    mco: null,
    mcoIme: '',
    program: null,
    ciklus: null
  },
  getters: {
    READY_STATE (state) {
      return state.readyState
    },
    isConnected (state) {

      if (state.readyState === 1) {
        return true
      }

      return false
    },
    trafficController (state) {
      return state.trafficController
    },
    statusnaRijec (state) {
      return {
        abc: 0,
        anf: state.statusnaRijec[0] & 64,
        cu: state.statusnaRijec[0] & 16,
        ru: state.statusnaRijec[0] & 8,
        sc: state.statusnaRijec[0] & 4,
        su: state.statusnaRijec[0] & 1,
        trz: state.statusnaRijec[0] & 2,
        vru: state.statusnaRijec[0] & 32
      }
    },
    serBr (state) {
      if (!state.serBr) return null
      return state.serBr[0]+256*state.serBr[1]
    },
    revizijaSw (state) {
      if (!state.serBr) return null
      return state.serBr[2]
    },
    isCommOk (state) {
      return state.t1Communicating
    },
    stanjeDetektora (state) {
      return state.stanjeDetektora
    },
    stanjeSignala (state) {
      var _stanjeSignala = []
      var stanje = [0, 0, 0]

      for(var i = 0; i < state.stanjeSignala.length; i++) {
        stanje = [0, 0, 0]

        // Crveno
        if (state.stanjeSignala[i] & 2) {
          stanje[0] = 1
        }
        // Zuto
        if (state.stanjeSignala[i] & 4) {
          stanje[1] = 1
        }
        // Zeleno
        if (state.stanjeSignala[i] & 8) {
          stanje[2] = 1
        }

        _stanjeSignala.push(stanje)

        stanje = [0, 0, 0]

        // Crveno
        if (state.stanjeSignala[i] & 32) {
          stanje[0] = 1
        }
        // Zuto
        if (state.stanjeSignala[i] & 64) {
          stanje[1] = 1
        }
        // Zeleno
        if (state.stanjeSignala[i] & 128) {
          stanje[2] = 1
        }
        _stanjeSignala.push(stanje)
      }

      return _stanjeSignala
    },
    program (state) {
      return state.program
    },
    mco (state) {
      return state.mco
    },
    mcoIme (state) {
      let MCO_IME = ''

      if (state.mco == 17) {
        MCO_IME = 'FTC'
      } else if (state.mco == 18) {
        MCO_IME = 'noFTC'
      } else if (state.mco == 19) {
        MCO_IME = 'LOC'
      } else if (state.mco == 20) {
        MCO_IME = 'TRŽ'
      } else if (state.mco == 255) {
        MCO_IME = "N/A"
      } else {
        MCO_IME = `MCO${state.mco}`
      }

      if (state.program <= 8) {
        MCO_IME = `P${state.program}-${MCO_IME}`
      }

      return MCO_IME
    },
    ciklus (state) {
      if (state.ciklus) {
        return state.ciklus
      }

      return null
    }
  },
  mutations: {
    changeReadyState (state, newReadyState) {
      state.readyState = newReadyState
    },
    setUri (state, uri) {
      state.uri = uri
    },
    set (state, trafficController) {
      state.t1Communicating = true
      for (const [key, value] of Object.entries(trafficController)) {
        state[key] = value
      }
    }
  }
}
