<template>
  <form>

    <v-flex xs12>
      <v-text-field
        v-model="config.key"
        :label="this.$t('ui.code')"
        :error-messages="keyErrors"
        @input="$v.config.key.$touch()"
        @blur="$v.config.key.$touch()"
        required
      ></v-text-field>
    </v-flex>

    <v-flex xs12>
        <v-textarea
        v-model="config.value"
        :label="this.$t('ui.value')"
        :error-messages="valueErrors"
        @input="$v.config.value.$touch()"
        @blur="$v.config.value.$touch()"
        outline
        >
        </v-textarea>
    </v-flex>

    <v-flex xs12>
      <v-text-field
        v-model="config.description"
        :label="this.$t('ui.description')"
      ></v-text-field>
    </v-flex>

  </form>
</template>

<script>
import ConfigMixin from '../mixins/ConfigMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ConfigForm',
  mixins: [ConfigMixin, validationMixin],
  props: {
    config: {
      type: Object,
      default: function () {
        return {
          key: '',
          value: '',
          description: ''
        }
      }
    }
  },
  validations: {
    config: {
      key: {
        required
      },
      value: {
        required
      }
    }
  },
  computed: {
    keyErrors () {
      const errors = []
      if (!this.$v.config.key.$dirty) return errors
      !this.$v.config.key.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    valueErrors () {
      const errors = []
      if (!this.$v.config.value.$dirty) return errors
      !this.$v.config.value.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    isFormValid () {
      return !this.$v.$invalid
    }
  },
  watch: {
    isFormValid: {
      handler: function (newValidSate) {
        this.$emit('isConfigFormValid', newValidSate)
      }
    }
  },
  methods: {
    submit: function () {
      this.loading = true
    }
  }
}
</script>
