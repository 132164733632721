<template>
  <v-container fluid grid-list-md>
    <v-row>
      <v-col xs="12">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ this.$t('ui.manual_control') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              v-if="manualControl === 'YES'"
              color="primary"
              @click="disableManualControl"
            >DISABLE</v-btn>
            <v-btn
              v-if="manualControl === 'NO'"
              color="primary"
              @click="enableManualControl"
            >ENABLE</v-btn>
          </v-toolbar>
        </v-card>
      </v-col>
      <v-progress-linear
        v-show="loading"
        color="primary"
        indeterminate
      ></v-progress-linear>
    </v-row>
    <v-row>
      <v-col xs="12" lg="4">
        <v-btn
          color="primary"
          @click="resetETX"
          :disabled="doPowerCycleDisabled"
          block
        >RESET ETX</v-btn>
      </v-col>
      <v-col xs="12" lg="4">
        <v-btn
          color="red"
          @click="shutdownETX"
          block
        >SHUTDOWN ETX</v-btn>
      </v-col>
      <v-col xs="12" lg="4">
        <v-btn
          color="green"
          @click="powerOnETX"
          block
        >POWER ON ETX</v-btn>
      </v-col>
      <v-col xs="12" lg="4">
        <v-btn
          color="black"
          @click="TrzOn"
          block
        >INTERMITTENCE ON</v-btn>
      </v-col>
      <v-col xs="12" lg="4">
        <v-btn
          color="black"
          @click="TrzOff"
          block
        >INTERMITTENCE OFF</v-btn>
      </v-col>
      <v-col xs="12" lg="4">
        <v-select
          label="ATMS CONTROL"
          :items="atmsControlStates"
          v-model="enableAtmsControl"
          item-text="label"
          item-value="id"
        ></v-select>
        <v-btn
          :disabled="loading"
          color="primary"
          @click="saveAtmsControlState"
        >SAVE</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="manualControl==='YES'">
      <v-col xs="12" lg="6">
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>CURRENT PHASE</td>
                <td>{{ currentPhase }}</td>
              </tr>
              <tr>
                <td>FIX TO PHASE</td>
                  <td>{{ fixEtxPhase }}</td>
              </tr>
              <tr>
                <td>
                  <v-text-field
                    v-model="selectedPhase"
                    type="number"
                  ></v-text-field>
                </td>
                <td>
                  <v-btn
                    :disabled="loading || !selectedPhase"
                    color="primary"
                    @click="submit"
                  >FIX PHASE</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <app-confirm-action-dialog
        :open-dialog="openConfirmResetDialog"
        headline="CONFIRM"
        text="RESET ETX?"
        @userAction="doresetETX"
      >
    </app-confirm-action-dialog>
    <app-confirm-action-dialog
        :open-dialog="openConfirmShutdownDialog"
        headline="CONFIRM"
        text="SHUTDOWN ETX?"
        @userAction="doShutdownETX"
      >
    </app-confirm-action-dialog>
    <app-confirm-action-dialog
      :open-dialog="openConfirmPowerOnDialog"
      headline="CONFIRM"
      text="POWER ON ETX?"
      @userAction="doPowerOnETX"
    >
    </app-confirm-action-dialog>
    <app-confirm-action-dialog
      :open-dialog="openConfirmTrzOnDialog"
      headline="CONFIRM"
      text="INTERMITTENCE ON?"
      @userAction="doTrzOn"
      >
    </app-confirm-action-dialog>
    <app-confirm-action-dialog
      :open-dialog="openConfirmTrzOffDialog"
      headline="CONFIRM"
      text="INTERMITTENCE OFF?"
      @userAction="doTrzOff"
      >
    </app-confirm-action-dialog>

  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import ConfigMixin from '@/mixins/ConfigMixin'
import EtxMixin from '@/mixins/EtxMixin'

export default {
  name: 'EtxManualControlPage',
  mixins: [validationMixin, ConfigMixin, EtxMixin],
  data () {
    return {
      config: {
        ENABLE_ATMS_CONTROL: null
      },
      loading: false,
      selectedPhase: 1,
      fixPhase: false,
      kickWdogId: null,
      openConfirmResetDialog: false,
      openConfirmShutdownDialog: false,
      openConfirmPowerOnDialog: false,
      openConfirmTrzOnDialog: false,
      openConfirmTrzOffDialog: false,
      atmsControlStates: [
        {
          label: 'ON',
          id: '1'
        },
        {
          label: 'OFF',
          id: '0'
        }
      ]
    }
  },
  methods: {
    init () {
      this.loading = true
      this.initConfigData()
        .finally(() => {
          this.loading = false
        })
    },
    submit () {
      this.loading = true
      this.EtxManualControl({phase: this.selectedPhase})
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'error',
            text: this.$t('ui.error')
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    enableManualControl () {
      this.selectedPhase = 1
      this.EtxManualControl({phase: ''})
      this.EtxManualControl({'etx_manual_control_enabled': '1'})
        .then(() => {
          this.kickWdogId = setInterval(() => {
            this.EtxManualControl({'ETX_INTERMITTENCE_ON': '0'})
            this.EtxManualControl({'etx_manual_control_enabled': '1'})
          }, 900)
        })
    },
    disableManualControl () {
      if (this.kickWdogId) {
        clearInterval(this.kickWdogId)
      }
      this.EtxManualControl({phase: ''})
      this.EtxManualControl({'ETX_INTERMITTENCE_ON': '0'})
      this.EtxManualControl({'etx_manual_control_enabled': '0'})
    },
    resetETX () {
      this.openConfirmResetDialog = true
    },
    doresetETX (actionResult) {
      this.openConfirmResetDialog = false
      if (actionResult === true) {
        this.EtxManualControl({'etx_do_power_cycle': '1'})
      }
    },
    shutdownETX () {
      this.openConfirmShutdownDialog = true
    },
    ETX_SHUTDOWN (value) {
      this.loading = true

        this.createConfig({
          'key': 'ETX_SHUTDOWN',
          'value': value
        })
        .then(() => {
          this.EtxManualControl({'reload': '1'})
        })
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'error',
            text: this.$t('ui.save_error')
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    doShutdownETX (actionResult) {
      this.openConfirmShutdownDialog = false
      if (actionResult === true) {
        this.ETX_SHUTDOWN('1')
      }
    },
    powerOnETX () {
      this.openConfirmPowerOnDialog = true
    },
    doPowerOnETX (actionResult) {
      this.openConfirmPowerOnDialog = false
      if (actionResult === true) {
        this.ETX_SHUTDOWN('0')
      }
    },
    TrzOn () {
      this.openConfirmTrzOnDialog = true
    },
    doTrzOn (actionResult) {
      this.openConfirmTrzOnDialog = false
      if (actionResult === true) {
        clearInterval(this.kickWdogId)
        this.EtxManualControl({'etx_manual_control_enabled': '0'})
        this.EtxManualControl({'ETX_INTERMITTENCE_ON': '1'})
      }
    },
    TrzOff () {
      this.openConfirmTrzOffDialog = true
    },
    doTrzOff (actionResult) {
      this.openConfirmTrzOffDialog = false
      if (actionResult === true) {
        this.EtxManualControl({'ETX_INTERMITTENCE_ON': '0'})
      }
    },
    saveAtmsControlState () {
      this.loading = true
      this.createConfig({
        'key': 'ENABLE_ATMS_CONTROL',
        'value': this.config.ENABLE_ATMS_CONTROL
      })
      .then(() => {
        this.EtxManualControl({'reload': '1'})
      })
      .then(() => {
        this.$EventBus.$emit('AppSnackbar/show', {
          color: 'success',
          text: this.$t('ui.save_success')
        })
      })
      .catch(() => {
        this.$EventBus.$emit('AppSnackbar/show', {
          color: 'error',
          text: this.$t('ui.error')
        })
      })
      .finally(() => {
        this.loading = false
      })
    }
  },
  computed: {
    currentPhase () {
      let cp = this.$store.getters['etx/currentPhase']
      if (cp) {
        return cp
      }

      return '...'
    },
    fixEtxPhase () {
      return this.$store.getters['etx/fixEtxPhase']
    },
    atmsControl () {
      let pl = this.$store.getters['etx/atmsControl']

      if (pl === '1') {
        return 'YES'
      } else {
        return 'NO'
      }
    },
    manualControl () {
      let pl = this.$store.getters['etx/manualControl']

      if (pl === '1') {
        return 'YES'
      } else {
        return 'NO'
      }
    },
    doPowerCycle () {
      return this.$store.getters['etx/doPowerCycle']
    },
    doPowerCycleDisabled () {
      if (this.doPowerCycle === '1') {
        return true
      }

      return false
    },
    enableAtmsControl: {
      get () {
        if (this.config.ENABLE_ATMS_CONTROL === '1') {
          return { 'id': '1', 'label': 'ON' }
        }
        return { 'id': '0', 'label': 'OFF' }
      },
      set (newValue) {
        if (newValue == '1') {
          this.config.ENABLE_ATMS_CONTROL = '1'
        } else {
          this.config.ENABLE_ATMS_CONTROL = '0'
        }
      }
    }
  },
  created () {
    this.init()
  },
  destroyed () {
    if (this.kickWdogId) {
      clearInterval(this.kickWdogId)
    }
  }
}
</script>
