export default {
  methods: {
    getRedisKey (key) {
      return this.$api.get(`/redis/?key=${key}`, {baseURL: this.$store.getters['app/APP_API_URI']})
    },
    setRedisKeyValue (keyValue) {
      return this.$api.post('/redis/', keyValue, {baseURL: this.$store.getters['app/APP_API_URI']})
    }
  }
}
