export default {
  ui: {
    auth_error: 'Authentication error! Please check username and password.',
    home: 'Home',
    traffic_controller: 'Traffic Controller',
    traffic_controllers: 'Traffic Controllers',
    traffic_controller_type: 'Traffic Controller Type',
    traffic_controller_delete: 'Remove',
    traffic_controller_add: 'Add new',
    traffic_controller_edit: 'Edit',
    traffic_controllers_simulate: 'Simulator uređaja',
    trafconnect_url: 'TrafConnect URL',
    comment: 'Comment',
    log: 'Log',
    refresh: 'Refresh',
    date_picker: 'Choose date',
    choose_layers: 'Choose Layers',
    date_and_time: 'Date and time',
    date_from: 'Date from',
    date_to: 'Date to',
    sent: 'Sent',
    status: 'Status',
    error: 'Error',
    user: 'User',
    user_level: 'User level',
    users: 'Users',
    map: 'Map',
    alarms: 'Alarms',
    password: 'Password',
    password_change: 'Change password',
    enter_password: 'Enter password',
    enter_password_again: 'Enter password again',
    login: 'Login',
    search: 'Search',
    centralno_upravljanje: 'Central control',
    sve_crveno: 'All signals red',
    rucno_upravljanje: 'Manual control',
    vremensko_upravljanje: 'Timed control',
    signali_ukljuceni: 'Signals ON',
    treptanje_zutog: 'Flashing yellow mode',
    greska: 'Error',
    abc: 'ABC',
    name: 'Name',
    surname: 'Surname',
    group: 'Group',
    code: 'Code',
    type: 'Type',
    name_and_surname: 'Name and surname',
    username: 'Username',
    email: 'E-mail',
    admin: 'Admin',
    actions: 'Actions',
    edit_user: 'Edit user',
    add_user: 'Add user',
    delete_user: 'Remove user',
    submit: 'Submit',
    active: 'Active',
    save_success: 'Save success',
    save_error: 'Error!',
    close: 'Close',
    field_required: 'Field is requred',
    field_vaild_email_required: 'Valid e-mail address is required',
    minimum: 'Minimum',
    passwords_must_be_identical: 'Passwords must be identical',
    cameras: 'Cameras',
    svg: 'SVG',
    description: 'Description',
    edit: 'Edit',
    delete: 'Remove',
    locate_on_map: 'Locate on map',
    week: 'Week',
    month: 'Month',
    year: 'Year',
    download: 'Download',
    alarm_details: 'Alarm details',
    location: 'Location',
    created: 'Created',
    ended: 'Ended',
    logout: 'Logout',
    start: 'Start',
    stop: 'Stop',
    settings: 'Settings',
    language: 'Language',
    disable_notification_sound: 'Disable notification sound',
    enable_notification_sound: 'Enable notification sound',
    companies: 'Companies',
    company_add: 'Add company',
    company_edit: 'Edit company',
    company_delete: 'Delete company',
    contacts: 'Contacts',
    persons: 'Persons',
    homepage: 'www',
    tel: 'Phone',
    add: 'Add',
    // novo
    person_add: 'Add person',
    person_edit: 'Edit person',
    person_delete: 'Delete person',
    company: 'Company',
    manufacturer: 'Manufacturer',
    maintainer: 'Maintainer',
    locations: 'Locations',
    unclassified: 'Unclassified',
    time_from: 'Time from',
    time_to: 'Time to',
    stanje_signala: 'Signals',
    mobilni_internet: 'Cellular internet',
    gsm: 'GSM',
    gps: 'GPS',
    wifi: 'Wi-Fi',
    mikrovalovni_detektori: 'Microwave detectors',
    centar: 'ATMS',
    watchdog: 'Watchdog',
    vpn: 'VPN',
    value: 'Value',
    config_add: 'Add new config',
    config_edit: 'Edit config',
    nadzorni_centar: 'ATMS',
    not_active: 'Not active',
    unknown: 'Unknown',
    running: 'Running',
    not_running: 'Not running',
    starting: 'Starting',
    yes: 'Yes',
    no: 'No',
    confirm_delete: 'Confirm deletion?',
    really_delete_items: 'Really delete items?',
    ip_address: 'IP address',
    private_key: 'Private key',
    public_key: 'Public key',
    camera_type: 'Camera type',
    cancel: 'Cancel',
    save: 'Save',
    cameras_detectors_backend: 'Cameras backend',
    video_feed_server: 'Video feed server',
    tlocrt: 'Top view',
    dashboard: 'Dashboard',
    add_tlocrt: 'Add',
    background_color: 'Background color',
    white: 'White',
    dark: 'Dark',
    system: 'System',
    ciklus: 'Cycle',
    detektori: 'Detectors',
    offset: 'Offset',
    cu_ciklus: 'Central control - Cycle',
    snage_signala: 'Lamp current',
    gain: 'Gain',
    izmjereno_stanje: 'Measured state',
    nominalne_vrijednosti: 'Nominal values',
    dozvoljeno_odstupanje: 'Tolerated difference',
    jedinice: 'Units',
    pragovi: 'Threshold',
    rebalans: 'Re balance',
    impuls: 'Impuls',
    logika: 'Logic',
    stalne_najave: 'Always ON',
    vremena: 'Timings',
    programski_rad: 'Programs',
    gpio: 'GPIO',
    errors: 'Errors',
    statistika: 'LOG',
    commands: 'Commands',
    sleep: 'Sleep',
    color: 'Color',
    languages: 'Languages',
    variable_gain_not_supported: 'Variable GAIN not supported',
    izmjereno_stanje_snaga: 'Measured current',
    day: 'Day',
    vrijeme: 'Time',
    raspored: 'Schedule',
    uredi_raspored: 'Edit schedule',
    radni_dan: 'Work day',
    nominalne_vrijednosti_snaga: 'Nominal current',
    zelena_vremena_signalnih_grupa: 'Green timings',
    signalna_grupa: 'Signal group',
    minimalno_zeleno_vrijeme: 'Minimal green time',
    maksimalno_zeleno_vrijeme: 'Max green time',
    rucni_odabir: 'Manual selection',
    automatski: 'Auto',
    trenutni_ptp: 'Current program',
    mod_rada: 'Mode',
    promjeni_aktivni_program: 'Change active program',
    ugasi_signale: 'Signals OFF',
    upisano_maksimalno_zeleno_vrijeme: 'Saved max timings',
    ok_dimmer_ukljucen: 'OK, dimmer ON',
    ok_dimmer_iskljucen: 'OK, dimmer OFF',
    temperatura_cps_modula: 'CPS module temperature',
    uline_napon_signala: 'U line',
    uptime: 'Uptime',
    vrijeme_i_datum: 'Time and date',
    ponedjeljak: 'Monday',
    utorak: 'Tuesday',
    srijeda: 'Wednesday',
    četvrtak: 'Thursday',
    petak: 'Friday',
    subota: 'Saturday',
    nedjelja: 'Sunday',
    sync: 'Sync',
    sync_time: 'Sync time',
    dimmer: 'Dimmer',
    stanje_dimmera: 'Dimmer state',
    LDR: 'LDR',
    histereza: 'Hysteresis',
    vrijeme_relaksacije: 'Grace period',
    prag: 'Threshold',
    pin: 'PIN',
    novi_pin: 'New PIN',
    ponovi_novi_pin: 'New PIN again',
    promjeni_pin: 'Change PIN',
    akusticki_signali: 'Acoustic signals',
    pin_se_mora_sastojati_od_4_znamenke: 'PIN has to have 4 digits',
    vrijednost_mora_biti_broj: 'Value has to be a number',
    rezim_rada: 'Operation mode',
    vrijeme_ukljucenja: 'Switch ON time',
    vrijeme_iskljucenja: 'Switch OFF time',
    ucitaj_izvorne_postavke: 'Restore to factory defaults',
    potvrdi_ucitavanje_izvornih_parametara: 'Confirm restore',
    zaista_ucitati_izvorne_postavke: 'Confirm restore',
    ucitaj_greske: 'Refresh error list',
    greska_u_sustavu_za_detekciju: 'Error in detector system',
    posalji_program: 'Upload firmware',
    upload_programa: 'Upload firmware',
    resetiraj_serijski_broj: 'Reset serial #',
    delete_statistika: 'Clear log',
    detektorska_logika: 'Detector logic',
    statistika_backend: 'Log backend',
    ima_stalnu_najavu: 'Constant demand for green',
    u_stalnoj_najavi: 'In constant demand for green',
    u_kvaru: 'At fault',
    detektor: 'Detector',
    edit_offset: 'Change offset',
    program: 'Program',
    novi_offset: 'New offset',
    nije_predviđeno: 'Not implemented',
    greska_koordinacije_LOKALNI_RAD: 'Coordination error, falling back to local mode',
    nije_ukljuceno: 'Not switched on',
    no_defined_cameras: 'No cameras added',
    reset_cams: 'Reset cameras',
    send: 'Send',
    wrong_pin_entered: 'Send PIN!',
    confirm_reset_cams: 'Do you really want to reset the cams?',
    really_reset_cams: 'Do you really want to reset the cams?',
    remember_me: 'Remember me',
    dozvoljeno_odstupanje_prag_2: 'Permissible deviation - threshold 2',
    prag_2: 'Threshold 2',
    postavke_snage_signala: 'Settings - lamp current',
    postavi_prag_1_na_100_posto: 'Threshold 1 to 100%',
    postavi_prag_1_na_0_posto: 'Threshold 1 to 0%',
    postavi_prag_2_na_100_posto: 'Threshold 2 to 100%',
    postavi_prag_2_na_0_posto: 'Threshold 2 to 0%',
    spremi_kao_default: 'Save as default',
    restore_default_values: 'Restore default values',
    postavi_pragove: 'Set thresholds',
    download_backup: 'Download backup',
    restore_backup: 'Restore backup',
    download_CSV: 'Download CSV',
    postavi_tolerancije: 'Set tolerance',
    tolerancija: 'Tolerance',
    reset_backend: 'Reset backend',
    gpio_settings: 'GPIO Settings',
    hrvatski: 'Hrvatski',
    slovenski: 'Slovenski',
    gpio_adresa: 'GPIO address',
    timeout: 'Timeout',
    razvojna_konzola: 'Development console',
    development: 'Development',
    detektorska_ploca: 'Detector panel',
    forget_me: 'Forget me',
    sinkro_sekunda: 'Sync second',
    vprog_rad_not_supported: 'Manual program selection is not supported',
    kalibracija_sig: 'SIG calibration',
    ac_status_check: 'AC status check',
    prag_1: 'Threshold 1',
    rezolucija: 'Resolution',
    noise: 'Noise',
    error_expecting_integer_as_noise_param: 'Integer is expected as noise parameter',
    not_supported: 'Not supported',
    centralno_upravljanje_kontroler: 'Central control - controller',
    stabilnostPetlje: 'Induction loop stability',
    koeficijent_stabilnosti_petlje: 'Induction loop stability coefficient',
    error_expecting_integer_as_koef_stabilnost_petlje_param: 'Integer is expected as induction loop stability coefficient',
    edit_program_names: 'Edit program names',
    treptanje: 'Amber flash',
    reset_program_names: 'Reset program names',
    reset_naziva_programa_warning: 'Broj programa na T-1 se razlikuje od broja upisanih naziva programa, napravite RESET naziva programa!',
    reset_matrice_paljenja_programa_warning: 'Broj programa na T-1 se razlikuje od broja programa u matrici, ponovo ispunite matricu!',
    reset_matrice_paljenja: 'Matrix reset',
    ispuni_matricu_paljenja_ispravno: 'Ispunite ispravno matricu paljenja, svi brojevi moraju biti upisani i > 0!',
    trap_settings: 'TRAP settings',
    led_marker: 'LED marker',
    led_marker_controller: 'LED marker controller',
    invalid_format: 'Wrong format!',
    system_time: 'System time',
    ntp_enabled: 'NTP active',
    time_zone: 'Time zone',
    time_and_date: 'Time and date',
    change_system_time: 'Change system date',
    gps_device_type: 'GPS device type',
    help: 'Help',
    sync_time_with_computer: 'Sync with local computer',
    auto_sync_system_time_to_t1_time: 'Sync Traffic controller time with system time',
    sync_every_x_seconds: 'Sync every X seconds',
    disabled: 'Disabled',
    engleski: 'English',
    proxy_requests: 'PROXY REQUESTS',
    start_backend: 'START BACKEND',
    vremenski: 'Timed',
    stalno_ukljuceno: 'Always ON',
    stalno_iskljuceno: 'Always OFF',
    date_time: 'Date and time',
    cps_temperature: 'CPS temp',
    napon: 'Voltage',
    trazi_zeleno: 'Requests green',
    produzava_zeleno: 'Extends green',
    memorija: 'Memory',
    odgoda_najave: 'Delay request',
    interval_izmedju_vozila: 'Vehicle interval',
    zona: 'Zone',
    video_wall: 'Video wall',
    stream_video: 'Stream video',
    system_hardware: 'System hardware',
    network: 'Network',
    network_settings: 'Network settings',
    waiting: 'Waiting',
    restart: 'Restart',
    shutdown: 'Shutdown',
    confirm_restart: 'Confirm restart',
    really_restart_the_system: 'Do You really want to restart the system?',
    confirm_shutdown: 'Confirm shutdown',
    really_shutdown_the_system: 'Do You really want to shutdown the system?',
    not_connected_to_traffic_controller: 'Not connected to traffic controller',
    phases: 'Phases',
    phase: 'Phase',
    green: 'Green',
    yellow: 'Yellow',
    red: 'Red',
    manual_control: 'Manual control',
    programs: 'Programs',
    cycle: 'Cycle',
    update: 'Update',
    time: 'Time',
    import: 'Import',
    export: 'Export',
    second: 'Second',
    internal_second: 'Internal second',
    enable_atms_control: 'ENABLE ATMS CONTROL',
    stage: 'Stage',
    bus_priority: 'Bus priority',
    licence_plate: 'Licence plate',
    duration: 'Duration',
    programming: 'Programming',
    cycle_length: 'Cycle length',
    fixed_time_control: 'Fixed time control',
    amber_flash: 'Amber flash',
    signals_off: 'Signals off',
    demand: 'Demand',
    privilege: 'Privilege',
    greska_pojedinog_virtualnog_detektora: 'Error of individual virtual detector',
    verifikacija: 'verification',
    crveno: 'red',
    nema_signala: 'no signal',
    pojava_signala: 'signal detected',
    zuto: 'yellow',
    zeleno: 'green',
    grupa: 'group',
    kontrolirano_crveno: 'controlled red',
    dozvoljena_greska_snage: 'non critical power error',
    greska_siga_br: 'Error on SIG card nr.',
    varijabilni_gain_nije_podrzan: 'Variable GAIN is not supported!',
    napon_u1_je_van_tolerancije: 'Voltage U1 is out of tolerance. The nominal voltage values of U1 are 4.8V < U1 < 5.3V',
    napon_u2_je_van_tolerancije: 'Voltage U2 is out of tolerance. The nominal voltage values of U1 are 23.7V < U1 < 24.3V',
    nepodudarnost_kontrolnog_broja_cps_i_upp_modula: 'CPS and UPP card control number mismatch!',
    neostvaren_zahtjev_signalne_grupe: 'Unfulfilled signaling group request',
    u_lokalnom_radu: 'in local mode',
    u_rezimu_centralnog_upravljanja: 'in central management mode',
    neispravna_kontrolna_suma_tablice_zaštitnih_vremena: 'Incorrect checksum of the conflict matrix!',
    neispravan_slijed_signala_u_signalnoj_logici: 'Incorrect signal sequence in the signal logic (inclusion of conflicting signals prevented)!',
    neispravna_kontrolna_suma_prometnih_podataka: 'Incorrect traffic data checksum!',
    neispravna_kontrolna_suma_boja_signala: 'Incorrect checksum of signal colors!',
    aktivirana_je_sklopka_ssw: 'The SSW switch is activated!',
    greska_det_br: 'Error in DET card no.',
    modul_ne_odgovara_na_upite_trafbus_protokola: 'The module does not respond to TRAFBUS protocol queries! All detectors are active!',
    greska_pojedinog_detektora: 'Detector error',
    greska_io_modula_br: 'Error of IO card no.',
    greska_linka_nema_odgovora: 'LINK error, no answer (ANSWER line error)',
    greska_linka_nema_naredbe: 'LINK error, no order (ORDER line error)',
    nedozvoljena_greska_snage: 'critical power error'
  }
}
