<template>
  <v-container fluid grid-list-md>

    <v-row>
      <v-col xs="12">
        <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title>
            <span class="text-xl-left">{{ $t('ui.programming') }}</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            dark
            icon
            @click="init()"
            :disabled="loading"
          >
            <v-icon>refresh</v-icon>
          </v-btn>
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, i) in availableActions"
                :key="i"
                link
                @click="item.action()"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
        </v-card>
        <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>
      </v-col>
    </v-row>

    <v-row v-if="programi">
      <v-col xs="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th><strong>PTP</strong></th>
                <!--<th><strong>Name</strong></th>-->
                <th><strong>{{ $t('ui.cycle_length') }} [s]</strong></th>
                <th><strong>{{ $t('ui.offset') }} [s]</strong></th>
                <th><strong>{{ $t('ui.fixed_time_control') }}</strong></th>
                <th><strong>{{ $t('ui.amber_flash') }}</strong></th>
                <th><strong>{{ $t('ui.signals_off') }}</strong></th>
                <th><strong>{{ $t('ui.actions') }}</strong></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="program in programi" :key="program.ptp">
                <td>{{program.ptp}}</td>
                <!--<td>{{program.name}}</td>-->
                <td>{{program.cycle}}</td>
                <td>{{program.offset}}</td>
                <td>{{program.ftc}}</td>
                <td>{{program.trz}}</td>
                <td>{{program.signals_off}}</td>
                <td>
                  <v-btn
                    icon
                    @click="editProgram(program)">
                    <v-icon color="primary">edit</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <form id="upload_lisa_xml_form">
      <input
        type="file"
        id="upload_lisa_xml"
        ref="uploadLisaXmlInput"
        accept=".xml"
        hidden
        v-on:change="handleImportLisaXML()"
      />
    </form>

  </v-container>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'

export default {
  name: 'McoProgramatorPage',
  mixins: [T1Mixin],
  data () {
    return {
      loading: true,
      BROJ_GRUPA: null,
      programi: null,
      actions: [
        // {'title': this.$t('ui.import'), 'action': this.import, 'needsAuth': true },
        {'title': this.$t('ui.export'), 'action': this.export, 'needsAuth': true },
        {'title': `${this.$t('ui.import')} LISA XML`, 'action': this.importLisaXML, 'needsAuth': true }
      ]
    }
  },
  computed: {
    availableActions () {
      let actions = []
      let action = null
      for (let i = 0; i < this.actions.length; i++) {
        action = this.actions[i]
        if (action.needsAuth === true) {
          if (this.isAuthenticated) {
            actions.push(action)
          }
        } else {
          actions.push(action)
        }
      }

      return actions
    }
  },
  methods: {
    init () {
      this.initT1Data()
    },
    initT1Data () {
      this.loading = true

      var promises = []

      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, this.t1.BROJ_GRUPA]))
      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x54, 1]))
      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x54, 2]))
      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x54, 3]))
      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x54, 4]))
      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x54, 5]))
      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x54, 6]))
      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x54, 7]))
      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x54, 8]))
      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x54, 9]))

      Promise.all(promises)
        .then(([BROJ_GRUPA, NMCO_DATA_1, NMCO_DATA_2, NMCO_DATA_3, NMCO_DATA_4,
          NMCO_DATA_5, NMCO_DATA_6, NMCO_DATA_7, NMCO_DATA_8, NMCO_DATA_9]) => {
          this.BROJ_GRUPA = BROJ_GRUPA[0]
          this.programi = []


          this.programi[0] = this.getProgramObject(0, NMCO_DATA_9)
          this.programi[1] = this.getProgramObject(1, NMCO_DATA_1)
          this.programi[2] = this.getProgramObject(2, NMCO_DATA_2)
          this.programi[3] = this.getProgramObject(3, NMCO_DATA_3)
          this.programi[4] = this.getProgramObject(4, NMCO_DATA_4)
          this.programi[5] = this.getProgramObject(5, NMCO_DATA_5)
          this.programi[6] = this.getProgramObject(6, NMCO_DATA_6)
          this.programi[7] = this.getProgramObject(7, NMCO_DATA_7)
          this.programi[8] = this.getProgramObject(8, NMCO_DATA_8)
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'error',
            text: this.$t('ui.error')
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    maybeNull(data) {
      if (data === 255) {
        return null
      }
      return data
    },
    getProgramObject (ptp, NMCO_DATA) {
      let program = {
        'ptp': ptp,
        'name': null,
        'cycle': this.maybeNull(NMCO_DATA[1]),
        'offset': this.maybeNull(NMCO_DATA[2]),
        'ftc': this.maybeNull(NMCO_DATA[3]),
        'trz': this.maybeNull(NMCO_DATA[4]),
        'signals_off': this.maybeNull(NMCO_DATA[5])
      }

      if (program.ftc === 0) {
        program.ftc = this.$t('ui.no')
      } else {
        program.ftc = this.$t('ui.yes')
      }

      if (program.trz === 0) {
        program.trz = this.$t('ui.no')
      } else {
        program.trz = this.$t('ui.yes')
      }

      if (program.signals_off === 0) {
        program.signals_off = this.$t('ui.no')
      } else {
        program.signals_off = this.$t('ui.yes')
      }

      return program
    },
    editProgram (program) {
      this.$router.push({
        name: 'McoProgramatorEditPage',
        params: { PTP: program.ptp }
      })
    },
    export () {
      this.loading = true
      let promises = []

      promises.push(this.getNmcoPtpGeneralData())
      promises.push(this.getNmcoPtpGrupeData(this.BROJ_GRUPA))

      Promise.all(promises)
        .then(([NMCO_GENERAL_DATA, NMCO_PTP_GRUPE_DATA]) => {
          console.log(NMCO_GENERAL_DATA)
          console.log(NMCO_PTP_GRUPE_DATA)

          let file = new Blob([], {type: 'text/plain'})
          let a = document.createElement('a'), url = URL.createObjectURL(file)
          a.href = url
          a.download = 'raspored.csv'
          document.body.appendChild(a)
          a.click()
          setTimeout(function() {
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)
          }, 0)

        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'error',
            text: this.$t('ui.error')
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    downloadBackup () {
      return new Promise((resolve, reject) => {
        this.getNmcoPtpGeneralData()
          .then((NMCO_GENERAL_DATA) => {
            resolve(NMCO_GENERAL_DATA)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    importLisaXML () {
      var uploadInput = this.jQuery('#upload_lisa_xml')
      uploadInput.trigger('click')
    },
    handleImportLisaXML () {
      this.loading = true
      let uploadFiles = this.$refs.uploadLisaXmlInput.files
      const reader = new FileReader()
      let programsData = []

      reader.addEventListener('load', (event) => {
        try {
          let xmlString = event.target.result
          const parser = new DOMParser()
          const xmlDoc = parser.parseFromString(xmlString, 'text/xml')

          // Extracting data from the parsed XML
          const programi = xmlDoc.getElementsByTagName('Signalprogramm')
          for (let i = 0; i < programi.length; i++) {
            const program = programi[i]
            let cycle = parseInt(program.getElementsByTagName('TU')[0].textContent)
            let offset = cycle - parseInt(program.getElementsByTagName('SignalzeitenVersatz')[0].textContent)
            let programData = {
              'name': program.getElementsByTagName('Bezeichnung')[0].textContent,
              'import': true,
              'cycle': cycle,
              'offset': offset,
              'ftc': 1,
              'trz': 0,
              'signals_off': 0,
              'grupe': []
            }
            const grupe = program.getElementsByTagName('SPZeile')

            for (let j = 0; j < grupe.length; j++) {
              const grupa = grupe[j]
              programData['grupe'].push({
                'name': grupa.getElementsByTagName('Signalgruppe')[0].textContent,
                'start': parseInt(grupa.getElementsByTagName('Schaltzeitpunkt')[0].textContent),
                'stop': parseInt(grupa.getElementsByTagName('Schaltzeitpunkt')[1].textContent)
              })
            }
            programsData.push(programData)
          }
          this.loading = false
          this.$router.push({
            name: 'McoProgramatorImportXmlPage',
            params: { programsData: programsData }
          })
        } catch(e) {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'error',
            text: e
          })
          this.jQuery('#upload_lisa_xml').val(null)
          this.loading = false
        }
      })
      reader.readAsText(uploadFiles[0])
    }
  },
  created () {
    this.init()
  }
}
</script>
