<template>
  <v-container fluid grid-list-md>

    <v-row>
      <v-col xs="12">
        <v-card>
          <v-toolbar dense flat>
            <v-btn
              icon
              @click="add()">
              <v-icon>add_circle</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="remove()">
              <v-icon>remove_circle</v-icon>
            </v-btn>
            <v-btn @click="restart()">RESTART</v-btn>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>

    <v-row >
      <v-col xs="12" style="background-color: #fff;">
        <app-t1-ciklus
          v-if="trafficController.broj_grupa > 0"
          :traffic-controller="trafficController"
          :events="events"
          :rezolucija="rezolucija"
          ref="ciklus"
        ></app-t1-ciklus>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import ConfigMixin from '@/mixins/ConfigMixin'
import T1Mixin from '@/mixins/T1Mixin'

export default {
  name: 'FlowCiklusPage',
  mixins: [ConfigMixin, T1Mixin],
  data () {
    return {
      loading: false,
      rezolucija: 2,
      greske: [],
      trafficController: {
        broj_grupa: 0,
        broj_detektora: 0
      },
      actions: [],
      OFFSET: null,
      getOffsetId: false,
    }
  },
  computed: {
    grupe () {
      let _grupe = []
      for (let i = 1; i <= this.trafficController.broj_grupa; i++) {
        _grupe.push(i)
      }

      return _grupe
    },
    trapSetIcon () {
      if (this.trapSet) {
        return 'pause'
      }

      return 'play_arrow'
    },
    availableActions () {
      let actions = []
      let action = null
      for (let i = 0; i < this.actions.length; i++) {
        action = this.actions[i]
        if (action.needsAuth === true) {
          if (this.isAuthenticated) {
            actions.push(action)
          }
        } else {
          actions.push(action)
        }
      }

      return actions
    },
    events () {
      return {
        stanje_signalnih_grupa: this.$store.getters['t1/stanjeSignala'],
        program: this.$store.getters['t1/program'],
        mcoIme: this.$store.getters['t1/mcoIme']
      }
    }
  },
  methods: {
    init () {
      this.loading = true
      this.initT1Data()
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'error',
            text: this.$t('ui.error')
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    initT1Data () {
      return new Promise((resolve, reject) => {
        this.getBrojGrupa()
          .then((BROJ_GRUPA) => {
            this.trafficController.broj_grupa = BROJ_GRUPA
            resolve()
          })
          .then(() => {
            this.t1getPodrzaniProgrami()
              .then((programi) => {
                let program = null
                this.naziviPrograma = []

                for (let i = 0; i < programi.length; i++) {
                  program = programi[i]
                  if (program.id < 255) {
                    this.naziviPrograma[program.id] = program.label
                  }
                }
              })
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    restart () {
      this.$refs['ciklus'].init()
    },
    add () {
      this.rezolucija = this.rezolucija + 1
      if (this.rezolucija > 5 ) {
        this.rezolucija = 5
      }
    },
    remove () {
      this.rezolucija = this.rezolucija - 1
      if (this.rezolucija <= 0 ) {
        this.rezolucija = 1
      }
    }
  },
  created () {
    this.init()
  }
}
</script>
