<template>
  <v-layout row justify-center>
    <v-dialog v-model="openDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="headline" primary-title>{{headline}}</v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>

              <v-flex xs12>
                <v-select
                  v-model="camera"
                  :items="cameras"
                  :label="this.$t('ui.cameras')"
                  item-text="ip"
                  return-object
                ></v-select>
              </v-flex>

              <v-flex xs12 lg3>
                <v-select
                  v-model="zone.logika"
                  :items="logika"
                  label="LOGIKA"
                ></v-select>
              </v-flex>

              <v-flex xs12 lg3>
                <v-select
                  v-model="zone.zone"
                  :items="zones"
                  multiple
                  label="ZONA"
                ></v-select>
              </v-flex>

              <v-flex xs12 lg6>
                <v-select
                  v-model="zone.detector"
                  :items="detectors"
                  label="DETEKTOR"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red darken-1" @click="userAction({action: 'delete', camera: camera, zone: zone})">
            {{this.$t('ui.delete')}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="userAction({action: 'cancel'})">
            {{this.$t('ui.cancel')}}
          </v-btn>

          <v-btn color="green darken-1" @click="userAction({action: 'save', camera: camera, zone: zone})">
            {{this.$t('ui.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  name: 'CameraZoneEditDialog',
  props: ['openDialog', 'headline', 'editCamera', 'cameras', 'zone', 'detectors', 'zones'],
  data () {
    return {
      camera: {},
      logika: ['OR', 'AND']
    }
  },
  methods: {
    userAction: function (actionResult) {
      this.$emit('userAction', actionResult)
    }
  },
  watch: {
    editCamera: {
      handler: function () {
        this.camera = this.editCamera
      },
      deep: true
    }
  }
}
</script>
