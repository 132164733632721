<template>
  <v-layout row justify-center>
    <v-dialog v-model="openDialog" persistent max-width="750px">
      <v-card>
        <v-card-title class="headline" primary-title>DETEKTOR {{detektor}}
          <v-spacer></v-spacer>
          <v-select
            v-if="!addDisabled"
            v-model="addCamera"
            :items="selectableCams"
            :item-text="item => item.code +' - '+ item.ip"
            return-object
          ></v-select>
          <v-btn v-show="selectableCams.length > 0" @click="add(addCamera)" color="primary" :disabled="addDisabled">{{ this.$t('ui.add') }}</v-btn>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>

              <v-col xs="12">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th><strong>Kamera Šifra</strong></th>
                      <th><strong>Kamera IP</strong></th>
                      <th><strong>Detektor</strong></th>
                      <th><strong>Zone</strong></th>
                      <th>{{ $t('ui.logika') }}</th>
                      <th>{{ $t('ui.actions') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                      <tr v-for="camera in contextData" :key="camera.code">
                        <td>{{camera.code}}</td>
                        <td>
                          <v-select
                            v-if="!camera.ip"
                            v-model="camera.ip"
                            :items="selectableCams"
                            :item-text="`${code} - ${ip}`"
                            item-value="ip"
                          ></v-select>

                          <strong v-if="camera.ip">{{camera.ip}}</strong>
                        </td>
                        <td><strong>{{camera.zona.detector}}</strong></td>
                        <td>
                          <v-select
                            v-model="camera.zona.zone"
                            :items="zones"
                            multiple
                          ></v-select>
                        </td>
                        <td>
                          <v-select
                            v-model="camera.zona.logika"
                            :items="logika"
                          ></v-select>
                        </td>
                        <td>
                          <v-btn
                            icon
                            @click="deleteCamera(camera.code)">
                            <v-icon color="red">delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>

            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <!--<v-btn color="red darken-1" @click="userAction({action: 'delete', camera: camera, zone: zone})">
            {{this.$t('ui.delete')}}
          </v-btn>-->
          <v-spacer></v-spacer>
          <v-btn @click="userAction({action: 'cancel'})">
            {{this.$t('ui.cancel')}}
          </v-btn>

          <v-btn color="green darken-1" @click="userAction({'action': 'save', 'deleteZones': JSON.parse(JSON.stringify(deleteZones)), 'contextData': JSON.parse(JSON.stringify(contextData))})">
            {{this.$t('ui.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  name: 'DetektorZoneEditDialog',
  props: ['openDialog', 'headline', 'cameras', 'zone', 'detektor', 'zones', 'contextCameras'],
  data () {
    return {
      logika: ['OR', 'AND'],
      contextData: null,
      addCamera: null,
      deleteZones: []
    }
  },
  methods: {
    userAction: function (actionResult) {
      this.$emit('userAction', actionResult)
    },
    deleteCamera (cameraCode) {
      let cameraIndex = this.$lodash.findIndex(this.contextData, function (s) { return s.code === cameraCode })
      if (cameraIndex > -1) {
        this.deleteZones.push(JSON.parse(JSON.stringify(this.contextData[cameraIndex])))
        this.contextData.splice(cameraIndex, 1)
      }
    },
    add (camera) {
      if (!camera) return false
      camera.zona.detector = this.detektor
      camera.zona.logika = 'OR'
      this.contextData.push(camera)
      this.addCamera = null
    }
  },
  computed: {
    contextDataIps () {
      if (!this.contextData) return []

      return this.contextData.map((cam) => {
        return cam.ip
      })
    },
    selectableCams () {
      let selectable = this.cameras.filter((possibleCam) => {
        if (this.contextDataIps.includes(possibleCam.ip) != true) {
          return true
        } else {
          return false
        }
      })

      if (!selectable) return []

      return selectable.map((cam) => {
        return {
          'code': cam.code,
          'name': cam.name,
          'ip': cam.ip,
          'key': cam.key,
          'zona': {
            'zone': [1]
          }
        }
      })

    },
    addDisabled () {

      if (!this.contextData) return true

      if (this.selectableCams.length === 0) return true

      let filtered = this.contextData.filter((cam) => {
        if (cam.ip) {
          return false
        } else {
          return true
        }
      })

      if (filtered.length > 0) {
        return true
      }

      return false
    }
  },
  watch: {
    openDialog (newVal) {
      if (newVal === true) {
        this.deleteZones = []
        this.contextData = JSON.parse(JSON.stringify(this.contextCameras))
      } else {
        this.contextData = null
      }
    }
  }
}
</script>
