<template>
  <v-container fluid grid-list-md>
    <v-row>
      <v-col xs="12" md="4">
        <app-traffic-controller-config-card></app-traffic-controller-config-card>
      </v-col>
      <v-col xs="12" md="8">
        <app-supervisor-job-log-card v-if="supervisorConfigKey" :supervisor-config-key="supervisorConfigKey"></app-supervisor-job-log-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Dashboard',
  data () {
    return {
      supervisorConfigKey: 'traffic_controller_backend'
    }
  },
  computed: {
    DEVICE_TYPE () {
      return this.$store.getters['app/config']['DEVICE_TYPE']
    }
  }
}
</script>
