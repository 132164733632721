export default {
  data () {
    return {

    }
  },
  methods: {
    s1GetNavItems () {
      return [
        {
          router_link: '/s-1/dev',
          text: this.$t('ui.razvojna_konzola'),
          show: true
        }
      ]
    },
    s1Write (action) {
      return new Promise((resolve, reject) => {
        this.$t1.get(`/s-1/?action=${action}`, {
          baseURL: this.$store.getters['app/TRAFFIC_CONTROLLER_API_URI']
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })

      })
    },
  }
}
