<template>
  <v-container fluid grid-list-md>

    <v-row v-if="VPROG_SUPPORTED">
      <v-col xs="12" md="6">
        <app-t1-programski-rad-card v-if="config.T1_CU_KONTROLER_ENABLED=='1'"></app-t1-programski-rad-card>
        <app-t1-programski-rad-card-old v-if="config.T1_CU_KONTROLER_ENABLED!='1'"></app-t1-programski-rad-card-old>
      </v-col>
      <v-col xs="12" md="6">
        <app-t1-maksimalno-zeleno-card></app-t1-maksimalno-zeleno-card>
      </v-col>
    </v-row>

    <v-row v-if="VPROG_SUPPORTED">
      <v-col xs="12">
        <app-t1-nazivi-programa-card></app-t1-nazivi-programa-card>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col xs="12">
        <v-alert type="error">
          {{ this.$t('ui.vprog_rad_not_supported') }}
        </v-alert>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import ConfigMixin from '@/mixins/ConfigMixin'

export default {
  name: 't1ProgramskiRad',
  mixins: [ConfigMixin, T1Mixin],
  data () {
    return {
      VPROG_SUPPORTED: false,
      config: {
        T1_CU_KONTROLER_ENABLED: false
      }
    }
  },
  methods: {
    getVprogConfig () {
      return new Promise((resolve, reject) => {
        this.t1Write([this.t1.CPS, this.t1.READ, 0x47])
          .then((VPROG_CONFIG) => {
            if (VPROG_CONFIG[0] == 102) {
              this.VPROG_SUPPORTED = false
            } else {
              this.VPROG_SUPPORTED = true
            }
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    }
  },
  created () {
    this.initConfigData()
      .then(this.getVprogConfig)
  }
}
</script>
