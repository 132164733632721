<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">{{ $t('ui.errors') }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="getGreske" color="primary" :disabled="loading">{{$t('ui.ucitaj_greske')}}</v-btn>
    </v-toolbar>
    <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr v-for="(greska, index) in greskeObj" :key="index">
              <td style="width: 25%">{{greska.kod}}</td>
              <td>{{greska.opis}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'

export default {
  name: 't1GreskeCard',
  mixins: [T1Mixin],
  data () {
    return {
      loading: false,
      GRESKE: null
    }
  },
  computed: {
    greskeObj () {
      if (!this.GRESKE) return []
      let kodovi = this.t1KodoviGresaka(this.GRESKE)
      let greskeOpisi = []

      for (let i = 0; i < kodovi.length; i++) {
        let kod = kodovi[i]
        greskeOpisi.push({kod: kod, opis: this.t1OpisGreske(kod)})
      }

      return greskeOpisi
    }
  },
  methods: {

    getGreske () {
      this.loading = true

      this.t1Write([this.t1.CPS, this.t1.READ, this.t1.GRESKE])
        .then((GRESKE) => {
          this.GRESKE = GRESKE
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    this.getGreske()
  }
}
</script>
