<template>
  <v-card>
    <v-toolbar flat dense>
      <v-toolbar-title>
        <span class="text-xl-left">{{ this.$t('ui.cameras_detectors_backend') }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        @click="doStartSupervisorJob(supervisorConfigKey)">
        <v-icon>play_arrow</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="doStopSupervisorJob(supervisorConfigKey)">
        <v-icon>pause</v-icon>
      </v-btn>
    </v-toolbar>

    <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>

    <v-card-text>
      <v-flex xs12>
        <v-select
          :items="supervisorJobStatusItems"
          item-text="name"
          item-value="id"
          v-model="config.CAMERAS_HANDLER_ENABLED"
        ></v-select>
      </v-flex>
    </v-card-text>

    <v-card-actions>
      <v-btn
        @click="submit"
        color="primary"
      >{{ this.$t('ui.submit') }}</v-btn>
      <v-spacer></v-spacer>
      {{supervisorJobStatusText}}
    </v-card-actions>

  </v-card>
</template>

<script>
import ConfigMixin from '@/mixins/ConfigMixin'
import SupervisorMixin from '@/mixins/SupervisorMixin'

export default {
  name: 'CamerasHandlerCard',
  mixins: [ConfigMixin, SupervisorMixin],
  data () {
    return {
      supervisorConfigKey: 'cameras_handler',
      loading: false,
      config: {
        CAMERAS_HANDLER_ENABLED: null
      }
    }
  },
  computed: {
    isFormValid () {
      return !this.$v.$invalid
    },
    jobAutoStart () {
      if (this.config.CAMERAS_HANDLER_ENABLED === '1') {
        return true
      }

      return false
    },
    supervisorTemplate () {
      return `[program:${this.supervisorConfigKey}]
command=/opt/.virtualenvs/trafconnect_v2/bin/python /opt/trafsignal/apps/kamere/app.py
directory=/opt/trafsignal/apps/kamere
environment=PYTHONPATH="/opt"
priority=1
autostart=${this.jobAutoStart}
autorestart=${this.jobAutoStart}
startretries=999
user=root
stdout_logfile_maxbytes=10KB
stdout_logfile_backups=0
stderr_logfile_maxbytes=10KB
stderr_logfile_backups=0
stdout_capture_maxbytes=10KB
`
    }
  },
  methods: {
    init () {
      this.initConfigData()
    },
    submit () {
      this.loading = true
      var promises = []

      promises.push(this.createConfig({
        'key': 'CAMERAS_HANDLER_ENABLED',
        'value': this.config.CAMERAS_HANDLER_ENABLED
      }))

      promises.push(this.createSupervisorConfig({
        'key': this.supervisorConfigKey,
        'config': this.supervisorTemplate
      }))

      Promise.all(promises)
        .then(() => {

          // svi upiti su gotovi
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })

        })
        .finally(() => {
          this.loading = false
          this.init()
        })
    }
  },
  created () {
    this.init()
    this.getJobStatusIntervalId = setInterval(() => {
      if (!this.statusUpdating) {
        this.updateSupervisorJobStatus(this.supervisorConfigKey)
      }
    }, 5000)
  },
  destroyed () {
    if (this.getJobStatusIntervalId) {
      clearInterval(this.getJobStatusIntervalId)
    }
  }
}
</script>
