<template>
  <v-container fluid grid-list-md>
    <v-row>
      <v-col xs="12">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ this.$t('ui.ciklus') }}</span>
            </v-toolbar-title>
            <v-btn
              icon
              @click="add()">
              <v-icon>add_circle</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="remove()">
              <v-icon>remove_circle</v-icon>
            </v-btn>
            <v-btn @click="restart()">RESTART</v-btn>
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>
    <v-row style="background-color: #fff;">
      <v-col xs="12">
        <app-t2000-ciklus
          v-if="trafficController.broj_grupa > 0"
          :traffic-controller="trafficController"
          :events="events"
          :rezolucija="rezolucija"
          ref="ciklus"
        ></app-t2000-ciklus>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" lg="6">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">INFO</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td><strong>{{ $t('ui.program') }}</strong></td>
                    <td>{{ currentProgram }}</td>
                  </tr>
                  <tr>
                    <td><strong>{{ $t('ui.phase') }}</strong></td>
                    <td>{{ currentPhase }}</td>
                  </tr>
                  <tr>
                    <td><strong>{{ $t('ui.cycle') }}</strong></td>
                    <td>{{ currentCycle }}</td>
                  </tr>
                  <tr>
                    <td><strong>{{ $t('ui.offset') }}</strong></td>
                    <td>{{ currentOffset }}</td>
                  </tr>
                  <tr>
                    <td><strong>{{ $t('ui.second') }}</strong></td>
                    <td>{{ commonSecond }}</td>
                  </tr>
                  <tr>
                    <td><strong>{{ $t('ui.internal_second') }}</strong></td>
                    <td>{{ internalSecond }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import ConfigMixin from '@/mixins/ConfigMixin'

export default {
  name: 'EtxCiklusPage',
  mixins: [validationMixin, ConfigMixin],
  data () {
    return {
      loading: false,
      rezolucija: this.$store.getters['app/TELVENT_CIKLUS_REZOLUCIJA'] || 5,
      greske: [],
      config: {
        TELVENT_NR_SIGNAL_GROUPS: 24
      },
      trafficController: {
        broj_grupa: 0,
        broj_detektora: 0
      }
    }
  },
  computed: {
    events () {
      return {
        stanje_signalnih_grupa: this.$store.getters['etx/stanjeSignala']
      }
    },
    currentProgram () {
      return this.$store.getters['etx/currentProgram']
    },
    currentCycle () {
      return this.$store.getters['etx/ciklus']
    },
    currentOffset () {
      return this.$store.getters['etx/offset']
    },
    currentPhase () {
      return this.$store.getters['etx/currentPhase']
    },
    commonSecond () {
      return this.$store.getters['etx/outsideSecond']
    },
    internalSecond () {
      return this.$store.getters['etx/internalSecond']
    }
  },
  methods: {
    init () {
      this.loading = true
      this.initConfigData()
        .then((data) => {
          this.trafficController.broj_grupa = parseInt(data.TELVENT_NR_SIGNAL_GROUPS)
        })
        .catch(() => {
          this.trafficController.broj_grupa = 24
        })
        .finally(() => {
          this.loading = false
        })
    },
    restart () {
      this.$refs['ciklus'].init()
    },
    add () {
      this.rezolucija = this.rezolucija + 1
      if (this.rezolucija > 5 ) {
        this.rezolucija = 5
      }
    },
    remove () {
      this.rezolucija = this.rezolucija - 1
      if (this.rezolucija <= 0 ) {
        this.rezolucija = 1
      }
    }
  },
  watch: {
    rezolucija (newRezolucija) {
      this.$store.commit('app/setLocal', {TELVENT_CIKLUS_REZOLUCIJA: newRezolucija})
    }
  },
  created () {
    this.init()
  }
}
</script>
