export default {
  data () {
    return {
      cameraTypes: [
        { 'id': 'Xcamp', 'name': 'Xcam-p' },
        { 'id': 'TrafiOne', 'name': 'TrafiOne' },
        { 'id': 'TrafiCamXstream', 'name': 'TrafiCam x-stream' },
        { 'id': 'TRAFSIGNAL_0', 'name': 'FLOW' },
        { 'id': 'xroads_connected_cam', 'name': 'XROADS'},
        { 'id': 'isapi', 'name': 'ISAPI'},
        { 'id': 'stream', 'name': 'STREAM'}
      ],
      cameraStreamTypes: [
        { 'id': 'rtsp', 'name': 'RTSP' },
        { 'id': 'frame_snap', 'name': 'HTTP IMAGE SNAP' },
        { 'id': 'mjpeg', 'name': 'MJPEG' }
      ]
    }
  }
}
