<template>
  <v-container fluid>
    <v-col xs="12">
      <v-tabs
        v-model="activeTab"
        color="grey darken-1"
        slider-color="primary"
      >
        <v-tab v-for="tab in tabs" :key="tab.id">
          {{tab.label}}
        </v-tab>

        <v-tab-item key="cameras_crud">
          <app-cameras-crud
            :traffic-controller="trafficController"
            :stanje-signala="stanjeSignala"
            :stanje-detektora="stanjeDetektora">
          </app-cameras-crud>
          <v-container fluid>
            <v-row dense>
              <v-col xs="12">
                <app-detektori-stalne-najave-card ref="stalneNajave" :cameras="cameras"></app-detektori-stalne-najave-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <v-tab-item key="cameras_handler_job">
          <v-col xs="12">
            <v-layout row wrap>
              <v-col xs="12" lg="6">
                <app-cameras-handler-card></app-cameras-handler-card>
              </v-col>
              <v-col xs="12" lg="6">
                <app-supervisor-job-log-card supervisor-config-key="cameras_handler"></app-supervisor-job-log-card>
              </v-col>
            </v-layout>
          </v-col>
        </v-tab-item>

        <v-tab-item key="cameras_feed_server_job">
          <v-col xs="12">
            <v-layout row wrap>
              <v-col xs="12" lg="6">
                <app-cameras-feed-server-card></app-cameras-feed-server-card>
              </v-col>
              <v-col xs="12" lg="6">
                <app-supervisor-job-log-card supervisor-config-key="cameras_feed_server"></app-supervisor-job-log-card>
              </v-col>
            </v-layout>
          </v-col>
        </v-tab-item>

      </v-tabs>
    </v-col>
  </v-container>
</template>

<script>
import ConfigMixin from '@/mixins/ConfigMixin'
export default {
  name: 'CamerasPage',
  mixins: [ConfigMixin],
  data () {
    return {
      activeTab: null,
      tabs: [
        {
          id: 'cameras_crud',
          label: this.$t('ui.cameras')
        },
        {
          id: 'cameras_handler_job',
          label: this.$t('ui.cameras_detectors_backend')
        },
        {
          id: 'cameras_feed_server_job',
          label: this.$t('ui.video_feed_server')
        }
      ],
      loading: false,
      config: {
        DEVICE_CODE: null,
        DEVICE_TYPE: null,
        TLOCRT: null,
        TLOCRT_COLOR: '#fff'
      },
      layers: [],
      t: Date.now(),
      cameras: []
    }
  },
  computed: {
    trafficController () {
      return {
        code: this.config.DEVICE_CODE,
        type: this.config.DEVICE_TYPE,
        svg_link: `${this.$store.getters['app/APP_API_URI']}/tlocrt/?t=${this.t}`,
        svg_background_color: this.config.TLOCRT_COLOR
      }
    },
    statusnaRijec () {
      return this.$store.getters['t1/statusnaRijec']
    },
    stanjeSignala () {
      return this.$store.getters['t1/stanjeSignala']
    },
    stanjeDetektora () {
      return this.$store.getters['t1/stanjeDetektora']
    }
  },
  methods: {
    init () {
      return new Promise((resolve, reject) => {
        this.config.DEVICE_CODE = null
        this.config.TLOCRT = null
        this.t = Date.now()
        this.initConfigData()
          .then(()=>{
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  created () {
    this.init()
    this.$EventBus.$on('CameraPage/loaded', (cameras) => {
      //this.$refs.stalneNajave.camerasChanged(doSubmit)
      this.cameras = cameras
    })

    this.$EventBus.$on('CameraPage/changed', (cameras) => {
      this.cameras = cameras
    })

    this.$EventBus.$on('CameraPage/saved', (restartBackend) => {
      this.$refs.stalneNajave.submit(restartBackend)
    })



  }
}
</script>
