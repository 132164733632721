<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">TABLICA PROMJENA PROGRAMA</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="isAuthenticated" @click="doResetMatricePaljenja()" color="error" :disabled="loading">{{ $t('ui.reset_matrice_paljenja') }}</v-btn>
      <v-btn
        @click="submit"
        :loading="loading"
        :disabled="SAVE_DISABLED"
        color="primary"
        class="ml-2"
        v-if="isAuthenticated">{{ this.$t('ui.save') }}</v-btn>
    </v-toolbar>
    <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>
    <v-card-text>
      <v-alert
          type="warning"
          v-if="SHOW_WARNING"
        >
          {{WARNING_TEXT}}
      </v-alert>
      <v-simple-table v-if="config.MATRICA_PALJENJA && NAZIVI_PROGRAMA">
        <template v-slot:default>
          <tbody>
            <!-- PRVI RED -->
            <tr>
              <td><v-icon color="primary">arrow_downward</v-icon></td>
              <td v-for="n in config.MATRICA_PALJENJA.length" :key="n" style="text-align:center;">
                <!-- n pocinje sa 1 pa oduzimamo 1 jer nama lista ide od 0 -->
                <strong>{{NAZIVI_PROGRAMA[n-1]}}</strong>
              </td>
            </tr>
            <!-- /PRVI RED -->

            <tr v-for="(row, index_row) in config.MATRICA_PALJENJA" :key="index_row">
              <!-- OZNAKA PROGRAMA -->
              <td><strong>{{NAZIVI_PROGRAMA[index_row]}}</strong></td>
              <!-- /OZNAKA PROGRAMA -->

              <td v-for="(col, index_col) in row" :key="index_col">
                <v-text-field
                  v-if="showCol(index_row, index_col)"
                  v-model.number="config.MATRICA_PALJENJA[index_row][index_col]"
                  type="number"
                >
                </v-text-field>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'

import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'
import ConfigMixin from '@/mixins/ConfigMixin'

export default {
  name: 't1TablicaPromjenaPrograma',
  mixins: [validationMixin, ConfigMixin, AuthMixin, T1Mixin],
  props: ['programi'],
  data () {
    return {
      loading: false,
      NAZIVI_PROGRAMA: null,
      RESET_WARNING: false,
      ISPUNI_MATRICU_ISPRAVNO_WARNING: false,
      configMeta: {
        MATRICA_PALJENJA: {
          type: Array
        }
      },
      config: {
        MATRICA_PALJENJA: null
      }
    }
  },
  computed: {
    SHOW_WARNING () {
      if (!this.provjeriMatricuPaljenja()) return true
      return false
    },
    WARNING_TEXT () {
      if (this.RESET_WARNING) {
        return this.$t('ui.reset_matrice_paljenja_programa_warning')
      } else if (this.ISPUNI_MATRICU_ISPRAVNO_WARNING) {
        return this.$t('ui.ispuni_matricu_paljenja_ispravno')
      }

      return ''
    },
    SAVE_DISABLED () {
      if (this.loading || this.SHOW_WARNING) return true
      return false
    }
  },
  methods: {
    init () {
      this.loading = true

      this.initConfigData()
        .then(this.provjeriMatricuPaljenja)
        .then((MATRICA_OK) => {
          if (MATRICA_OK !== true) {
            this.resetMatricePaljenja()
          }
        })
        .then(() => {
          let naziviPrograma = []
          this.t1getPodrzaniProgrami()
            .then((programi) => {
              let program = null

              for (let i = 0; i < programi.length; i++) {
                program = programi[i]
                if (program.id < 255) {
                  naziviPrograma[program.id] = program.label
                }
              }
              this.NAZIVI_PROGRAMA = naziviPrograma

            })
        })
        .finally(() => {
          this.loading = false
        })
    },
    provjeriMatricuPaljenja () {
      this.RESET_WARNING = false
      this.ISPUNI_MATRICU_ISPRAVNO_WARNING = false

      if (this.config.MATRICA_PALJENJA && this.config.MATRICA_PALJENJA.length > 0) {

        // Ako je matrica vec definirana u bazi, provjeri da li se broj programa u matrici podudara sa brojem programa u uređaju
        let kolikoPrograma = this.programi[1]

        if (kolikoPrograma === this.config.MATRICA_PALJENJA.length) {

          let val = null

          for (let row = 0; row < this.config.MATRICA_PALJENJA.length; row++) {
            for (let col = 0; col < this.config.MATRICA_PALJENJA[row].length; col++) {
              val = this.config.MATRICA_PALJENJA[row][col]

              if (row != col && !Number.isInteger(val)) {
                // provjeri da li je upisan broj, ignoriraj ako isti program skace u isti program jer je to nemoguce i pise null
                this.ISPUNI_MATRICU_ISPRAVNO_WARNING = true
                return false
              } else if (row != col && val < 0) {
                // provjeri da li je upisan broj veci od 0, ignoriraj ako isti program skace u isti program jer je to nemoguce i pise null
                this.ISPUNI_MATRICU_ISPRAVNO_WARNING = true
                return false
              }
            }
          }

          return true
        } else {
          this.RESET_WARNING = true
          return false
        }

      } else {

        // matrica nije definirana
        return false
      }
    },
    resetMatricePaljenja () {

      let i = this.programi[1]
      let n = 0
      this.config.MATRICA_PALJENJA = []

      // t-1 zadnji program gasi signale, ignoriraj ga
      while (n < i) {
        let x = 0
        let p = []

        while (x < i) {
          p.push(null)
          x += 1
        }

        this.config.MATRICA_PALJENJA.push(p)

        n += 1
      }
    },
    doResetMatricePaljenja () {
      this.resetMatricePaljenja()
      this.provjeriMatricuPaljenja()
    },
    showCol (index_row, index_col) {
      if (index_row === index_col) return false
      return true
    },
    submit () {

      if (!this.provjeriMatricuPaljenja()) {
        return false
      }

      this.loading = true
      let promises = []

      for (let row = 0; row < this.config.MATRICA_PALJENJA.length; row++) {
        for (let col = 0; col < this.config.MATRICA_PALJENJA[row].length; col++) {
          if (!Number.isInteger(this.config.MATRICA_PALJENJA[row][col])) {
            this.config.MATRICA_PALJENJA[row][col] = null
          }
        }
      }

      promises.push(this.createConfig({
        'key': 'MATRICA_PALJENJA',
        'value': this.config.MATRICA_PALJENJA
      }))

      Promise.all(promises)
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .finally(() => {
          this.loading = false
          this.init()
        })

    }
  },
  created () {
    this.init()
  }
}
</script>
