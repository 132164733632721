<template>
  <v-container fluid grid-list-md>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ this.$t('ui.network_settings') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              v-show="config.SYSTEM_HW === 'kontron_alpitron_cm3+'"
              @click="submit"
              color="primary"
            >{{ this.$t('ui.submit') }}</v-btn>
          </v-toolbar>
        </v-card>
        <v-progress-linear
          v-show="loading"
          color="primary"
          indeterminate
        ></v-progress-linear>
      </v-flex>
    </v-layout>

    <v-layout row wrap>
      <v-flex xs12 md4 v-for="network in config.NETWORK_CONFIG" :key="network.device">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ network.label }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <app-network-settings-form :settings="network"></app-network-settings-form>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12 md4>
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left"></span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              dark
              icon
              @click="init()"
              :disabled="loading"
            >
              <v-icon>refresh</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-textarea
              box
              rows=20
              v-model="ifconfig"
            ></v-textarea>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="openRedirectDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline" primary-title>{{$t('ui.waiting')}}</v-card-title>
        <v-card-text>
          <p style="text-align: center;" class="text-h1">{{ timer }}</p>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import ConfigMixin from '@/mixins/ConfigMixin'

export default {
  name: 'NetworkSettingsPage',
  mixins: [ConfigMixin],
  data () {
    return {
      loading: false,
      openRedirectDialog: false,
      timer: 100,
      configMeta: {
        NETWORK_CONFIG: {
          type: Array
        }
      },
      config: {
        NETWORK_CONFIG: null,
        SYSTEM_HW: 'NPE'
      },
      ifconfig: ''
    }
  },
  computed: {
    isFormValid () {
      return !this.$v.$invalid
    },
    submitAvailable () {
      if (!this.loading && this.isFormValid) {
        return true
      }
      return false
    }
  },
  methods: {
    init () {
      this.loading = true
      this.initConfigData()
        .then((config) => {
          if (config.NETWORK_CONFIG === null) {
            this.config.NETWORK_CONFIG = [
              {
                label: 'lan0',
                device: 'eth0',
                ip_address_type: 'static',
                ip_address: '192.168.0.21',
                gateway: '192.168.0.1',
                netmask: '255.255.255.0',
                dns: ['1.1.1.1', '8.8.8.8', '208.67.220.220']
              },
              {
                label: 'lan1',
                device: 'eth1',
                ip_address_type: 'dhcp',
                dns: ['1.1.1.1', '8.8.8.8', '208.67.220.220']
              }
            ]
          }
        })
        .then(this.getIfConfig)
        .then((response) => {
          this.ifconfig = response.data
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false
          }, 200)
        })
    },
    submit () {
      this.loading = true
      var promises = []

      promises.push(this.createConfig({
        'key': 'NETWORK_CONFIG',
        'value': this.config.NETWORK_CONFIG
      }))

      promises.push(this.saveNetworkConfig(this.config.NETWORK_CONFIG))

      Promise.all(promises)
        .then(() => {

          // svi upiti su gotovi
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })

          this.openRedirectDialog = true

          setTimeout(() => {
            window.location.assign(`//${this.config.NETWORK_CONFIG[0]['ip_address']}`)
          }, this.timer * 1000)

          setInterval(() => {
            if (this.timer > 0) {
              this.timer -= 1
            }
          }, 1000)

        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false
            this.init()
          }, 200)
        })
    }
  },
  created () {
    this.init()
  }
}
</script>
