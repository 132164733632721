<template>
  <v-container fluid grid-list-md>
    <v-row>
      <v-col>
        <v-toolbar dense flat>
          <v-toolbar-title>
            <span class="text-xl-left">{{ this.$t('ui.centar') }}</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="addCentar" color="primary" class="mr-6">{{ this.$t('ui.add') }}</v-btn>
          <v-btn @click="submit" color="primary">{{ this.$t('ui.submit') }}</v-btn>
        </v-toolbar>
        <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>
      </v-col>
    </v-row>

    <v-row v-for="centar in centri" v-bind:key="centar.supervisor_key">
      <v-col xs="12" md="4">
        <app-centar-card :config="centar"></app-centar-card>
      </v-col>
      <v-col xs="12" md="8">
        <app-supervisor-job-log-card :supervisor-config-key="centar.supervisor_key"></app-supervisor-job-log-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import ConfigMixin from '@/mixins/ConfigMixin'
import SupervisorMixin from '@/mixins/SupervisorMixin'
import { EventBus } from '@/services/EventBus'

export default {
  name: 'CentarPage',
  mixins: [ConfigMixin, SupervisorMixin],
  data () {
    return {
      configKey: 'CENTAR_CONFIGS',
      loading: false,
      centri: [],
      centarConfigs: {}
    }
  },
  methods: {
    init () {
      this.loading = true

      this.getConfigByKey(this.configKey)
        .then((response) => {
          this.centri = JSON.parse(response.data.value)
        })
        .finally(() => {
          this.loading = false
        })
    },
    uniqueId (prefix, idCounter) {
      var id = ++idCounter
      return prefix + id
    },
    addCentar () {
      var idCounter = 0

      for (var i = 0; i < this.centri.length; i++) {
        var centar = this.centri[i]
        var x = centar.supervisor_key.split('_')
        idCounter = parseInt(x[x.length - 1])
      }

      this.centri.push({
        'name': 'Centar',
        'enabled': '1',
        'supervisor_key': `${this.uniqueId('notify_centar_', idCounter)}`,
        'api_url': 'http://46.101.124.108/api/v2/tce/',
        'device_type': 'T-1',
        'device_code': 'K000',
        'device_backend_url': 'http://127.0.0.1:8888/t-1/za-centar/',
        'debug': false,
        'template': ''
      })
    },
    submit () {
      this.loading = true

      this.createConfig({
        'key': this.configKey,
        'value': this.centri
      })
      .then((response) => {
        this.centri = JSON.parse(response.data.value)
        var promises = []

        for (var i = 0; i < this.centri.length; i++) {

          promises.push(this.createSupervisorConfig({
            'key': this.centri[i].supervisor_key,
            'config': this.centri[i].template
          }))

        }

        Promise.all(promises)
          .finally(() => {
            this.loading = false
          })

      })
    }
  },
  created () {
    var self = this
    this.init()
    this.$on('supervisor-job-deleted', (e) => {
      this.loading = true
      this.doDeleteSupervisorJob(this.supervisorConfigKey)
      .then(() => {
        var makniStavkuIndex = this.$lodash.findIndex(this.centri, function (s) { return s.supervisor_key === e.key })
        if (makniStavkuIndex > -1) {
          this.centri.splice(makniStavkuIndex, 1)
          this.submit()
        }
      })
      .catch((error) => {
        console.log(error)
        EventBus.$emit('AppSnackbar/show', {
          color: 'red',
          text: self.$t('ui.save_error')
        })
      })
    })
  },
  destroyed () {
    EventBus.$off('supervisor-job-deleted')
  }
}
</script>
