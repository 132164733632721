<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">{{ $t('ui.programski_rad') }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="isAuthenticated" @click="promjeniAktivniProgram()" color="primary" :disabled="saveDisabled">{{ $t('ui.save') }}</v-btn>
    </v-toolbar>
    <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr></tr>
            <tr></tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ $t('ui.trenutni_ptp') }}: {{trenutniPTP}}</td>
              <td>{{ $t('ui.mod_rada') }}: {{modRada}}</td>
            </tr>
            <tr>
              <td>{{ $t('ui.promjeni_aktivni_program')}}</td>
              <td>
                <v-select
                  :disabled="!isAuthenticated"
                  :items="T1_PROGRAMI"
                  :label="$t('ui.program')"
                  item-text="program"
                  return-object
                  v-model="odabraniProgram"
                ></v-select>
                <!--<v-select
                  :disabled="!isAuthenticated"
                  :items="podrzaniProgrami"
                  item-text="label"
                  item-value="id"
                  v-model="odabraniProgram"
                ></v-select>-->
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'
import RedisMixin from '@/mixins/RedisMixin'
import ConfigMixin from '@/mixins/ConfigMixin'

export default {
  name: 't1ProgramskiRadCard',
  mixins: [ConfigMixin, T1Mixin, RedisMixin, AuthMixin],
  data () {
    return {
      config: {
        T1_URNIK_ODABRANI_PROGRAM: null
      },
      configMeta: {
        T1_URNIK_ODABRANI_PROGRAM: {
          type: Object
        }
      },
      loading: false,
      odabraniProgram: null,
      VPROG_CONFIG: null,
      getT1DataId: null,
      VPROG_NOT_SUPPORTED: false,
      podrzaniProgrami: [],
      T1_PROGRAMI: []
    }
  },
  computed: {
    saveDisabled () {
      if (this.loading) return true
      return false
    },
    modRada () {
      if (!this.VPROG_CONFIG) return ''

      if (parseInt(this.VPROG_CONFIG[3]) != 255) {
        return this.$t('ui.rucni_odabir')
      }

      return this.$t('ui.automatski')
    },
    vProgAktivan () {
      if (!this.VPROG_CONFIG) return ''
      return parseInt(this.VPROG_CONFIG[0])
    },
    trenutniPTP () {
      if (!this.VPROG_CONFIG) return ''
      if (this.VPROG_CONFIG[2]) {
        let ptp = parseInt(this.VPROG_CONFIG[2])

        var index = this.$lodash.findIndex(
          this.T1_PROGRAMI,
          (s) => { return s.programId === ptp }
        )

        if (index > 0) {
          return `${this.T1_PROGRAMI[index]['program']} (${ptp})`
        } else {
          return ptp
        }
      }
      return ''
    },
    aktivanProgram () {
      if (!this.VPROG_CONFIG) return ''
      return parseInt(this.VPROG_CONFIG[3])
    }
  },
  methods: {
    init () {
      this.loading = true
      this.initConfigData()
        .then((CONFIG) => {
          this.odabraniProgram = CONFIG['T1_URNIK_ODABRANI_PROGRAM']
        })
        .then(this.initT1Data())
        .finally(() => {
          this.loading = false
        })
    },
    getVprogConfig () {
      this.t1Write([this.t1.CPS, this.t1.READ, 0x47])
        .then((VPROG_CONFIG) => {
          if (VPROG_CONFIG[0] == 102) {
            this.VPROG_CONFIG = null
            this.VPROG_NOT_SUPPORTED = true
          } else {
            this.VPROG_CONFIG = VPROG_CONFIG
          }
        })
    },
    initT1Data () {
      return new Promise((resolve) => {
        let promises = []

        promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x47]))

        Promise.all(promises)
          .then(([VPROG_CONFIG]) => {
            if (VPROG_CONFIG[0] == 102) {
              this.VPROG_CONFIG = null
              this.VPROG_NOT_SUPPORTED = true
            } else {
              this.VPROG_CONFIG = VPROG_CONFIG
            }

            if (this.VPROG_CONFIG) {
              this.t1getNaziviPrograma(this.VPROG_CONFIG)
                .then((T1_PROGRAMI) => {
                  this.T1_PROGRAMI = T1_PROGRAMI.map((o) => { return {'program': o.label, 'programId': o.id} })
                })
            }
          })
          .finally(() => {
            resolve()
          })
      })
    },
    promjeniAktivniProgram () {
      this.loading = true

      this.createConfig({
        'key': 'T1_URNIK_ODABRANI_PROGRAM',
        'value': JSON.stringify(this.odabraniProgram)
      })
      .then(this.setRedisKeyValue({'T1_URNIK_ODABRANI_PROGRAM': JSON.stringify(this.odabraniProgram)}))
      .then(() => {
        this.$EventBus.$emit('AppSnackbar/show', {
          color: 'success',
          text: this.$t('ui.save_success')
        })
      })
      .catch(() => {
        this.$EventBus.$emit('AppSnackbar/show', {
          color: 'red',
          text: this.$t('ui.save_error')
        })
      })
      .finally(() => {
        this.loading = false
        this.init()
      })
    }
  },
  created () {
    this.init()
    this.getT1DataId = setInterval(this.getVprogConfig, 900)
  },
  destroyed () {
    if (this.getT1DataId) {
      clearInterval(this.getT1DataId)
    }
  }
}
</script>
