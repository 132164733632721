<template>
  <v-container fluid grid-list-md>

    <v-row>
      <v-col xs="12">
        <app-t1-greske-card></app-t1-greske-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {
  name: 't1GreskePage'
}
</script>
