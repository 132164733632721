<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">{{ $t('ui.jedinice') }} / {{ $t('ui.impuls') }}</span>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-container grid>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          lg="2"
          v-for="detektor in detektori" :key="detektor.id"
          style="text-align:center"
        >
          <v-sheet
            min-height="25"
            color="grey"
          >
            <strong style="color:#00000099">{{detektor.id}}</strong>

              <v-progress-linear
                v-model="detektor.percentValue"
                color="primary"
                height="25"
                v-if="detektor.jedinice === 0 || detektor.jedinice > 0"
              >
                <strong>{{ detektor.jedinice }}</strong>
              </v-progress-linear>

              <v-progress-linear
                v-if="detektor.jedinice === 0 || detektor.jedinice > 0"
                v-model="detektor.impulsPercentValue"
                :color="detektor.color"
                height="25"
              >
                <!--<strong>{{ detektor.impuls }}</strong>-->
              </v-progress-linear>

              <v-progress-linear
                v-if="detektor.jedinice===null"
                v-model="detektor.impulsPercentValue"
                :color="detektor.color"
                height="50"
              >
                <!--<strong>{{ detektor.impuls }}</strong>-->
              </v-progress-linear>

            </v-sheet>

        </v-col>
      </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 't1DetektoriJediniceCard',
  props: ['detektori']
}
</script>
