<template>
  <v-container fluid grid-list-md>
    <v-row>
      <v-col xs="12">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ this.$t('ui.sinkro_sekunda') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon
              :loading="downloading"
              :disabled="downloading"
              @click="downloadBackup">
              <v-icon>get_app</v-icon>
            </v-btn>
            <v-btn
              v-if="isAuthenticated"
              icon
              :disabled="downloading"
              @click="selectFilesToUpload()">
              <v-icon>publish</v-icon>
            </v-btn>
            <v-btn
              @click="submit"
              color="primary"
              v-if="isAuthenticated">{{ this.$t('ui.save') }}</v-btn>
          </v-toolbar>
          <v-progress-linear
            v-show="loading"
            color="primary"
            indeterminate
          ></v-progress-linear>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <!-- CIKLUS -->
      <v-col xs="12" md="4">
        <v-card>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">PTP</th>
                    <th class="text-center">{{ $t('ui.ciklus') }}</th>
                    <th class="text-center">{{ $t('ui.sinkro_sekunda') }}</th>
                    <th class="text-center">{{ $t('ui.disabled') }}</th>
                  </tr>
                </thead>
                <tbody>
                <tr v-for="(ciklus, index) in config.RASPORED_CIKLUS" :key="index">
                  <td class="text-center">{{index}}</td>
                  <td class="text-center">
                    <v-text-field
                      type="number"
                      v-model="config.RASPORED_CIKLUS[index]"
                    ></v-text-field>
                  </td>
                  <td class="text-center">
                    <v-text-field
                      type="number"
                      v-model="config.SINKRO_SEKUNDA[index]"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-checkbox
                      v-model="config.RASPORED_SINKRO_DISABLED[index]"
                    ></v-checkbox>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- /CIKLUS -->

      <!-- APP CONFIG -->
      <v-col xs="12" md="4">
        <v-card>
          <v-toolbar flat dense>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="doStartSupervisorJob(supervisorConfigKey)">
              <v-icon>play_arrow</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="doStopSupervisorJob(supervisorConfigKey)">
              <v-icon>pause</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-col xs="12">
              <v-select
                :items="supervisorJobStatusItems"
                item-text="name"
                item-value="id"
                v-model="config.SINKRO_SEKUNDA_APP_ENABLED"
              ></v-select>
            </v-col>

            <v-col xs="12">
              <v-combobox
                v-model="config.SINKRO_SEKUNDA_APP_BACKEND_API_URL"
                :items="apiUrls"
                label="API URL"
              ></v-combobox>
            </v-col>

            <v-col xs="12">
              <v-combobox
                v-model="config.SINKRO_SEKUNDA_APP_DEVICE_BACKEND_URL"
                :items="deviceBackendUrls"
                label="DEVICE BACKEND URL"
              ></v-combobox>
            </v-col>

            <v-col xs="12">
              <v-text-field
                type="text"
                label="GPIO"
                v-model="config.SINKRO_SEKUNDA_APP_GPIO"
              ></v-text-field>
            </v-col>

            <v-col xs="12">
              <v-text-field
                type="number"
                label="TIMEOUT"
                v-model="config.SINKRO_SEKUNDA_APP_TIMEOUT"
              ></v-text-field>
            </v-col>

          </v-card-text>
        </v-card>
      </v-col>
      <!-- /APP CONFIG -->

      <!-- APP LOG -->
      <v-col xs="12" md="4">
        <app-supervisor-job-log-card v-if="supervisorConfigKey" :supervisor-config-key="supervisorConfigKey"></app-supervisor-job-log-card>
      </v-col>
      <!-- /APP LOG -->
    </v-row>

    <form id="upload_backup_form">
      <input
        type="file"
        id="upload_backup_input"
        ref="uploadBackup"
        accept=".json"
        hidden
        v-on:change="handleBackupUpload()"
      />
    </form>

  </v-container>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'
import ConfigMixin from '@/mixins/ConfigMixin'
import SupervisorMixin from '@/mixins/SupervisorMixin'

export default {
  name: 'SinkroSekundaPage',
  mixins: [T1Mixin, ConfigMixin, AuthMixin, SupervisorMixin],
  data () {
    return {
      loading: false,
      downloading: false,
      uploading: false,
      supervisorConfigKey: 't1_sinkro_sekunda',
      configMeta: {
        RASPORED_CIKLUS: {
          type: Array
        },
        SINKRO_SEKUNDA: {
          type: Array
        }
      },
      config: {
        RASPORED_CIKLUS: [60, 95, 80, 95, 60, 60, 60, 60, 60],
        RASPORED_SINKRO_DISABLED: [null, null, null, null, null, null, null, null, null],
        SINKRO_SEKUNDA: [null, null, null, null, null, null, null, null, null],
        SINKRO_SEKUNDA_APP_ENABLED: '1',
        SINKRO_SEKUNDA_APP_BACKEND_API_URL: 'http://127.0.0.1:6001',
        SINKRO_SEKUNDA_APP_DEVICE_BACKEND_URL: 'http://127.0.0.1:8888/t-1/',
        SINKRO_SEKUNDA_APP_GPIO: 'DO3',
        SINKRO_SEKUNDA_APP_TIMEOUT: '5',
        DEVICE_CODE: null
      },
      VPROG_CONFIG: null,
      apiUrls: [
        'http://127.0.0.1:6001'
      ],
      deviceBackendUrls: [
        'http://127.0.0.1:8888/t-1/'
      ]
    }
  },
  computed: {
    jobAutoStart () {
      if (this.config.SINKRO_SEKUNDA_APP_ENABLED === '1') {
        return true
      }

      return false
    },
    supervisorTemplate () {
      return `[program:${this.supervisorConfigKey}]
command=/opt/.virtualenvs/trafconnect_v2/bin/python /opt/trafsignal/apps/t1_sinkro_sekunda/app.py --gpio=${this.config.SINKRO_SEKUNDA_APP_GPIO} --timeout=${this.config.SINKRO_SEKUNDA_APP_TIMEOUT} --api_url=${this.config.SINKRO_SEKUNDA_APP_BACKEND_API_URL} --device_backend_url=${this.config.SINKRO_SEKUNDA_APP_DEVICE_BACKEND_URL}
directory=/opt/trafsignal/apps/t1_sinkro_sekunda
environment=PYTHONPATH="/opt"
priority=1
autostart=${this.jobAutoStart}
autorestart=${this.jobAutoStart}
startretries=999
user=root
stdout_logfile_maxbytes=10KB
stdout_logfile_backups=0
stderr_logfile_maxbytes=10KB
stderr_logfile_backups=0
stdout_capture_maxbytes=10KB
`
    }
  },
  methods: {
    init () {
      return new Promise((resolve, reject) => {
        this.initConfigData()
          .then(() => {
            this.config.RASPORED_SINKRO_DISABLED = JSON.parse(this.config.RASPORED_SINKRO_DISABLED)
          })
          .catch(() => {
            reject()
          })
          .then(this.initT1Data)
          .catch(() => {
            reject()
          })
          .then(() => {
            resolve()
          })
      })
    },
    initT1Data () {
      return new Promise((resolve, reject) => {
        let promises = []

        promises.push(this.t1Write([this.t1.CPS, this.t1.READ, 0x47]))

        Promise.all(promises)
          .then(([VPROG_CONFIG]) => {
            this.VPROG_CONFIG = VPROG_CONFIG
          })
          .catch(() => {
            reject()
          })
          .finally(() => {
            resolve()
          })
      })
    },
    makePTPActive (ptp) {
      this.raspored = [
        {
          'dan': 'radni_dan',
          'pos': 0,
          'program': ptp,
          'vrijeme': '00:00'
        },
        {
          'dan': 'subota',
          'pos': 1,
          'program': ptp,
          'vrijeme': '00:00'
        },
        {
          'dan': 'nedjelja',
          'pos': 2,
          'program': ptp,
          'vrijeme': '00:00'
        }
      ]
      this.submit()
    },
    submit () {
      this.loading = true
      let promises = []

      for (const key in this.config) {

        promises.push(this.createConfig({
          'key': key,
          'value': this.config[key]
        }))

      }

      promises.push(this.createSupervisorConfig({
        'key': this.supervisorConfigKey,
        'config': this.supervisorTemplate
      }))

      Promise.all(promises)
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .finally(() => {
          this.loading = false
          this.init()
        })

    },
    downloadBackup () {
      this.downloading = true
      this.getConfigByKeys(['RASPORED_CIKLUS', 'SINKRO_SEKUNDA'])
        .then((response) => {
          console.log(response)
          let file = new Blob([JSON.stringify(response.data)], {type: 'text/plain'})
          let a = document.createElement('a'), url = URL.createObjectURL(file)
          a.href = url
          a.download = `${this.config.DEVICE_CODE}_sinkro_sekunda.json`
          document.body.appendChild(a)
          a.click()
          setTimeout(function() {
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)
          }, 0)
        })
        .finally(() => {
          this.downloading = false
        })
    },
    selectFilesToUpload () {
      var uploadInput = this.jQuery('#upload_backup_input')
      uploadInput.trigger('click')
    },
    handleBackupUpload () {
      this.loading = true
      this.uploadFiles = this.$refs.uploadBackup.files
      const reader = new FileReader()
      reader.addEventListener('load', (event) => {
        let data = JSON.parse(event.target.result)
        let promises = []

        for (let i=0; i < data.rows.length; i++) {
          let row = data.rows[i]
          promises.push(this.createConfig({
            'key': row.key,
            'value': row.value
          }))
        }

        Promise.all(promises)
          .then(() => {
            this.$EventBus.$emit('AppSnackbar/show', {
              color: 'success',
              text: this.$t('ui.save_success')
            })
          })
          .finally(() => {
            this.jQuery('#upload_backup_input').val(null)
            this.loading = false
            this.init()
          })

      })
      reader.readAsText(this.uploadFiles[0])
    }
  },
  watch: {
    config: {
      handler: function () {
        this.raspored = this.config.RASPORED
      },
      deep: true
    }
  },
  created () {
    this.loading = true
    this.init()
      .finally(() => {
        this.loading = false
      })
  }
}
</script>
