/* eslint no-unused-vars: 0 */  // --> OFF

import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from '@/pages/Dashboard'
import AppPage from '@/pages/AppPage'
import StatistikaPage from '@/pages/StatistikaPage'
// SYSTEM
import ConfigPage from '@/pages/system/ConfigPage'
import ConfigAddPage from '@/pages/system/ConfigAddPage'
import ConfigEditPage from '@/pages/system/ConfigEditPage'
import ConfigDeletePage from '@/pages/system/ConfigDeletePage'
import CellularInternetPage from '@/pages/system/CellularInternetPage'
import CentarPage from '@/pages/system/CentarPage'
import VpnPage from '@/pages/system/VpnPage'
import GpsPage from '@/pages/system/GpsPage'
import HvacPage from '@/pages/system/HvacPage'
import WatchDogPage from '@/pages/system/WatchDogPage'
import RasporedPage from '@/pages/RasporedPage'
import DetektoriStalneNajavePage from '@/pages/DetektoriStalneNajavePage'
import CameraFeedPage from '@/pages/CameraFeedPage'
import TlocrtRedirectorPage from '@/pages/TlocrtRedirectorPage'
import SystemTimePage from '@/pages/system/SystemTimePage'
import DemoPage from '@/pages/DemoPage'
import NetworkingSettingsPage from '@/pages/system/NetworkingSettingsPage'
// T-1
import TlocrtPage from '@/pages/t1/TlocrtPage'
import CamerasPage from '@/pages/t1/CamerasPage'
import t1Gain from '@/pages/t1/GainPage'
import t1Ciklus from '@/pages/t1/CiklusPage'
import t1IzmjerenoStanjeSnagaPage from '@/pages/t1/IzmjerenoStanjeSnagaPage'
import NominalneVrijednostiSnagaPage from '@/pages/t1/NominalneVrijednostiSnagaPage'
import tiDozvoljenoOdstupanjePage from '@/pages/t1/DozvoljenoOdstupanjePage'
import t1ZelenaVremenaPage from '@/pages/t1/ZelenaVremenaPage'
import t1ProgramskiRad from '@/pages/t1/ProgramskiRad'
import t1GpioPage from '@/pages/t1/GpioPage'
import t1PostavkePage from '@/pages/t1/PostavkePage'
import t1GreskePage from '@/pages/t1/GreskePage'
import t1PosaljiProgramPage from '@/pages/t1/PosaljiProgramPage'
import t1DetektoriJedinicePage from '@/pages/t1/detektori/JedinicePage'
import t1DetektoriLogikaPage from '@/pages/t1/detektori/Logika'
import t1DozvoljenoOdstupanjePrag2Page from '@/pages/t1/DozvoljenoOdstupanjePrag2Page'
import t1SnageSignalaPostavkePage from '@/pages/t1/SnageSignalaPostavkePage'
import t1Tolerancija2Page from '@/pages/t1/Tolerancija2Page'
import t1RazvojnaKonzolaPage from '@/pages/t1/RazvojnaKonzolaPage'
import t1DetektorskaPlocaPage from '@/pages/t1/DetektorskaPlocaPage'
import t1SinkroSekundaPage from '@/pages/t1/SinkroSekundaPage'
import t1KalibracijaSigPage from '@/pages/t1/KalibracijaSigPage'
import t1CuKontrolerPage from '@/pages/t1/CuKontrolerPage'
import t1RasporedPage from '@/pages/t1/t1RasporedPage'
import t1DevRaznoPage from '@/pages/t1/DevRaznoPage'
import DfsDemoPage from '@/pages/t1/DfsDemoPage'
import FlowTopViewPage from '@/pages/t1/FlowTopViewPage'
import FlowCiklusPage from '@/pages/t1/FlowCiklusPage'
import McoProgramatorPage from '@/pages/t1/McoProgramatorPage'
import McoProgramatorEditPage from '@/pages/t1/McoProgramatorEditPage'
import McoProgramatorImportXmlPage from '@/pages/t1/McoProgramatorImportXmlPage'
// T-2000
import T2000TlocrtPage from '@/pages/t2000/TlocrtPage'
import T2000GreskePage from '@/pages/t2000/GreskePage'
import  T2000NultaSekundaSyncPage from '@/pages/t2000/NultaSekundaSyncPage'
import T2000CiklusPage from '@/pages/t2000/CiklusPage'
// LED-MARKER
import LedMarkerPage from '@/pages/led-marker/LedMarkerPage'
// S-1
import S1DevPage from '@/pages/s1/S1DevPage'
// Telvent
import TelventTlocrtPage from '@/pages/telvent/TlocrtPage'
import TelventCiklusPage from '@/pages/telvent/CiklusPage'
import TelventPhasesPage from '@/pages/telvent/PhasesPage'
import TelventManualControlPage from '@/pages/telvent/ManualControlPage'
import TelventProgramsCRUDPage from '@/pages/telvent/ProgramsCRUDPage'
import TelventRasporedPage from '@/pages/telvent/TelventRasporedPage'
// Etx
import EtxTlocrtPage from '@/pages/etx/TlocrtPage'
import EtxCiklusPage from '@/pages/etx/CiklusPage'
import EtxProgramsCRUDPage from '@/pages/etx/ProgramsCRUDPage'
import EtxRasporedPage from '@/pages/etx/EtxRasporedPage'
import EtxManualControlPage from '@/pages/etx/EtxManualControlPage'
// GONJEVA
import GonjevaScadaPage from '@/pages/gonjeva/GonjevaScadaPage'
import GonjevaScadaCentarPage from '@/pages/gonjeva/GonjevaScadaCentarPage'

Vue.use(Router)

const routes = [
  {
    path: '/',
    component: AppPage,
    redirect: '/tlocrt',
    name: 'AppPage',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: 'raspored',
        name: 'RasporedPage',
        component: RasporedPage
      },
      {
        path: 'statistika',
        name: 'StatistikaPage',
        component: StatistikaPage
      },
      {
        path: 'kamere',
        name: 'CameraFeedPage',
        component: CameraFeedPage
      },
      {
        path: 'tlocrt',
        name: 'TlocrtRedirectorPage',
        component: TlocrtRedirectorPage
      },
      {
        path: '/t-1/tlocrt',
        name: 't1TlocrtPage',
        component: TlocrtPage
      },
      {
        path: '/t-1/ciklus',
        name: 't1CiklusPage',
        component: t1Ciklus
      },
      {
        path: '/t-1/snage-signala/postavke',
        name: 't1SnageSignalaPostavkePage',
        component: t1SnageSignalaPostavkePage
      },
      {
        path: '/t-1/snage-signala/gain',
        name: 't1GainPage',
        component: t1Gain
      },
      {
        path: '/t-1/snage-signala/tolerancija/2',
        name: 't1Tolerancija2Page',
        component: t1Tolerancija2Page
      },
      {
        path: '/t-1/snage-signala/izmjereno-stanje',
        name: 't1IzmjerenoStanjeSnaga',
        component: t1IzmjerenoStanjeSnagaPage
      },
      {
        path: '/t-1/snage-signala/nominalne-vrijednosti',
        name: 't1NominalneVrijednostiSnagaPage',
        component: NominalneVrijednostiSnagaPage
      },
      {
        path: '/t-1/snage-signala/dozvoljeno-odstupanje',
        name: 'tiDozvoljenoOdstupanjePage',
        component: tiDozvoljenoOdstupanjePage
      },
      {
        path: '/t-1/snage-signala/dozvoljeno-odstupanje-prag-2',
        name: 't1DozvoljenoOdstupanjePrag2Page',
        component: t1DozvoljenoOdstupanjePrag2Page
      },
      {
        path: '/t-1/detektori/jedinice',
        name: 't1DetektoriJedinicePage',
        component: t1DetektoriJedinicePage
      },
      {
        path: '/t-1/detektori/logika',
        name: 't1DetektoriLogikaPage',
        component: t1DetektoriLogikaPage
      },
      {
        path: '/t-1/detektori/stalne-najave',
        name: 'DetektoriStalneNajavePage',
        component: DetektoriStalneNajavePage
      },
      {
        path: '/t-1/detektori/kamere',
        name: 't1CamerasPage',
        component: CamerasPage
      },
      {
        path: '/t-1/vremena',
        name: 't1ZelenaVremenaPage',
        component: t1ZelenaVremenaPage
      },
      {
        path: '/t-1/programski-rad',
        name: 't1ProgramskiRad',
        component: t1ProgramskiRad
      },
      {
        path: '/t-1/gpio',
        name: 't1GpioPage',
        component: t1GpioPage
      },
      {
        path: '/t-1/postavke',
        name: 't1PostavkePage',
        component: t1PostavkePage
      },
      {
        path: '/t-1/greske',
        name: 't1GreskePage',
        component: t1GreskePage
      },
      {
        path: '/t-1/posalji-program',
        name: 't1PosaljiProgramPage',
        component: t1PosaljiProgramPage
      },
      {
        path: '/t-1/razvojna-konzola',
        name: 't1RazvojnaKonzolaPage',
        component: t1RazvojnaKonzolaPage
      },
      {
        path: '/t-1/detektorska-ploca',
        name: 't1DetektorskaPlocaPage',
        component: t1DetektorskaPlocaPage
      },
      {
        path: '/t-1/dev-razno',
        name: 't1DevRaznoPage',
        component: t1DevRaznoPage
      },
      {
        path: 't-1/centralno-upravljanje/sinkro-sekunda',
        name: 't1SinkroSekundaPage',
        component: t1SinkroSekundaPage
      },
      {
        path: '/t-1/centralno-upravljanje/kontroler',
        name: 't1CuKontrolerPage',
        component: t1CuKontrolerPage
      },
      {
        path: 't-1/kalibracija-sig',
        name: 't1KalibracijaSigPage',
        component: t1KalibracijaSigPage
      },
      {
        path: 't-1/raspored',
        name: 't1RasporedPage',
        component: t1RasporedPage
      },
      {
        path: 't-1/mco-programator',
        name: 'McoProgramatorPage',
        component: McoProgramatorPage
      },
      {
        path: 't-1/mco-programator/edit/:PTP',
        name: 'McoProgramatorEditPage',
        component: McoProgramatorEditPage,
        props: true
      },
      {
        path: 't-1/mco-programator/import',
        name: 'McoProgramatorImportXmlPage',
        component: McoProgramatorImportXmlPage,
        props: true
      },
      {
        path: '/t-2000/tlocrt',
        name: 't2000TlocrtPage',
        component: T2000TlocrtPage
      },
      {
        path: '/t-2000/ciklus',
        name: 't2000CiklusPage',
        component: T2000CiklusPage
      },
      {
        path: '/t-2000/greske',
        name: 't2000GreskePage',
        component: T2000GreskePage
      },
      {
        path: '/t-2000/nulta-sekunda-sync',
        name: 't2000NultaSekundaSyncPage',
        component: T2000NultaSekundaSyncPage
      },
      {
        path: '/settings/mobilni-internet',
        name: 'CellularInternetPage',
        component: CellularInternetPage
      },
      {
        path: '/settings/centar',
        name: 'CentarPage',
        component: CentarPage
      },
      {
        path: '/settings/vpn',
        name: 'VpnPage',
        component: VpnPage
      },
      {
        path: '/settings/gps',
        name: 'GpsPage',
        component: GpsPage
      },
      {
        path: '/settings/watchdog',
        name: 'WatchDogPage',
        component: WatchDogPage
      },
      {
        path: '/settings/hvac',
        name: 'HvacPage',
        component: HvacPage
      },
      {
        path: '/settings/config',
        name: 'ConfigPage',
        component: ConfigPage
      },
      {
        path: '/settings/config/add',
        name: 'ConfigAddPage',
        component: ConfigAddPage
      },
      {
        path: '/settings/config/:config_id/edit',
        name: 'ConfigEditPage',
        component: ConfigEditPage,
        props: true
      },
      {
        path: '/settings/config/:config_id/delete',
        name: 'ConfigDeletePage',
        component: ConfigDeletePage,
        props: true
      },
      {
        path: '/settings/system-time',
        name: 'SystemTimePage',
        component: SystemTimePage
      },
      {
        path: '/settings/network',
        name: 'NetworkingSettingsPage',
        component: NetworkingSettingsPage
      },
      {
        path: '/led-marker/info',
        name: 'LedMarkerPage',
        component: LedMarkerPage,
        props: true
      },
      {
        path: '/s-1/dev',
        name: 'S1Dev',
        component: S1DevPage,
        props: true
      },
      {
        path: '/telvent/tlocrt',
        name: 'TelventTlocrtPage',
        component: TelventTlocrtPage,
        props: true
      },
      {
        path: '/telvent/ciklus',
        name: 'TelventCiklusPage',
        component: TelventCiklusPage,
        props: true
      },
      {
        path: '/telvent/phases',
        name: 'TelventPhasesPage',
        component: TelventPhasesPage,
        props: true
      },
      {
        path: '/telvent/manual-control',
        name: 'TelventManualControlPage',
        component: TelventManualControlPage,
        props: true
      },
      {
        path: '/telvent/programs-crud',
        name: 'TelventProgramsCRUDPage',
        component: TelventProgramsCRUDPage,
        props: true
      },
      {
        path: '/telvent/schedule',
        name: 'TelventRasporedPage',
        component: TelventRasporedPage,
        props: true
      },
      {
        path: '/etx/tlocrt',
        name: 'EtxTlocrtPage',
        component: EtxTlocrtPage,
        props: true
      },
      {
        path: '/etx/ciklus',
        name: 'EtxCiklusPage',
        component: EtxCiklusPage,
        props: true
      },
      {
        path: '/etx/programs-crud',
        name: 'EtxProgramsCRUDPage',
        component: EtxProgramsCRUDPage,
        props: true
      },
      {
        path: '/etx/schedule',
        name: 'EtxRasporedPage',
        component: EtxRasporedPage,
        props: true
      },
      {
        path: '/etx/manual-control',
        name: 'EtxManualControlPage',
        component: EtxManualControlPage,
        props: true
      },
      {
        path: '/gonjeva/scada',
        name: 'GonjevaScadaPage',
        component: GonjevaScadaPage,
        props: true
      },
      {
        path: '/gonjeva/centar',
        name: 'GonjevaScadaCentarPage',
        component: GonjevaScadaCentarPage,
        props: true
      }
    ]
  },
  {
    path: '/demo',
    component: DemoPage,
    redirect: '/',
    name: 'DemoPage',
    children: [
      {
        path: 'dfs',
        name: 't1DfsDemoPage',
        component: DfsDemoPage
      },
      {
        path: 'top-view',
        name: 'FlowTopViewPage',
        component: FlowTopViewPage
      },
      {
        path: 'phases-view',
        name: 'FlowCiklusPage',
        component: FlowCiklusPage
      }
    ]
  },
  { path: '*', redirect: '/dashboard' }
]


const router = new Router({
  //mode: 'history',
  routes
})

//router.beforeEach((to, from, next) => {
//console.log(to)
//return next()
//})

export default router
