export default {
  methods: {
    getWgConfig (configKey) {
      return this.$api.get(`/wg/config/?key=${configKey}`, {baseURL: this.$store.getters['app/APP_API_URI']})
    },
    createWgConfig (config) {
      return this.$api.post(`/wg/config/?key=${config.key}`, {'config': config.config}, {baseURL: this.$store.getters['app/APP_API_URI']})
    },
    deleteWgConfig (configKey) {
      return this.$api.delete(`/wg/config/?key=${configKey}`, {baseURL: this.$store.getters['app/APP_API_URI']})
    }
  }
}
