<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">{{ phase.name }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        dark
        icon
        @click="userAction({'name': 'delete', 'phase': phase})"
      >
        <v-icon color="red">close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-flex xs12 sm6 md4 lg4>
        <v-text-field
          v-model="phase.name"
          :label="this.$t('ui.name')"
          :error-messages="nameErrors"
          @input="$v.phase.name.$touch()"
          @blur="$v.phase.name.$touch()"
        ></v-text-field>
      </v-flex>

      <v-flex xs12>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th style="width: 10%"><strong>{{ $t('ui.group') }}</strong></th>
              <th style="width: 20%"><strong>{{ $t('ui.green') }}</strong></th>
              <th style="width: 20%"><strong>{{ $t('ui.yellow') }}</strong></th>
              <th style="width: 20%"><strong>{{ $t('ui.red') }}</strong></th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="signal in signals" :key="signal.id">
                <td>
                  {{ signal.id }}
                </td>
                <td>
                  <v-checkbox
                    v-model="signal.green"
                    color="green"
                  ></v-checkbox>
                </td>
                <td>
                  <v-checkbox
                    v-model="signal.yellow"
                    color="yellow"
                  ></v-checkbox>
                </td>
                <td>
                  <v-checkbox
                    v-model="signal.red"
                    color="red"
                  ></v-checkbox>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>

    </v-card-text>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'PhaseEditCard',
  mixins: [validationMixin],
  props: ['phase'],
  validations: {
    phase: {
      name: {
        required
      }
    }
  },
  data () {
    return {
      signals: []
    }
  },
  computed: {
    nameErrors () {
      const errors = []
      if (!this.$v.phase.name.$dirty) return errors
      !this.$v.phase.name.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    isFormValid () {
      return !this.$v.$invalid
    }
  },
  methods: {
    userAction: function (actionResult) {
      this.$emit('userAction', actionResult)
    }
  },
  watch: {
    signals: {
      handler: function (newValue) {
        for (let i = 0; i < newValue.length; i++) {
          let green = newValue[i].green
          let yellow = newValue[i].yellow
          let red = newValue[i].red

          if (green == true) {
            green = 1
          } else {
            green = 0
          }

          if (yellow == true) {
            yellow = 1
          } else {
            yellow = 0
          }

          if (red == true) {
            red = 1
          } else {
            red = 0
          }

          this.phase.signals[i] = [green, yellow, red]
        }
      },
      deep: true
    }
  },
  created () {
    this.signals = this.phase.signals.map((item, idx) => ({
      'id': idx+1,
      'green': item[0],
      'yellow': item[1],
      'red': item[2],
    }))
  }
}
</script>
