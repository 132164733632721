<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">{{ $t('ui.sync_time') }}</span>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col>
          <v-btn v-if="isAuthenticated" @click="syncTime()" color="primary" :disabled="syncDisabled" block tile>{{$t('ui.sync_time_with_computer')}}</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="changeSystemTimeTo"
            hint="dd.mm.yyyy hh:mm:ss"
            :rules="changeSystemTimeRules"
            clearable
            persistent-hint></v-text-field>
        </v-col>
        <v-col>
          <v-btn v-if="isAuthenticated" @click="syncTime(changeSystemTimeTo)" color="primary" :disabled="customSyncDisabled" block>{{$t('ui.sync_time')}}</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            :items="supervisorJobStatusItems"
            item-text="name"
            item-value="id"
            v-model="config.T1_SYSTEM_TIME_SYNC_ENABLED"
            :hint="$t('ui.auto_sync_system_time_to_t1_time')"
            persistent-hint
          ></v-select>
        </v-col>
        <v-col>
          <v-combobox
            v-model="config.T1_SYSTEM_TIME_EVERY_X_SECONDS"
            :items="seconds"
            :hint="$t('ui.sync_every_x_seconds')"
            persistent-hint
          ></v-combobox>
        </v-col>
        <v-col>
          <v-btn v-if="isAuthenticated" @click="submit" color="primary" :disabled="loading" block tile>{{$t('ui.submit')}}</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <app-supervisor-job-log-card :supervisor-config-key="supervisorConfigKey"></app-supervisor-job-log-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'
import ConfigMixin from '@/mixins/ConfigMixin'
import SupervisorMixin from '@/mixins/SupervisorMixin'

export default {
  name: 't1SyncCard',
  mixins: [ConfigMixin, SupervisorMixin, T1Mixin, AuthMixin],
  data () {
    return {
      loading: false,
      syncing: false,
      changeSystemTimeTo: null,
      changeTimeFormValid: false,
      changeSystemTimeRules: [
        value => {
          if (!value) return true
          const pattern = /\d{2}.\d{2}.\d{4}\s\d{2}:\d{2}:\d{2}/
          return pattern.test(value) || this.$t('ui.invalid_format')
        }
      ],
      supervisorConfigKey: 't1_sync_system_time',
      config: {
        T1_SYSTEM_TIME_SYNC_ENABLED: false,
        T1_SYSTEM_TIME_EVERY_X_SECONDS: 60
      },
      seconds: [1,3,5,15,30,60,90]
    }
  },
  computed: {
    syncDisabled () {
      if (this.syncing || this.loading) return true
      return false
    },
    customSyncDisabled () {
      if (!this.changeSystemTimeTo) return true
      if (this.syncDisabled) return true
      let fn = null
      for (let i = 0; i < this.changeSystemTimeRules.length; i++) {
        fn = this.changeSystemTimeRules[i]
        if (fn(this.changeSystemTimeTo) !== true) return true
      }
      return false
    },
    jobAutoStart () {
      if (this.config.T1_SYSTEM_TIME_SYNC_ENABLED === '1') {
        return true
      }

      return false
    },
    supervisorTemplate () {
      return `[program:${this.supervisorConfigKey}]
command=/opt/.virtualenvs/trafconnect_v2/bin/python /opt/trafsignal/apps/t1_sync_system_time.py --seconds ${this.config.T1_SYSTEM_TIME_EVERY_X_SECONDS}
directory=/opt/trafsignal/apps
environment=PYTHONPATH="/opt"
priority=10
autostart=${this.jobAutoStart}
autorestart=${this.jobAutoStart}
startretries=999
user=root
stdout_logfile_maxbytes=10KB
stdout_logfile_backups=0
stderr_logfile_maxbytes=10KB
stderr_logfile_backups=0
stdout_capture_maxbytes=10KB
    `
    }
  },
  methods: {
    init () {
      this.initConfigData()
    },
    submit () {
      this.loading = true
      var promises = []

      promises.push(this.createConfig({
        'key': 'T1_SYSTEM_TIME_SYNC_ENABLED',
        'value': this.config.T1_SYSTEM_TIME_SYNC_ENABLED
      }))

      promises.push(this.createConfig({
        'key': 'T1_SYSTEM_TIME_EVERY_X_SECONDS',
        'value': this.config.T1_SYSTEM_TIME_EVERY_X_SECONDS
      }))

      promises.push(this.createSupervisorConfig({
        'key': this.supervisorConfigKey,
        'config': this.supervisorTemplate
      }))

      Promise.all(promises)
        .then(() => {

          // svi upiti su gotovi
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })

        })
        .finally(() => {
          this.loading = false
          this.init()
        })

    },
    syncTime (inputDate) {
      this.syncing = true
      let sendDate = null
      if (inputDate) {
        let t = inputDate.split(' ')
        let datum = t[0]
        let vrijeme = t[1]

        let _ = datum.split('.')

        sendDate = new Date(`${_[2]}-${_[1]}-${_[0]} ${vrijeme}`)
      }
      this.setCpsTime(this.getClientTime(sendDate))
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'red',
            text: this.$t('ui.save_error')
          })
        })
        .finally(() => {
          this.syncing = false
        })
    }
  },
  created () {
    this.init()
    this.getJobStatusIntervalId = setInterval(() => {
      if (!this.statusUpdating) {
        this.updateSupervisorJobStatus(this.supervisorConfigKey)
      }
    }, 5000)
  },
  destroyed () {
    if (this.getJobStatusIntervalId) {
      clearInterval(this.getJobStatusIntervalId)
    }
  }
}
</script>
