<template>
  <v-container fluid grid-list-md>

    <v-row>
      <v-col xs="12">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">T-1 {{ this.$t('ui.raspored') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              @click="submit"
              color="primary"
            >{{ this.$t('ui.save') }}
            </v-btn>
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, i) in availableActions"
                  :key="i"
                  link
                  @click="item.action()"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
          <v-progress-linear
            v-show="loading"
            color="primary"
            indeterminate
          ></v-progress-linear>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" xs="12" lg="6">
        <ag-grid-vue
          class="ag-theme-alpine"
          rowSelection="single"
          :style="{height: height-250 + 'px'}"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowDragManaged="true"
          :suppressMoveWhenRowDragging="true"
          :animateRows="true"
          :rowData="config.T1_RASPORED"
          @grid-ready="onRasporedGridReady"
          @row-drag-end="onRasporedRowDragEnd"
          @first-data-rendered="onFirstDataRendered"
          @selection-changed="onSelectionChanged">
        </ag-grid-vue>
      </v-col>

      <v-col cols="12" xs="12" lg="6" v-if="stavka">

        <v-col cols="12" xs="12">
          <v-select
            :items="T1_PROGRAMI"
            :label="this.$t('ui.program')"
            item-text="label"
            return-object
            v-model="stavka.program"
            :error-messages="programErrors"
            @input="$v.stavka.program.$touch()"
            @blur="$v.stavka.program.$touch()"
          ></v-select>
        </v-col>

        <v-col cols="12" xs="12">
          <v-text-field
            v-model="stavka.time"
            hint="hh:mm, 24h format"
            :rules="changeSystemTimeRules"
            persistent-hint
            :error-messages="timeErrors"
            @input="$v.stavka.time.$touch()"
            @blur="$v.stavka.time.$touch()"
          ></v-text-field>
        </v-col>

        <v-col cols="12" xs="12">
          <v-select
            :items="possibleDays"
            :label="this.$t('ui.day')"
            item-text="name"
            item-value="value"
            v-model="stavka.day"
            :error-messages="dayErrors"
            @input="$v.stavka.day.$touch()"
            @blur="$v.stavka.day.$touch()"
          ></v-select>
        </v-col>

        <v-col cols="12" xs="12">
          <v-btn v-if="!isSelected"
            :disabled="!isFormValid || loading"
            @click="add"
            color="primary"
          >{{ this.$t('ui.add') }}</v-btn>
          <v-btn v-if="isSelected"
            :disabled="!isFormValid || loading"
            @click="update"
            color="primary"
            class="mr-6"
          >{{ this.$t('ui.update') }}</v-btn>
          <v-btn v-if="isSelected"
            @click="unselect"
            color="primary"
            class="mr-6"
          >{{ this.$t('ui.cancel') }}</v-btn>
          <v-btn v-if="isSelected"
            @click="remove"
            color="red"
          >{{ this.$t('ui.delete') }}</v-btn>
        </v-col>
        <v-alert
          type="warning"
          v-if="SHOW_WARNING"
        >
          {{WARNING_TEXT}}
        </v-alert>
      </v-col>

    </v-row>
    <form id="upload_backup_form">
      <input
        type="file"
        id="upload_backup_input"
        ref="uploadBackup"
        accept=".json"
        hidden
        v-on:change="handleBackupUpload()"
      />
    </form>
  </v-container>
</template>

<script>
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AgGridVue } from 'ag-grid-vue'

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import AuthMixin from '@/mixins/AuthMixin'
import ConfigMixin from '@/mixins/ConfigMixin'
import T1Mixin from '@/mixins/T1Mixin'
import T1CommandsMixin from '@/mixins/T1CommandsMixin'
import RedisMixin from '@/mixins/RedisMixin'

export default {
  name: 't1RasporedPage',
  mixins: [ConfigMixin, AuthMixin, RedisMixin, T1Mixin, T1CommandsMixin, validationMixin],
  components: {
    AgGridVue
  },
  validations: {
    stavka: {
      program: {
        required
      },
      time: {
        required
      },
      day: {
        required
      }
    }
  },
  data () {
    return {
      configMeta: {
        T1_RASPORED: {
          type: Array
        }
      },
      config: {
        T1_RASPORED: [],
        DEVICE_TYPE: null,
        DEVICE_CODE: '',
      },
      defaultColDef: {
        suppressMovable: true,
        sortable: false,
        filter: false,
        editable: false
      },
      columnDefs: null,
      rasporedGridApi: null,
      loading: false,
      height: window.innerHeight,
      rowSelection: 'single',
      isSelected: false,
      changeSystemTimeRules: [
        value => {
          if (!value) return true
          const pattern = /\d{2}:\d{2}/
          return pattern.test(value) || this.$t('ui.invalid_format')
        }
      ],
      stavka: null,
      possibleDays: [
        {
          'name': 'Every day',
          'value': 'every_day'
        },
        {
          'name': 'Work day',
          'value': 'work_day'
        },
        {
          'name': 'Saturday',
          'value': 'saturday'
        },
        {
          'name': 'Sunday',
          'value': 'sunday'
        }
      ],
      actions: [
        {'title': this.$t('ui.import'), 'action': this.import, 'needsAuth': false },
        {'title': this.$t('ui.export'), 'action': this.export, 'needsAuth': false }
      ],
      SHOW_WARNING: false,
      WARNING_TEXT: 'DATA CHANGED! REMEMBER TO SAVE!!!',
      T1_PROGRAMI: []
    }
  },
  computed: {
    programErrors () {
      const errors = []
      if (!this.$v.stavka.program.$dirty) return errors
      !this.$v.stavka.program.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    timeErrors () {
      const errors = []
      if (!this.$v.stavka.time.$dirty) return errors
      !this.$v.stavka.time.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    dayErrors () {
      const errors = []
      if (!this.$v.stavka.day.$dirty) return errors
      !this.$v.stavka.day.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    isFormValid () {
      return !this.$v.$invalid
    },
    availableActions () {
      let actions = []
      let action = null
      for (let i = 0; i < this.actions.length; i++) {
        action = this.actions[i]
        if (action.needsAuth === true) {
          if (this.isAuthenticated) {
            actions.push(action)
          }
        } else {
          actions.push(action)
        }
      }

      return actions
    }
  },
  methods: {
    init () {

      this.loading = true

      let promises = []

      promises.push(this.initConfigData())
      promises.push(this.t1getVprogConfig())

      Promise.all(promises)
        /* eslint no-unused-vars: 0 */  // --> OFF
        .then(([CONFIG_DATA, VRPROG_CONFIG]) => {

          this.t1getNaziviPrograma(VRPROG_CONFIG)
            .then((T1_PROGRAMI) => {
              this.T1_PROGRAMI = T1_PROGRAMI
            })
        })
        .finally(() => {
          this.stavka = this.getNewStavka()
          this.loading = false
        })
    },
    getNewStavka () {
      var idCounter = 0

      for (var i = 0; i < this.config.T1_RASPORED.length; i++) {
        var stavka = this.config.T1_RASPORED[i]
        var x = stavka.id.split('_')
        idCounter = parseInt(x[x.length - 1])
      }

      return {
        id: this.uniqueId('rasporedId_', idCounter),
        program: null,
        programId: null,
        time: '00:00',
        day: 'every_day'
      }
    },
    onRasporedGridReady (params) {
      this.rasporedGridApi = params.api
    },
    onRasporedRowDragEnd () {
      let itemsToUpdate = []

      this.rasporedGridApi.forEachNodeAfterFilterAndSort((rowNode) => {
        itemsToUpdate.push(rowNode.data)
      })

      this.config.T1_RASPORED = itemsToUpdate
    },
    onFirstDataRendered (params) {
      params.api.sizeColumnsToFit()
    },
    onSelectionChanged () {
      const selectedRows = this.rasporedGridApi.getSelectedRows()

      if (selectedRows[0]) {
        this.isSelected = true
        this.stavka = JSON.parse(JSON.stringify(selectedRows[0]))
      } else {
        this.isSelected = false
        this.stavka = this.getNewStavka()
      }
    },
    uniqueId (prefix, idCounter) {
      var id = ++idCounter
      return prefix + id
    },
    add () {
      let programName = this.stavka.program.label
      let programId = this.stavka.program.id
      this.stavka.program = programName
      this.stavka.programId = programId
      this.config.T1_RASPORED.push(JSON.parse(JSON.stringify(this.stavka)))
      this.rasporedGridApi.setRowData(JSON.parse(JSON.stringify(this.config.T1_RASPORED)))
      this.stavka = this.getNewStavka()
      this.SHOW_WARNING = true
    },
    update () {
      var index = this.$lodash.findIndex(this.config.T1_RASPORED,
        (s) => { return s.id === this.stavka.id }
      )
      if (index > -1) {

        if (this.stavka.program.label) {
          let programName = this.stavka.program.label
          this.stavka.program = programName
        }

        if (this.stavka.program.id) {
          let programId = this.stavka.program.id
          this.stavka.programId = programId
        }

        this.config.T1_RASPORED[index] = JSON.parse(JSON.stringify(this.stavka))
        this.rasporedGridApi.setRowData(JSON.parse(JSON.stringify(this.config.T1_RASPORED)))

        let nodesToSelect = []
        this.rasporedGridApi.forEachNode((node) => {
          if (node.data && node.data.id === this.stavka.id) {
            nodesToSelect.push(node)
          }
        })

        this.rasporedGridApi.setNodesSelected({ nodes: nodesToSelect, newValue: true  })
        this.SHOW_WARNING = true
      }
    },
    unselect () {
      this.stavka = this.getNewStavka()
      this.rasporedGridApi.deselectAll()
    },
    remove () {
      var index = this.$lodash.findIndex(this.config.T1_RASPORED,
        (s) => { return s.id === this.stavka.id }
      )
      if (index > -1) {
        this.config.T1_RASPORED.splice(index, 1)
        this.rasporedGridApi.setRowData(JSON.parse(JSON.stringify(this.config.T1_RASPORED)))
        this.stavka = this.getNewStavka()
        this.isSelected = false
      }
    },
    submit () {
      this.loading = true

      this.createConfig({
        'key': 'T1_RASPORED',
        'value': this.config.T1_RASPORED
      })
      .then(() => {
        this.setRedisKeyValue({'t1_schedule_changed': '1'})
      })
      .then(() => {
        this.$EventBus.$emit('AppSnackbar/show', {
          color: 'success',
          text: this.$t('ui.save_success')
        })
        this.SHOW_WARNING = false
      })
      .catch(() => {
        this.$EventBus.$emit('AppSnackbar/show', {
          color: 'error',
          text: this.$t('ui.save_error')
        })
      })
      .finally(() => {
        this.init()
      })
    },
    import () {
      var uploadInput = this.jQuery('#upload_backup_input')
      uploadInput.trigger('click')
    },
    export () {
      this.downloadBackup()
    },
    downloadBackup () {
      this.loading = true
      this.getConfigByKeys(['T1_RASPORED'])
        .then((response) => {

          let exportData = {}

          response.data.rows.forEach((item) => {
            exportData[item.key] = JSON.parse(item.value)
          })

          let file = new Blob([JSON.stringify(exportData)], {type: 'text/plain'})
          let a = document.createElement('a'), url = URL.createObjectURL(file)
          a.href = url
          a.download = `${this.config.DEVICE_CODE}_t1_schedule.json`
          document.body.appendChild(a)
          a.click()
          setTimeout(function() {
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)
          }, 0)
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectFilesToUpload () {
      var uploadInput = this.jQuery('#upload_backup_input')
      uploadInput.trigger('click')
    },
    handleBackupUpload () {
      this.loading = true
      this.uploadFiles = this.$refs.uploadBackup.files
      const reader = new FileReader()
      reader.addEventListener('load', (event) => {
        try {
          let data = JSON.parse(event.target.result)
          this.config.T1_RASPORED = data['T1_RASPORED']

          let promises = []

          promises.push(
            this.createConfig({
              'key': 'T1_RASPORED',
              'value': this.config.T1_RASPORED
            })
          )

          Promise.all(promises)
            .then(() => {
              this.$EventBus.$emit('AppSnackbar/show', {
                color: 'success',
                text: this.$t('ui.save_success')
              })
            })
            .catch(() => {
              this.$EventBus.$emit('AppSnackbar/show', {
                color: 'error',
                text: this.$t('ui.save_error')
              })
            })
            .finally(() => {
              this.jQuery('#upload_backup_input').val(null)
              this.init()
            })
        } catch(e) {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'error',
            text: e
          })
          this.jQuery('#upload_backup_input').val(null)
          this.loading = false
          this.init()
        }
      })
      reader.readAsText(this.uploadFiles[0])
    }
  },
  beforeMount () {
    this.columnDefs = [
      {
        headerName: this.$t('ui.program'),
        field: 'program',
        rowDrag: true
      },
      {
        headerName: 'PTP',
        field: 'programId',
        rowDrag: false
      },
      {
        headerName: this.$t('ui.time'),
        field: 'time'
      },
      {
        headerName: this.$t('ui.day'),
        field: 'day'
      }
    ]
  },
  created () {
    this.init()
  }
}
</script>
