<template>
  <v-card>
    <v-toolbar flat dense p0 v-show="showToolbar">
      <v-toolbar-title>{{title}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        class="ml-2"
        :loading="loading"
        :disabled="loading"
        plain
        color="secondary"
      >
      </v-btn>
      <!--<app-status-dot :status="status" v-show="showStatusDot"></app-status-dot>-->
      <v-btn
      icon
      @click="toggleVideoPlay()">
        <v-icon>{{cameraPlayIcon}}</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="canvas-holder" v-show="showCanvas">
      <canvas :id="canvasId"></canvas>
    </div>
    <div class="inactive-icon-holder" v-show="showCanvasIcon" style="height:240px; line-height: 240px; width: 320px; text-align:center;">
      <v-icon x-large dark>{{canvasIcon}}</v-icon>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'CameraSnapTile',
  props: {
    cameraCode: {
      type: String
    },
    title: {
      type: String,
      default () {
        return ''
      }
    },
    activeIcon: {
      type: String,
      default: function () {
        return 'check_circle'
      }
    },
    inActiveIcon: {
      type: String,
      default: function () {
        return 'radio_button_unchecked'
      }
    },
    active: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    activeCardColor: {
      type: String,
      default: function () {
        return 'green darken-1'
      }
    },
    inActiveCardColor: {
      type: String,
      default: function () {
        return 'grey darken-1'
      }
    },
    play: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    showToolbar: {
      type: Boolean,
      default: function () {
        return true
      }
    }
  },
  data () {
    return {
      status: 'first_load',
      date: new Date(),
      image: new Image(),
      canvasId: this.$lodash.uniqueId('canvas_'),
      canvas: null,
      ctx: null,
      warningTimeout: 7000,
      inactiveTimeout: 15000,
      getSnapSrcId: null,
      cameraPlay: false,
      loaded: false,
      has_cached: false,
      loading: false,
      lastRequest: null
    }
  },
  computed: {
    icon: function () {
      if (this.active) {
        return this.activeIcon
      }
      return this.inActiveIcon
    },
    cardColor: function () {
      if (this.active) {
        return this.activeCardColor
      }
      return this.inActiveCardColor
    },
    playVideo () {
      return this.cameraPlay
    },
    cameraPlayIcon () {
      if (this.playVideo) {
        return 'pause'
      }
      return 'play_arrow'
    },
    showStatusDot () {
      return this.cameraPlay
    },
    showCanvas () {
      if (this.loaded && (this.status === 'warning' || this.status === 'active')) {
        return true
      }

      return false
    },
    showCanvasIcon () {
      return !this.showCanvas
    },
    canvasIcon () {
      if (this.status === 'first_load' || this.status == 'warning') {
        return 'hourglass_empty'
      }

      return 'videocam_off'
    }
  },
  mounted () {
    this.canvas = document.getElementById(this.canvasId)
    this.ctx = this.canvas.getContext('2d')

    this.image.onload = this.snapLoaded // draw when image has loaded
    this.image.onerror = this.snapError
    this.setSnapSrc(true)
    this.setStatusTimeouts()
  },
  created () {
    this.cameraPlay = JSON.parse(JSON.stringify(this.play))
    this.isImgRequestingId = setInterval(() => {
      let now = new Date().getTime() / 1000
      if (this.lastRequest && now - this.lastRequest < 1.5) {
        this.loading = true
      } else {
        this.loading = false
      }
    }, 100)
  },
  destroyed () {
    // clearInterval(this.getSnapInterval)
    this.image.removeAttribute('src')
    this.clearStatusTimeouts()
    clearTimeout(this.getSnapSrcId)
    clearInterval(this.isImgRequestingId)
  },
  methods: {
    setStatus (status) {
      this.status = status
    },
    setSnapSrc (cache) {
      this.lastRequest = new Date().getTime() / 1000
      if (cache) {
        this.image.src = `${this.$store.getters['app/CAMERAS_FEED_SERVER_API_URI']}?code=${this.cameraCode}`
      } else {
        this.image.src = `${this.$store.getters['app/CAMERAS_FEED_SERVER_API_URI']}?code=${this.cameraCode}&t=${new Date().getTime()}`
        if (this.has_cached) {
          this.image.src = `${this.image.src}&hc=1`
        }
      }
    },
    getSnap () {
      this.getSnapSrcId = setTimeout(this.setSnapSrc, 900 + (Math.random() * (100 - 200) + 200))
    },
    snapLoaded () {
      this.loaded = true
      this.has_cached = true
      this.clearStatusTimeouts()
      this.setStatus('active')

      // use the intrinsic size of image in CSS pixels for the canvas element
      // this.canvas.width = this.image.naturalWidth
      // this.canvas.height = this.image.naturalHeight
      this.canvas.width = 320
      this.canvas.height = 240
      this.ctx.drawImage(this.image, 0, 0, 320, 240)

      if (this.playVideo) {
        this.getSnap()
        this.setStatusTimeouts()
      }

    },
    snapError () {
      if (this.playVideo) {
        this.getSnap()
      } else {
        this.clearStatusTimeouts()
        this.setStatus('inactive')
      }
    },
    setStatusTimeouts () {
      this.warningTimeoutId = setTimeout(() => {
        this.setStatus('warning')
      }, this.warningTimeout)

      this.inactiveTimeoutId = setTimeout(() => {
        this.setStatus('inactive')
        this.loaded = false
        this.has_cached = false
      }, this.inactiveTimeout)
    },
    clearStatusTimeouts () {
      clearTimeout(this.warningTimeoutId)
      clearTimeout(this.inactiveTimeoutId)
    },
    toggleVideoPlay () {
      this.cameraPlay = !this.cameraPlay
    }
  },
  watch: {
    playVideo (newVal) {
      if (newVal === true) {
        this.setStatus('warning')
        this.setSnapSrc()
        this.setStatusTimeouts()
      } else {
        this.clearStatusTimeouts()
        clearTimeout(this.getSnapSrcId)
      }
    },
    play (newVal) {
      this.cameraPlay = newVal
    }
  }
}
</script>
