import env from '../env'

export const EtxStore = {
  namespaced: true,
  state: {
    uri: env.T2000_WS_URI,
    readyState: 0,
    readyStateConstants: {
      'CONNECTING': 0,
      'OPEN': 1,
      'CLOSING': 2,
      'CLOSED': 3,
      'RECONNECT_ABORTED': 4
    },
    serBr: [],
    stanjeSignala: [],
    currentPhase: [],
    greske: [],
    statusnaRijec: [],
    brojGrupa: [],
    stanjeDetektora: [],
    EtxCommunicating: false,
    fixEtxPhase: [],
    atmsControl: [],
    manualControl: [],
    currentProgram: [],
    ciklus: [],
    offset: [],
    outsideSecond: [],
    internalSecond: [],
    doPowerCycle: [],
    etxPlan: [],
    etxCiklus: [],
    etxCiklusSekunda: [],
    etxStage: [],
    etxSekunda: []
  },
  getters: {
    READY_STATE (state) {
      return state.readyState
    },
    isConnected (state) {

      if (state.readyState === 1) {
        return true
      }

      return false
    },
    trafficController (state) {
      return state.trafficController
    },
    statusnaRijec (state) {
      console.log(state)
      return {
        abc: 0,
        anf: 0,
        cu: 0,
        ru: 0,
        sc: 0,
        su: 0,
        trz: 0,
        vru: 0
      }
    },
    isCommOk (state) {
      return state.EtxCommunicating
    },
    stanjeSignala (state) {
      let _stanjeSignala = []
      let x = String.fromCharCode(...state.stanjeSignala)
      let signali = x.split(' ')
      signali.forEach((signal) => {
        let green = 0
        let yellow = 0
        let red = 0

        if (signal === 'R') {
          red = 1
        } else if (signal === 'A') {
          yellow = 1
        } else if (signal === 'V') {
          green = 1
        }
        let res = [red, yellow, green]
        _stanjeSignala.push(res)
      })
      return _stanjeSignala
    },
    greske: (state) => (lang) =>{
      console.log([state, lang])
      return []
    },
    stanjeDetektora (state) {
      console.log(state)
      return []
    },
    program (state) {
      console.log(state)
      return 255
    },
    currentPhase (state) {
      if (state.etxStage) {
        return String.fromCharCode(...state.etxStage)
      }
      return null
    },
    fixEtxPhase (state) {
      if (state.fixEtxPhase) {
        return String.fromCharCode(...state.fixEtxPhase)
      }
      return null
    },
    atmsControl (state) {
      if (state.atmsControl >= 0) {
        return String.fromCharCode(...state.atmsControl)
      }
      return null
    },
    manualControl (state) {
      if (state.manualControl >= 0) {
        return String.fromCharCode(...state.manualControl)
      }
      return null
    },
    currentProgram (state) {
      return String.fromCharCode(...state.etxPlan)
    },
    ciklus (state) {
      return String.fromCharCode(...state.ciklus)
    },
    offset (state) {
      return String.fromCharCode(...state.offset)
    },
    outsideSecond (state) {
      return String.fromCharCode(...state.outsideSecond)
    },
    internalSecond (state) {
      return String.fromCharCode(...state.internalSecond)
    },
    doPowerCycle (state) {
      return String.fromCharCode(...state.doPowerCycle)
    }
  },
  mutations: {
    changeReadyState (state, newReadyState) {
      state.readyState = newReadyState
    },
    setUri (state, uri) {
      state.uri = uri
    },
    set (state, trafficController) {
      state.telventCommunicating = true
      for (const [key, value] of Object.entries(trafficController)) {
        state[key] = value
      }
    }
  }
}
