<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">{{ $t('ui.maksimalno_zeleno_vrijeme') }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="isAuthenticated" @click="submit()" color="primary" :disabled="saveDisabled">{{ $t('ui.save') }}</v-btn>
    </v-toolbar>
    <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>
    <v-card-text>
      <v-select
        :disabled="!isAuthenticated"
        :items="lokalniProgrami"
        item-text="label"
        item-value="id"
        v-model="odabraniProgram"
        @change="odabraniProgramChanged"
      ></v-select>
      <v-simple-table v-if="odabraniProgram">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                {{ $t('ui.signalna_grupa') }}
              </th>
              <th class="text-left">
                {{ $t('ui.upisano_maksimalno_zeleno_vrijeme') }}
              </th>
              <th class="text-left">
                {{ $t('ui.maksimalno_zeleno_vrijeme') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(index, grupa) in brojGrupa" :key="index">
              <td>{{grupa+1}}</td>
              <td><v-text-field v-model="upisanaVremena[grupa]" disabled></v-text-field></td>
              <td><v-text-field v-model="novaVremena[grupa]"></v-text-field></td>
            </tr>
          </tbody>
        </template>
        </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'
import ConfigMixin from '@/mixins/ConfigMixin'

export default {
  name: 't1MaksimalnoZelenoCard',
  mixins: [ConfigMixin, T1Mixin, AuthMixin],
  data () {
    return {
      loading: false,
      odabraniProgram: null,
      LOKALNI_PROGRAMI: null,
      brojGrupa: 0,
      upisanaVremena: [],
      novaVremena: [],
      lokalniProgrami: []
    }
  },
  computed: {
    saveDisabled () {
      if (this.loading) return true
      return false
    },
    _lokalniProgrami () {
      if (!this.LOKALNI_PROGRAMI) return []

      let programi = []

      for (let i = 1; i < this.LOKALNI_PROGRAMI+1; i++) {
        programi.push({'id': i, 'label': `Program ${i}`})
      }

      return programi
    },
  },
  methods: {
    init () {
      this.initT1Data()
    },
    initT1Data () {
      this.loading = true
      let promises = []
      promises.push(this.getBrojGrupa())
      promises.push(this.getBrojLokalnihPrograma())

      Promise.all(promises)
        .then(([BROJ_GRUPA, LOKALNI_PROGRAMI]) => {
          this.brojGrupa = BROJ_GRUPA
          this.LOKALNI_PROGRAMI = LOKALNI_PROGRAMI
          this.t1getNaziviPrograma([null, LOKALNI_PROGRAMI])
            .then((programi) => {

              this.lokalniProgrami = []
              let program = null

              for (let n = 0; n < programi.length; n++) {
                program = programi[n]
                if (program.id > 0 && program.id < 255) {
                  this.lokalniProgrami.push(programi[n])
                }
              }

            })
        })
        .finally(() => {
          this.loading = false
        })
    },
    odabraniProgramChanged () {
      this.loading = true
      this.ucitajVremena()
        .finally(() => {
          this.loading = false
        })
    },
    ucitajVremena () {
      return new Promise((resolve, reject) => {
        this.getLokalniProgramMaxZelenaVremena(this.odabraniProgram)
          .then((VREMENA) => {
            this.upisanaVremena = JSON.parse(JSON.stringify(VREMENA))
            this.novaVremena = JSON.parse(JSON.stringify(VREMENA))
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    submit () {
      this.loading = true
      this.saveLokalniProgramVremena(this.odabraniProgram, this.novaVremena)
        .then(() => {
            this.$EventBus.$emit('AppSnackbar/show', {
              color: 'success',
              text: this.$t('ui.save_success')
            })
        })
        .then(this.ucitajVremena)
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'red',
            text: this.$t('ui.save_error')
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    this.init()
  }
}
</script>
