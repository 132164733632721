<template>
    <v-dialog v-model="openDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="headline" primary-title>{{headline}}</v-card-title>

        <v-card-text>
          <v-col>
            <v-select
              :items="pragovi"
              :label="this.$t('ui.tolerancija')"
              item-text="label"
              item-value="id"
              v-model="prag"
              v-on:keyup.esc="close"
              v-on:keyup.enter="submit"
            ></v-select>
          </v-col>

          <v-col>
            <v-text-field
              v-model="pragValue"
              :error-messages="pragValueErrors"
              @input="$v.pragValue.$touch()"
              @blur="$v.pragValue.$touch()"
              label="Vrijednost"
              autofocus
              v-on:keyup.esc="close"
              v-on:keyup.enter="submit"
            ></v-text-field>
          </v-col>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="userAction({method: 'cancel', methodArgs: null})">
            {{this.$t('ui.close')}}
          </v-btn>

          <v-btn color="green darken-1" @click="userAction('save')" :disabled="$v.$invalid">
            {{this.$t('ui.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, between } from 'vuelidate/lib/validators'
export default {
  name: 'CustomPragDialog',
  mixins: [validationMixin],
  props: ['openDialog', 'headline'],
  data () {
    return {
      prag: 1,
      pragValue: 0,
      pragovi: [
        {'id': 1, label: 'Tolerancija 1'},
        {'id': 2, label: 'Tolerancija 2'}
      ]
    }
  },
  validations: {
    pragValue: {
      required,
      between: between(0, 100)
    }
  },
  computed: {

    pragValueErrors () {
      const errors = []
      if (!this.$v.pragValue.$dirty) return errors
      !this.$v.pragValue.required && errors.push(this.$t('ui.field_required'))
      !this.$v.pragValue.between && errors.push(this.$t('ui.field_required'))
      return errors
    }
  },
  methods: {
    close () {
      this.userAction({method: 'cancel', methodArgs: null})
    },
    submit () {
      if (this.$v.$invalid) return false
      this.userAction('save')
    },
    userAction (action) {
      if (action == 'save') {
        this.$emit('userAction', {method: 'save', methodArgs: [this.prag, this.pragValue]})
      } else {
        this.$emit('userAction', action)
      }

    }
  }
}
</script>
