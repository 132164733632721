<template>
  <v-container fluid grid-list-md>

    <v-layout row wrap>
      <v-flex xs12 md2>

          <v-card>
            <v-toolbar flat dense>
              <v-toolbar-title>
                <span class="text-xl-left">{{this.$t('ui.led_marker_controller')}}</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>

            <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>

            <v-card-text>
              <form>

                <v-flex xs12>
                  <v-select
                    :items="supervisorJobStatusItems"
                    item-text="name"
                    item-value="id"
                    v-model="config.LED_MARKER_ENABLED"
                  ></v-select>
                </v-flex>

                <v-flex xs12>
                  <v-combobox
                    v-model="config.LED_MARKER_PORT"
                    :items="ports"
                    label="PORT"
                  ></v-combobox>
                </v-flex>

              </form>
            </v-card-text>

            <v-card-actions>
              <v-btn
                :disabled="submitAvailable ? false : true"
                @click="submit"
                color="primary"
              >{{ this.$t('ui.submit') }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>

          </v-card>

      </v-flex>

      <v-flex xs12 md4>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{ $t('ui.name') }}
                </th>
                <th class="text-left">
                  {{ $t('ui.value') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, name) in ledResponse" :key="name">
                <td>{{name}}</td>
                <td>{{value}}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

      </v-flex>

      <v-flex xs12 md4 v-if="!$lodash.isEmpty(ledResponse)">

        <v-flex xs12>
          <table>
            <tbody>
              <tr v-for="(item, index) in ledMarkerActions" :key="index">
                <td v-for="action in item" :key="action.name" :colspan="action.colspan || '1'">
                  <v-btn
                    block
                    :disabled="cmdAvailable ? false : true"
                    @click="submitLedCmd(action.cmd)"
                    :color="action.color || 'primary'"
                  >{{action.name}}</v-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </v-flex>

        <v-flex xs12>
          <v-btn
            :color="ipColor"
            width="64"
            height="64"
          ></v-btn>
        </v-flex>

      </v-flex>

    </v-layout>

  </v-container>
</template>

<script>
import axios from 'axios'
import $ws from '@/lib/ws'

import ConfigMixin from '@/mixins/ConfigMixin'
import SupervisorMixin from '@/mixins/SupervisorMixin'

import { validationMixin } from 'vuelidate'

export default {
  name: 'LedMarkerPage',
  mixins: [ConfigMixin, SupervisorMixin, validationMixin],
  data () {
    return {
      loading: false,
      cmdLoading: false,
      config: {
        LED_MARKER_ENABLED: false,
        LED_MARKER_PORT: '/dev/ttyAMA0'
      },
      ports: [
        '/dev/ttyAMA0',
        '/dev/ttyAMA1'
      ],
      ledResponse: {},
      ledMarkerActions: [
        [{'name': 'LDR +', 'cmd': 'L'}, {'name': 'LDR -', 'cmd': 'l'}],
        [{'name': 'SON ON', 'cmd': 'O'}, {'name': 'SON OFF', 'cmd': 'o'}],
        [{'name': 'Impuls +', 'cmd': 'I'}, {'name': 'Impuls -', 'cmd': 'i'}],
        [{'name': 'Pauza +', 'cmd': 'P'}, {'name': 'Pauza -', 'cmd': 'p'}],
        [{'name': 'RELEJ', 'cmd': 'RELEJ', 'colspan': 2}],
        [{'name': 'RESET', 'cmd': '15702256936', 'colspan': 2}],
        [{'name': 'DIAGNOSTIKA', 'cmd': 'X', 'color': 'red', 'colspan': 2}],
      ],
      impulsMs: null,
      pauzaMs: null,
      ipColor: 'black',
      impulsGeneratorMs: 0
    }
  },
  computed: {
    isFormValid () {
      return true
      // return !this.$v.$invalid
    },
    submitAvailable () {
      if (!this.loading && this.isFormValid) {
        return true
      }
      return false
    },
    cmdAvailable () {
      if (!this.cmdLoading) {
        return true
      }

      return false
    }
  },
  methods: {
    init () {
      this.initConfigData()
    },
    submit () {
      this.loading = true
      var promises = []

      promises.push(this.createConfig({
        'key': 'LED_MARKER_ENABLED',
        'value': this.config.LED_MARKER_ENABLED
      }))

      promises.push(this.createConfig({
        'key': 'LED_MARKER_PORT',
        'value': this.config.LED_MARKER_PORT
      }))

      Promise.all(promises)
        .then(() => {

          // svi upiti su gotovi
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })

        })
        .finally(() => {
          this.loading = false
          this.init()
        })
    },
    submitLedCmd (cmd) {
      this.cmdLoading = true
      this.ledResponse = {}
      axios.post(`${this.$store.getters['app/APP_API_URI']}/led-marker/control/`, {
        'cmd': cmd
      })
      .catch(() => {
        this.$EventBus.$emit('AppSnackbar/show', {
          color: 'red',
          text: this.$t('ui.error')
        })
      })
      .finally(() => {
        this.cmdLoading = false
      })
    },
    resetLedResponse () {
      this.ledResponse = {}
    },
    impulsGenerator () {

      if (!this.impulsMs) {
        return
      }

      if (this.ipColor == 'black' && this.impulsGeneratorMs > this.pauzaMs) {
        this.ipColor = 'yellow'
        this.impulsGeneratorMs = 0
      } else if (this.ipColor == 'yellow' && this.impulsGeneratorMs > this.impulsMs) {
        this.ipColor = 'black'
        this.impulsGeneratorMs = 0
      }

      this.impulsGeneratorMs += 1

    }
  },
  created () {
    this.init()
    const ws = $ws()
    this.$led_ws = ws(`${this.$store.getters['app/APP_WS_URI']}/led-marker/stanje/`, null, {maxTimeout: 3000})
    this.$led_ws.onMessage((message) => {
      if (this.resetLedResponseId) {
        clearTimeout(this.resetLedResponseId)
      }

      this.resetLedResponseId = setTimeout(this.resetLedResponse, 7000)

      this.ledResponse = JSON.parse(message.data)
      if ('I/P' in this.ledResponse) {
        this.impulsMs = parseInt(this.ledResponse['I/P'].split('/')[0])
        this.pauzaMs = parseInt(this.ledResponse['I/P'].split('/')[1])
      }
    })

    this.ipGeneratorInterval = setInterval(this.impulsGenerator, 1)
  },
  destroyed () {
    this.$led_ws.closeAndDoNotReconnect()

    if (this.ipGeneratorInterval) {
      clearInterval(this.ipGeneratorInterval)
    }

    if (this.resetLedResponseId) {
      clearTimeout(this.resetLedResponseId)
    }
  }
}
</script>
