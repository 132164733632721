<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">{{ $t('ui.akusticki_signali') }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="isAuthenticated" @click="sync" color="primary" :disabled="submitFormDisabled">{{$t('ui.save')}}</v-btn>
    </v-toolbar>
    <v-progress-linear v-show="syncing" color="primary" indeterminate></v-progress-linear>
    <v-card-text>
      <v-form
        ref="timeForm"
        v-model="timeFormValid"
      >
        <v-col>
          <v-select
            :disabled="!isAuthenticated"
            :label="this.$t('ui.rezim_rada')"
            :items="rezimiRada"
            v-model="odabraniRezimRada"
            item-text="name"
            item-value="id"
            persistent-hint
          ></v-select>
        </v-col>
        <v-col xs="12" v-if="odabraniRezimRada === 0">
          <h3>{{ $t('ui.vrijeme_ukljucenja') }}</h3>
          <v-text-field
            v-model="vrijemeUkljucenja"
            :rules="timeInputRules"
          ></v-text-field>
        </v-col>
        <v-col xs="12" v-if="odabraniRezimRada === 0">
          <h3>{{ $t('ui.vrijeme_iskljucenja') }}</h3>
          <v-text-field
            v-model="vrijemeIskljucenja"
            :rules="timeInputRules"
          ></v-text-field>
        </v-col>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'

export default {
  name: 't1AkustickiSignaliCard',
  mixins: [T1Mixin, AuthMixin],
  props: ['postavke'],
  data () {
    return {
      timeFormValid: false,
      syncing: false,
      ANSWER: null,
      timeInputRules: [
        value => !!value || this.$t('ui.field_required'),
        value => (value || '').length <= 8 || 'Max 8 characters',
        value => {
          const pattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]/
          return pattern.test(value) || this.$t('ui.invalid_format')
        },
      ],
      rezimiRada: [
        {id: 0, name: this.$t('ui.vremenski')},
        {id: 1, name: this.$t('ui.stalno_ukljuceno')},
        {id: 2, name: this.$t('ui.stalno_iskljuceno')}
      ]
    }
  },
  computed: {
    syncDisabled () {
      if (this.syncing) return true
      return false
    },
    submitFormDisabled () {
      if (this.syncing || this.timeFormValid !== true) return true
      return false
    },
    odabraniRezimRada: {
      get () {

        if (!this.ANSWER || this.ANSWER.length < 4) return null

        if (this.ANSWER[0] === 255 &&
            this.ANSWER[1] === 255 &&
            this.ANSWER[2] === 255 &&
            this.ANSWER[3] === 255) {
          // Stalno ukljuceno
          return 1
        } else if (this.ANSWER[0] === 254 &&
            this.ANSWER[1] === 254 &&
            this.ANSWER[2] === 254 &&
            this.ANSWER[3] === 254) {
          // Stalno iskljuceno
          return 2
        } else {
          // Vremenski
          return 0
        }
      },
      set (value) {
        if (value === 1) {
          // Stalno ukljuceno
          this.ANSWER = [255, 255, 255, 255]
        } else if (value === 2) {
          // Stalno iskljuceno
          this.ANSWER = [254, 254, 254, 254]
        } else if (value === 0){
          this.ANSWER = [6, 0, 22, 0]
        }
      }
    },
    vrijemeUkljucenja: {
      get () {
        if (!this.ANSWER || this.ANSWER.length < 4) return null

        let hh = this.ANSWER[0]
        if (hh > 0 && hh < 10 ) {
          hh = `0${hh}`
        }

        let mm = this.ANSWER[1]
        if (mm >= 0 && mm < 10 ) {
          mm = `0${mm}`
        }

        if (mm >= 60 || hh > 23) return null

        return `${hh}:${mm}`
      },
      set (value) {
        let v = value.split(':')
        this.ANSWER[0] = parseInt(v[0])
        this.ANSWER[1] = parseInt(v[1])
      }
    },
    vrijemeIskljucenja: {
      get () {
        if (!this.ANSWER || this.ANSWER.length < 4) return null

        let hh = this.ANSWER[2]
        if (hh >= 0 && hh < 10 ) {
          hh = `0${hh}`
        }

        let mm = this.ANSWER[3]
        if (mm >= 0 && mm < 10 ) {
          mm = `0${mm}`
        }

        if (mm > 60 || hh > 23) return null

        return `${hh}:${mm}`
      },
      set (value) {
        let v = value.split(':')
        this.ANSWER[2] = parseInt(v[0])
        this.ANSWER[3] = parseInt(v[1])
      }
    }
  },
  methods: {
    init () {
      this.initT1Data()
    },
    initT1Data () {
      this.syncing = true
      this.t1Write([this.t1.CPS, this.t1.READ, 0x48])
        .then((ANSWER) => {
          this.ANSWER = ANSWER
        })
        .finally(() => {
          this.syncing = false
        })
    },
    sync () {
      this.syncing = true
      let data = [this.t1.CPS, this.t1.WRITE, 0x48, this.ANSWER[0], this.ANSWER[1], this.ANSWER[2], this.ANSWER[3]]
      console.log(data)
      this.t1Write(data)
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'red',
            text: this.$t('ui.save_error')
          })
        })
        .finally(() => {
          this.syncing = false
          this.init()
        })
    }
  },
  created () {
    this.init()
  }
}
</script>
