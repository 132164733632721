<template>
  <v-layout row justify-center>
    <v-dialog v-model="openDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="headline" primary-title>{{headline}}</v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md4 lg2>
                <v-text-field
                  v-model="camera.code"
                  :label="this.$t('ui.code')"
                  :error-messages="codeErrors"
                  @input="$v.camera.code.$touch()"
                  @blur="$v.camera.code.$touch()"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md4 lg4>
                <v-text-field
                  v-model="camera.name"
                  :label="this.$t('ui.name')"
                  :error-messages="nameErrors"
                  @input="$v.camera.name.$touch()"
                  @blur="$v.camera.name.$touch()"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md4 lg6>
                <v-select
                  :items="cameraTypes"
                  :label="this.$t('ui.camera_type')"
                  item-text="name"
                  item-value="id"
                  v-model="camera.product"
                  @change="onCameraTypeChange()"
                ></v-select>
              </v-flex>

              <v-flex xs12 lg6>
                <v-text-field
                  v-model="camera.ip"
                  label="IP"
                  :error-messages="ipErrors"
                  @input="$v.camera.ip.$touch()"
                  @blur="onIpBlur()"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 lg6>
                <v-text-field
                  v-model="camera.port"
                  label="PORT"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 lg6>
                <v-text-field
                  v-model="camera.username"
                  label="USERNAME"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 lg6>
                <v-text-field
                  v-model="camera.password"
                  label="PASSWORD"
                  type="password"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 lg6 v-if="camera.product=='xroads_connected_cam'">
                <v-text-field
                  v-model="camera.xroads_ip"
                  label="TrafficXroads IP"
                  :error-messages="ipErrors"
                  @input="$v.camera.ip.$touch()"
                  @blur="onIpBlur()"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 lg6 v-if="camera.product=='xroads_connected_cam'">
                <v-text-field
                  v-model="camera.xroads_port"
                  label="TrafficXroads PORT"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 >
                <v-select
                  :items="cameraStreamTypes"
                  label="STREAM"
                  item-text="name"
                  item-value="id"
                  v-model="camera.stream_type"
                ></v-select>
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  v-model="camera.stream_url"
                  label="STREAM URL"
                ></v-text-field>
              </v-flex>

            <v-flex xs12>
              <v-checkbox
                v-model="camera.stream_video"
                :label="$t('ui.stream_video')"
              ></v-checkbox>
            </v-flex>

            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red darken-1" @click="userAction('delete')">
            {{this.$t('ui.delete')}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="userAction('cancel')">
            {{this.$t('ui.cancel')}}
          </v-btn>

          <v-btn color="green darken-1" @click="userAction('save')">
            {{this.$t('ui.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CameraEditDialog',
  mixins: [validationMixin],
  props: ['openDialog', 'headline', 'camera', 'cameraTypes', 'cameraStreamTypes'],
  validations: {
    camera: {
      asset: {
        required
      },
      code: {
        required
      },
      name: {
        required
      },
      ip: {
        required
      },
      snap_url: {
        required
      }
    }
  },
  computed: {
    assetErrors () {
      const errors = []
      if (!this.$v.camera.asset.$dirty) return errors
      !this.$v.camera.asset.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    codeErrors () {
      const errors = []
      if (!this.$v.camera.code.$dirty) return errors
      !this.$v.camera.code.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    nameErrors () {
      const errors = []
      if (!this.$v.camera.name.$dirty) return errors
      !this.$v.camera.name.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    ipErrors () {
      const errors = []
      if (!this.$v.camera.ip.$dirty) return errors
      !this.$v.camera.ip.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    snapUrlErrors () {
      const errors = []
      if (!this.$v.camera.snap_url.$dirty) return errors
      !this.$v.camera.snap_url.required && errors.push(this.$t('ui.field_required'))
      return errors
    },
    isFormValid () {
      return !this.$v.$invalid
    }
  },
  methods: {
    userAction: function (actionResult) {
      this.$emit('userAction', actionResult)
    },
    submit: function () {
      this.loading = true
    },
    onIpBlur () {

      this.$v.camera.ip.$touch()

      if (!this.camera.snap_url && this.camera.ip && window.isIP.test(this.camera.ip) && this.camera.port) {
        this.camera.snap_url = `http://${window.isIP.exec(this.camera.ip)[0]}/`
        this.camera.live_url = `http://${window.isIP.exec(this.camera.ip)[0]}/`

        if (this.camera.product === 'TRAFSIGNAL_0') {
          //
        } else if (this.camera.product === 'Xcamp') {
          this.camera.snap_url = `http://${window.isIP.exec(this.camera.ip)[0]}/getsnap`
        } else if (this.camera.product === 'TrafiCamXstream' || this.camera.product === 'TrafiOne') {
          this.camera.snap_url = `http://${window.isIP.exec(this.camera.ip)[0]}/api/image/current/`
        }

        return
      }

      if (this.camera.ip && window.isIP.test(this.camera.ip)) {
        this.camera.snap_url = this.camera.snap_url.replace(/(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/, window.isIP.exec(this.camera.ip)[0])
        this.camera.live_url = this.camera.live_url.replace(/(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/, window.isIP.exec(this.camera.ip)[0])
      }
    },
    onCameraTypeChange () {
      if (this.camera.product === 'TRAFSIGNAL_0') {
        this.camera.port = 55570
        // Još ne znam koji je URL za snap
        //this.camera.snap_url = ``
        //this.camera.live_url = ``
      } else if (this.camera.product === 'xroads_connected_cam') {
        this.camera.xroads_port = 55570
      } else if (this.camera.product === 'Xcamp') {
        this.camera.port = 20800
        this.camera.snap_url = `http://${window.isIP.exec(this.camera.ip)[0]}/getsnap`
      } else if (this.camera.product === 'TrafiCamXstream' || this.camera.product === 'TrafiOne') {
        this.camera.port = 80
        this.camera.snap_url = `http://${window.isIP.exec(this.camera.ip)[0]}/api/image/current/`
      }
    }
  }
}
</script>
