<template>
  <v-container fluid grid-list-md>
    <v-flex xs12>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            <span class="text-xl-left">{{ this.$t('ui.settings') }}</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-flex xs6 xl2>
            <v-text-field
              append-icon="search"
              :label="this.$t('ui.search')"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-flex>
            <v-btn
              icon
              @click="$router.push({name: 'ConfigAddPage'})">
              <v-icon>add_box</v-icon>
            </v-btn>
          <v-btn icon
            :loading="loading"
            :disabled="loading"
            @click="downloadBackup">
            <v-icon>get_app</v-icon>
          </v-btn>
          <v-btn
            icon
            :disabled="loading"
            @click="selectFilesToUpload()">
            <v-icon>publish</v-icon>
          </v-btn>
        </v-toolbar>

        <form id="upload_backup_form">
          <input
            type="file"
            id="upload_backup_input"
            ref="uploadBackup"
            accept=".sql"
            hidden
            v-on:change="handleBackupUpload()"
          />
        </form>

        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :search="search"
          class="elevation-1"
        >
          <v-progress-linear slot="progress" color="primary" indeterminate></v-progress-linear>

          <template v-slot:item.actions="{ item }">
            <v-btn
              icon
              @click="editConfig(item)"
              class="mx-0">
              <v-icon color="white">edit</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="deleteConfig(item)"
              class="mx-0">
              <v-icon color="red">delete</v-icon>
            </v-btn>
          </template>

        </v-data-table>

      </v-card>
    </v-flex>
  </v-container>
</template>

<script>
import ConfigMixin from '@/mixins/ConfigMixin'

export default {
  name: 'ConfigPage',
  mixins: [ConfigMixin],
  data () {
    return {
      loading: true,
      search: '',
      items: [],
      config: {
        DEVICE_CODE: null
      },
      pagination_config: {
        sortBy: 'code',
        page: 1,
        rowsPerPage: 25
      },
      headers: [
        {
          text: this.$t('ui.code'),
          align: 'left',
          value: 'key'
        },
        {
          text: this.$t('ui.value'),
          align: 'left',
          value: 'value'
        },
        {
          text: this.$t('ui.description'),
          align: 'left',
          value: 'description'
        },
        {
          text: this.$t('ui.actions'),
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  methods: {
    init () {
      return new Promise((resolve, reject) => {
        this.initConfigData()
          .catch(() => {
            reject()
          })
          .then(this.getConfigRows)
          .catch(() => {
            this.$EventBus.$emit('AppSnackbar/show', {
              color: 'red',
              text: this.$t('ui.error')
            })
            reject()
          })
          .then(() => {
            resolve()
          })
      })
    },
    getConfigRows () {
      return new Promise((resolve, reject) => {
        this.getConfigs()
          .then((response) => {
            this.items = response.data.rows
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    editConfig (config) {
      this.$router.push({
        name: 'ConfigEditPage',
        params: { config_id: config.id }
      })
    },
    deleteConfig (config) {
      this.$router.push({
        name: 'ConfigDeletePage',
        params: { config_id: config.id }
      })
    },
    downloadBackup () {
      this.loading = true

      this.downloadConfigBackup()
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'backup.sql')
          if (this.config.DEVICE_CODE) {
            link.setAttribute('download', `${this.config.DEVICE_CODE}_backup.sql`)
          }
          document.body.appendChild(link)
          link.click()
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectFilesToUpload () {
      var uploadInput = this.jQuery('#upload_backup_input')
      uploadInput.trigger('click')
    },
    handleBackupUpload () {
      this.loading = true
      this.uploadFiles = this.$refs.uploadBackup.files
      let formData = new FormData()

      for (let i = 0; i < this.uploadFiles.length; i++) {
        formData.append(`files`, this.uploadFiles[i])
      }

      this.uploadConfigBackup(formData)
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'red',
            text: this.$t('ui.error')
          })
        })
        .then(this.init)
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .finally(() => {
          // Resetiraj vrijednosti inputa tako da se triggerira change ako se odabere opet ista datoteka
          this.jQuery('#upload_backup_input').val(null)
        })
    }
  },
  created () {
    this.init()
  }
}
</script>
