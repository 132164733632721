<template>
<div id="signali-holder" style="overflow: auto;">
  <canvas id="ciklus_canvas"></canvas>
</div>
</template>

<script>
export default {
  name: 't2000Ciklus',
  props: {
    events: {
      type: Object,
      default: function () {
        return {}
      }
    },
    trafficController: {
      type: Object,
      default: function () {
        return {}
      }
    },
    rezolucija: {
      type: Number,
      default: 1
    },
    grupaLabelColor: {
      type: String,
      default: '#0000ff'
    },
    detektorLabelColor: {
      type: String,
      default: '#0967b2'
    },
    tickColor: {
      type: String,
      default: '#7A7A7A'
    },
    sekundaFont: {
      type: String,
      default: '10pt Calibri'
    },
    grupaFont: {
      type: String,
      default: '15pt Calibri'
    }
  },
  data () {
    return {
      x: 40,
      y: 0,
      t: 0,
      n: 1,
      korak: 1,
      toggler: 0,
      ciklusCanvas: null,
      ctx: null,
      renderTickIntervalId: null
    }
  },
  watch: {
    events: function () {
      this.renderSignalPlan()
    }
  },
  methods: {
    init () {
      if (this.renderTickIntervalId) {
        clearInterval(this.renderTickIntervalId)
      }
      this.x = 40
      this.y = 0
      this.t = 0
      this.n = 1
      this.korak = 1
      this.toggler = 0

      this.ciklusCanvas = document.getElementById('ciklus_canvas')
      this.ciklusCanvas.width  = 5000
      this.ciklusCanvas.height = this.trafficController.broj_grupa*30
      this.ctx = this.ciklusCanvas.getContext('2d')
      this.nacrtajGrupeText()
      this.renderTickIntervalId = setInterval(this.renderTick, 1000)
    },
    nacrtajGrupeText () {
      // Prva linija 5s
      this.ctx.strokeStyle = this.tickColor
      this.ctx.moveTo(41, 1)
      this.ctx.lineTo(41, 1200)
      this.ctx.stroke()

      // Nacrtaj 0 sekundu
      this.ctx.font = this.sekundaFont
      this.ctx.fillStyle = this.grupaLabelColor
      this.ctx.fillText(this.t, this.x+4, (this.trafficController.broj_grupa*30))
      this.t = this.t + 5

      // Grupe labels
      this.ctx.font = this.grupaFont
      this.ctx.fillStyle = this.grupaLabelColor
      let redak = 15
      for (let j = 1; j <= this.trafficController.broj_grupa; j++) {
        if (j < 10) {
          this.ctx.fillText(j, 5, redak)
        } else {
          this.ctx.fillText(j, 0, redak)
        }
        redak = redak + 30
      }
    },
    renderSignalPlan () {
      if (this.korak === 0.5) {
        this.toggler = this.toggler + 1
        if (this.toggler > 1) {
          this.toggler = 0
          return
        }
      }

      let y_crveno = 0
      let y_zuto = 0
      let y_zeleno = 0
      let crveno = 0
      let zuto = 0
      let zeleno = 0

      this.x = this.x + this.rezolucija
      for (let i = 0; i < this.trafficController.broj_grupa; i++) {
        this.y = i * 30
        y_crveno = this.y
        y_zuto = this.y + 5
        y_zeleno = this.y + 10
        crveno = this.events.stanje_signalnih_grupa[i][0]
        zuto = this.events.stanje_signalnih_grupa[i][1]
        zeleno = this.events.stanje_signalnih_grupa[i][2]
        // crveno
        if ( crveno == 1) {
          this.ctx.fillStyle="#F62817"
        } else {
          this.ctx.fillStyle="#F2F2F2"
        }
        this.ctx.fillRect(this.x, y_crveno, this.rezolucija, 5)

        // zuto
        if (zuto == 1) {
          this.ctx.fillStyle="#FDD017"
        } else {
          this.ctx.fillStyle="#F2F2F2"
        }
        this.ctx.fillRect(this.x, y_zuto, this.rezolucija, 5)

        // zeleno
        if (zeleno == 1) {
          this.ctx.fillStyle="#8FC33A"
        } else {
          this.ctx.fillStyle="#F2F2F2"
        }
        this.ctx.fillRect(this.x, y_zeleno, this.rezolucija, 5)

      }
    },
    renderTick () {
      if (this.n < 5) {
        this.ctx.beginPath()
        this.ctx.strokeStyle = '#FFFFFF'
        this.ctx.moveTo(this.x, 0)
        this.ctx.lineTo(this.x, 1200)
        this.ctx.stroke()
        this.ctx.closePath()
      } else {
        this.ctx.beginPath()
        this.ctx.strokeStyle = this.tickColor
        this.ctx.moveTo(this.x, 0)
        this.ctx.lineTo(this.x, 1200)
        this.ctx.stroke()
        this.ctx.closePath()
        this.ctx.font = this.sekundaFont
        this.ctx.fillStyle = this.grupaLabelColor
        this.ctx.fillText(this.t, this.x + 2, this.trafficController.broj_grupa * 30)
        this.t = this.t + 5
        this.n = 0
      }
      if (this.n >= 5) {
        this.n = 0
      }
      this.n = this.n + 1
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    if (this.renderTickIntervalId) {
      clearInterval(this.renderTickIntervalId)
    }
  }
}
</script>
