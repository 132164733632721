import env from '../env'
import Vue from 'vue'

export const AppStore = {
  namespaced: true,
  state: {
    isAuthenticated: false,
    isSuperUser: false,
    config: {},
    local: {
      locale: env.DEFAULT_LANGUAGE || 'hr',
      APP_HOST_ADDRESS: `${window.location.hostname}`,
      APP_HOST_PORT: 6001,
      TRAFFIC_CONTROLLER_HOST_ADDRESS: `${window.location.hostname}`,
      TRAFFIC_CONTROLLER_HOST_PORT: 8888,
      CAMERAS_FEED_SERVER_HOST: `${window.location.hostname}`,
      CAMERAS_FEED_SERVER_PORT: 8877,
      REMEMBER_ME: false,
      PIN: null
    },
    locales: [
      {
        locale: 'hr',
        label: 'HR'
      },
      {
        locale: 'si',
        label: 'SLO'
      },
      {
        locale: 'en',
        label: 'EN'
      }
    ]
  },
  getters: {
    isAuthenticated (state) {
      return state.isAuthenticated
    },
    isSuperUser (state) {
      return state.isSuperUser
    },
    config (state) {
      return state.config
    },
    locale (state) {
      return state.local.locale
    },
    locales (state) {
      return state.locales
    },
    local (state) {
      return state.local
    },
    REMEMBER_ME (state) {
      return state.local.REMEMBER_ME
    },
    PIN (state) {
      return state.local.PIN
    },
    APP_API_URI (state) {
      return `http://${state.local.APP_HOST_ADDRESS}:${state.local.APP_HOST_PORT}`
    },
    APP_WS_URI (state) {
      return `ws://${state.local.APP_HOST_ADDRESS}:${state.local.APP_HOST_PORT}`
    },
    TRAFFIC_CONTROLLER_API_URI (state) {
      return `http://${state.local.TRAFFIC_CONTROLLER_HOST_ADDRESS}:${state.local.TRAFFIC_CONTROLLER_HOST_PORT}`
    },
    TRAFFIC_CONTROLLER_WS_URI (state) {
      return `ws://${state.local.TRAFFIC_CONTROLLER_HOST_ADDRESS}:${state.local.TRAFFIC_CONTROLLER_HOST_PORT}`
    },
    CAMERAS_FEED_SERVER_API_URI (state) {
      return `http://${state.local.CAMERAS_FEED_SERVER_HOST}:${state.local.CAMERAS_FEED_SERVER_PORT}/kamere/snap/`
    },
    TELVENT_CIKLUS_REZOLUCIJA (state) {
      return state.local.TELVENT_CIKLUS_REZOLUCIJA || 5
    }
  },
  mutations: {
    setAuthenticated (state, value) {
      if (value === true) {
        state.isAuthenticated = true
      } else {
        state.isAuthenticated = false
      }
    },
    setSuperUser (state, value) {
      if (value === true) {
        state.isSuperUser = true
      } else {
        state.isSuperUser = false
      }
    },
    set (state, c) {
      for (const [key, value] of Object.entries(c)) {
        Vue.set(state.config, key, value)
      }
    },
    setLocal (state, c) {
      for (const [key, value] of Object.entries(c)) {
        Vue.set(state.local, key, value)
      }
      localStorage.setItem('appLocal', JSON.stringify(state.local))
    }
  }
}
