export default {
  methods: {
    getConfigs () {
      return this.$api.get('/config/?offset=0&limit=10000', {baseURL: this.$store.getters['app/APP_API_URI']})
    },
    getConfig (configId) {
      return this.$api.get(`/config/?id=${configId}`, {baseURL: this.$store.getters['app/APP_API_URI']})
    },
    getConfigByKey (configKey) {
      return this.$api.get(`/config/?key=${configKey}`, {baseURL: this.$store.getters['app/APP_API_URI']})
    },
    getConfigByKeys (configKeysArray) {
      var configKeyList = configKeysArray.join(',')
      return this.$api.get(`/config/?keys=${configKeyList}`, {baseURL: this.$store.getters['app/APP_API_URI']})
    },
    createConfig (config) {
      return this.$api.post('/config/', config, {baseURL: this.$store.getters['app/APP_API_URI']})
    },
    updateConfig (config) {
      return this.$api.put('/config/', config, {baseURL: this.$store.getters['app/APP_API_URI']})
    },
    deleteConfig (configId) {
      return this.$api.delete(`/config/?id=${configId}`, {baseURL: this.$store.getters['app/APP_API_URI']})
    },
    initConfigData () {

      return new Promise((resolve, reject) => {

        if (!this.config) return

        this.loading = true

        var configKeys = []

        this.$lodash.each(this.config, function (value, key) {
          configKeys.push(key)
        })

        this.getConfigByKeys(configKeys)
          .then((response) => {

            this.$lodash.each(response.data.rows, (row) => {
              if (row.key in this.config) {

                if (this.configMeta && row.key in this.configMeta) {
                  this.config[row.key] = JSON.parse(row.value)
                } else {
                  this.config[row.key] = row.value
                }

                var c = {}
                c[row.key] = this.config[row.key]

                this.$store.commit('app/set', c)
              }
            })

            this.loading = false

            resolve(this.config)

          })
          .catch((error) => {
            this.loading = false
            console.log(error)

            this.$EventBus.$emit('AppSnackbar/show', {
              color: 'error',
              text: this.$t('ui.error')
            })

            reject(error)
          })
      })

    },
    downloadConfigBackup () {
      return this.$api.get('/backup/', {
        baseURL: this.$store.getters['app/APP_API_URI'],
        responseType: 'blob'
      })
    },
    uploadConfigBackup (formData) {
      return this.$api.post('/backup/', formData, {
        baseURL: this.$store.getters['app/APP_API_URI'],
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    getIfConfig () {
      return this.$api.get('/network/config/', {baseURL: this.$store.getters['app/APP_API_URI']})
    },
    saveNetworkConfig (config) {
      return this.$api.post('/network/config/', {config: config}, {baseURL: this.$store.getters['app/APP_API_URI']})
    },
    doBackendAction (action) {
      return this.$api.get(`/action/?a=${action}`, {baseURL: this.$store.getters['app/APP_API_URI']})
    }
  }
}
