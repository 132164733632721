<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left"></span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!--<v-btn v-if="isAuthenticated" @click="promjeniAktivniProgram()" color="primary" :disabled="saveDisabled">{{ $t('ui.save') }}</v-btn>-->
    </v-toolbar>
    <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>UREF</td>
              <td>
                <v-text-field
                  v-model="SET_UREF_VAL"
                ></v-text-field>
              </td>
              <td>
                <v-btn v-if="isAuthenticated" @click="saveUREF()" color="primary" :disabled="saveDisabled" block>{{ $t('ui.save') }}</v-btn>
              </td>
            </tr>
            <tr>
              <td>RESET CPS UPTIME</td>
              <td>

              </td>
              <td>
                <v-btn v-if="isAuthenticated" @click="resetCpsUptime()" color="primary" :disabled="saveDisabled" block>RESET</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'

export default {
  name: 't1UrefCard',
  mixins: [T1Mixin, AuthMixin],
  data () {
    return {
      loading: false,
      getT1DataId: null,
      SET_UREF_VAL: 2.56
    }
  },
  methods: {
    saveUREF () {
      let uref = parseFloat(this.SET_UREF_VAL)*10000
      let urefHigh = parseInt(uref / 256)
      let urefLow = uref - urefHigh * 256
      this.loading = true
      this.t1Write([this.t1.CPS, this.t1.WRITE, 0x1A, urefLow, urefHigh])
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'error',
            text: this.$t('ui.error')
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    resetCpsUptime () {
      this.loading = true
      this.t1Write([this.t1.CPS, this.t1.WRITE, 0x19, 0x00, 0x00, 0x00, 0x00, 0x00])
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'error',
            text: this.$t('ui.error')
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    saveDisabled () {
      if (this.loading) return true
      return false
    }
  }
}
</script>
