export default {
  ui: {
    auth_error: 'Greška! Provjerite korisničko ime i lozinku.',
    home: 'Home',
    traffic_controller: 'Uređaj',
    traffic_controllers: 'Uređaji',
    traffic_controller_type: 'Tip uređaja',
    traffic_controller_delete: 'Ukloni uređaj',
    traffic_controller_add: 'Dodaj novi uređaj',
    traffic_controller_edit: 'Uredi uređaj',
    traffic_controllers_simulate: 'Simulator uređaja',
    trafconnect_url: 'TrafConnect URL',
    comment: 'Komentar',
    log: 'Log',
    refresh: 'Ponovo učitaj',
    date_picker: 'Izaberi datum',
    choose_layers: 'Odaberi podlogu',
    date_and_time: 'Datum i vrijeme',
    date_from: 'Datum od',
    date_to: 'Datum do',
    sent: 'Poslano',
    status: 'Status',
    error: 'Greška',
    user: 'Korisnik',
    user_level: 'Razina pristupa',
    users: 'Korisnici',
    map: 'Karta',
    alarms: 'Alarmi',
    password: 'Lozinka',
    password_change: 'Promjena lozinke',
    enter_password: 'Unesite lozinku',
    enter_password_again: 'Unesite lozinku ponovo',
    login: 'Prijava',
    search: 'Pretraga',
    centralno_upravljanje: 'Centralno upravljanje',
    sve_crveno: 'Sve crveno',
    rucno_upravljanje: 'Ručno upravljanje',
    vremensko_upravljanje: 'Vremensko upravljanje',
    signali_ukljuceni: 'Signali uključeni',
    treptanje_zutog: 'Treptanje žutog',
    greska: 'Greška',
    abc: 'ABC',
    name: 'Ime',
    surname: 'Prezime',
    group: 'Grupa',
    code: 'Šifra',
    type: 'Tip',
    name_and_surname: 'Ime i prezime',
    username: 'Korisničko ime',
    email: 'E-mail',
    admin: 'Admin',
    actions: 'Akcije',
    edit_user: 'Uredi korisnika',
    add_user: 'Dodaj korisnika',
    delete_user: 'Ukloni korisnika',
    submit: 'Spremi',
    active: 'Aktivan',
    save_success: 'Uspješno spremljeno',
    save_error: 'Greška!',
    close: 'Zatvori',
    field_required: 'Polje je obavezno!',
    field_vaild_email_required: 'Unesite ispravnu e-mail adresu!',
    minimum: 'Minimum',
    passwords_must_be_identical: 'Šifre moraju biti jednake',
    cameras: 'Kamere',
    svg: 'SVG',
    description: 'Opis',
    edit: 'Uredi',
    delete: 'Ukloni',
    locate_on_map: 'Prikaži na karti',
    week: 'Tjedan',
    month: 'Mjesec',
    year: 'Godina',
    download: 'Preuzimanje datoteka',
    alarm_details: 'Detalji alarma',
    location: 'Lokacija',
    created: 'Stvoren',
    ended: 'Završen',
    logout: 'Odjavi se',
    start: 'Start',
    stop: 'Stop',
    settings: 'Postavke',
    language: 'Jezik',
    disable_notification_sound: 'Ugasi zvuk notifikacija',
    enable_notification_sound: 'Upali zvuk notifikacija',
    companies: 'Poduzeća',
    company_add: 'Dodaj novo poduzeće',
    company_edit: 'Uredi poduzeće',
    company_delete: 'Ukloni poduzeće',
    contacts: 'Kontakti',
    persons: 'Osobe',
    homepage: 'www',
    tel: 'Telefon',
    add: 'Dodaj',
    // novo
    person_add: 'Dodaj osobu',
    person_edit: 'Uredi osobu',
    person_delete: 'Ukloni osobu',
    company: 'Poduzeće',
    manufacturer: 'Proizvođač',
    maintainer: 'Održavatelj',
    locations: 'Lokacije',
    unclassified: 'Nesvrstani',
    time_from: 'Vrijeme od',
    time_to: 'Vrijeme do',
    stanje_signala: 'Signali',
    mobilni_internet: 'Mobilni internet',
    gsm: 'GSM',
    gps: 'GPS',
    wifi: 'Wi-Fi',
    mikrovalovni_detektori: 'Mikrovalni detektori',
    centar: 'Centar',
    watchdog: 'Watchdog',
    vpn: 'VPN',
    value: 'Vrijednost',
    config_add: 'Dodaj novu postavku',
    config_edit: 'Uredi postavku',
    nadzorni_centar: 'Nadzorni centar',
    not_active: 'Nije aktivan',
    unknown: 'Nije poznat',
    running: 'Pokrenut',
    not_running: 'Nije pokrenut',
    starting: 'Pokreće se',
    yes: 'Da',
    no: 'Ne',
    confirm_delete: 'Potvrdi uklanjanje',
    really_delete_items: 'Stvarno ukloniti odabrane stavke?',
    ip_address: 'IP adresa',
    private_key: 'Privatni ključ',
    public_key: 'Javni ključ',
    camera_type: 'Tip kamere',
    cancel: 'Odustani',
    save: 'Spremi',
    cameras_detectors_backend: 'Cameras backend',
    video_feed_server: 'Video feed server',
    tlocrt: 'Tlocrt',
    dashboard: 'Nadzorna ploča',
    add_tlocrt: 'Dodaj tlocrt',
    background_color: 'Boja pozadine',
    white: 'Bijela',
    dark: 'Tamna',
    system: 'Sistem',
    ciklus: 'Ciklus',
    detektori: 'Detektori',
    offset: 'Offset',
    cu_ciklus: 'CU - Ciklus',
    snage_signala: 'Snage signala',
    gain: 'Gain',
    izmjereno_stanje: 'Izmjereno stanje',
    nominalne_vrijednosti: 'Nominalne vrijednosti',
    dozvoljeno_odstupanje: 'Dozvoljeno odstupanje',
    jedinice: 'Jedinice',
    pragovi: 'Pragovi',
    rebalans: 'Rebalans',
    impuls: 'Impuls',
    logika: 'Logika',
    stalne_najave: 'Stalne najave',
    vremena: 'Vremena',
    programski_rad: 'Programski rad',
    gpio: 'GPIO',
    errors: 'Greške',
    statistika: 'Statistika',
    commands: 'Naredbe',
    sleep: 'Spavaj',
    color: 'Boja',
    languages: 'Jezici',
    variable_gain_not_supported: 'Varijabilni gain nije podržan',
    izmjereno_stanje_snaga: 'Izmjereno stanje snaga',
    day: 'Dan',
    vrijeme: 'Vrijeme',
    raspored: 'Raspored',
    uredi_raspored: 'Uredi raspored',
    radni_dan: 'Radni dan',
    nominalne_vrijednosti_snaga: 'Nominalne vrijednosti snaga',
    zelena_vremena_signalnih_grupa: 'Zelena vremena signalnih grupa',
    signalna_grupa: 'Signalna grupa',
    minimalno_zeleno_vrijeme: 'Minimalno zeleno vrijeme',
    maksimalno_zeleno_vrijeme: 'Maksimalno zeleno vrijeme',
    rucni_odabir: 'Ručni odabir',
    automatski: 'Automatski',
    trenutni_ptp: 'Trenutni PTP',
    mod_rada: 'Mod rada',
    promjeni_aktivni_program: 'Promijeni aktivni program',
    ugasi_signale: 'Ugasi signale',
    upisano_maksimalno_zeleno_vrijeme: 'Upisano maksimalno zeleno vrijeme',
    ok_dimmer_ukljucen: 'OK, dimmer uključen',
    ok_dimmer_iskljucen: 'OK, dimmer isključen',
    temperatura_cps_modula: 'Temperatura CPS modula',
    uline_napon_signala: 'Uline (napon signala)',
    uptime: 'Uptime',
    vrijeme_i_datum: 'Vrijeme i datum',
    ponedjeljak: 'Ponedjeljak',
    utorak: 'Utorak',
    srijeda: 'Srijeda',
    četvrtak: 'Četvrtak',
    petak: 'Petak',
    subota: 'Subota',
    nedjelja: 'Nedjelja',
    sync: 'Uskladi',
    sync_time: 'Uskladi vrijeme',
    dimmer: 'Dimmer',
    stanje_dimmera: 'Stanje dimmera',
    LDR: 'LDR',
    histereza: 'Histereza',
    vrijeme_relaksacije: 'Vrijeme relaksacije',
    prag: 'Prag',
    pin: 'PIN',
    novi_pin: 'Novi PIN',
    ponovi_novi_pin: 'Ponovi novi PIN',
    promjeni_pin: 'Promjeni PIN',
    akusticki_signali: 'Akustički signali',
    pin_se_mora_sastojati_od_4_znamenke: 'PIN se mora sastojati od 4 znamenke',
    vrijednost_mora_biti_broj: 'Vrijednost mora biti broj',
    rezim_rada: 'Režim rada',
    vrijeme_ukljucenja: 'Vrijeme uključenja',
    vrijeme_iskljucenja: 'Vrijeme isključenja',
    ucitaj_izvorne_postavke: 'Učitaj izvorne postavke',
    potvrdi_ucitavanje_izvornih_parametara: 'Potvrdi učitavanje izvornih parametara',
    zaista_ucitati_izvorne_postavke: 'Zaista učitati izvorne postavke?',
    ucitaj_greske: 'Učitaj greške',
    greska_u_sustavu_za_detekciju: 'Greška u sustavu za detekciju',
    posalji_program: 'Pošalji program',
    upload_programa: 'Upload programa',
    resetiraj_serijski_broj: 'Resetiraj serijski broj',
    delete_statistika: 'Ukloni statistiku',
    detektorska_logika: 'Detektorska logika',
    statistika_backend: 'Statistika backend',
    ima_stalnu_najavu: 'Ima stalnu najavu',
    u_stalnoj_najavi: 'U stalnoj najavi',
    u_kvaru: 'U kvaru',
    detektor: 'Detektor',
    edit_offset: 'Uredi offset',
    program: 'Program',
    novi_offset: 'Novi offset',
    nije_predviđeno: 'Nije predviđeno',
    greska_koordinacije_LOKALNI_RAD: 'Greška koordinacije - LOKALNI RAD',
    nije_ukljuceno: 'Nije uključeno',
    no_defined_cameras: 'Nema definiranih kamera',
    reset_cams: 'Resetiraj kamere',
    send: 'Šalji',
    wrong_pin_entered: 'Krivi PIN!',
    confirm_reset_cams: 'Potvrdi resetiranje kamera',
    really_reset_cams: 'Resetiraj kamere?',
    remember_me: 'Zapamti me',
    dozvoljeno_odstupanje_prag_2: 'Dozvoljeno odstupanje - prag 2',
    prag_2: 'Prag 2',
    postavke_snage_signala: 'Postavke - snage signala',
    postavi_prag_1_na_100_posto: 'Prag1 na 100%',
    postavi_prag_1_na_0_posto: 'Prag1 na 0%',
    postavi_prag_2_na_100_posto: 'Prag2 na 100%',
    postavi_prag_2_na_0_posto: 'Prag2 na 0%',
    spremi_kao_default: 'Spremi kao default',
    restore_default_values: 'Vrati na default postavke',
    postavi_pragove: 'Postavi pragove',
    download_backup: 'Preuzmi sigurnosnu kopiju',
    restore_backup: 'Obnovi pomoću sigurnosne kopije',
    download_CSV: 'Preuzmi CSV',
    postavi_tolerancije: 'Postavi tolerancije',
    tolerancija: 'Tolerancija',
    reset_backend: 'Reset backend',
    gpio_settings: 'GPIO Postavke',
    hrvatski: 'Hrvatski',
    slovenski: 'Slovenski',
    gpio_adresa: 'GPIO adresa',
    timeout: 'Timeout',
    razvojna_konzola: 'Razvojna konzola',
    development: 'Razvoj',
    detektorska_ploca: 'Detektorska ploča',
    forget_me: 'Zaboravi me',
    sinkro_sekunda: 'Sinkro sekunda',
    vprog_rad_not_supported: 'Upravljanje višeprogramskim radom nije podržan',
    kalibracija_sig: 'Kalibracija SIG',
    ac_status_check: 'Provjera stanja napajanja',
    prag_1: 'Prag 1',
    rezolucija: 'Rezolucija',
    noise: 'Šum',
    error_expecting_integer_as_noise_param: 'Očekujem integer kao parametar za šum detektora!',
    not_supported: 'Funkcija nije podržana!',
    centralno_upravljanje_kontroler: 'CU - Kontroler',
    stabilnostPetlje: 'Stabilnost petlje',
    koeficijent_stabilnosti_petlje: 'Koeficijent stabilnosti petlje',
    error_expecting_integer_as_koef_stabilnost_petlje_param: 'Očekujem integer kao parametar za koeficijent stabilnosti petlje!',
    edit_program_names: 'Uredi nazive programa',
    treptanje: 'Treptanje',
    reset_program_names: 'Resetiraj nazive programa',
    reset_naziva_programa_warning: 'Broj programa na T-1 se razlikuje od broja upisanih naziva programa, napravite RESET naziva programa!',
    reset_matrice_paljenja_programa_warning: 'Broj programa na T-1 se razlikuje se razilkuje od broja programa u matrici, ponovo ispunite matricu!',
    reset_matrice_paljenja: 'Reset matrice',
    ispuni_matricu_paljenja_ispravno: 'Ispunite ispravno matricu paljenja, svi brojevi moraju biti upisani i > 0!',
    trap_settings: 'TRAP postavke',
    led_marker: 'LED Marker',
    led_marker_controller: 'LED marker kontroler',
    invalid_format: 'Krivi format!',
    system_time: 'Sistemsko vrijeme',
    ntp_enabled: 'NTP aktivan',
    time_zone: 'Vremenska zona',
    time_and_date: 'Vrijeme i datum',
    change_system_time: 'Promjeni sistemsko vrijeme',
    gps_device_type: 'Tip GPS uređaja',
    help: 'Help',
    sync_time_with_computer: 'Uskladi vrijeme sa lokalnim računalom',
    auto_sync_system_time_to_t1_time: 'Automatski uskladi T-1 vrijeme sa sistemskim vremenom.',
    sync_every_x_seconds: 'Napravi sync svake X sekunde.',
    disabled: 'Onemogočeno',
    engleski: 'Engleski',
    proxy_requests: 'ŠALJI ZAHTJEVE NA PROXY',
    start_backend: 'POKRENI BACKEND APP',
    vremenski: 'Vremenski',
    stalno_ukljuceno: 'Stalno uključeno',
    stalno_iskljuceno: 'Stalno isključeno',
    date_time: 'Vrijeme',
    cps_temperature: 'Temperatura CPS',
    napon: 'Napon',
    trazi_zeleno: 'Traži zeleno',
    produzava_zeleno: 'Produžava zeleno',
    memorija: 'Memorija',
    odgoda_najave: 'Odgoda najave',
    interval_izmedju_vozila: 'Interval između vozila',
    zona: 'Zona',
    video_wall: 'Video zid',
    stream_video: 'Stream video',
    system_hardware: 'Hardver sustava',
    network: 'Mreža',
    network_settings: 'Postavke mreže',
    waiting: 'Čekam',
    restart: 'Restart',
    shutdown: 'Shutdown',
    confirm_restart: 'Potvrdi ponovno pokretanje',
    really_restart_the_system: 'Da li zaista želite ponovno pokrenuti sistem?',
    confirm_shutdown: 'Potvrdi gašenje',
    really_shutdown_the_system: 'Da li zaista želite ugasiti sistem?',
    not_connected_to_traffic_controller: 'Nije spojen na semaforski uređaj',
    phases: 'Faze',
    phase: 'Faza',
    green: 'Zeleno',
    yellow: 'Zuto',
    red: 'Crveno',
    manual_control: 'Ručno upravljanje',
    programs: 'Programi',
    cycle: 'Ciklus',
    update: 'Update',
    time: 'Vrijeme',
    import: 'Import',
    export: 'Export',
    second: 'Sekunda',
    internal_second: 'Unutarnja sekunda',
    enable_atms_control: 'ENABLE ATMS CONTROL',
    stage: 'Stage',
    bus_priority: 'Bus priority',
    licence_plate: 'Registrska tablica',
    duration: 'Trajanje',
    programming: 'Programiranje',
    cycle_length: 'Ciklus',
    fixed_time_control: 'Vremensko upravljanje',
    amber_flash: 'Treptanje žutog',
    signals_off: 'Ugasi signale',
    demand: 'Zahtjev',
    privilege: 'Privilegija',
    greska_pojedinog_virtualnog_detektora: 'Greška pojedinog virtualnog detektora',
    verifikacija: 'verifikacija',
    crveno: 'crveno',
    nema_signala: 'nema signala',
    pojava_signala: 'pojava signala',
    zuto: 'žuto',
    zeleno: 'zeleno',
    grupa: 'grupa',
    kontrolirano_crveno: 'kontrolirano crveno',
    dozvoljena_greska_snage: 'dozvoljena greska snage',
    greska_siga_br: 'Greška SIG-a br.',
    varijabilni_gain_nije_podrzan: 'Varijabilni GAIN nije podržan!',
    napon_u1_je_van_tolerancije: 'Napon U1 je van tolerancije. Nominalne vrijednosti napona U1 su 4.8V < U1 < 5.3V',
    napon_u2_je_van_tolerancije: 'Napon U2 je van tolerancije. Nominalne vrijednosti napona U2 su 23.7V < U2 < 24.3V',
    nepodudarnost_kontrolnog_broja_cps_i_upp_modula: 'Nepodudarnost kontrolnog broja CPS i UPP modula!',
    neostvaren_zahtjev_signalne_grupe: 'Neostvaren zahtjev signalne grupe',
    u_lokalnom_radu: 'u lokalnom radu',
    u_rezimu_centralnog_upravljanja: 'u režimu centralnog upravljanja',
    neispravna_kontrolna_suma_tablice_zaštitnih_vremena: 'Neispravna kontrolna suma tablice zaštitnih vremena!',
    neispravan_slijed_signala_u_signalnoj_logici: 'Neispravan slijed signala u signalnoj logici (spriječeno uključivanje konfliktnih signala)!',
    neispravna_kontrolna_suma_prometnih_podataka: 'Neispravna kontrolna suma prometnih podataka!',
    neispravna_kontrolna_suma_boja_signala: 'Neispravna kontrolna suma boja signala!',
    aktivirana_je_sklopka_ssw: 'Aktivirana je sklopka SSW!',
    greska_det_br: 'Greška DET br.',
    modul_ne_odgovara_na_upite_trafbus_protokola: 'Modul ne odgovara na upite TRAFBUS protokola! T-1 daje stalne najave.',
    greska_pojedinog_detektora: 'Greška pojedinog detektora',
    greska_io_modula_br: 'Greška IO modula br.',
    greska_linka_nema_odgovora: 'Greška LINK-a, nema odgovora (greška ANSWER-ske linije)',
    greska_linka_nema_naredbe: 'Greška LINK-a, nema naredbe (greška ORDER-ske linije)',
    nedozvoljena_greska_snage: 'nedozvoljena greška snage'
  }
}
