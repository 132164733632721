export default {
  ui: {
    auth_error: 'Napaka! Preveriti uporabniško ime in geslo.',
    home: 'Home',
    traffic_controller: 'Naprava',
    traffic_controllers: 'Naprave',
    traffic_controller_type: 'Tip naprave',
    traffic_controller_delete: 'Uklanjanje naprave',
    traffic_controller_add: 'Dodaj novo napravo',
    traffic_controller_edit: 'Uredi napravo',
    traffic_controllers_simulate: 'Simulator naprave',
    trafconnect_url: 'TrafConnect URL',
    comment: 'Opomba',
    log: 'Dnevnik',
    refresh: 'Osvežite',
    date_picker: 'Izberi datum',
    choose_layers: 'Izberi podlagu',
    date_and_time: 'Datum in čas',
    date_from: 'Datum od',
    date_to: 'Datum do',
    sent: 'Poslano',
    status: 'Stanje',
    error: 'Napaka',
    user: 'Uporabnik',
    user_level: 'Raven dostopa',
    users: 'Uporabniki',
    map: 'Zemljevid',
    alarms: 'Alarmi',
    password: 'Geslo',
    password_change: 'Sprememba gesla',
    enter_password: 'Vnesite geslo',
    enter_password_again: 'Ponovno vnesite geslo',
    login: 'Prijava',
    search: 'Iskanje',
    centralno_upravljanje: 'Centralno upravljanje',
    sve_crveno: 'Vse rdeče',
    rucno_upravljanje: 'Ročno upravljanje',
    vremensko_upravljanje: 'Vremensko upravljanje',
    signali_ukljuceni: 'Signali vključeni',
    treptanje_zutog: 'Utripa rumena',
    greska: 'Napaka',
    abc: 'ABC',
    name: 'Ime',
    surname: 'Priimek',
    group: 'Skupina',
    code: 'Koda',
    type: 'Tip',
    name_and_surname: 'Ime in priimek',
    username: 'Uporabniško ime',
    email: 'E-mail',
    admin: 'Admin',
    actions: 'Akcije',
    edit_user: 'Uredi uporabnika',
    add_user: 'Dodaj uporabnika',
    delete_user: 'Brisanje uporabnika',
    submit: 'Shranjevanje',
    active: 'Aktivan',
    save_success: 'Uspešno shranjeno',
    save_error: 'Napaka!',
    close: 'Zapri',
    field_required: 'Polje je obvezno!',
    field_vaild_email_required: 'Vnesite pravilen e-mail naslov',
    minimum: 'Minimum',
    passwords_must_be_identical: 'Gesla morajo biti enaka',
    cameras: 'Kamere',
    svg: 'SVG',
    description: 'Opis',
    edit: 'Uredi',
    delete: 'Briši',
    locate_on_map: 'Pokaži na zemljevidu',
    week: 'Teden',
    month: 'Mesec',
    year: 'Leto',
    download: 'Prenesi datoteko',
    alarm_details: 'Podrobnosti alarma',
    location: 'Lokacija',
    created: 'Ustvarjen',
    ended: 'Končano',
    logout: 'Odjavi se',
    start: 'Start',
    stop: 'Stop',
    settings: 'Nastavitve',
    language: 'Jezik',
    disable_notification_sound: 'Onemogoči zvok obvestila',
    enable_notification_sound: 'Vklopi zvok obvestila',
    companies: 'Podjetja',
    company_add: 'Dodaj novo podjetje',
    company_edit: 'Uredi podjetje',
    company_delete: 'Brisanje podjetja',
    contacts: 'Kontakti',
    persons: 'Osebe',
    homepage: 'Domača stran',
    tel: 'Telefon',
    add: 'Dodaj',
    mobilni_internet: 'Mobilni internet',
    gsm: 'GSM',
    gps: 'GPS',
    wifi: 'Wi-Fi',
    mikrovalovni_detektori: 'Mikrovalni detektori',
    // novo
    person_add: 'Dodaj osobu',
    person_edit: 'Uredi osobu',
    person_delete: 'Ukloni osobu',
    company: 'Podjetje',
    centar: 'Centar',
    watchdog: 'Watchdog',
    vpn: 'VPN',
    value: 'Vrijednost',
    config_add: 'Dodaj novu postavku',
    config_edit: 'Uredi postavku',
    disabled: 'Onemogočeno',
    vremenski: 'Vremenski',
    stalno_ukljuceno: 'Stalno uključeno',
    stalno_iskljuceno: 'Stalno isključeno',
    date_time: 'Vrijeme',
    cps_temperature: 'Temperatura CPS',
    napon: 'Napon',
    trazi_zeleno: 'Traži zeleno',
    produzava_zeleno: 'Produžava zeleno',
    memorija: 'Memorija',
    odgoda_najave: 'Odgoda najave',
    interval_izmedju_vozila: 'Interval između vozila',
    zona: 'Zona',
    video_wall: 'Video stena',
    stream_video: 'Stream video',
    system_hardware: 'Sistemsko strojno opremo',
    network: 'Omrežje',
    network_settings: 'Omrežne nastavitve',
    waiting: 'Čakam',
    restart: 'Restart',
    shutdown: 'Shutdown',
    confirm_restart: 'Potrdite ponovni zagon',
    really_restart_the_system: 'Ali res želite znova zagnati sistem?',
    confirm_shutdown: 'Potrdite zaustavitev',
    really_shutdown_the_system: 'Ali res želite zaustaviti sistem?',
    not_connected_to_traffic_controller: 'Nije spojen na semaforski uređaj',
    phases: 'Faze',
    phase: 'Faza',
    green: 'Zeleno',
    yellow: 'Zuto',
    red: 'Crveno',
    manual_control: 'Ručno upravljanje',
    programs: 'Programi',
    cycle: 'Cikl',
    update: 'Update',
    time: 'Čas',
    import: 'Import',
    export: 'Export',
    second: 'Sekunda',
    internal_second: 'Unutarnja sekunda',
    enable_atms_control: 'ENABLE ATMS CONTROL',
    stage: 'Stage',
    bus_priority: 'Bus priority',
    licence_plate: 'Registrska tablica',
    duration: 'Trajanje',
    programming: 'Programiranje',
    cycle_length: 'Cikel',
    fixed_time_control: 'Vremensko upravljanje',
    amber_flash: 'Utrip',
    signals_off: 'Signali izklopljeni',
    offset: 'Zamik',
    demand: 'Zahteva',
    privilege: 'Privilegij',
    yes: 'Da',
    no: 'Ne',
    greska_pojedinog_virtualnog_detektora: 'Napaka posameznega virtualnega detektorja',
    verifikacija: 'preverjanje',
    crveno: 'rdeča',
    nema_signala: 'ni signala',
    pojava_signala: 'pojav signala',
    zuto: 'rumena',
    zeleno: 'zelena',
    grupa: 'skupina',
    kontrolirano_crveno: 'kontrolirano rdeče',
    dozvoljena_greska_snage: 'dovoljena napaka pri napajanju',
    greska_siga_br: 'Napaka SIG št.',
    varijabilni_gain_nije_podrzan: 'Spremenljivka GAIN ni podprta!',
    napon_u1_je_van_tolerancije: 'Napetost U1 je izven tolerance. Nazivne vrednosti napetosti U1 so 4,8 V < U1 < 5,3 V',
    napon_u2_je_van_tolerancije: 'Napetost U2 je izven tolerance. Nazivne vrednosti napetosti U2 so 23,7 V < U2 < 24,3 V',
    nepodudarnost_kontrolnog_broja_cps_i_upp_modula: 'Neujemanje kontrolne številke modula CPS in UPP!',
    neostvaren_zahtjev_signalne_grupe: 'Neizpolnjena zahteva signalizacijske skupine',
    u_lokalnom_radu: 'pri lokalnem delu',
    u_rezimu_centralnog_upravljanja: 'v režimu centralnega upravljanja',
    neispravna_kontrolna_suma_tablice_zaštitnih_vremena: 'Napačna kontrolna vsota časovne tabele zaščite!',
    neispravan_slijed_signala_u_signalnoj_logici: 'Nepravilno zaporedje signalov v signalni logiki (preprečena vključitev konfliktnih signalov)!',
    neispravna_kontrolna_suma_prometnih_podataka: 'Napačna kontrolna vsota prometnih podatkov!',
    neispravna_kontrolna_suma_boja_signala: 'Napačna kontrolna vsota signalnih barv!',
    aktivirana_je_sklopka_ssw: 'Stikalo SSW je aktivirano!',
    greska_det_br: 'Napaka DET št.',
    modul_ne_odgovara_na_upite_trafbus_protokola: 'Modul ne odgovarja na poizvedbe protokola TRAFBUS! T-1 nenehno obvešča.',
    greska_pojedinog_detektora: 'Napaka detektorja',
    greska_io_modula_br: 'Napaka IO modula št.',
    greska_linka_nema_odgovora: 'Napaka LINK, ni odgovora (napaka vrstice ANSWER)',
    greska_linka_nema_naredbe: 'Napaka LINK, ni naročila (napaka vrstice ORDER)',
    nedozvoljena_greska_snage: 'kritična napaka pri napajanju'
  }
}
