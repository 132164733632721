<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>
        <span class="text-xl-left">{{ this.title }}</span>
      </v-toolbar-title>
    </v-toolbar>

    <v-progress-linear
      v-show="loading"
      color="primary"
      indeterminate
    ></v-progress-linear>

    <v-card-text>
      <form>

        <v-textarea
          box
          name="supervisorConfig"
          label="Supervisor config"
          rows=20
          v-model="supervisorConfig.config"
        ></v-textarea>

      </form>
    </v-card-text>

    <v-card-actions>
      <v-btn
        :disabled="submitAvailable ? false : true"
        @click="submit"
        color="primary"
      >{{ this.$t('ui.submit') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import SupervisorMixin from '../mixins/SupervisorMixin'

export default {
  name: 'app-supervisor-config-card',
  mixins: [SupervisorMixin, validationMixin],
  props: {
    title: {
      type: String,
      default: ''
    },
    configKey: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      enabled: false,
      started: false,
      supervisorConfig: {
        key: '',
        config: '',
        description: ''
      }
    }
  },
  validations: {

  },
  computed: {
    isFormValid () {
      return !this.$v.$invalid
    },
    submitAvailable () {
      if (!this.loading && this.isFormValid) {
        return true
      }
      return false
    }
  },
  methods: {
    submit () {
      this.createConfig({
        key: this.configKey,
        config: this.supervisorConfig.config,
        description: this.supervisorConfig.description
      })
    }
  },
  created () {
    this.initSupervisorConfigData(this.configKey)
  }
}
</script>
