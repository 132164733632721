<template>
  <div>
    <v-navigation-drawer
      persistent
      clipped
      app
      v-model="drawer"
      width="300"
    >
      <v-list dense nav  >
        <template v-for="(item, i) in items">

          <!-- 1. level drop down-a -->
          <v-list-group v-if="item.children" :key="i" :prepend-icon="item.icon" :value="item.opened" no-action>

            <template v-slot:activator>
              <v-list-item-title>{{item.text}}</v-list-item-title>
            </template>

            <!-- 2. level drow down-a -->
            <template v-for="(child, x) in item.children">

              <v-list-group v-if="child.children" :key="x" :value="child.opened" no-action sub-group>

                <template v-slot:activator>
                  <v-list-item-title>{{child.text}}</v-list-item-title>
                </template>

                <v-list-item
                  v-for="(childsChild, y) in child.children"
                  :key="y"
                  :to="childsChild.router_link"
                  link
                  compact
                >
                  <v-list-item-title v-text="childsChild.text"></v-list-item-title>

                  <v-list-item-icon>
                    <v-icon v-text="childsChild.icon"></v-icon>
                  </v-list-item-icon>
                </v-list-item>

              </v-list-group>

              <v-list-item
                v-else
                :key="x"
                :to="child.router_link"
                link
                compact
              >
                <v-list-item-title v-text="child.text"></v-list-item-title>

                <v-list-item-icon>
                  <v-icon v-text="child.icon"></v-icon>
                </v-list-item-icon>
              </v-list-item>

            </template>
            <!-- /2. level drow down-a -->

          </v-list-group>
          <!-- /1. level drop down-a -->

          <!-- nema razine ispod sebe -->
          <v-list-item v-else :to="item.router_link" :key="item.router_link" v-show="item.show">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- /nema razine ispod sebe -->

        </template>
      </v-list>
      <template v-slot:append>
        <div class="pa-1">
          <v-alert>
            <p v-if="statusnaRijec.su">{{$t('ui.signali_ukljuceni')}}</p>
            <p v-if="statusnaRijec.cu">{{$t('ui.centralno_upravljanje')}}</p>
            <p v-if="statusnaRijec.vru">{{$t('ui.vremensko_upravljanje')}}</p>
            <p v-if="statusnaRijec.ru">{{$t('ui.rucno_upravljanje')}}</p>
            <p v-if="statusnaRijec.sc">{{$t('ui.sve_crveno')}}</p>
            <p v-if="statusnaRijec.trz">{{$t('ui.treptanje_zutog')}}</p>
            <p v-if="statusnaRijec.anf">{{$t('ui.greska')}}</p>
            <v-spacer v-if="trenutniProgram && trenutniProgram >= 0 && trenutniProgram < 9"></v-spacer>
            <p v-if="trenutniProgram && trenutniProgram >= 0 && trenutniProgram < 9">Program: {{ trenutniProgram }}</p>
          </v-alert>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app clipped-left fixed>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{appTitle}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon class="mr-1" :color="trafficControllerStatusIcon.color">{{trafficControllerStatusIcon.icon}}</v-icon>
      <v-btn
        class="mr-1"
        icon
        :loading="loadingAuth"
        @click="openAuthDialog()">
        <v-icon>{{authIcon}}</v-icon>
      </v-btn>
      <v-btn
        class="mr-1"
        icon
        :disabled="loadingAuth"
        :loading="loadingAuth"
        @click="openSettingsDialog()">
        <v-icon>settings</v-icon>
      </v-btn>
      <v-menu
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, i) in availableActions"
            :key="i"
            link
            @click="item.action()"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!--<v-icon class="mr-1" v-if="mobileInternetStatusIcon">{{mobileInternetStatusIcon}}</v-icon>
      <v-icon class="mr-1" v-if="gpsStatusIcon">{{gpsStatusIcon}}</v-icon>
      <v-icon class="mr-1" v-if="wifiStatusIcon">{{wifiStatusIcon}}</v-icon>-->
      <!--<v-icon class="mr-1" :color="backendConnectionStatusIcon.color">{{backendConnectionStatusIcon.icon}}</v-icon>-->
    </v-app-bar>

    <v-main>
      <app-notification-snackbar></app-notification-snackbar>
      <router-view></router-view>
      <app-settings-dialog
        :config="appSettingsConfig"
        @userAction="handleSettingsDialogUserAction">
      </app-settings-dialog>
      <app-auth-dialog
        :open-dialog="authDialog"
        @userAction="handleAuthDialogUserAction">
      </app-auth-dialog>
      <app-confirm-action-dialog
        :open-dialog="openShutdownDialog"
        :headline="$t('ui.confirm_shutdown')"
        :text="$t('ui.really_shutdown_the_system')"
        @userAction="confirmShutdownAction"
      >
      </app-confirm-action-dialog>
      <app-confirm-action-dialog
        :open-dialog="openRestartDialog"
        :headline="$t('ui.confirm_restart')"
        :text="$t('ui.really_restart_the_system')"
        @userAction="confirmRestartAction"
      >
      </app-confirm-action-dialog>
      <v-dialog v-model="openRedirectDialog" max-width="600px">
        <v-card>
          <v-card-title class="headline" primary-title>{{$t('ui.waiting')}}</v-card-title>
          <v-card-text>
            <p style="text-align: center;" class="text-h1">{{ timer }}</p>
          </v-card-text>
        </v-card>
      </v-dialog>

    </v-main>

  </div>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'
import T2000Mixin from '@/mixins/T2000Mixin'
import S1Mixin from '@/mixins/S1Mixin'
import ConfigMixin from '@/mixins/ConfigMixin'
import TelventMixin from '@/mixins/TelventMixin'
import EtxMixin from '@/mixins/EtxMixin'

export default {
  name: 'AppPage',
  mixins: [AuthMixin, ConfigMixin, T1Mixin, T2000Mixin, S1Mixin, TelventMixin, EtxMixin],
  data () {
    return {
      openRedirectDialog: false,
      timer: 100,
      openShutdownDialog: false,
      openRestartDialog : false,
      drawer: true,
      loadingAuth: false,
      settingsDialog: false,
      authDialog: false,
      items: [ ],
      props: {
        source: String
      },
      showSnackbar: false,
      snackbarText: '',
      snackbarTimeout: 5000,
      snackbarColor: 'primary',
      config: {
        DEVICE_CODE: null,
        DEVICE_NAME: null,
        DEVICE_TYPE: null
      },
      actions: [
        {'title': this.$t('ui.shutdown'), 'action': this.shutdownDialog, 'needsAuth': true },
        {'title': this.$t('ui.restart'), 'action': this.restartDialog, 'needsAuth': true }
      ]
    }
  },
  computed: {
    availableActions () {
      let actions = []
      let action = null
      for (let i = 0; i < this.actions.length; i++) {
        action = this.actions[i]
        if (action.needsAuth === true) {
          if (this.isAuthenticated) {
            actions.push(action)
          }
        } else {
          actions.push(action)
        }
      }

      return actions
    },
    authDisabled () {
      if (this.loadingAuth || this.isAuthenticated) {
        return true
      }

      return false
    },
    authIcon () {
      if (this.isAuthenticated === true) {
        return 'lock_open'
      }

      return 'lock'
    },
    appLocale () {
      return this.$store.getters['app/locale']
    },
    isWsConnectionActive () {
      return this.$store.getters['ws/isConnected']
    },
    mobileInternetStatusIcon () {
      // 'signal_cellular_4_bar' : 'signal_cellular_off'
      return false
    },
    gpsStatusIcon () {
      // 'gps_fixed' : 'gps_off'
      return false
    },
    wifiStatusIcon () {
      // 'signal_wifi_4_bar' : 'signal_wifi_off'
      return false
    },
    backendConnectionStatusIcon () {
      if (this.isWsConnectionActive) {
        return {
          icon: 'check_circle',
          color: 'green'
        }
      }

      return {
        icon: 'error',
        color: 'red'
      }
    },
    appTitle () {
      if (this.appConfig.DEVICE_TYPE === 'T-1') {
        return `TrafConnect | ${this.config.DEVICE_CODE}, ${this.appConfig.DEVICE_NAME}, ${this.appConfig.DEVICE_TYPE}, ${this.serBr}, R ${this.revizijaSw}`
      }

      return `TrafConnect | ${this.config.DEVICE_CODE}, ${this.appConfig.DEVICE_NAME}, ${this.appConfig.DEVICE_TYPE}`

    },
    statusnaRijec () {
      if (this.DEVICE_TYPE === 'T-1') {
        return this.$store.getters['t1/statusnaRijec']
      } else if (this.DEVICE_TYPE === 'T-2000') {
        return this.$store.getters['t2000/statusnaRijec']
      } else {
        return {
          abc: 0,
          anf: 0,
          cu: 0,
          ru: 0,
          sc: 0,
          su: 0,
          trz: 0,
          vru: 0
        }
      }
    },
    serBr () {
      return this.$store.getters['t1/serBr']
    },
    revizijaSw () {
      return this.$store.getters['t1/revizijaSw']
    },
    trenutniProgram () {
      if (this.DEVICE_TYPE === 'T-1') {
        return 255
      } else if (this.DEVICE_TYPE === 'T-2000') {
        return this.$store.getters['t2000/program']
      }
      return 255
    },
    trafficControllerCommunicating () {
      if (this.DEVICE_TYPE === 'T-1') {
        return this.$store.getters['t1/isCommOk']
      } else if (this.DEVICE_TYPE === 'T-2000') {
        return this.$store.getters['t2000/isCommOk']
      } else {
        return false
      }
    },
    trafficControllerStatusIcon () {
      if (this.trafficControllerCommunicating) {
        return {
          icon: 'usb',
          color: 'green'
        }
      }

      return {
        icon: 'usb',
        color: 'red'
      }
    },
    appConfig () {
      return this.$store.getters['app/config']
    },
    DEVICE_TYPE () {
      return this.$store.getters['app/config']['DEVICE_TYPE']
    },
    appSettingsConfig () {
      let local = this.$store.getters['app/local']
      return {
        APP_HOST_ADDRESS: local.APP_HOST_ADDRESS,
        APP_HOST_PORT: local.APP_HOST_PORT,
        TRAFFIC_CONTROLLER_HOST_ADDRESS: local.TRAFFIC_CONTROLLER_HOST_ADDRESS,
        TRAFFIC_CONTROLLER_HOST_PORT: local.TRAFFIC_CONTROLLER_HOST_PORT,
        CAMERAS_FEED_SERVER_HOST: local.CAMERAS_FEED_SERVER_HOST,
        CAMERAS_FEED_SERVER_PORT: local.CAMERAS_FEED_SERVER_PORT
      }
    },
    showTrafficControllerNav () {
      if (this.DEVICE_TYPE === 'n/a') {
        return false
      }
      return true
    },
    showGonjevaScada () {
      if (this.$env.GONJEVA_SCADA === true) {
        return true
      }
      return false
    },
    showGonjevaCentarScada () {
      if (this.$env.GONJEVA_CENTAR_SCADA === true) {
        return true
      }
      return false
    }
  },
  methods: {
    init () {
      return new Promise((resolve, reject) => {
        this.initConfigData()
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    shutdownDialog () {
      this.openShutdownDialog = true
    },
    restartDialog () {
      this.openRestartDialog = true
    },
    confirmShutdownAction (action) {
      if (action === false) {
        this.openShutdownDialog = false
      } else {
        this.openRedirectDialog = true
        this.timer = 30
        this.doBackendAction('sh')
          .then(() => {
            setTimeout(() => {
              window.location.reload()
            }, this.timer * 1000)

            setInterval(() => {
              if (this.timer > 0) {
                this.timer -= 1
              }
            }, 1000)
          })
      }
    },
    confirmRestartAction (action) {
      if (action === false) {
        this.openRestartDialog = false
      } else {
        this.openRedirectDialog = true
        this.timer = 100
        this.doBackendAction('rb')
          .then(() => {
            setTimeout(() => {
              window.location.reload()
            }, this.timer * 1000)

            setInterval(() => {
              if (this.timer > 0) {
                this.timer -= 1
              }
            }, 1000)
          })
      }
    },
    openAuthDialog () {
      this.authDialog = true
    },
    openSettingsDialog () {
      this.$EventBus.$emit('AppSettingsDialog/show')
    },
    getTrafficControllerNavItems () {
      if (this.DEVICE_TYPE === 'T-1') {
        return this.t1GetNavItems()
      } else if (this.DEVICE_TYPE === 'T-2000') {
        return this.t2000GetNavItems()
      } else if (this.DEVICE_TYPE === 'S-1') {
        return this.s1GetNavItems()
      } else if (this.DEVICE_TYPE === 'Telvent') {
        return this.TelventGetNavItems()
      } else if (this.DEVICE_TYPE === 'ETX') {
        return this.EtxGetNavItems()
      }
    },
    createNavItems () {
      this.items = [
        {
          router_link: '/dashboard',
          text: this.$t('ui.dashboard'),
          icon: 'dashboard',
          show: true
        },
        {
          text: this.$t('ui.traffic_controller'),
          icon: 'traffic',
          show: this.showTrafficControllerNav,
          opened: true,
          children: this.getTrafficControllerNavItems()
        },
        {
          router_link: '/gonjeva/scada',
          text: 'SCADA',
          icon: 'laptop',
          show: this.showGonjevaScada
        },
        {
          router_link: '/gonjeva/centar',
          text: 'SCADA',
          icon: 'laptop',
          show: this.showGonjevaCentarScada
        },
        {
          router_link: '/kamere',
          text: this.$t('ui.video_wall'),
          icon: 'mdi-cctv',
          show: true
        },
        /*{
          router_link: '/led-marker/info',
          text: this.$t('ui.led_marker_controller'),
          icon: 'mdi-minus-network',
          show: true
        },*/
        {
          text: this.$t('ui.system'),
          icon: 'memory',
          show: true,
          opened: false,
          children: [
            {
              router_link: '/settings/network',
              text: this.$t('ui.network'),
              icon: 'device_hub',
              show: true
            },
            {
              router_link: '/settings/mobilni-internet',
              text: this.$t('ui.mobilni_internet'),
              icon: 'sim_card',
              show: true
            },
            {
              router_link: '/settings/centar',
              text: this.$t('ui.centar'),
              icon: 'cloud_upload',
              show: true
            },
            {
              router_link: '/settings/vpn',
              text: this.$t('ui.vpn'),
              icon: 'vpn_key',
              show: true
            },
            {
              router_link: '/settings/system-time',
              text: this.$t('ui.time_and_date'),
              icon: 'alarm',
              show: true
            },
            {
              router_link: '/t-1/gpio',
              text: this.$t('ui.gpio'),
              icon: 'settings',
              show: true
            },
            {
              router_link: '/settings/hvac',
              text: 'HVAC',
              icon: 'toys',
              show: true
            },
            {
              router_link: '/settings/watchdog',
              text: this.$t('ui.watchdog'),
              icon: 'pets',
              show: true
            },
            {
              router_link: '/settings/config',
              text: this.$t('ui.settings'),
              icon: 'settings',
              show: true
            }
          ]
        }
      ]
    },
    connectToTrafficController () {
      if (this.$websocket) {
        this.$websocket.closeAndDoNotReconnect()
      }

      if (this.t1ResetStateWdogId) {
        clearInterval(this.t1ResetStateWdogId)
      }

      if (this.DEVICE_TYPE === 'T-1') {
        this.t1HandleWsConnection()
      } else if (this.DEVICE_TYPE === 'T-2000') {
        this.t2000HandleWsConnection()
        this.$store.commit('app/setAuthenticated', true)
      } else if (this.DEVICE_TYPE === 'S-1') {
        this.$store.commit('app/setAuthenticated', true)
      } else if (this.DEVICE_TYPE === 'Telvent') {
        this.TelventHandleWsConnection()
        this.$store.commit('app/setAuthenticated', true)
      } else if (this.DEVICE_TYPE === 'ETX') {
        this.EtxHandleWsConnection()
        this.$store.commit('app/setAuthenticated', true)
      }
    },
    handleAuthDialogUserAction (action) {
      if (action === 'cancel') {
        this.authDialog = false
      }
    },
    handleSettingsDialogUserAction (action) {
      if (action === 'cancel') {
        this.settingsDialog = false
        this.init()
          .then(() => {
            this.createNavItems()
            this.connectToTrafficController()
          })
      }
    }
  },
  watch: {
    appLocale () {
      this.createNavItems()
    },
    DEVICE_TYPE () {
      // Ako se promjeni tip uređaja, ponovo složi navigaciju
      this.createNavItems()
      this.connectToTrafficController()
    },
    isSuperUser () {
      this.createNavItems()
    }
  },
  created () {
    let appLocal = localStorage.getItem('appLocal')
    if (appLocal) {
      this.$store.commit('app/setLocal', JSON.parse(appLocal))
    }
    this.$i18n.locale = this.$store.getters['app/locale']
    this.init()
      .then(() => {
        this.createNavItems()
        this.connectToTrafficController()
      })
  }
}
</script>


