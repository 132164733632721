<template>
  <v-container fluid grid-list-md>
    <v-row>
      <v-col>
        <v-toolbar dense flat>
          <v-toolbar-title>
            <span class="text-xl-left">VPN</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="addVpn" color="primary" class="mr-6">{{ this.$t('ui.add') }}</v-btn>
          <v-btn @click="submit" color="primary">{{ this.$t('ui.submit') }}</v-btn>
        </v-toolbar>
        <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>
      </v-col>
    </v-row>

    <v-row v-for="vpn in vpns" v-bind:key="vpn.supervisor_key">
      <v-col xs="12" md="4">
        <app-wireguard-card :config="vpn" :endpoints="endpoints" :networkDevices="networkDevices"></app-wireguard-card>
      </v-col>
      <v-col xs="12" md="8">
        <app-supervisor-job-log-card :supervisor-config-key="vpn.supervisor_key"></app-supervisor-job-log-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ConfigMixin from '@/mixins/ConfigMixin'
import SupervisorMixin from '@/mixins/SupervisorMixin'
import WireguardMixin from '@/mixins/WireguardMixin'
import { EventBus } from '@/services/EventBus'

export default {
  name: 'VpnPage',
  mixins: [ConfigMixin, SupervisorMixin, WireguardMixin],
  data () {
    return {
      configKey: 'VPN_CONFIGS',
      loading: false,
      vpns: [],
      vpnConfigs: {},
      endpoints: [
        {
          'id': 'crossing',
          'name': 'CROSSING IT',
          'public_key': 'mekTjgN6KPvAWAHuQcUczyizC40NpC9hUHGoevMjUHc=',
          'allowed_ips': '10.12.0.0/17, 192.168.10.0/24',
          'endpoint': 'wg.trafsignal.hr:51823',
          'ping': '10.12.0.0'
        },
        {
          'id': 'nigrad',
          'name': 'NIGRAD',
          'public_key': 'jvEYEevhDgYlc/SnZxlibFna58DJS9oPHj2xM2/E9Ss=',
          'allowed_ips': '10.11.0.0/17, 192.168.53.0/24',
          'endpoint': 'wg.trafsignal.hr:51822',
          'ping': '10.11.0.0'
        },
        {
          'id': 'trafsignal',
          'name': 'TRAF SIGNAL',
          'public_key': 'awx2sdzgUj3PSLO3KvQ2nMTu5YK2rElnY2CIIApGd30=',
          'allowed_ips': '10.14.0.0/24',
          'endpoint': 'wg.trafsignal.hr:51820',
          'ping': '10.14.0.0'
        },
        {
          'id': 'zuc_split',
          'name': 'ŽUC - SPLIT',
          'public_key': 'w/ybQCfx4AazMhB97I7dVDrHflHPnWBdkM9SJTACtFw=',
          'allowed_ips': '10.17.0.0/24',
          'endpoint': 'wg.trafsignal.hr:51824',
          'ping': '10.17.0.0'
        },
        {
          'id': 'du_grad',
          'name': 'Dubrovnik - grad',
          'public_key': '9IrpK+/0/sp/uhRT4ukdozmGs/rKtLEKcIEFmHnQegE=',
          'allowed_ips': '10.19.0.0/8',
          'endpoint': 'wg.trafsignal.hr:51826',
          'ping': '10.19.0.1'
        },
        {
          'id': 'abbmatica',
          'name': 'ABBMATICA',
          'public_key': '0iRLvnACbC04jtyIhD85hX8yPdFtudSVGayfQwjE1B4=',
          'allowed_ips': '10.20.0.0/8',
          'endpoint': 'wg.trafsignal.hr:51827',
          'ping': '10.20.0.1'
        },
        {
          'id': 'transcore',
          'name': 'TRANSCORE',
          'public_key': 'Uu8tiHAGOvE5yZEmJJAzO34/MuCm2DOtwYiEezUhjlY=',
          'allowed_ips': '10.21.0.0/24',
          'endpoint': 'wg.trafsignal.hr:51828',
          'ping': '10.21.0.240'
        }
      ],
      networkDevices: [ 'eth0' ]
    }
  },
  methods: {
    init () {
      this.loading = true

      this.getConfigByKey(this.configKey)
        .then((response) => {
          this.vpns = JSON.parse(response.data.value)
        })
        .finally(() => {
          this.loading = false
        })
    },
    uniqueId (prefix, idCounter) {
      var id = ++idCounter
      return prefix + id
    },
    addVpn () {
      var idCounter = 0

      for (var i = 0; i < this.vpns.length; i++) {
        var vpn = this.vpns[i]
        var x = vpn.supervisor_key.split('_')
        idCounter = parseInt(x[x.length - 1])
      }

      this.vpns.push({
        'name': 'Centar',
        'enabled': '1',
        'supervisor_key': `${this.uniqueId('wg_', idCounter)}`,
        'ip_address': this.endpoints[2]['ping'],
        'private_key': '',
        'public_key': '',
        'endpoint': this.endpoints[2],
        'supervisor_template': '',
        'wg_template': '',
        'network_device': this.networkDevices[0]
      })
    },
    submit () {
      this.loading = true

      this.createConfig({
        'key': this.configKey,
        'value': this.vpns
      })
      .then((response) => {
        this.vpns = JSON.parse(response.data.value)
        var promises = []

        for (var i = 0; i < this.vpns.length; i++) {

          promises.push(this.createWgConfig({
            'key': this.vpns[i].supervisor_key,
            'config': this.vpns[i].wg_template
          }))

          promises.push(this.createSupervisorConfig({
            'key': this.vpns[i].supervisor_key,
            'config': this.vpns[i].supervisor_template
          }))

        }

        Promise.all(promises)
          .finally(() => {
            this.loading = false
          })

      })
    }
  },
  created () {
    var self = this
    this.init()

    this.$on('supervisor-job-deleted', (e) => {
      self.loading = true
      console.log(e)
      self.doDeleteSupervisorJob(e.key)
      .then(self.deleteWgConfig(e.key))
      .then(() => {
        var makniStavkuIndex = this.$lodash.findIndex(this.vpns, function (s) { return s.supervisor_key === e.key })
        console.log(makniStavkuIndex)
        if (makniStavkuIndex > -1) {
          this.vpns.splice(makniStavkuIndex, 1)
          self.submit()
        }
      })
      .catch(() => {
        EventBus.$emit('AppSnackbar/show', {
          color: 'red',
          text: self.$t('ui.save_error')
        })
      })
    })
  },
  destroyed () {
    EventBus.$off('supervisor-job-deleted')
  }
}
</script>
