<template>
  <v-container fluid grid-list-md>

    <v-layout row wrap>
      <v-flex xs12 md6>
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ this.$t('ui.config_add') }}</span>
            </v-toolbar-title>
          </v-toolbar>

          <v-progress-linear
            v-show="loading"
            color="primary"
            indeterminate
          ></v-progress-linear>

          <v-card-text>
            <app-config-form
              :config="config"
              @isConfigFormValid="isConfigFormValidHandler">
            </app-config-form>
          </v-card-text>

          <v-card-actions>
            <v-btn
              :disabled="submitAvailable ? false : true"
              @click="submit"
              color="primary"
            >{{ this.$t('ui.submit') }}</v-btn>
          </v-card-actions>

        </v-card>
      </v-flex>
    </v-layout>

  </v-container>
</template>

<script>
import ConfigMixin from '@/mixins/ConfigMixin'

export default {
  name: 'ConfigAddPage',
  mixins: [ConfigMixin],
  data () {
    return {
      loading: false,
      isFormValid: false,
      config: {
        key: '',
        value: '',
        description: ''
      }
    }
  },
  computed: {
    submitAvailable () {
      if (!this.loading && this.isFormValid) {
        return true
      }
      return false
    }
  },
  methods: {
    submit: function () {
      this.loading = true
      this.createConfig(this.config)
        .then((response) => {

          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })

          setTimeout(() => {
            this.$router.push({
              name: 'ConfigEditPage',
              params: { config_id: response.data.id }
            })
          }, 1000)
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'red',
            text: this.$t('ui.save_error')
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    isConfigFormValidHandler: function (isFormValid) {
      this.isFormValid = isFormValid
    }
  }
}
</script>
