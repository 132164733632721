<template>
  <v-container fluid grid-list-md>

    <v-row>
      <v-col xs="12" lg="6">

        <v-card v-if="isAuthenticated">
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ this.$t('ui.kalibracija_sig') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              dark
              icon
              @click="init()"
              :disabled="loading"
            >
              <v-icon>refresh</v-icon>
            </v-btn>
          </v-toolbar>
          <v-progress-linear v-show="loading" color="primary" indeterminate></v-progress-linear>
          <v-card-text>
            <v-simple-table dark>
              <template v-slot:default>
                <tbody>
                  <tr v-for="sig in sigoviZaOdabrati" :key="sig.id">
                    <td>{{sig.name}}</td>
                    <td>
                      <v-btn
                        color="primary"
                        :loading="sig.loading"
                        :disabled="sig.loading"
                        @click="kalibracijaReset(sig)"
                      >
                        KALIBRACIJA RESET
                      </v-btn>
                    </td>
                    <td>
                      <v-btn
                        color="primary"
                        :loading="sig.loading"
                        :disabled="sig.loading"
                        @click="kalibracijaAutoSet(sig)"
                      >
                        AUTO SET
                      </v-btn>
                    </td>
                  </tr>
                </tbody>

              </template>
            </v-simple-table>

           </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'

export default {
  name: 'T1KalibracijaSigPage',
  mixins: [T1Mixin, AuthMixin],
  data () {
    return {
      brojGrupa: 0,
      odabraniSig: 0,
      loading: false
    }
  },
  computed: {
    brojSigova () {
      return Math.ceil(this.brojGrupa/4)
    },
    sigoviZaOdabrati () {
      let s = []
      for (let i = 0; i < this.brojSigova; i++) {
        s.push({
          id: i,
          name: `SIG ${i+1}`,
          state: 'off',
          loading: false
        })
      }

      return s
    }
  },
  methods: {
    init () {
      this.loading = true

      this.initT1Data()
        .finally(() => {
          this.loading = false
        })
    },
    initT1Data () {
      return new Promise((resolve, reject) => {
        var promises = []

        promises.push(this.t1Write([this.t1.CPS, this.t1.READ, this.t1.BROJ_GRUPA]))

        Promise.all(promises)
          .then(([BROJ_GRUPA]) => {
            this.brojGrupa = BROJ_GRUPA[0]
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    kalibracijaReset (sig) {
      return new Promise((resolve, reject) => {

        sig.loading = true

        this.t1Write([this.t1.SIG(sig.id), this.t1.WRITE, 0x68, 0])
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            sig.loading = false
          })
      })
    },
    kalibracijaAutoSet (sig) {
      return new Promise((resolve, reject) => {

        sig.loading = true

        this.t1Write([this.t1.SIG(sig.id), this.t1.WRITE, 0x67, 0])
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            sig.loading = false
          })
      })
    }
  },
  created () {
    this.init()
  }
}
</script>
