<template>
  <v-card>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <span class="text-xl-left">T-1</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        @click="clearLog">
        <v-icon color="red">delete</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">ORDER</th>
              <th class="text-left">ANSWER</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in log" :key="index">
              <td>{{item.order}}</td>
              <td>{{item.answer}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-actions>
      <v-row>
      <v-col sm="6" lg="1">
        <v-select
          :items="adrese"
          v-model="adresa"
          item-text="name"
          item-value="value"
          solo
          light
        ></v-select>
      </v-col>
      <v-col sm="6" lg="1">
        <v-select
          :items="akcije"
          v-model="akcija"
          item-text="name"
          item-value="value"
          solo
          light
        ></v-select>
      </v-col>
      <v-col sm="6" lg="1">
        <v-combobox
          :items="registri"
          v-model="registar"
          :return-object="false"
          item-text="name"
          item-value="value"
          solo
          light
        ></v-combobox>
      </v-col>
      <v-col sm="6" lg="1" v-for="(podatak, index) in podaci" :key="index">
        <v-text-field
          v-model="podatak.value"
          solo
          light
        ></v-text-field>
        <v-btn
          @click="removeData(index)"
          color="primary"
        >
          <v-icon>
            mdi-minus
          </v-icon>
        </v-btn>
      </v-col>
      <v-col sm="6" lg="1" style="padding:0; margin:0">
        <v-btn
          @click="addData"
          color="primary"
          style="margin-top: 17px"
        >
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
      <v-col sm="6" lg="1" style="padding:0; margin:0">
        <v-btn
          @click="submit"
          :loading="loading"
          color="primary"
          style="margin-top: 17px"
        >
          <v-icon>
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'

export default {
  name: 't1ReadWriteCard',
  mixins: [T1Mixin],
  data () {
    return {
      loading: false,
      adresa: 0x70,
      akcija: 0x03,
      registar: null,
      podaci: [],
      adrese: [
        { 'name': 'CPS',   'value': 0x70 },
        { 'name': 'SIG 1', 'value': 0xF0 },
        { 'name': 'SIG 2', 'value': 0xF1 },
        { 'name': 'SIG 3', 'value': 0xF2 },
        { 'name': 'SIG 4', 'value': 0xF3 },
        { 'name': 'SIG 5', 'value': 0xF4 },
        { 'name': 'SIG 6', 'value': 0xF5 },
        { 'name': 'SIG 7', 'value': 0xF6 },
        { 'name': 'SIG 8', 'value': 0xF7 },
        { 'name': 'UPP',   'value': 0x50 },
        { 'name': 'DET 1', 'value': 0xA0 },
        { 'name': 'DET 2', 'value': 0xA1 },
        { 'name': 'DET 3', 'value': 0xA2 },
        { 'name': 'DET 4', 'value': 0xA3 }
      ],
      akcije: [
        { 'name': 'READ', 'value': 0x03 },
        { 'name': 'WRITE', 'value': 0x10 },
      ],
      registriCPS: [
        { 'name': 'u230kalib', 'value': 0x1B },
        { 'name': 'amp_gain_v2', 'value': 0x49 },
        { 'name': 'uptime_reg', 'value': 0x19 },
        { 'name': 'uref_reg', 'value': 0x1A },
      ],
      log: []
    }
  },
  computed: {
    registri () {
      if (this.adresa === 0x70) {
        return this.registriCPS
      }
      return []
    },
    order () {
      let o = [this.adresa, this.akcija, parseInt(this.registar)]

      for (let i = 0; i < this.podaci.length; i++) {
        o.push(parseInt(this.podaci[i].value))
      }

      return o
    }
  },
  methods: {
    getLogColor (item) {
      if (item.action === 'send') return 'green'

      return 'red'
    },
    addData () {
      this.podaci.push({'value': 0})
    },
    removeData (index) {
      this.podaci.splice(index, 1)
    },
    humanizeOrder (order) {
      let adresa = order[0]
      let akcija = order[1]
      let registar = order[2]

      for (let i = 0; i < this.adrese.length; i++) {
        if (order[0] === this.adrese[i].value) {
          adresa = this.adrese[i].name
        }
      }

      for (let i = 0; i < this.akcije.length; i++) {
        if (order[1] === this.akcije[i].value) {
          akcija = this.akcije[i].name
        }
      }

      if (adresa === 'CPS') {
        for (let i = 0; i < this.registriCPS.length; i++) {
          if (order[2] === this.registriCPS[i].value) {
            registar = this.registriCPS[i].name
          }
        }
      }

      let o = [adresa, akcija, registar]

      for (let j = 0; j < order.length; j++) {
        if (j > 2) {
          o.push(order[j])
        }
      }

      return o
    },
    clearLog () {
      this.log = []
    },
    submit () {
      this.loading = true
      let order = JSON.parse(JSON.stringify(this.order))

      this.t1Write(order)
        .then((answer) => {
          this.log.push({
            'order': this.humanizeOrder(order),
            'answer': answer
          })
        })
        .catch(() => {
          this.log.push({
            'order': this.humanizeOrder(order),
            'answer': 'ERROR'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
