export default {
  // OVO SE VISE NE KORISTI
  // definiraju se urlovi u AppStore.vue
  APP_HOST_ADDRESS: `${window.location.hostname}`,
  APP_HOST_PORT: 6001,
  TRAFFIC_CONTROLLER_HOST_ADDRESS: `${window.location.hostname}`,
  TRAFFIC_CONTROLLER_HOST_PORT: 8888,
  APP_API_URI: `http://${window.location.hostname}:6001`,
  APP_WS_URI: `ws://${window.location.hostname}:6001/system-log/`,
  TRAFFIC_CONTROLLER_API_URI: `http://${window.location.hostname}:8888`,
  TRAFFIC_CONTROLLER_WS_URI: `ws://${window.location.hostname}:8888`,
  DEFAULT_LANGUAGE: 'en',
  GONJEVA_SCADA: false,
  GONJEVA_CENTAR_SCADA: false
}
