<template>
  <div></div>
</template>

<script>
import ConfigMixin from '@/mixins/ConfigMixin'

export default {
  name: 'TlocrtRedirectorPage',
  mixins: [ConfigMixin],
  data () {
    return {
      config: {
        DEVICE_TYPE: null
      }
    }
  },
  created () {
    this.initConfigData()
      .then(() => {
        let name = 't1TlocrtPage'

        if (this.config.DEVICE_TYPE === 'T-2000') {
          name = 't2000TlocrtPage'
        } else if (this.config.DEVICE_TYPE === 'Telvent') {
          name = 'TelventTlocrtPage'
        } else if (this.config.DEVICE_TYPE === 'ETX') {
          name = 'EtxTlocrtPage'
        }

        this.$router.push({
          name: name
        })

      })
  }
}
</script>
