<template>
  <v-container fluid>
    <v-row dense>
      <!-- CAMERAS -->
      <v-col xs="12">
        <v-card>
          <v-toolbar flat dense>
            <v-toolbar-title>
              <span class="text-xl-left">{{ this.$t('ui.cameras') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="isAuthenticated" @click="addCamera" color="primary">{{ this.$t('ui.add') }}</v-btn>
            <v-btn v-if="isAuthenticated" @click="openConfirmResetDialog" color="error" class="ml-2">{{ this.$t('ui.reset_cams') }}</v-btn>
            <v-btn v-if="isAuthenticated" @click="resetCamerasBackend" color="primary" :loading="resetCamerasBackendLoading"  class="ml-2">{{ this.$t('ui.reset_backend') }}</v-btn>
            <v-btn v-if="isAuthenticated" @click="submit" color="green" :loading="loading"  class="ml-2">{{ this.$t('ui.submit') }}</v-btn>
            <!--<v-btn
              icon
              @click="toggleCameraPlay()">
              <v-icon>{{cameraPlayIcon}}</v-icon>
            </v-btn>-->
          </v-toolbar>
          <v-data-table
            :headers="cameraTableHeaders"
            :items="cameras"
            :loading="loading"
            :items-per-page="limit"
            hide-default-footer
            class="elevation-1"
          >
            <v-progress-linear
              v-show="loading"
              color="primary"
              indeterminate
            ></v-progress-linear>
            <template v-slot:item.snap_url="{ item }">
              <a target="_blank" :href="`${item.snap_url}`">{{ item.snap_url }}</a>
            </template>

            <template v-slot:item.live_url="{ item }">
              <a target="_blank" :href="`${item.live_url}`">{{ item.live_url }}</a>
            </template>

            <template v-slot:item.xroads_url="{ item }">
              <p v-if="item.xroads_ip">{{item.xroads_ip}}:{{ item.xroads_port }}</p>
            </template>

            <template v-slot:item.snap="{ item }">
              <app-camera-tile :camera="item" :show-toolbar="true" :play="cameraPlay"></app-camera-tile>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn v-if="isAuthenticated" icon class="mx-0" @click="doEditCamera(item)">
                <v-icon color="white">edit</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- /END CAMERAS -->
    </v-row>

    <v-row dense>
      <!-- ZONES -->
      <v-col xs="12" md="6">
        <v-card>
          <v-toolbar flat dense>
            <v-toolbar-title>
              <span class="text-xl-left">ZONE</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="isAuthenticated" @click="addZone" color="primary">{{ this.$t('ui.add') }}</v-btn>
          </v-toolbar>
          <v-data-table
            :headers="cameraZoneTableHeaders"
            :items="camerasZones"
            :loading="loading"
            :items-per-page="limit"
            hide-default-footer
            class="elevation-1"
          >
            <v-progress-linear
                v-show="loading"
                color="primary"
                indeterminate
            ></v-progress-linear>

            <template v-slot:item.actions="{ item }">
              <v-btn v-if="isAuthenticated" icon class="mx-0" @click="doEditZone(item)">
                <v-icon color="white">edit</v-icon>
              </v-btn>
            </template>

            <!--<template slot="items" slot-scope="props">
              <td class="text-xs-left">{{ props.item.zone.detector }}</td>
              <td class="text-xs-left">{{ props.item.zone.zone }}</td>
              <td class="text-xs-left">{{ props.item.camera.ip }}</td>
              <td class="text-xs-right">
                <v-btn icon class="mx-0" @click="doEditZone(props.item)">
                  <v-icon color="white">edit</v-icon>
                </v-btn>
              </td>
            </template>-->
          </v-data-table>
        </v-card>
      </v-col>
      <!-- /END ZONES -->

      <v-col xs="12" md="6">
        <traffic-controller-svg
          :traffic-controller="trafficController"
          :stanje-signala="stanjeSignala"
          :stanje-detektora="stanjeDetektora"
          :detektor-click-enabled="detektorClickEnabled"
          v-on:detektorClicked="detektorClickedHandler"
        >
        </traffic-controller-svg>
      </v-col>

      <!-- CAMERA EDIT DIALOG -->
      <app-camera-edit-dialog
        :open-dialog="openCameraDialog"
        :camera="editCamera"
        :cameraTypes="cameraTypes"
        :cameraStreamTypes="cameraStreamTypes"
        @userAction="handleCameraEditDialogUserAction">
      </app-camera-edit-dialog>
      <!-- /END CAMERA EDIT DIALOG -->

      <!-- CAMERA ZONE EDIT DIALOG -->
      <app-camera-zone-edit-dialog
        :open-dialog="openCameraZoneDialog"
        :cameras="cameras"
        :edit-camera="editCamera"
        :zone="editZone"
        :detectors="detectors"
        :zones="zones"
        @userAction="handleCameraZoneEditDialogUserAction">
      </app-camera-zone-edit-dialog>
      <!-- /END CAMERA ZONE EDIT DIALOG -->

      <app-detektor-zone-edit-dialog
        :zones="zones"
        :cameras="cameras"
        :context-cameras="showDetektorZoneCameras"
        :detektor="odabraniDetektor"
        :open-dialog="openDetektorZoneDialog"
        ref="detektorZoneEditDialog"
        @userAction="handleDetektorZoneEditDialogUserAction">
      >
      </app-detektor-zone-edit-dialog>

      <app-confirm-action-dialog
        :open-dialog="openConfirmResetCamsDialog"
        :headline="$t('ui.confirm_reset_cams')"
        :text="$t('ui.really_reset_cams')"
        @userAction="resetDialogUserActionHandler"
      >
      </app-confirm-action-dialog>

      <app-confirm-action-dialog
        :open-dialog="openConfirmDeleteCamDialog"
        :headline="$t('ui.confirm_delete')"
        :text="$t('ui.really_delete_items')"
        @userAction="deleteCamDialogActionHandler"
      >
      </app-confirm-action-dialog>

    </v-row>
  </v-container>
</template>

<script>
import T1Mixin from '@/mixins/T1Mixin'
import AuthMixin from '@/mixins/AuthMixin'
import ConfigMixin from '@/mixins/ConfigMixin'
import CamerasMixin from '@/mixins/CamerasMixin'
import SupervisorMixin from '@/mixins/SupervisorMixin'

export default {
  name: 'CamerasCRUD',
  mixins: [ConfigMixin, SupervisorMixin, CamerasMixin, T1Mixin, AuthMixin],
  props: ['trafficController', 'stanjeSignala', 'stanjeDetektora'],
  data () {
    return {
      supervisorConfigKey: 'cameras_handler',
      configKey: 'CAMERAS_CONFIG',
      loading: false,
      openCameraDialog: false,
      openDeleteCameraDialog: false,
      openCameraZoneDialog: false,
      openConfirmResetCamsDialog: false,
      openConfirmDeleteCamDialog: false,
      cameras: [],
      editCamera: {},
      editZone: {},
      detectors: this.$lodash.range(1, 64),
      zones: this.$lodash.range(1, 64),
      limit: 999,
      camerasChanged: false,
      resetCamerasBackendLoading: false,
      detektorClickEnabled: true,
      openDetektorZoneDialog: false,
      odabraniDetektor: null,
      cameraPlay: false,
      showDetektorZoneCameras: [],
      cameraZoneTableHeaders: [
        {
          text: this.$t('ui.name'),
          align: 'left',
          value: 'camera.name'
        },
        {
          text: 'IP',
          align: 'left',
          value: 'camera.ip'
        },
        {
          text: this.$t('ui.logika'),
          align: 'left',
          value: 'zone.logika'
        },
        {
          text: this.$t('ui.zona'),
          align: 'left',
          value: 'zone.zone'
        },
        {
          text: this.$t('ui.detektor'),
          align: 'left',
          value: 'zone.detector'
        },
        {
          text: this.$t('ui.actions'),
          align: 'right',
          value: 'actions',
          sortable: false
        }
      ],
      cameraTableHeaders: [
        {
          text: this.$t('ui.code'),
          align: 'left',
          value: 'code'
        },
        {
          text: 'IP',
          align: 'left',
          value: 'ip'
        },
        {
          text: 'PORT',
          align: 'left',
          value: 'port'
        },
        {
          text: this.$t('ui.type'),
          align: 'left',
          value: 'product'
        },
        {
          text: 'XROADS URL',
          align: 'left',
          value: 'xroads_url'
        },
        {
          text: 'STREAM',
          align: 'center',
          value: 'snap',
          width: '320px'
        },
        {
          text: this.$t('ui.actions'),
          align: 'right',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  computed: {
    camerasZones () {
      let flatZones = []
      let camerasWithZones = this.$lodash.filter(this.cameras, (camera) => { if (camera.zones.length > 0) return camera })
      for (var i = 0; i < camerasWithZones.length; i++) {
        let camera = camerasWithZones[i]
        for (var n = 0; n < camera.zones.length; n++) {
          flatZones.push({camera: camera, zone: camera.zones[n]})
        }

      }
      return flatZones
    },
    resetCamerasBackendDisabled () {
      if (this.camerasChanged) return false

      return true
    },
    cameraPlayIcon () {
      if (this.cameraPlay) {
        return 'pause'
      }
      return 'play_arrow'
    },
  },
  methods: {
    init () {
      return new Promise((resolve, reject) => {
        this.getConfigByKey(this.configKey)
          .then((response) => {
            let cams = JSON.parse(response.data.value)
            let cam = null
            for (let i = 0; i < cams.length; i++) {
              cam = cams[i]

              for (let j = 0; j < cam.zones.length; j++) {
                // BACKWARDS COMPATIBILITY SA STAROM VERZIJOM KOJA NIJE IMALA LOGIKE
                if (Number.isInteger(cam.zones[j].zone)) {
                  cam.zones[j].zone = [cam.zones[j].zone]
                  cam.zones[j].logika = 'OR'
                }
              }
            }
            this.cameras = cams
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    uniqueId (prefix, idCounter) {
      var id = ++idCounter
      return prefix + id
    },
    openConfirmResetDialog () {
      this.openConfirmResetCamsDialog = true
    },
    resetDialogUserActionHandler (actionResult) {
      this.openConfirmResetCamsDialog = false
      if (actionResult === true) {
        this.resetCams()
      }
    },
    deleteCamDialogActionHandler (actionResult) {
      this.openConfirmDeleteCamDialog = false
      let self = this
      if (actionResult === true) {
        let cameraIndex = this.$lodash.findIndex(this.cameras, function (s) { return s.key === self.editCamera.key })
        if (cameraIndex > -1) {
          this.cameras.splice(cameraIndex, 1)
          // this.submit()
        }
      }
    },
    returnNewCamera () {
      var idCounter = 0

      for (var i = 0; i < this.cameras.length; i++) {
        var camera = this.cameras[i]
        var x = camera.key.split('_')
        idCounter = parseInt(x[x.length - 1])
      }

      return {
        key: `${this.uniqueId('camera_', idCounter)}`,
        name: '',
        code: '',
        description: '',
        ip: '',
        port: 80,
        snap_url: '',
        live_url: '',
        manufacturer: '',
        product: '',
        zones: [],
        stream_video: true,
        username: '',
        password: ''
      }
    },
    returnNewZone () {
      var idCounter = 0
      var seen = []

      for (var i = 0; i < this.cameras.length; i++) {
        var camera = this.cameras[i]

        for (var n = 0; n < camera.zones.length; n++) {
          var zona = camera.zones[n]
          if (zona) {
            var x = zona.key.split('_')
            idCounter = parseInt(x[x.length - 1])
            seen.push(idCounter)
          }
        }
      }

      idCounter = this.$lodash.max(seen)

      if (!idCounter) idCounter = 0

      return {
        key: `${this.uniqueId('zone_', idCounter)}`,
        detector: 1,
        zone: [1],
        logika: 'OR'
      }
    },
    addZone () {
      this.openCameraZoneDialog = true
      this.editZone = this.returnNewZone()
    },
    addCamera () {
      this.editCamera = this.returnNewCamera()
      this.openCameraDialog = true
    },
    doEditCamera (camera) {
      this.editCamera = JSON.parse(JSON.stringify(camera))
      // Object.assign(this.editCamera, camera)
      this.openCameraDialog = true
    },
    doEditZone (args) {
      this.openCameraZoneDialog = true
      this.editCamera = {}
      this.editZone = {}
      Object.assign(this.editCamera, args.camera)
      Object.assign(this.editZone, args.zone)
    },
    resetCams () {
      this.loading = true
      this.t1Write([this.t1.CPS, this.t1.WRITE, this.t1.RESET_CAMS, 3, 10])
        .then(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'success',
            text: this.$t('ui.save_success')
          })
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'error',
            text: this.$t('ui.error')
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    resetCamerasBackend () {
      this.resetCamerasBackendLoading = true
      this.startSupervisorJob('cameras_handler')
      .then(this.startSupervisorJob('cameras_feed_server'))
      .finally(() => {
        this.resetCamerasBackendLoading = false
      })
    },
    submit () {
      // STALNE NAJAVE CARD RESETIRA BACKEND ZA KAMERE
      // NAKON ŠTO SE SUBMITAJU PROMJENE KONFIGURACIJE KAMERA!

      this.loading = true
      var promises = []

      promises.push(this.createConfig({
        'key': 'CAMERAS_CONFIG',
        'value': this.cameras
      }))

      Promise.all(promises)
        .then(() => {
          // svi upiti su uspješno gotovi
          this.$EventBus.$emit('CameraPage/saved', true)
        })
        .catch(() => {
          this.$EventBus.$emit('AppSnackbar/show', {
            color: 'error',
            text: this.$t('ui.error')
          })
        })
        .finally(() => {
          // ne gasi loading jer stalne najave rade reload stranice!
          // this.loading = false
          this.camerasChanged = true
        })
    },
    handleCameraEditDialogUserAction (action) {

      this.openCameraDialog = false

      var self = this

      if (action === 'save') {
        var cameraIndex = this.$lodash.findIndex(this.cameras, function (s) { return s.key === self.editCamera.key })
        if (cameraIndex > -1) {
          this.cameras[cameraIndex] = this.editCamera
        } else {
          this.cameras.push(this.editCamera)
        }

        let _cameras = JSON.stringify(this.cameras)
        this.cameras = []
        this.cameras = JSON.parse(_cameras)

        // this.submit()
      } else if (action === 'delete') {
        this.openConfirmDeleteCamDialog = true
      }
    },
    handleCameraZoneEditDialogUserAction (args) {

      this.openCameraZoneDialog = false

      if (args.action === 'save') {

        let updated = false
        for (let i = 0; i < this.cameras.length; i++) {
          let camera = this.cameras[i]

          let zonaIndex = this.$lodash.findIndex(camera.zones, function (s) { return s.key === args.zone.key })
          if (zonaIndex > -1) {
            if (camera.key !== args.camera.key) {
              // promjenila se kamera, brisi zonu iz prijasnje kamere!
              camera.zones.splice(zonaIndex, 1)
            } else {
              this.cameras[i].zones[zonaIndex] = args.zone
              updated = true
            }
          }
        }

        if (!updated) {
          let cameraIndex = this.$lodash.findIndex(this.cameras, function (s) { return s.key === args.camera.key })
          if (cameraIndex > -1) {
            this.cameras[cameraIndex].zones.push(args.zone)
          }
        }

        let _cameras = JSON.stringify(this.cameras)
        this.cameras = []
        this.cameras = JSON.parse(_cameras)

        // this.submit()
        this.$EventBus.$emit('CameraPage/changed', this.cameras)
      } else if (args.action === 'delete') {
        for (let i = 0; i < this.cameras.length; i++) {
          let camera = this.cameras[i]
          let zonaIndex = this.$lodash.findIndex(camera.zones, function (s) { return s.key === args.zone.key })
          if (zonaIndex > -1) {
            camera.zones.splice(zonaIndex, 1)
          }
        }
        this.$EventBus.$emit('CameraPage/changed', this.cameras)
        // this.submit()
      }
    },
    detektorClickedHandler (detektorBr) {
      this.odabraniDetektor = detektorBr
      this.showDetektorZoneCameras = []
      let contextCameras = JSON.parse(JSON.stringify(this.cameras))
      let camera = null
      let zona = null

      for (let i = 0; i < contextCameras.length; i++) {
        camera = contextCameras[i]

        for (let j = 0; j < camera.zones.length; j++) {

          zona = camera.zones[j]

          if (zona.detector === detektorBr) {
            this.showDetektorZoneCameras.push({
              'code': camera.code,
              'name': camera.name,
              'ip': camera.ip,
              'key': camera.key,
              'zona': zona
            })
          }
        }
      }

      this.openDetektorZoneDialog = true
    },
    handleDetektorZoneEditDialogUserAction (args) {
      // klik na detektor na tlocrtu, user action
      if (args.action === 'cancel') {
        this.openDetektorZoneDialog = false
      } else if (args.action === 'save') {
        let cam = null
        let zona = null

        for (let i = 0; i < args.contextData.length; i++) {
          cam = args.contextData[i]
          zona = null

          if (cam.zona.key) {
            // ako zona ima key, napravi update postojece definicje
            for (let k = 0; k < this.cameras.length; k++) {
              for (let l = 0; l < this.cameras[k].zones.length; l++) {
                if (this.cameras[k].zones[l].key === cam.zona.key) {
                  this.cameras[k].zones[l].logika = cam.zona.logika
                  this.cameras[k].zones[l].zone = cam.zona.zone
                }
              }
            }

          } else {
            zona = this.returnNewZone()
            zona.detector = cam.zona.detector
            zona.logika = cam.zona.logika
            zona.zone = cam.zona.zone

            for (let k = 0; k < this.cameras.length; k++) {
              if (this.cameras[k].key === cam.key) {
                this.cameras[k].zones.push(zona)
              }
            }
          }
        }

        // Brisi zone ako ih ima
        for (let i = 0; i < args.deleteZones.length; i++) {
          cam = args.deleteZones[i]

          if (cam.zona.key) {
            let cameraIndex = this.$lodash.findIndex(this.cameras, function (s) { return s.code === cam.code })
            if (cameraIndex > -1) {
              let zoneIndex = this.$lodash.findIndex(this.cameras[cameraIndex].zones, function (s) { return s.key === cam.zona.key })
              if (zoneIndex > -1) {
                this.cameras[cameraIndex].zones.splice(zoneIndex, 1)
              }
            }
          }
        }

        this.openDetektorZoneDialog = false
        this.$EventBus.$emit('CameraPage/changed', this.cameras)
        // this.submit()
      }
    },
    toggleCameraPlay () {
      this.cameraPlay = !this.cameraPlay
    },
  },
  created () {
    this.loading = true
    this.init()
      .finally(() => {
        this.loading = false
        this.$EventBus.$emit('CameraPage/loaded', this.cameras)
      })
  }
}
</script>
