<template>
  <v-container fluid grid-list-md>

    <v-row>
      <v-col xs="12">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span class="text-xl-left">{{ this.$t('ui.izmjereno_stanje_snaga') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col xs="12" md="2">
        <v-combobox
          v-model="selectedSigs"
          :items="sigoviZaOdabrati"
          label="SIG"
          item-text="name"
          item-value="id"
          multiple
        ></v-combobox>
      </v-col>
    </v-row>

    <v-row v-for="sig in sigovi" :key="sig.id">
      <v-col v-for="grupa in sig.grupe" :key="grupa.grupa" xs="12" md="2">
        <app-kutija-snage-card :grupa-id="grupa.grupaId" :signali="grupa.signali" :snage="grupa.snage"></app-kutija-snage-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import ConfigMixin from '@/mixins/ConfigMixin'
import T1Mixin from '@/mixins/T1Mixin'

export default {
  name: 't1IzmjerenoStanjeSnagaPage',
  mixins: [validationMixin, ConfigMixin, T1Mixin],
  data () {
    return {
      loading: false,
      brojGrupa: 0,
      greske: [],
      selectedSigs: [],
      snageSignala: [],
      gain: [],
      grupeGain: [],
      defaultGain: [],
      getSnageGrupaZaSigId: null
    }
  },
  computed: {
    brojSigova () {
      return Math.ceil(this.brojGrupa/4)
    },
    sigoviZaOdabrati () {
      let s = []
      for (let i = 0; i < this.brojSigova; i++) {
        s.push({id: i, name: `SIG ${i+1}`})
      }

      return s
    },
    sigovi () {
      let s = []
      let odabraniSigovi = []
      let sig = {}
      let grupe = []
      let g = null

      for (let g = 1; g <= this.brojGrupa; g++) {
        grupe.push({'grupaId': g, snage: this.snageSignala[g-1], signali: this.stanjeSignala[g-1]})
      }

      for (let i = 0; i < this.brojSigova; i++) {
        sig = {id: i, name: `SIG ${i+1}`, grupe: []}
        for (let n = 1; n <=4; n++ ) {
          g = grupe.shift()
          if (g) {
            sig.grupe.push(g)
          }

        }
        s.push(sig)
      }

      for (let i = 0; i < s.length; i++) {
        for (let n = 0; n < this.selectedSigs.length; n++) {
          if (this.selectedSigs[n].id === s[i].id) {
            odabraniSigovi.push(s[i])
          }
        }
      }

      return odabraniSigovi
    },
    stanjeSignala () {
      return this.$store.getters['t1/stanjeSignala']
    }
  },
  methods: {
    init () {
      this.initT1Data()
    },
    initT1Data () {
      this.loading = true
      var promises = []

      promises.push(this.t1Write([this.t1.CPS, this.t1.READ, this.t1.BROJ_GRUPA]))

      Promise.all(promises)
        .then(([BROJ_GRUPA]) => {
          this.brojGrupa = BROJ_GRUPA[0]
          if (this.brojGrupa) {
            this.selectedSigs = this.sigoviZaOdabrati
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    getSnageSignala () {
      let promises = []
      for (let sig = 0; sig < this.brojSigova; sig++) {
        promises.push(this.t1Write([this.t1.SIG(sig), this.t1.READ, this.t1.SIG_SNAGE]))
      }

      Promise.all(promises)
        .then((answers) => {

          this.snageSignala = []

          for (let sig = 0; sig < this.brojSigova; sig++) {
            let c = this.$lodash.chunk(answers[sig], 4)
            let kcrv = c[0]
            let crv = c[1]
            let zut = c[2]
            let zel = c[3]

            this.snageSignala.push([kcrv[0], crv[0], zut[0], zel[0]])
            this.snageSignala.push([kcrv[1], crv[1], zut[1], zel[1]])
            this.snageSignala.push([kcrv[2], crv[2], zut[2], zel[2]])
            this.snageSignala.push([kcrv[3], crv[3], zut[3], zel[3]])
          }

        })
    },
    sigChange () {
      // this.getGainData()
    }
  },
  created () {
    this.init()
    this.getSnageSignalaId = setInterval(this.getSnageSignala, 900)
  },
  destroyed () {
    if (this.getSnageSignalaId) {
      clearInterval(this.getSnageSignalaId)
    }
  }
}
</script>
